import "./Homepage.scss";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../../components/footer/Footer";
import { Card, ICard, ILocationSlot } from "../../components/card/Card";
import { bemElement } from "utils/bem-class-names";
import { useHeader } from "providers/header-provider";
import { useGeolocation } from "providers/geolocation-provider";
import SwiperCore, {
  Navigation as Nav,
  Controller,
  Autoplay,
  Mousewheel
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Navigation from "components/navigation/Navigation";
import useWindowSize from "../../hooks/useWindowSize";
import { useAxios } from "providers/axios";
import { TPossibleAppointment } from "../../types";
import { getTime, getZonedDate } from "utils/date-time";
import { useUser } from "providers/user";
import { useCart } from "providers/cart-provider";
import { schedulingBufferMinutes, stateOptions } from "utils/constants";
import { clearCache } from "utils/cacheHelper";
import { Input } from "elements/input/Input";
import { COLORS } from "models/colors";
import { IconButton } from "elements/icon-button/IconButton";
import SelectWithFilter from "elements/selectWithFilter/SelectWithFilter";
import { gql, useQuery } from "@apollo/client";
import DraggableRadiusMap from "components/search-with-radius/DraggableRadiusMap";
import { VARIANTS } from "models/variants";
import { HomepageLocationItemFragment } from "./Homepage.generated";

export function useDebounce<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

// Haversine distance in meters
function getDistanceInMeters(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
): number {
  const R = 6371000; // Earth radius in meters
  function toRadians(val: number): number {
    return (val * Math.PI) / 180;
  }
  const lat1Rad = toRadians(lat1);
  const lat2Rad = toRadians(lat2);
  const deltaLat = toRadians(lat2 - lat1);
  const deltaLng = toRadians(lng2 - lng1);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1Rad) *
      Math.cos(lat2Rad) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

const baseClassName = "homepage";
const bem = bemElement(baseClassName);
SwiperCore.use([Nav, Autoplay, Mousewheel]);

interface GetLocationsQueryData {
  locations: HomepageLocationItemFragment[];
}

const Homepage = () => {
  const { calculateDistance } = useGeolocation();
  const { reset } = useHeader();
  const { user } = useUser();
  const { api } = useAxios();
  const { resetEverything: resetCart } = useCart();

  const [cards, setCards] = useState<
    Array<HomepageLocationItemFragment & ICard>
  >([]);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);

  const filteredStateFromLocalStorage =
    localStorage.getItem("filteredState") || "";
  const filteredMapFromLocalStorage = JSON.parse(
    localStorage.getItem("filteredMap") || "{}"
  );

  const [filteredState, setFilteredState] = useState<string>(
    filteredStateFromLocalStorage
  );
  const [enableRadiusFilter, setEnableRadiusFilter] = useState<boolean>(
    typeof filteredMapFromLocalStorage?.enabled === "boolean"
      ? filteredMapFromLocalStorage?.enabled
      : false
  );
  const [radiusCenter, setRadiusCenter] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: filteredMapFromLocalStorage?.lat || 37.7749,
    lng: filteredMapFromLocalStorage?.lng || -122.4194
  });
  const [radiusInMeters, setRadiusInMeters] = useState<number>(
    filteredMapFromLocalStorage?.radius || 3000
  );

  const { width } = useWindowSize();
  const slidesOnScreenCount = useMemo(() => width / 370, [width]);
  const autoplay = 5000;
  const isClearable =
    !!filteredStateFromLocalStorage ||
    !!filteredMapFromLocalStorage?.enabled ||
    !!search;

  const clearFilters = () => {
    setFilteredState("");
    setEnableRadiusFilter(false);
    setSearch("");
    setRadiusCenter({ lat: 37.7749, lng: -122.4194 });
    setRadiusInMeters(3000);
    localStorage.removeItem("filteredState");
    localStorage.removeItem("filteredMap");
  };

  const GET_LOCATIONS = gql`
    query GetLocations($where: LocationWhereInput) {
      locations(where: $where) {
        id
        cover_photo_url
        visible_on_homepage
        name
        address_display
        address_latitude
        address_longitude
        slug
        company {
          id
          slug
        }
      }
    }
  `;

  const { data: allLocationsData } = useQuery<GetLocationsQueryData>(
    GET_LOCATIONS,
    {
      variables: {
        where: {
          visible_on_homepage: { equals: true }
        }
      }
    }
  );

  function calculateBoundingBox(lat: number, lng: number, radius: number) {
    const earthRadiusKm = 6371;
    const radiusKm = radius / 1000;
    const latDelta = radiusKm / earthRadiusKm;
    const lngDelta =
      radiusKm / (earthRadiusKm * Math.cos((Math.PI * lat) / 180));

    return {
      minLat: lat - (latDelta * 180) / Math.PI,
      maxLat: lat + (latDelta * 180) / Math.PI,
      minLng: lng - (lngDelta * 180) / Math.PI,
      maxLng: lng + (lngDelta * 180) / Math.PI
    };
  }

  const boundingBox = calculateBoundingBox(
    radiusCenter.lat,
    radiusCenter.lng,
    radiusInMeters
  );

  const { data } = useQuery<GetLocationsQueryData>(GET_LOCATIONS, {
    variables: {
      where: {
        visible_on_homepage: { equals: true },
        name: { contains: debouncedSearch, mode: "insensitive" },
        ...(filteredState && { state: { equals: filteredState } }),
        ...(enableRadiusFilter && {
          AND: [
            { address_latitude: { gte: boundingBox.minLat } },
            { address_latitude: { lte: boundingBox.maxLat } },
            { address_longitude: { gte: boundingBox.minLng } },
            { address_longitude: { lte: boundingBox.maxLng } }
          ]
        })
      }
    }
  });

  const [filteredLocations, setFilteredLocations] = useState<
    HomepageLocationItemFragment[]
  >([]);

  useEffect(() => {
    reset();
    resetCart();
    clearCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data?.locations) {
      return;
    }

    if (!enableRadiusFilter) {
      setFilteredLocations(data.locations);
    } else {
      const fullyFiltered = data.locations.filter((loc) => {
        if (loc.address_latitude == null || loc.address_longitude == null) {
          return false;
        }
        const dist = getDistanceInMeters(
          radiusCenter.lat,
          radiusCenter.lng,
          loc.address_latitude,
          loc.address_longitude
        );
        return dist <= radiusInMeters;
      });
      setFilteredLocations(fullyFiltered);
    }
  }, [data, enableRadiusFilter, radiusCenter, radiusInMeters]);

  useEffect(() => {
    const rating = {
      value: "5.0",
      reviewsCount: 142
    };
    const currency = "$$$";

    async function fetchAndRenderLocations() {
      if (!filteredLocations) {
        return;
      }

      const cardPromises = filteredLocations.map(async (loc) => {
        let calculatedDistance = 0;
        if (loc.address_latitude != null && loc.address_longitude != null) {
          calculatedDistance = (await calculateDistance(
            loc.address_latitude,
            loc.address_longitude
          )) as number;
        }

        const card: HomepageLocationItemFragment & ICard = {
          ...loc,
          preview: loc.cover_photo_url || "",
          title: loc.name,
          address: loc.address_display || "",
          rating,
          distance: calculatedDistance,
          currency,
          slots: []
        };
        return card;
      });

      const resolvedCards = await Promise.all(cardPromises);
      setCards(resolvedCards);
      fetchPossibleAppointments(resolvedCards);
    }

    async function fetchPossibleAppointments(
      locations: (HomepageLocationItemFragment & ICard)[]
    ) {
      if (!locations.length) {
        return;
      }

      const startTime =
        new Date().getTime() +
        schedulingBufferMinutes(!!user?.impersonate_name) * 60 * 1000;

      const { data: appointmentsData } = await api.post(
        "/v1/possible-appointments",
        {
          locations: locations.map((card) => card.id),
          startTime,
          limit: 3
        }
      );

      const slots: ILocationSlot[][] = appointmentsData.map(
        (possibleAppointment: any) => {
          return possibleAppointment.appointments.map(
            (appointment: TPossibleAppointment, i: number) => {
              const appointmentDay = getZonedDate(
                appointment.start_time,
                possibleAppointment.location.timezone
              ).toLocaleDateString("en-US", {
                weekday: "long"
              });

              const currentDay = getZonedDate(
                new Date().toISOString(),
                possibleAppointment.location.timezone
              ).getDay();

              const appointmentDayIndex = getZonedDate(
                appointment.start_time,
                possibleAppointment.location.timezone
              ).getDay();

              // If tomorrow
              if (appointmentDayIndex === currentDay + 1) {
                if (i === 0) {
                  return {
                    time: "Available tomorrow",
                    available: true,
                    locationId: possibleAppointment.location.id
                  };
                }
                return {
                  available: false,
                  locationId: possibleAppointment.location.id
                };
              } else {
                if (appointmentDayIndex === currentDay) {
                  return {
                    time: getTime(
                      appointment.start_time,
                      possibleAppointment.location.timezone
                    ),
                    available: true,
                    locationId: possibleAppointment.location.id
                  };
                }
                return {
                  time: "Available " + appointmentDay,
                  available: true,
                  locationId: possibleAppointment.location.id
                };
              }
            }
          );
        }
      );

      const updatedCards = locations.map((location) => {
        const locationSlots = slots.find(
          (slot) => slot?.[0]?.locationId === location.id
        );

        if (!locationSlots?.length) {
          return {
            ...location,
            slots: [{ time: "Unavailable", available: true }],
            slotType: "low"
          };
        }

        return { ...location, slots: locationSlots };
      });

      setCards(updatedCards as (HomepageLocationItemFragment & ICard)[]);
    }

    fetchAndRenderLocations();
  }, [calculateDistance, filteredLocations, api, user?.impersonate_name]);

  // Adjust main layout classes
  useEffect(() => {
    document.getElementsByTagName("main")[0].classList.remove("max-w-1200px");
    return () =>
      document.getElementsByTagName("main")[0].classList.add("max-w-1200px");
  }, []);

  if (!cards) return null;

  return (
    <>
      <Helmet>
        <title>Pointment</title>
        <meta property="og:title" content="Pointment" />
        <meta name="theme-color" content="#F9F4F4" />
      </Helmet>
      <div>
        <div className={baseClassName}>
          <div className="w-full flex justify-center">
            <div
              className="max-w-1200px flex flex-wrap sm:flex-nowrap gap-2 sm:gap-4
            items-center w-full m-auto px-12px lg:px-24px"
            >
              <div className="w-full mt-2">
                <Input
                  label="Search by Name"
                  iconName="search"
                  clearable
                  type="text"
                  value={search}
                  onChange={(value) => setSearch(value as string)}
                  withoutErrorAndHint
                />
              </div>
              <div className="w-full flex items-center gap-4 min-h-[64px]">
                {filteredState ? (
                  <div
                    className="flex gap-1 items-center h-[48px] mt-2
                py-1 pr-1 pl-4 bg-[rgba(184,114,42,0.1)] rounded-[24px]"
                  >
                    <div className="text-[rgb(184,114,42)] text-[16px] font-[400]">
                      {filteredState}
                    </div>
                    <IconButton
                      color={COLORS.PRIMARY}
                      iconName="close_circle"
                      onClick={() => {
                        setFilteredState("");
                        localStorage.removeItem("filteredState");
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-full sm:w-[200px] mt-2">
                    <SelectWithFilter
                      label="State"
                      options={stateOptions}
                      value={filteredState}
                      onChange={(value) => {
                        setFilteredState(value as string);
                        localStorage.setItem("filteredState", value as string);
                      }}
                      withoutErrorAndHint
                    />
                  </div>
                )}
                <IconButton
                  className="mt-2 !w-12 !h-12 min-w-[48px] !border-[rgba(204,199,192,1)]"
                  color={enableRadiusFilter ? COLORS.PRIMARY : COLORS.SECONDARY}
                  iconName="location"
                  variant={
                    enableRadiusFilter ? VARIANTS.FILLED : VARIANTS.OUTLINED
                  }
                  onClick={() => {
                    setEnableRadiusFilter((prev) => {
                      localStorage.setItem(
                        "filteredMap",
                        JSON.stringify({
                          ...JSON.parse(
                            localStorage.getItem("filteredMap") || "{}"
                          ),
                          enabled: !prev
                        })
                      );
                      return !prev;
                    });
                  }}
                />
                {isClearable && (
                  <div
                    className="mt-2 cursor-pointer whitespace-nowrap"
                    aria-hidden
                    onClick={clearFilters}
                  >
                    Clear All
                  </div>
                )}
              </div>
            </div>
          </div>

          {enableRadiusFilter && (
            <DraggableRadiusMap
              initialCenter={radiusCenter}
              initialRadius={radiusInMeters}
              onCircleChange={(info) => {
                setRadiusCenter({ lat: info.lat, lng: info.lng });
                setRadiusInMeters(info.radius);
                localStorage.setItem(
                  "filteredMap",
                  JSON.stringify({
                    lat: info.lat,
                    lng: info.lng,
                    radius: info.radius,
                    enabled: enableRadiusFilter
                  })
                );
              }}
              allLocationsData={allLocationsData}
            />
          )}

          {!enableRadiusFilter && (
            <img
              src={require("assets/Hero_image.png")}
              alt="hero"
              style={{ paddingTop: user?.impersonate_name ? "30px" : "0px" }}
              className={bem("image")}
            />
          )}
          {!enableRadiusFilter && (
            <div className={bem("title")}>Nail Salons</div>
          )}
          {!enableRadiusFilter && (
            <div className={bem("caption")}>
              Your best nail experience is in your hands
            </div>
          )}

          <div
            className={
              enableRadiusFilter
                ? "w-full flex justify-center lg:block lg:absolute lg:bottom-[52px] lg:left-0 lg:z-20"
                : "w-full flex justify-center lg:block"
            }
          >
            <div
              className={
                enableRadiusFilter
                  ? bem("companies-list") + " mt-3"
                  : bem("companies-list")
              }
            >
              {cards.map((companyCard, i) => (
                <Link
                  className={bem("mobile-location")}
                  to={`/company/${companyCard.company.slug}/location/${companyCard.slug}`}
                  key={companyCard.id || i}
                >
                  <Card value={companyCard} className="company-card" />
                </Link>
              ))}
              <Swiper
                className="companies-slider"
                autoHeight
                autoplay={{ delay: autoplay }}
                spaceBetween={16}
                loop={cards.length > slidesOnScreenCount + 3}
                loopedSlides={3}
                slidesPerView={"auto"}
                navigation={{
                  nextEl: ".right-navigation-button",
                  prevEl: ".left-navigation-button"
                }}
                mousewheel
                modules={[Controller]}
                centerInsufficientSlides
              >
                {cards.map((companyCard, i) => (
                  <SwiperSlide key={companyCard.id || i}>
                    <Link
                      className={bem("slider-location")}
                      to={`/company/${companyCard.company.slug}/location/${companyCard.slug}`}
                    >
                      <Card value={companyCard} />
                    </Link>
                  </SwiperSlide>
                ))}
                <Navigation
                  className="companies-navigation"
                  text={`Nail Salons (${cards.length})`}
                  enableRadiusFilter={enableRadiusFilter}
                  rightButtonConfig={{
                    className: "right-navigation-button"
                  }}
                  leftButtonConfig={{
                    className: "left-navigation-button"
                  }}
                />
              </Swiper>
            </div>
          </div>
          <Footer className="absolute bottom-0" />
        </div>
      </div>
    </>
  );
};

export default Homepage;
