export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
  JSON: any;
};

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  postal_code?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type AffectedRowsOutput = {
  __typename?: "AffectedRowsOutput";
  count: Scalars["Int"];
};

export type AggregateAmenity = {
  __typename?: "AggregateAmenity";
  _count?: Maybe<AmenityCountAggregate>;
  _max?: Maybe<AmenityMaxAggregate>;
  _min?: Maybe<AmenityMinAggregate>;
};

export type AggregateAppointment = {
  __typename?: "AggregateAppointment";
  _avg?: Maybe<AppointmentAvgAggregate>;
  _count?: Maybe<AppointmentCountAggregate>;
  _max?: Maybe<AppointmentMaxAggregate>;
  _min?: Maybe<AppointmentMinAggregate>;
  _sum?: Maybe<AppointmentSumAggregate>;
};

export type AggregateAppointmentService = {
  __typename?: "AggregateAppointmentService";
  _avg?: Maybe<AppointmentServiceAvgAggregate>;
  _count?: Maybe<AppointmentServiceCountAggregate>;
  _max?: Maybe<AppointmentServiceMaxAggregate>;
  _min?: Maybe<AppointmentServiceMinAggregate>;
  _sum?: Maybe<AppointmentServiceSumAggregate>;
};

export type AggregateArtist = {
  __typename?: "AggregateArtist";
  _avg?: Maybe<ArtistAvgAggregate>;
  _count?: Maybe<ArtistCountAggregate>;
  _max?: Maybe<ArtistMaxAggregate>;
  _min?: Maybe<ArtistMinAggregate>;
  _sum?: Maybe<ArtistSumAggregate>;
};

export type AggregateArtistAvailability = {
  __typename?: "AggregateArtistAvailability";
  _count?: Maybe<ArtistAvailabilityCountAggregate>;
  _max?: Maybe<ArtistAvailabilityMaxAggregate>;
  _min?: Maybe<ArtistAvailabilityMinAggregate>;
};

export type AggregateArtistInstagram = {
  __typename?: "AggregateArtistInstagram";
  _avg?: Maybe<ArtistInstagramAvgAggregate>;
  _count?: Maybe<ArtistInstagramCountAggregate>;
  _max?: Maybe<ArtistInstagramMaxAggregate>;
  _min?: Maybe<ArtistInstagramMinAggregate>;
  _sum?: Maybe<ArtistInstagramSumAggregate>;
};

export type AggregateArtistServiceExclusion = {
  __typename?: "AggregateArtistServiceExclusion";
  _count?: Maybe<ArtistServiceExclusionCountAggregate>;
  _max?: Maybe<ArtistServiceExclusionMaxAggregate>;
  _min?: Maybe<ArtistServiceExclusionMinAggregate>;
};

export type AggregateAvailability = {
  __typename?: "AggregateAvailability";
  _count?: Maybe<AvailabilityCountAggregate>;
  _max?: Maybe<AvailabilityMaxAggregate>;
  _min?: Maybe<AvailabilityMinAggregate>;
};

export type AggregateCompany = {
  __typename?: "AggregateCompany";
  _avg?: Maybe<CompanyAvgAggregate>;
  _count?: Maybe<CompanyCountAggregate>;
  _max?: Maybe<CompanyMaxAggregate>;
  _min?: Maybe<CompanyMinAggregate>;
  _sum?: Maybe<CompanySumAggregate>;
};

export type AggregateCompanyAmenity = {
  __typename?: "AggregateCompanyAmenity";
  _count?: Maybe<CompanyAmenityCountAggregate>;
  _max?: Maybe<CompanyAmenityMaxAggregate>;
  _min?: Maybe<CompanyAmenityMinAggregate>;
};

export type AggregateCompanyPaymentMethod = {
  __typename?: "AggregateCompanyPaymentMethod";
  _count?: Maybe<CompanyPaymentMethodCountAggregate>;
  _max?: Maybe<CompanyPaymentMethodMaxAggregate>;
  _min?: Maybe<CompanyPaymentMethodMinAggregate>;
};

export type AggregateCompanyUserDescription = {
  __typename?: "AggregateCompanyUserDescription";
  _count?: Maybe<CompanyUserDescriptionCountAggregate>;
  _max?: Maybe<CompanyUserDescriptionMaxAggregate>;
  _min?: Maybe<CompanyUserDescriptionMinAggregate>;
};

export type AggregateFacility = {
  __typename?: "AggregateFacility";
  _avg?: Maybe<FacilityAvgAggregate>;
  _count?: Maybe<FacilityCountAggregate>;
  _max?: Maybe<FacilityMaxAggregate>;
  _min?: Maybe<FacilityMinAggregate>;
  _sum?: Maybe<FacilitySumAggregate>;
};

export type AggregateFacilityService = {
  __typename?: "AggregateFacilityService";
  _count?: Maybe<FacilityServiceCountAggregate>;
  _max?: Maybe<FacilityServiceMaxAggregate>;
  _min?: Maybe<FacilityServiceMinAggregate>;
};

export type AggregateFavoriteArtist = {
  __typename?: "AggregateFavoriteArtist";
  _count?: Maybe<FavoriteArtistCountAggregate>;
  _max?: Maybe<FavoriteArtistMaxAggregate>;
  _min?: Maybe<FavoriteArtistMinAggregate>;
};

export type AggregateGroupAvailability = {
  __typename?: "AggregateGroupAvailability";
  _count?: Maybe<GroupAvailabilityCountAggregate>;
  _max?: Maybe<GroupAvailabilityMaxAggregate>;
  _min?: Maybe<GroupAvailabilityMinAggregate>;
};

export type AggregateLocation = {
  __typename?: "AggregateLocation";
  _avg?: Maybe<LocationAvgAggregate>;
  _count?: Maybe<LocationCountAggregate>;
  _max?: Maybe<LocationMaxAggregate>;
  _min?: Maybe<LocationMinAggregate>;
  _sum?: Maybe<LocationSumAggregate>;
};

export type AggregateLocationAmenity = {
  __typename?: "AggregateLocationAmenity";
  _count?: Maybe<LocationAmenityCountAggregate>;
  _max?: Maybe<LocationAmenityMaxAggregate>;
  _min?: Maybe<LocationAmenityMinAggregate>;
};

export type AggregateMenu = {
  __typename?: "AggregateMenu";
  _count?: Maybe<MenuCountAggregate>;
  _max?: Maybe<MenuMaxAggregate>;
  _min?: Maybe<MenuMinAggregate>;
};

export type AggregateMenuSection = {
  __typename?: "AggregateMenuSection";
  _count?: Maybe<MenuSectionCountAggregate>;
  _max?: Maybe<MenuSectionMaxAggregate>;
  _min?: Maybe<MenuSectionMinAggregate>;
};

export type AggregateMenuSectionService = {
  __typename?: "AggregateMenuSectionService";
  _count?: Maybe<MenuSectionServiceCountAggregate>;
  _max?: Maybe<MenuSectionServiceMaxAggregate>;
  _min?: Maybe<MenuSectionServiceMinAggregate>;
};

export type AggregateNotification = {
  __typename?: "AggregateNotification";
  _count?: Maybe<NotificationCountAggregate>;
  _max?: Maybe<NotificationMaxAggregate>;
  _min?: Maybe<NotificationMinAggregate>;
};

export type AggregateOrder = {
  __typename?: "AggregateOrder";
  _avg?: Maybe<OrderAvgAggregate>;
  _count?: Maybe<OrderCountAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
};

export type AggregatePlatform = {
  __typename?: "AggregatePlatform";
  _avg?: Maybe<PlatformAvgAggregate>;
  _count?: Maybe<PlatformCountAggregate>;
  _max?: Maybe<PlatformMaxAggregate>;
  _min?: Maybe<PlatformMinAggregate>;
  _sum?: Maybe<PlatformSumAggregate>;
};

export type AggregateRefund = {
  __typename?: "AggregateRefund";
  _avg?: Maybe<RefundAvgAggregate>;
  _count?: Maybe<RefundCountAggregate>;
  _max?: Maybe<RefundMaxAggregate>;
  _min?: Maybe<RefundMinAggregate>;
  _sum?: Maybe<RefundSumAggregate>;
};

export type AggregateReminderSetting = {
  __typename?: "AggregateReminderSetting";
  _count?: Maybe<ReminderSettingCountAggregate>;
  _max?: Maybe<ReminderSettingMaxAggregate>;
  _min?: Maybe<ReminderSettingMinAggregate>;
};

export type AggregateRole = {
  __typename?: "AggregateRole";
  _count?: Maybe<RoleCountAggregate>;
  _max?: Maybe<RoleMaxAggregate>;
  _min?: Maybe<RoleMinAggregate>;
};

export type AggregateService = {
  __typename?: "AggregateService";
  _avg?: Maybe<ServiceAvgAggregate>;
  _count?: Maybe<ServiceCountAggregate>;
  _max?: Maybe<ServiceMaxAggregate>;
  _min?: Maybe<ServiceMinAggregate>;
  _sum?: Maybe<ServiceSumAggregate>;
};

export type AggregateServiceAddOn = {
  __typename?: "AggregateServiceAddOn";
  _count?: Maybe<ServiceAddOnCountAggregate>;
  _max?: Maybe<ServiceAddOnMaxAggregate>;
  _min?: Maybe<ServiceAddOnMinAggregate>;
};

export type AggregateStripePaymentMethod = {
  __typename?: "AggregateStripePaymentMethod";
  _avg?: Maybe<StripePaymentMethodAvgAggregate>;
  _count?: Maybe<StripePaymentMethodCountAggregate>;
  _max?: Maybe<StripePaymentMethodMaxAggregate>;
  _min?: Maybe<StripePaymentMethodMinAggregate>;
  _sum?: Maybe<StripePaymentMethodSumAggregate>;
};

export type AggregateTransaction = {
  __typename?: "AggregateTransaction";
  _avg?: Maybe<TransactionAvgAggregate>;
  _count?: Maybe<TransactionCountAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
};

export type AggregateTxAddon = {
  __typename?: "AggregateTxAddon";
  _avg?: Maybe<TxAddonAvgAggregate>;
  _count?: Maybe<TxAddonCountAggregate>;
  _max?: Maybe<TxAddonMaxAggregate>;
  _min?: Maybe<TxAddonMinAggregate>;
  _sum?: Maybe<TxAddonSumAggregate>;
};

export type AggregateTxCancellationPolicy = {
  __typename?: "AggregateTxCancellationPolicy";
  _avg?: Maybe<TxCancellationPolicyAvgAggregate>;
  _count?: Maybe<TxCancellationPolicyCountAggregate>;
  _max?: Maybe<TxCancellationPolicyMaxAggregate>;
  _min?: Maybe<TxCancellationPolicyMinAggregate>;
  _sum?: Maybe<TxCancellationPolicySumAggregate>;
};

export type AggregateTxItem = {
  __typename?: "AggregateTxItem";
  _avg?: Maybe<TxItemAvgAggregate>;
  _count?: Maybe<TxItemCountAggregate>;
  _max?: Maybe<TxItemMaxAggregate>;
  _min?: Maybe<TxItemMinAggregate>;
  _sum?: Maybe<TxItemSumAggregate>;
};

export type AggregateTxService = {
  __typename?: "AggregateTxService";
  _avg?: Maybe<TxServiceAvgAggregate>;
  _count?: Maybe<TxServiceCountAggregate>;
  _max?: Maybe<TxServiceMaxAggregate>;
  _min?: Maybe<TxServiceMinAggregate>;
  _sum?: Maybe<TxServiceSumAggregate>;
};

export type AggregateUser = {
  __typename?: "AggregateUser";
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type AggregateUserFavoriteLocation = {
  __typename?: "AggregateUserFavoriteLocation";
  _count?: Maybe<UserFavoriteLocationCountAggregate>;
  _max?: Maybe<UserFavoriteLocationMaxAggregate>;
  _min?: Maybe<UserFavoriteLocationMinAggregate>;
};

export type Amenity = {
  __typename?: "Amenity";
  _count?: Maybe<AmenityCount>;
  companies: Array<CompanyAmenity>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  locations: Array<LocationAmenity>;
  logo_url?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type AmenityCompaniesArgs = {
  cursor?: InputMaybe<CompanyAmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyAmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type AmenityLocationsArgs = {
  cursor?: InputMaybe<LocationAmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationAmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type AmenityCount = {
  __typename?: "AmenityCount";
  companies: Scalars["Int"];
  locations: Scalars["Int"];
};

export type AmenityCountCompaniesArgs = {
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type AmenityCountLocationsArgs = {
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type AmenityCountAggregate = {
  __typename?: "AmenityCountAggregate";
  _all: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  logo_url: Scalars["Int"];
  name: Scalars["Int"];
  type: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type AmenityCountOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  logo_url?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type AmenityCreateInput = {
  companies?: InputMaybe<CompanyAmenityCreateNestedManyWithoutAmenityInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  locations?: InputMaybe<LocationAmenityCreateNestedManyWithoutAmenityInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AmenityCreateManyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  logo_url?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AmenityCreateNestedOneWithoutCompaniesInput = {
  connect?: InputMaybe<AmenityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AmenityCreateOrConnectWithoutCompaniesInput>;
  create?: InputMaybe<AmenityCreateWithoutCompaniesInput>;
};

export type AmenityCreateNestedOneWithoutLocationsInput = {
  connect?: InputMaybe<AmenityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AmenityCreateOrConnectWithoutLocationsInput>;
  create?: InputMaybe<AmenityCreateWithoutLocationsInput>;
};

export type AmenityCreateOrConnectWithoutCompaniesInput = {
  create: AmenityCreateWithoutCompaniesInput;
  where: AmenityWhereUniqueInput;
};

export type AmenityCreateOrConnectWithoutLocationsInput = {
  create: AmenityCreateWithoutLocationsInput;
  where: AmenityWhereUniqueInput;
};

export type AmenityCreateWithoutCompaniesInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  locations?: InputMaybe<LocationAmenityCreateNestedManyWithoutAmenityInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AmenityCreateWithoutLocationsInput = {
  companies?: InputMaybe<CompanyAmenityCreateNestedManyWithoutAmenityInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  logo_url?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export enum AmenityEnum {
  AsianOwned = "Asian_Owned",
  BlackOwned = "Black_Owned",
  CashTipsOnly = "Cash_Tips_Only",
  DogFriendly = "Dog_Friendly",
  FreeWifi = "Free_Wifi",
  GoodForGroups = "Good_For_Groups",
  HandicapAccessible = "Handicap_Accessible",
  KidFriendly = "Kid_Friendly",
  LgbtqOwned = "LGBTQ_Owned",
  MaskedWorkforce = "Masked_Workforce",
  NaturalNailsOnly = "Natural_Nails_Only",
  NonToxic = "Non_Toxic",
  PrivateParties = "Private_Parties",
  Tv = "TV",
  VeteranOwned = "Veteran_Owned",
  Waterless = "Waterless",
  WomanOwned = "Woman_Owned"
}

export type AmenityGroupBy = {
  __typename?: "AmenityGroupBy";
  _count?: Maybe<AmenityCountAggregate>;
  _max?: Maybe<AmenityMaxAggregate>;
  _min?: Maybe<AmenityMinAggregate>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  logo_url?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type AmenityMaxAggregate = {
  __typename?: "AmenityMaxAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  logo_url?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type AmenityMaxOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  logo_url?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type AmenityMinAggregate = {
  __typename?: "AmenityMinAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  logo_url?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type AmenityMinOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  logo_url?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type AmenityOrderByWithAggregationInput = {
  _count?: InputMaybe<AmenityCountOrderByAggregateInput>;
  _max?: InputMaybe<AmenityMaxOrderByAggregateInput>;
  _min?: InputMaybe<AmenityMinOrderByAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  logo_url?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type AmenityOrderByWithRelationInput = {
  companies?: InputMaybe<CompanyAmenityOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locations?: InputMaybe<LocationAmenityOrderByRelationAggregateInput>;
  logo_url?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type AmenityRelationFilter = {
  is?: InputMaybe<AmenityWhereInput>;
  isNot?: InputMaybe<AmenityWhereInput>;
};

export enum AmenityScalarFieldEnum {
  CreatedAt = "created_at",
  Id = "id",
  LogoUrl = "logo_url",
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at"
}

export type AmenityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AmenityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AmenityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AmenityScalarWhereWithAggregatesInput>>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  logo_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<StringNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type AmenityUpdateInput = {
  companies?: InputMaybe<CompanyAmenityUpdateManyWithoutAmenityNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationAmenityUpdateManyWithoutAmenityNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AmenityUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AmenityUpdateOneRequiredWithoutCompaniesNestedInput = {
  connect?: InputMaybe<AmenityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AmenityCreateOrConnectWithoutCompaniesInput>;
  create?: InputMaybe<AmenityCreateWithoutCompaniesInput>;
  update?: InputMaybe<AmenityUpdateWithoutCompaniesInput>;
  upsert?: InputMaybe<AmenityUpsertWithoutCompaniesInput>;
};

export type AmenityUpdateOneRequiredWithoutLocationsNestedInput = {
  connect?: InputMaybe<AmenityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AmenityCreateOrConnectWithoutLocationsInput>;
  create?: InputMaybe<AmenityCreateWithoutLocationsInput>;
  update?: InputMaybe<AmenityUpdateWithoutLocationsInput>;
  upsert?: InputMaybe<AmenityUpsertWithoutLocationsInput>;
};

export type AmenityUpdateWithoutCompaniesInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationAmenityUpdateManyWithoutAmenityNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AmenityUpdateWithoutLocationsInput = {
  companies?: InputMaybe<CompanyAmenityUpdateManyWithoutAmenityNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AmenityUpsertWithoutCompaniesInput = {
  create: AmenityCreateWithoutCompaniesInput;
  update: AmenityUpdateWithoutCompaniesInput;
};

export type AmenityUpsertWithoutLocationsInput = {
  create: AmenityCreateWithoutLocationsInput;
  update: AmenityUpdateWithoutLocationsInput;
};

export type AmenityWhereInput = {
  AND?: InputMaybe<Array<AmenityWhereInput>>;
  NOT?: InputMaybe<Array<AmenityWhereInput>>;
  OR?: InputMaybe<Array<AmenityWhereInput>>;
  companies?: InputMaybe<CompanyAmenityListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  locations?: InputMaybe<LocationAmenityListRelationFilter>;
  logo_url?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type AmenityWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type Appointment = {
  __typename?: "Appointment";
  Notification: Array<Notification>;
  Order?: Maybe<Order>;
  Refund: Array<Refund>;
  _count?: Maybe<AppointmentCount>;
  appointment_services: Array<AppointmentService>;
  cancellation_info?: Maybe<Scalars["JSON"]>;
  cancelled_at?: Maybe<Scalars["DateTime"]>;
  cancelled_type: CancellationType;
  checked_in_at?: Maybe<Scalars["DateTime"]>;
  completed_at?: Maybe<Scalars["DateTime"]>;
  confirmed_at?: Maybe<Scalars["DateTime"]>;
  created_at: Scalars["DateTime"];
  end_time: Scalars["DateTime"];
  full_amount?: Maybe<Scalars["Float"]>;
  id: Scalars["String"];
  inserted_by: User;
  inserted_by_id: Scalars["String"];
  is_blocked_time: Scalars["Boolean"];
  location: Location;
  location_id: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  reminded_at?: Maybe<Scalars["DateTime"]>;
  rescheduled: Array<Appointment>;
  rescheduled_at?: Maybe<Scalars["DateTime"]>;
  rescheduled_from?: Maybe<Appointment>;
  rescheduled_from_id?: Maybe<Scalars["String"]>;
  start_time: Scalars["DateTime"];
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
};

export type AppointmentNotificationArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<NotificationWhereInput>;
};

export type AppointmentRefundArgs = {
  cursor?: InputMaybe<RefundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RefundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RefundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RefundWhereInput>;
};

export type AppointmentAppointment_ServicesArgs = {
  cursor?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type AppointmentRescheduledArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentAvgAggregate = {
  __typename?: "AppointmentAvgAggregate";
  full_amount?: Maybe<Scalars["Float"]>;
};

export type AppointmentAvgOrderByAggregateInput = {
  full_amount?: InputMaybe<SortOrder>;
};

export type AppointmentCount = {
  __typename?: "AppointmentCount";
  Notification: Scalars["Int"];
  Refund: Scalars["Int"];
  appointment_services: Scalars["Int"];
  rescheduled: Scalars["Int"];
};

export type AppointmentCountNotificationArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};

export type AppointmentCountRefundArgs = {
  where?: InputMaybe<RefundWhereInput>;
};

export type AppointmentCountAppointment_ServicesArgs = {
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type AppointmentCountRescheduledArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentCountAggregate = {
  __typename?: "AppointmentCountAggregate";
  _all: Scalars["Int"];
  cancellation_info: Scalars["Int"];
  cancelled_at: Scalars["Int"];
  cancelled_type: Scalars["Int"];
  checked_in_at: Scalars["Int"];
  completed_at: Scalars["Int"];
  confirmed_at: Scalars["Int"];
  created_at: Scalars["Int"];
  end_time: Scalars["Int"];
  full_amount: Scalars["Int"];
  id: Scalars["Int"];
  inserted_by_id: Scalars["Int"];
  is_blocked_time: Scalars["Int"];
  location_id: Scalars["Int"];
  notes: Scalars["Int"];
  reminded_at: Scalars["Int"];
  rescheduled_at: Scalars["Int"];
  rescheduled_from_id: Scalars["Int"];
  start_time: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type AppointmentCountOrderByAggregateInput = {
  cancellation_info?: InputMaybe<SortOrder>;
  cancelled_at?: InputMaybe<SortOrder>;
  cancelled_type?: InputMaybe<SortOrder>;
  checked_in_at?: InputMaybe<SortOrder>;
  completed_at?: InputMaybe<SortOrder>;
  confirmed_at?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inserted_by_id?: InputMaybe<SortOrder>;
  is_blocked_time?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  reminded_at?: InputMaybe<SortOrder>;
  rescheduled_at?: InputMaybe<SortOrder>;
  rescheduled_from_id?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type AppointmentCreateInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  Order?: InputMaybe<OrderCreateNestedOneWithoutAppointmentInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutAppointmentInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAppointmentInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutBooked_AppointmentsInput;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled?: InputMaybe<AppointmentCreateNestedManyWithoutRescheduled_FromInput>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from?: InputMaybe<AppointmentCreateNestedOneWithoutRescheduledInput>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutAppointmentsInput;
};

export type AppointmentCreateManyInput = {
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by_id: Scalars["String"];
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location_id: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from_id?: InputMaybe<Scalars["String"]>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type AppointmentCreateManyInserted_ByInput = {
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location_id: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from_id?: InputMaybe<Scalars["String"]>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type AppointmentCreateManyInserted_ByInputEnvelope = {
  data: Array<AppointmentCreateManyInserted_ByInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AppointmentCreateManyLocationInput = {
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by_id: Scalars["String"];
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from_id?: InputMaybe<Scalars["String"]>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type AppointmentCreateManyLocationInputEnvelope = {
  data: Array<AppointmentCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AppointmentCreateManyRescheduled_FromInput = {
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by_id: Scalars["String"];
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location_id: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type AppointmentCreateManyRescheduled_FromInputEnvelope = {
  data: Array<AppointmentCreateManyRescheduled_FromInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AppointmentCreateManyUserInput = {
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by_id: Scalars["String"];
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location_id: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from_id?: InputMaybe<Scalars["String"]>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AppointmentCreateManyUserInputEnvelope = {
  data: Array<AppointmentCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AppointmentCreateNestedManyWithoutInserted_ByInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentCreateOrConnectWithoutInserted_ByInput>
  >;
  create?: InputMaybe<Array<AppointmentCreateWithoutInserted_ByInput>>;
  createMany?: InputMaybe<AppointmentCreateManyInserted_ByInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<AppointmentCreateWithoutLocationInput>>;
  createMany?: InputMaybe<AppointmentCreateManyLocationInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutRescheduled_FromInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentCreateOrConnectWithoutRescheduled_FromInput>
  >;
  create?: InputMaybe<Array<AppointmentCreateWithoutRescheduled_FromInput>>;
  createMany?: InputMaybe<AppointmentCreateManyRescheduled_FromInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<AppointmentCreateWithoutUserInput>>;
  createMany?: InputMaybe<AppointmentCreateManyUserInputEnvelope>;
};

export type AppointmentCreateNestedOneWithoutAppointment_ServicesInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutAppointment_ServicesInput>;
  create?: InputMaybe<AppointmentCreateWithoutAppointment_ServicesInput>;
};

export type AppointmentCreateNestedOneWithoutNotificationInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutNotificationInput>;
  create?: InputMaybe<AppointmentCreateWithoutNotificationInput>;
};

export type AppointmentCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<AppointmentCreateWithoutOrderInput>;
};

export type AppointmentCreateNestedOneWithoutRefundInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutRefundInput>;
  create?: InputMaybe<AppointmentCreateWithoutRefundInput>;
};

export type AppointmentCreateNestedOneWithoutRescheduledInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutRescheduledInput>;
  create?: InputMaybe<AppointmentCreateWithoutRescheduledInput>;
};

export type AppointmentCreateOrConnectWithoutAppointment_ServicesInput = {
  create: AppointmentCreateWithoutAppointment_ServicesInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutInserted_ByInput = {
  create: AppointmentCreateWithoutInserted_ByInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutLocationInput = {
  create: AppointmentCreateWithoutLocationInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutNotificationInput = {
  create: AppointmentCreateWithoutNotificationInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutOrderInput = {
  create: AppointmentCreateWithoutOrderInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutRefundInput = {
  create: AppointmentCreateWithoutRefundInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutRescheduledInput = {
  create: AppointmentCreateWithoutRescheduledInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutRescheduled_FromInput = {
  create: AppointmentCreateWithoutRescheduled_FromInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutUserInput = {
  create: AppointmentCreateWithoutUserInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateWithoutAppointment_ServicesInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  Order?: InputMaybe<OrderCreateNestedOneWithoutAppointmentInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutAppointmentInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutBooked_AppointmentsInput;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled?: InputMaybe<AppointmentCreateNestedManyWithoutRescheduled_FromInput>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from?: InputMaybe<AppointmentCreateNestedOneWithoutRescheduledInput>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutAppointmentsInput;
};

export type AppointmentCreateWithoutInserted_ByInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  Order?: InputMaybe<OrderCreateNestedOneWithoutAppointmentInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutAppointmentInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAppointmentInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled?: InputMaybe<AppointmentCreateNestedManyWithoutRescheduled_FromInput>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from?: InputMaybe<AppointmentCreateNestedOneWithoutRescheduledInput>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutAppointmentsInput;
};

export type AppointmentCreateWithoutLocationInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  Order?: InputMaybe<OrderCreateNestedOneWithoutAppointmentInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutAppointmentInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAppointmentInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutBooked_AppointmentsInput;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled?: InputMaybe<AppointmentCreateNestedManyWithoutRescheduled_FromInput>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from?: InputMaybe<AppointmentCreateNestedOneWithoutRescheduledInput>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutAppointmentsInput;
};

export type AppointmentCreateWithoutNotificationInput = {
  Order?: InputMaybe<OrderCreateNestedOneWithoutAppointmentInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutAppointmentInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAppointmentInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutBooked_AppointmentsInput;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled?: InputMaybe<AppointmentCreateNestedManyWithoutRescheduled_FromInput>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from?: InputMaybe<AppointmentCreateNestedOneWithoutRescheduledInput>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutAppointmentsInput;
};

export type AppointmentCreateWithoutOrderInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutAppointmentInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAppointmentInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutBooked_AppointmentsInput;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled?: InputMaybe<AppointmentCreateNestedManyWithoutRescheduled_FromInput>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from?: InputMaybe<AppointmentCreateNestedOneWithoutRescheduledInput>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutAppointmentsInput;
};

export type AppointmentCreateWithoutRefundInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  Order?: InputMaybe<OrderCreateNestedOneWithoutAppointmentInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAppointmentInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutBooked_AppointmentsInput;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled?: InputMaybe<AppointmentCreateNestedManyWithoutRescheduled_FromInput>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from?: InputMaybe<AppointmentCreateNestedOneWithoutRescheduledInput>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutAppointmentsInput;
};

export type AppointmentCreateWithoutRescheduledInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  Order?: InputMaybe<OrderCreateNestedOneWithoutAppointmentInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutAppointmentInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAppointmentInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutBooked_AppointmentsInput;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from?: InputMaybe<AppointmentCreateNestedOneWithoutRescheduledInput>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutAppointmentsInput;
};

export type AppointmentCreateWithoutRescheduled_FromInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  Order?: InputMaybe<OrderCreateNestedOneWithoutAppointmentInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutAppointmentInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAppointmentInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutBooked_AppointmentsInput;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled?: InputMaybe<AppointmentCreateNestedManyWithoutRescheduled_FromInput>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutAppointmentsInput;
};

export type AppointmentCreateWithoutUserInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  Order?: InputMaybe<OrderCreateNestedOneWithoutAppointmentInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutAppointmentInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAppointmentInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  cancelled_type?: InputMaybe<CancellationType>;
  checked_in_at?: InputMaybe<Scalars["DateTime"]>;
  completed_at?: InputMaybe<Scalars["DateTime"]>;
  confirmed_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutBooked_AppointmentsInput;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  notes?: InputMaybe<Scalars["String"]>;
  reminded_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled?: InputMaybe<AppointmentCreateNestedManyWithoutRescheduled_FromInput>;
  rescheduled_at?: InputMaybe<Scalars["DateTime"]>;
  rescheduled_from?: InputMaybe<AppointmentCreateNestedOneWithoutRescheduledInput>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AppointmentGroupBy = {
  __typename?: "AppointmentGroupBy";
  _avg?: Maybe<AppointmentAvgAggregate>;
  _count?: Maybe<AppointmentCountAggregate>;
  _max?: Maybe<AppointmentMaxAggregate>;
  _min?: Maybe<AppointmentMinAggregate>;
  _sum?: Maybe<AppointmentSumAggregate>;
  cancellation_info?: Maybe<Scalars["JSON"]>;
  cancelled_at?: Maybe<Scalars["DateTime"]>;
  cancelled_type: CancellationType;
  checked_in_at?: Maybe<Scalars["DateTime"]>;
  completed_at?: Maybe<Scalars["DateTime"]>;
  confirmed_at?: Maybe<Scalars["DateTime"]>;
  created_at: Scalars["DateTime"];
  end_time: Scalars["DateTime"];
  full_amount?: Maybe<Scalars["Float"]>;
  id: Scalars["String"];
  inserted_by_id: Scalars["String"];
  is_blocked_time: Scalars["Boolean"];
  location_id: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  reminded_at?: Maybe<Scalars["DateTime"]>;
  rescheduled_at?: Maybe<Scalars["DateTime"]>;
  rescheduled_from_id?: Maybe<Scalars["String"]>;
  start_time: Scalars["DateTime"];
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
};

export type AppointmentListRelationFilter = {
  every?: InputMaybe<AppointmentWhereInput>;
  none?: InputMaybe<AppointmentWhereInput>;
  some?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentMaxAggregate = {
  __typename?: "AppointmentMaxAggregate";
  cancelled_at?: Maybe<Scalars["DateTime"]>;
  cancelled_type?: Maybe<CancellationType>;
  checked_in_at?: Maybe<Scalars["DateTime"]>;
  completed_at?: Maybe<Scalars["DateTime"]>;
  confirmed_at?: Maybe<Scalars["DateTime"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  end_time?: Maybe<Scalars["DateTime"]>;
  full_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["String"]>;
  inserted_by_id?: Maybe<Scalars["String"]>;
  is_blocked_time?: Maybe<Scalars["Boolean"]>;
  location_id?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  reminded_at?: Maybe<Scalars["DateTime"]>;
  rescheduled_at?: Maybe<Scalars["DateTime"]>;
  rescheduled_from_id?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type AppointmentMaxOrderByAggregateInput = {
  cancelled_at?: InputMaybe<SortOrder>;
  cancelled_type?: InputMaybe<SortOrder>;
  checked_in_at?: InputMaybe<SortOrder>;
  completed_at?: InputMaybe<SortOrder>;
  confirmed_at?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inserted_by_id?: InputMaybe<SortOrder>;
  is_blocked_time?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  reminded_at?: InputMaybe<SortOrder>;
  rescheduled_at?: InputMaybe<SortOrder>;
  rescheduled_from_id?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type AppointmentMinAggregate = {
  __typename?: "AppointmentMinAggregate";
  cancelled_at?: Maybe<Scalars["DateTime"]>;
  cancelled_type?: Maybe<CancellationType>;
  checked_in_at?: Maybe<Scalars["DateTime"]>;
  completed_at?: Maybe<Scalars["DateTime"]>;
  confirmed_at?: Maybe<Scalars["DateTime"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  end_time?: Maybe<Scalars["DateTime"]>;
  full_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["String"]>;
  inserted_by_id?: Maybe<Scalars["String"]>;
  is_blocked_time?: Maybe<Scalars["Boolean"]>;
  location_id?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  reminded_at?: Maybe<Scalars["DateTime"]>;
  rescheduled_at?: Maybe<Scalars["DateTime"]>;
  rescheduled_from_id?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type AppointmentMinOrderByAggregateInput = {
  cancelled_at?: InputMaybe<SortOrder>;
  cancelled_type?: InputMaybe<SortOrder>;
  checked_in_at?: InputMaybe<SortOrder>;
  completed_at?: InputMaybe<SortOrder>;
  confirmed_at?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inserted_by_id?: InputMaybe<SortOrder>;
  is_blocked_time?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  reminded_at?: InputMaybe<SortOrder>;
  rescheduled_at?: InputMaybe<SortOrder>;
  rescheduled_from_id?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type AppointmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AppointmentOrderByWithAggregationInput = {
  _avg?: InputMaybe<AppointmentAvgOrderByAggregateInput>;
  _count?: InputMaybe<AppointmentCountOrderByAggregateInput>;
  _max?: InputMaybe<AppointmentMaxOrderByAggregateInput>;
  _min?: InputMaybe<AppointmentMinOrderByAggregateInput>;
  _sum?: InputMaybe<AppointmentSumOrderByAggregateInput>;
  cancellation_info?: InputMaybe<SortOrderInput>;
  cancelled_at?: InputMaybe<SortOrderInput>;
  cancelled_type?: InputMaybe<SortOrder>;
  checked_in_at?: InputMaybe<SortOrderInput>;
  completed_at?: InputMaybe<SortOrderInput>;
  confirmed_at?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inserted_by_id?: InputMaybe<SortOrder>;
  is_blocked_time?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrderInput>;
  reminded_at?: InputMaybe<SortOrderInput>;
  rescheduled_at?: InputMaybe<SortOrderInput>;
  rescheduled_from_id?: InputMaybe<SortOrderInput>;
  start_time?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type AppointmentOrderByWithRelationInput = {
  Notification?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  Order?: InputMaybe<OrderOrderByWithRelationInput>;
  Refund?: InputMaybe<RefundOrderByRelationAggregateInput>;
  appointment_services?: InputMaybe<AppointmentServiceOrderByRelationAggregateInput>;
  cancellation_info?: InputMaybe<SortOrderInput>;
  cancelled_at?: InputMaybe<SortOrderInput>;
  cancelled_type?: InputMaybe<SortOrder>;
  checked_in_at?: InputMaybe<SortOrderInput>;
  completed_at?: InputMaybe<SortOrderInput>;
  confirmed_at?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inserted_by?: InputMaybe<UserOrderByWithRelationInput>;
  inserted_by_id?: InputMaybe<SortOrder>;
  is_blocked_time?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByWithRelationInput>;
  location_id?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrderInput>;
  reminded_at?: InputMaybe<SortOrderInput>;
  rescheduled?: InputMaybe<AppointmentOrderByRelationAggregateInput>;
  rescheduled_at?: InputMaybe<SortOrderInput>;
  rescheduled_from?: InputMaybe<AppointmentOrderByWithRelationInput>;
  rescheduled_from_id?: InputMaybe<SortOrderInput>;
  start_time?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export type AppointmentRelationFilter = {
  is?: InputMaybe<AppointmentWhereInput>;
  isNot?: InputMaybe<AppointmentWhereInput>;
};

export enum AppointmentScalarFieldEnum {
  CancellationInfo = "cancellation_info",
  CancelledAt = "cancelled_at",
  CancelledType = "cancelled_type",
  CheckedInAt = "checked_in_at",
  CompletedAt = "completed_at",
  ConfirmedAt = "confirmed_at",
  CreatedAt = "created_at",
  EndTime = "end_time",
  FullAmount = "full_amount",
  Id = "id",
  InsertedById = "inserted_by_id",
  IsBlockedTime = "is_blocked_time",
  LocationId = "location_id",
  Notes = "notes",
  RemindedAt = "reminded_at",
  RescheduledAt = "rescheduled_at",
  RescheduledFromId = "rescheduled_from_id",
  StartTime = "start_time",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type AppointmentScalarWhereInput = {
  AND?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  OR?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  cancellation_info?: InputMaybe<JsonNullableFilter>;
  cancelled_at?: InputMaybe<DateTimeNullableFilter>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFilter>;
  checked_in_at?: InputMaybe<DateTimeNullableFilter>;
  completed_at?: InputMaybe<DateTimeNullableFilter>;
  confirmed_at?: InputMaybe<DateTimeNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  end_time?: InputMaybe<DateTimeFilter>;
  full_amount?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  inserted_by_id?: InputMaybe<UuidFilter>;
  is_blocked_time?: InputMaybe<BoolFilter>;
  location_id?: InputMaybe<UuidFilter>;
  notes?: InputMaybe<StringNullableFilter>;
  reminded_at?: InputMaybe<DateTimeNullableFilter>;
  rescheduled_at?: InputMaybe<DateTimeNullableFilter>;
  rescheduled_from_id?: InputMaybe<UuidNullableFilter>;
  start_time?: InputMaybe<DateTimeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type AppointmentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  cancellation_info?: InputMaybe<JsonNullableWithAggregatesFilter>;
  cancelled_at?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  cancelled_type?: InputMaybe<EnumCancellationTypeWithAggregatesFilter>;
  checked_in_at?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  completed_at?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  confirmed_at?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  end_time?: InputMaybe<DateTimeWithAggregatesFilter>;
  full_amount?: InputMaybe<FloatNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  inserted_by_id?: InputMaybe<UuidWithAggregatesFilter>;
  is_blocked_time?: InputMaybe<BoolWithAggregatesFilter>;
  location_id?: InputMaybe<UuidWithAggregatesFilter>;
  notes?: InputMaybe<StringNullableWithAggregatesFilter>;
  reminded_at?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  rescheduled_at?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  rescheduled_from_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  start_time?: InputMaybe<DateTimeWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export type AppointmentService = {
  __typename?: "AppointmentService";
  _count?: Maybe<AppointmentServiceCount>;
  add_ons: Array<ServiceAddOn>;
  appointment: Appointment;
  appointment_id: Scalars["String"];
  artist: Artist;
  artist_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  end_time: Scalars["DateTime"];
  id: Scalars["String"];
  is_blocked_time: Scalars["Boolean"];
  order: Scalars["Int"];
  person_name?: Maybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service: Service;
  service_id: Scalars["String"];
  start_time: Scalars["DateTime"];
  tx_addons: Array<TxAddon>;
  tx_service?: Maybe<TxService>;
  updated_at: Scalars["DateTime"];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars["String"]>;
};

export type AppointmentServiceAdd_OnsArgs = {
  cursor?: InputMaybe<ServiceAddOnWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceAddOnScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceAddOnOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type AppointmentServiceTx_AddonsArgs = {
  cursor?: InputMaybe<TxAddonWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxAddonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxAddonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxAddonWhereInput>;
};

export type AppointmentServiceAvgAggregate = {
  __typename?: "AppointmentServiceAvgAggregate";
  order?: Maybe<Scalars["Float"]>;
  person_number?: Maybe<Scalars["Float"]>;
};

export type AppointmentServiceAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
  person_number?: InputMaybe<SortOrder>;
};

export type AppointmentServiceCount = {
  __typename?: "AppointmentServiceCount";
  add_ons: Scalars["Int"];
  tx_addons: Scalars["Int"];
};

export type AppointmentServiceCountAdd_OnsArgs = {
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type AppointmentServiceCountTx_AddonsArgs = {
  where?: InputMaybe<TxAddonWhereInput>;
};

export type AppointmentServiceCountAggregate = {
  __typename?: "AppointmentServiceCountAggregate";
  _all: Scalars["Int"];
  appointment_id: Scalars["Int"];
  artist_id: Scalars["Int"];
  created_at: Scalars["Int"];
  end_time: Scalars["Int"];
  id: Scalars["Int"];
  is_blocked_time: Scalars["Int"];
  order: Scalars["Int"];
  person_name: Scalars["Int"];
  person_number: Scalars["Int"];
  service_id: Scalars["Int"];
  start_time: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type AppointmentServiceCountOrderByAggregateInput = {
  appointment_id?: InputMaybe<SortOrder>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_blocked_time?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  person_name?: InputMaybe<SortOrder>;
  person_number?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type AppointmentServiceCreateInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAppointment_ServicesInput>;
  appointment: AppointmentCreateNestedOneWithoutAppointment_ServicesInput;
  artist: ArtistCreateNestedOneWithoutAppointmentServiceInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service: ServiceCreateNestedOneWithoutAppointment_ServicesInput;
  start_time: Scalars["DateTime"];
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAppointment_ServiceInput>;
  tx_service?: InputMaybe<TxServiceCreateNestedOneWithoutAppointment_ServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAppointment_ServicesInput>;
};

export type AppointmentServiceCreateManyAppointmentInput = {
  artist_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service_id: Scalars["String"];
  start_time: Scalars["DateTime"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id?: InputMaybe<Scalars["String"]>;
};

export type AppointmentServiceCreateManyAppointmentInputEnvelope = {
  data: Array<AppointmentServiceCreateManyAppointmentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AppointmentServiceCreateManyArtistInput = {
  appointment_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service_id: Scalars["String"];
  start_time: Scalars["DateTime"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id?: InputMaybe<Scalars["String"]>;
};

export type AppointmentServiceCreateManyArtistInputEnvelope = {
  data: Array<AppointmentServiceCreateManyArtistInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AppointmentServiceCreateManyInput = {
  appointment_id: Scalars["String"];
  artist_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service_id: Scalars["String"];
  start_time: Scalars["DateTime"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id?: InputMaybe<Scalars["String"]>;
};

export type AppointmentServiceCreateManyServiceInput = {
  appointment_id: Scalars["String"];
  artist_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  start_time: Scalars["DateTime"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id?: InputMaybe<Scalars["String"]>;
};

export type AppointmentServiceCreateManyServiceInputEnvelope = {
  data: Array<AppointmentServiceCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AppointmentServiceCreateManyUserInput = {
  appointment_id: Scalars["String"];
  artist_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service_id: Scalars["String"];
  start_time: Scalars["DateTime"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AppointmentServiceCreateManyUserInputEnvelope = {
  data: Array<AppointmentServiceCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AppointmentServiceCreateNestedManyWithoutAdd_OnsInput = {
  connect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentServiceCreateOrConnectWithoutAdd_OnsInput>
  >;
  create?: InputMaybe<Array<AppointmentServiceCreateWithoutAdd_OnsInput>>;
};

export type AppointmentServiceCreateNestedManyWithoutAppointmentInput = {
  connect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentServiceCreateOrConnectWithoutAppointmentInput>
  >;
  create?: InputMaybe<Array<AppointmentServiceCreateWithoutAppointmentInput>>;
  createMany?: InputMaybe<AppointmentServiceCreateManyAppointmentInputEnvelope>;
};

export type AppointmentServiceCreateNestedManyWithoutArtistInput = {
  connect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentServiceCreateOrConnectWithoutArtistInput>
  >;
  create?: InputMaybe<Array<AppointmentServiceCreateWithoutArtistInput>>;
  createMany?: InputMaybe<AppointmentServiceCreateManyArtistInputEnvelope>;
};

export type AppointmentServiceCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentServiceCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<AppointmentServiceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<AppointmentServiceCreateManyServiceInputEnvelope>;
};

export type AppointmentServiceCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentServiceCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<AppointmentServiceCreateWithoutUserInput>>;
  createMany?: InputMaybe<AppointmentServiceCreateManyUserInputEnvelope>;
};

export type AppointmentServiceCreateNestedOneWithoutTx_AddonsInput = {
  connect?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentServiceCreateOrConnectWithoutTx_AddonsInput>;
  create?: InputMaybe<AppointmentServiceCreateWithoutTx_AddonsInput>;
};

export type AppointmentServiceCreateNestedOneWithoutTx_ServiceInput = {
  connect?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentServiceCreateOrConnectWithoutTx_ServiceInput>;
  create?: InputMaybe<AppointmentServiceCreateWithoutTx_ServiceInput>;
};

export type AppointmentServiceCreateOrConnectWithoutAdd_OnsInput = {
  create: AppointmentServiceCreateWithoutAdd_OnsInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceCreateOrConnectWithoutAppointmentInput = {
  create: AppointmentServiceCreateWithoutAppointmentInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceCreateOrConnectWithoutArtistInput = {
  create: AppointmentServiceCreateWithoutArtistInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceCreateOrConnectWithoutServiceInput = {
  create: AppointmentServiceCreateWithoutServiceInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceCreateOrConnectWithoutTx_AddonsInput = {
  create: AppointmentServiceCreateWithoutTx_AddonsInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceCreateOrConnectWithoutTx_ServiceInput = {
  create: AppointmentServiceCreateWithoutTx_ServiceInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceCreateOrConnectWithoutUserInput = {
  create: AppointmentServiceCreateWithoutUserInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceCreateWithoutAdd_OnsInput = {
  appointment: AppointmentCreateNestedOneWithoutAppointment_ServicesInput;
  artist: ArtistCreateNestedOneWithoutAppointmentServiceInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service: ServiceCreateNestedOneWithoutAppointment_ServicesInput;
  start_time: Scalars["DateTime"];
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAppointment_ServiceInput>;
  tx_service?: InputMaybe<TxServiceCreateNestedOneWithoutAppointment_ServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAppointment_ServicesInput>;
};

export type AppointmentServiceCreateWithoutAppointmentInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAppointment_ServicesInput>;
  artist: ArtistCreateNestedOneWithoutAppointmentServiceInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service: ServiceCreateNestedOneWithoutAppointment_ServicesInput;
  start_time: Scalars["DateTime"];
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAppointment_ServiceInput>;
  tx_service?: InputMaybe<TxServiceCreateNestedOneWithoutAppointment_ServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAppointment_ServicesInput>;
};

export type AppointmentServiceCreateWithoutArtistInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAppointment_ServicesInput>;
  appointment: AppointmentCreateNestedOneWithoutAppointment_ServicesInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service: ServiceCreateNestedOneWithoutAppointment_ServicesInput;
  start_time: Scalars["DateTime"];
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAppointment_ServiceInput>;
  tx_service?: InputMaybe<TxServiceCreateNestedOneWithoutAppointment_ServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAppointment_ServicesInput>;
};

export type AppointmentServiceCreateWithoutServiceInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAppointment_ServicesInput>;
  appointment: AppointmentCreateNestedOneWithoutAppointment_ServicesInput;
  artist: ArtistCreateNestedOneWithoutAppointmentServiceInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  start_time: Scalars["DateTime"];
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAppointment_ServiceInput>;
  tx_service?: InputMaybe<TxServiceCreateNestedOneWithoutAppointment_ServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAppointment_ServicesInput>;
};

export type AppointmentServiceCreateWithoutTx_AddonsInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAppointment_ServicesInput>;
  appointment: AppointmentCreateNestedOneWithoutAppointment_ServicesInput;
  artist: ArtistCreateNestedOneWithoutAppointmentServiceInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service: ServiceCreateNestedOneWithoutAppointment_ServicesInput;
  start_time: Scalars["DateTime"];
  tx_service?: InputMaybe<TxServiceCreateNestedOneWithoutAppointment_ServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAppointment_ServicesInput>;
};

export type AppointmentServiceCreateWithoutTx_ServiceInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAppointment_ServicesInput>;
  appointment: AppointmentCreateNestedOneWithoutAppointment_ServicesInput;
  artist: ArtistCreateNestedOneWithoutAppointmentServiceInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service: ServiceCreateNestedOneWithoutAppointment_ServicesInput;
  start_time: Scalars["DateTime"];
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAppointment_ServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAppointment_ServicesInput>;
};

export type AppointmentServiceCreateWithoutUserInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAppointment_ServicesInput>;
  appointment: AppointmentCreateNestedOneWithoutAppointment_ServicesInput;
  artist: ArtistCreateNestedOneWithoutAppointmentServiceInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  is_blocked_time?: InputMaybe<Scalars["Boolean"]>;
  order: Scalars["Int"];
  person_name?: InputMaybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service: ServiceCreateNestedOneWithoutAppointment_ServicesInput;
  start_time: Scalars["DateTime"];
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAppointment_ServiceInput>;
  tx_service?: InputMaybe<TxServiceCreateNestedOneWithoutAppointment_ServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AppointmentServiceGroupBy = {
  __typename?: "AppointmentServiceGroupBy";
  _avg?: Maybe<AppointmentServiceAvgAggregate>;
  _count?: Maybe<AppointmentServiceCountAggregate>;
  _max?: Maybe<AppointmentServiceMaxAggregate>;
  _min?: Maybe<AppointmentServiceMinAggregate>;
  _sum?: Maybe<AppointmentServiceSumAggregate>;
  appointment_id: Scalars["String"];
  artist_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  end_time: Scalars["DateTime"];
  id: Scalars["String"];
  is_blocked_time: Scalars["Boolean"];
  order: Scalars["Int"];
  person_name?: Maybe<Scalars["String"]>;
  person_number: Scalars["Int"];
  service_id: Scalars["String"];
  start_time: Scalars["DateTime"];
  updated_at: Scalars["DateTime"];
  user_id?: Maybe<Scalars["String"]>;
};

export type AppointmentServiceListRelationFilter = {
  every?: InputMaybe<AppointmentServiceWhereInput>;
  none?: InputMaybe<AppointmentServiceWhereInput>;
  some?: InputMaybe<AppointmentServiceWhereInput>;
};

export type AppointmentServiceMaxAggregate = {
  __typename?: "AppointmentServiceMaxAggregate";
  appointment_id?: Maybe<Scalars["String"]>;
  artist_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  end_time?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  is_blocked_time?: Maybe<Scalars["Boolean"]>;
  order?: Maybe<Scalars["Int"]>;
  person_name?: Maybe<Scalars["String"]>;
  person_number?: Maybe<Scalars["Int"]>;
  service_id?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type AppointmentServiceMaxOrderByAggregateInput = {
  appointment_id?: InputMaybe<SortOrder>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_blocked_time?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  person_name?: InputMaybe<SortOrder>;
  person_number?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type AppointmentServiceMinAggregate = {
  __typename?: "AppointmentServiceMinAggregate";
  appointment_id?: Maybe<Scalars["String"]>;
  artist_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  end_time?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  is_blocked_time?: Maybe<Scalars["Boolean"]>;
  order?: Maybe<Scalars["Int"]>;
  person_name?: Maybe<Scalars["String"]>;
  person_number?: Maybe<Scalars["Int"]>;
  service_id?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type AppointmentServiceMinOrderByAggregateInput = {
  appointment_id?: InputMaybe<SortOrder>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_blocked_time?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  person_name?: InputMaybe<SortOrder>;
  person_number?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type AppointmentServiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AppointmentServiceOrderByWithAggregationInput = {
  _avg?: InputMaybe<AppointmentServiceAvgOrderByAggregateInput>;
  _count?: InputMaybe<AppointmentServiceCountOrderByAggregateInput>;
  _max?: InputMaybe<AppointmentServiceMaxOrderByAggregateInput>;
  _min?: InputMaybe<AppointmentServiceMinOrderByAggregateInput>;
  _sum?: InputMaybe<AppointmentServiceSumOrderByAggregateInput>;
  appointment_id?: InputMaybe<SortOrder>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_blocked_time?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  person_name?: InputMaybe<SortOrderInput>;
  person_number?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrderInput>;
};

export type AppointmentServiceOrderByWithRelationInput = {
  add_ons?: InputMaybe<ServiceAddOnOrderByRelationAggregateInput>;
  appointment?: InputMaybe<AppointmentOrderByWithRelationInput>;
  appointment_id?: InputMaybe<SortOrder>;
  artist?: InputMaybe<ArtistOrderByWithRelationInput>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_blocked_time?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  person_name?: InputMaybe<SortOrderInput>;
  person_number?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  service_id?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  tx_addons?: InputMaybe<TxAddonOrderByRelationAggregateInput>;
  tx_service?: InputMaybe<TxServiceOrderByWithRelationInput>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrderInput>;
};

export type AppointmentServiceRelationFilter = {
  is?: InputMaybe<AppointmentServiceWhereInput>;
  isNot?: InputMaybe<AppointmentServiceWhereInput>;
};

export enum AppointmentServiceScalarFieldEnum {
  AppointmentId = "appointment_id",
  ArtistId = "artist_id",
  CreatedAt = "created_at",
  EndTime = "end_time",
  Id = "id",
  IsBlockedTime = "is_blocked_time",
  Order = "order",
  PersonName = "person_name",
  PersonNumber = "person_number",
  ServiceId = "service_id",
  StartTime = "start_time",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type AppointmentServiceScalarWhereInput = {
  AND?: InputMaybe<Array<AppointmentServiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentServiceScalarWhereInput>>;
  OR?: InputMaybe<Array<AppointmentServiceScalarWhereInput>>;
  appointment_id?: InputMaybe<UuidFilter>;
  artist_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  end_time?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  is_blocked_time?: InputMaybe<BoolFilter>;
  order?: InputMaybe<IntFilter>;
  person_name?: InputMaybe<StringNullableFilter>;
  person_number?: InputMaybe<IntFilter>;
  service_id?: InputMaybe<UuidFilter>;
  start_time?: InputMaybe<DateTimeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidNullableFilter>;
};

export type AppointmentServiceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AppointmentServiceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AppointmentServiceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AppointmentServiceScalarWhereWithAggregatesInput>>;
  appointment_id?: InputMaybe<UuidWithAggregatesFilter>;
  artist_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  end_time?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  is_blocked_time?: InputMaybe<BoolWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  person_name?: InputMaybe<StringNullableWithAggregatesFilter>;
  person_number?: InputMaybe<IntWithAggregatesFilter>;
  service_id?: InputMaybe<UuidWithAggregatesFilter>;
  start_time?: InputMaybe<DateTimeWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
};

export type AppointmentServiceSumAggregate = {
  __typename?: "AppointmentServiceSumAggregate";
  order?: Maybe<Scalars["Int"]>;
  person_number?: Maybe<Scalars["Int"]>;
};

export type AppointmentServiceSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
  person_number?: InputMaybe<SortOrder>;
};

export type AppointmentServiceUpdateInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAppointment_ServicesNestedInput>;
  appointment?: InputMaybe<AppointmentUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutAppointmentServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  person_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  person_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAppointment_ServiceNestedInput>;
  tx_service?: InputMaybe<TxServiceUpdateOneWithoutAppointment_ServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutAppointment_ServicesNestedInput>;
};

export type AppointmentServiceUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  person_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  person_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentServiceUpdateManyWithWhereWithoutAdd_OnsInput = {
  data: AppointmentServiceUpdateManyMutationInput;
  where: AppointmentServiceScalarWhereInput;
};

export type AppointmentServiceUpdateManyWithWhereWithoutAppointmentInput = {
  data: AppointmentServiceUpdateManyMutationInput;
  where: AppointmentServiceScalarWhereInput;
};

export type AppointmentServiceUpdateManyWithWhereWithoutArtistInput = {
  data: AppointmentServiceUpdateManyMutationInput;
  where: AppointmentServiceScalarWhereInput;
};

export type AppointmentServiceUpdateManyWithWhereWithoutServiceInput = {
  data: AppointmentServiceUpdateManyMutationInput;
  where: AppointmentServiceScalarWhereInput;
};

export type AppointmentServiceUpdateManyWithWhereWithoutUserInput = {
  data: AppointmentServiceUpdateManyMutationInput;
  where: AppointmentServiceScalarWhereInput;
};

export type AppointmentServiceUpdateManyWithoutAdd_OnsNestedInput = {
  connect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentServiceCreateOrConnectWithoutAdd_OnsInput>
  >;
  create?: InputMaybe<Array<AppointmentServiceCreateWithoutAdd_OnsInput>>;
  delete?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AppointmentServiceUpdateWithWhereUniqueWithoutAdd_OnsInput>
  >;
  updateMany?: InputMaybe<
    Array<AppointmentServiceUpdateManyWithWhereWithoutAdd_OnsInput>
  >;
  upsert?: InputMaybe<
    Array<AppointmentServiceUpsertWithWhereUniqueWithoutAdd_OnsInput>
  >;
};

export type AppointmentServiceUpdateManyWithoutAppointmentNestedInput = {
  connect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentServiceCreateOrConnectWithoutAppointmentInput>
  >;
  create?: InputMaybe<Array<AppointmentServiceCreateWithoutAppointmentInput>>;
  createMany?: InputMaybe<AppointmentServiceCreateManyAppointmentInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AppointmentServiceUpdateWithWhereUniqueWithoutAppointmentInput>
  >;
  updateMany?: InputMaybe<
    Array<AppointmentServiceUpdateManyWithWhereWithoutAppointmentInput>
  >;
  upsert?: InputMaybe<
    Array<AppointmentServiceUpsertWithWhereUniqueWithoutAppointmentInput>
  >;
};

export type AppointmentServiceUpdateManyWithoutArtistNestedInput = {
  connect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentServiceCreateOrConnectWithoutArtistInput>
  >;
  create?: InputMaybe<Array<AppointmentServiceCreateWithoutArtistInput>>;
  createMany?: InputMaybe<AppointmentServiceCreateManyArtistInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AppointmentServiceUpdateWithWhereUniqueWithoutArtistInput>
  >;
  updateMany?: InputMaybe<
    Array<AppointmentServiceUpdateManyWithWhereWithoutArtistInput>
  >;
  upsert?: InputMaybe<
    Array<AppointmentServiceUpsertWithWhereUniqueWithoutArtistInput>
  >;
};

export type AppointmentServiceUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentServiceCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<AppointmentServiceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<AppointmentServiceCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AppointmentServiceUpdateWithWhereUniqueWithoutServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<AppointmentServiceUpdateManyWithWhereWithoutServiceInput>
  >;
  upsert?: InputMaybe<
    Array<AppointmentServiceUpsertWithWhereUniqueWithoutServiceInput>
  >;
};

export type AppointmentServiceUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentServiceCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<AppointmentServiceCreateWithoutUserInput>>;
  createMany?: InputMaybe<AppointmentServiceCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentServiceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AppointmentServiceUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<AppointmentServiceUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<AppointmentServiceUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type AppointmentServiceUpdateOneWithoutTx_AddonsNestedInput = {
  connect?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentServiceCreateOrConnectWithoutTx_AddonsInput>;
  create?: InputMaybe<AppointmentServiceCreateWithoutTx_AddonsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<AppointmentServiceUpdateWithoutTx_AddonsInput>;
  upsert?: InputMaybe<AppointmentServiceUpsertWithoutTx_AddonsInput>;
};

export type AppointmentServiceUpdateOneWithoutTx_ServiceNestedInput = {
  connect?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentServiceCreateOrConnectWithoutTx_ServiceInput>;
  create?: InputMaybe<AppointmentServiceCreateWithoutTx_ServiceInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<AppointmentServiceUpdateWithoutTx_ServiceInput>;
  upsert?: InputMaybe<AppointmentServiceUpsertWithoutTx_ServiceInput>;
};

export type AppointmentServiceUpdateWithWhereUniqueWithoutAdd_OnsInput = {
  data: AppointmentServiceUpdateWithoutAdd_OnsInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: AppointmentServiceUpdateWithoutAppointmentInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceUpdateWithWhereUniqueWithoutArtistInput = {
  data: AppointmentServiceUpdateWithoutArtistInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceUpdateWithWhereUniqueWithoutServiceInput = {
  data: AppointmentServiceUpdateWithoutServiceInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceUpdateWithWhereUniqueWithoutUserInput = {
  data: AppointmentServiceUpdateWithoutUserInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceUpdateWithoutAdd_OnsInput = {
  appointment?: InputMaybe<AppointmentUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutAppointmentServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  person_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  person_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAppointment_ServiceNestedInput>;
  tx_service?: InputMaybe<TxServiceUpdateOneWithoutAppointment_ServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutAppointment_ServicesNestedInput>;
};

export type AppointmentServiceUpdateWithoutAppointmentInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAppointment_ServicesNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutAppointmentServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  person_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  person_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAppointment_ServiceNestedInput>;
  tx_service?: InputMaybe<TxServiceUpdateOneWithoutAppointment_ServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutAppointment_ServicesNestedInput>;
};

export type AppointmentServiceUpdateWithoutArtistInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAppointment_ServicesNestedInput>;
  appointment?: InputMaybe<AppointmentUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  person_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  person_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAppointment_ServiceNestedInput>;
  tx_service?: InputMaybe<TxServiceUpdateOneWithoutAppointment_ServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutAppointment_ServicesNestedInput>;
};

export type AppointmentServiceUpdateWithoutServiceInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAppointment_ServicesNestedInput>;
  appointment?: InputMaybe<AppointmentUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutAppointmentServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  person_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  person_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAppointment_ServiceNestedInput>;
  tx_service?: InputMaybe<TxServiceUpdateOneWithoutAppointment_ServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutAppointment_ServicesNestedInput>;
};

export type AppointmentServiceUpdateWithoutTx_AddonsInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAppointment_ServicesNestedInput>;
  appointment?: InputMaybe<AppointmentUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutAppointmentServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  person_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  person_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tx_service?: InputMaybe<TxServiceUpdateOneWithoutAppointment_ServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutAppointment_ServicesNestedInput>;
};

export type AppointmentServiceUpdateWithoutTx_ServiceInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAppointment_ServicesNestedInput>;
  appointment?: InputMaybe<AppointmentUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutAppointmentServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  person_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  person_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAppointment_ServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutAppointment_ServicesNestedInput>;
};

export type AppointmentServiceUpdateWithoutUserInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAppointment_ServicesNestedInput>;
  appointment?: InputMaybe<AppointmentUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutAppointmentServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  person_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  person_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutAppointment_ServicesNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAppointment_ServiceNestedInput>;
  tx_service?: InputMaybe<TxServiceUpdateOneWithoutAppointment_ServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentServiceUpsertWithWhereUniqueWithoutAdd_OnsInput = {
  create: AppointmentServiceCreateWithoutAdd_OnsInput;
  update: AppointmentServiceUpdateWithoutAdd_OnsInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: AppointmentServiceCreateWithoutAppointmentInput;
  update: AppointmentServiceUpdateWithoutAppointmentInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceUpsertWithWhereUniqueWithoutArtistInput = {
  create: AppointmentServiceCreateWithoutArtistInput;
  update: AppointmentServiceUpdateWithoutArtistInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceUpsertWithWhereUniqueWithoutServiceInput = {
  create: AppointmentServiceCreateWithoutServiceInput;
  update: AppointmentServiceUpdateWithoutServiceInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceUpsertWithWhereUniqueWithoutUserInput = {
  create: AppointmentServiceCreateWithoutUserInput;
  update: AppointmentServiceUpdateWithoutUserInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type AppointmentServiceUpsertWithoutTx_AddonsInput = {
  create: AppointmentServiceCreateWithoutTx_AddonsInput;
  update: AppointmentServiceUpdateWithoutTx_AddonsInput;
};

export type AppointmentServiceUpsertWithoutTx_ServiceInput = {
  create: AppointmentServiceCreateWithoutTx_ServiceInput;
  update: AppointmentServiceUpdateWithoutTx_ServiceInput;
};

export type AppointmentServiceWhereInput = {
  AND?: InputMaybe<Array<AppointmentServiceWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentServiceWhereInput>>;
  OR?: InputMaybe<Array<AppointmentServiceWhereInput>>;
  add_ons?: InputMaybe<ServiceAddOnListRelationFilter>;
  appointment?: InputMaybe<AppointmentRelationFilter>;
  appointment_id?: InputMaybe<UuidFilter>;
  artist?: InputMaybe<ArtistRelationFilter>;
  artist_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  end_time?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  is_blocked_time?: InputMaybe<BoolFilter>;
  order?: InputMaybe<IntFilter>;
  person_name?: InputMaybe<StringNullableFilter>;
  person_number?: InputMaybe<IntFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  service_id?: InputMaybe<UuidFilter>;
  start_time?: InputMaybe<DateTimeFilter>;
  tx_addons?: InputMaybe<TxAddonListRelationFilter>;
  tx_service?: InputMaybe<TxServiceRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidNullableFilter>;
};

export type AppointmentServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type AppointmentSumAggregate = {
  __typename?: "AppointmentSumAggregate";
  full_amount?: Maybe<Scalars["Float"]>;
};

export type AppointmentSumOrderByAggregateInput = {
  full_amount?: InputMaybe<SortOrder>;
};

export type AppointmentUpdateInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  Order?: InputMaybe<OrderUpdateOneWithoutAppointmentNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutAppointmentNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAppointmentNestedInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutBooked_AppointmentsNestedInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAppointmentsNestedInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled?: InputMaybe<AppointmentUpdateManyWithoutRescheduled_FromNestedInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_from?: InputMaybe<AppointmentUpdateOneWithoutRescheduledNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateManyMutationInput = {
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentUpdateManyWithWhereWithoutInserted_ByInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutLocationInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutRescheduled_FromInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutUserInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithoutInserted_ByNestedInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentCreateOrConnectWithoutInserted_ByInput>
  >;
  create?: InputMaybe<Array<AppointmentCreateWithoutInserted_ByInput>>;
  createMany?: InputMaybe<AppointmentCreateManyInserted_ByInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AppointmentUpdateWithWhereUniqueWithoutInserted_ByInput>
  >;
  updateMany?: InputMaybe<
    Array<AppointmentUpdateManyWithWhereWithoutInserted_ByInput>
  >;
  upsert?: InputMaybe<
    Array<AppointmentUpsertWithWhereUniqueWithoutInserted_ByInput>
  >;
};

export type AppointmentUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<AppointmentCreateWithoutLocationInput>>;
  createMany?: InputMaybe<AppointmentCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AppointmentUpdateWithWhereUniqueWithoutLocationInput>
  >;
  updateMany?: InputMaybe<
    Array<AppointmentUpdateManyWithWhereWithoutLocationInput>
  >;
  upsert?: InputMaybe<
    Array<AppointmentUpsertWithWhereUniqueWithoutLocationInput>
  >;
};

export type AppointmentUpdateManyWithoutRescheduled_FromNestedInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentCreateOrConnectWithoutRescheduled_FromInput>
  >;
  create?: InputMaybe<Array<AppointmentCreateWithoutRescheduled_FromInput>>;
  createMany?: InputMaybe<AppointmentCreateManyRescheduled_FromInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AppointmentUpdateWithWhereUniqueWithoutRescheduled_FromInput>
  >;
  updateMany?: InputMaybe<
    Array<AppointmentUpdateManyWithWhereWithoutRescheduled_FromInput>
  >;
  upsert?: InputMaybe<
    Array<AppointmentUpsertWithWhereUniqueWithoutRescheduled_FromInput>
  >;
};

export type AppointmentUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AppointmentCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<AppointmentCreateWithoutUserInput>>;
  createMany?: InputMaybe<AppointmentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AppointmentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<
    Array<AppointmentUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<Array<AppointmentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type AppointmentUpdateOneRequiredWithoutAppointment_ServicesNestedInput =
  {
    connect?: InputMaybe<AppointmentWhereUniqueInput>;
    connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutAppointment_ServicesInput>;
    create?: InputMaybe<AppointmentCreateWithoutAppointment_ServicesInput>;
    update?: InputMaybe<AppointmentUpdateWithoutAppointment_ServicesInput>;
    upsert?: InputMaybe<AppointmentUpsertWithoutAppointment_ServicesInput>;
  };

export type AppointmentUpdateOneWithoutNotificationNestedInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutNotificationInput>;
  create?: InputMaybe<AppointmentCreateWithoutNotificationInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<AppointmentUpdateWithoutNotificationInput>;
  upsert?: InputMaybe<AppointmentUpsertWithoutNotificationInput>;
};

export type AppointmentUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<AppointmentCreateWithoutOrderInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<AppointmentUpdateWithoutOrderInput>;
  upsert?: InputMaybe<AppointmentUpsertWithoutOrderInput>;
};

export type AppointmentUpdateOneWithoutRefundNestedInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutRefundInput>;
  create?: InputMaybe<AppointmentCreateWithoutRefundInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<AppointmentUpdateWithoutRefundInput>;
  upsert?: InputMaybe<AppointmentUpsertWithoutRefundInput>;
};

export type AppointmentUpdateOneWithoutRescheduledNestedInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutRescheduledInput>;
  create?: InputMaybe<AppointmentCreateWithoutRescheduledInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<AppointmentUpdateWithoutRescheduledInput>;
  upsert?: InputMaybe<AppointmentUpsertWithoutRescheduledInput>;
};

export type AppointmentUpdateWithWhereUniqueWithoutInserted_ByInput = {
  data: AppointmentUpdateWithoutInserted_ByInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutLocationInput = {
  data: AppointmentUpdateWithoutLocationInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutRescheduled_FromInput = {
  data: AppointmentUpdateWithoutRescheduled_FromInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutUserInput = {
  data: AppointmentUpdateWithoutUserInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithoutAppointment_ServicesInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  Order?: InputMaybe<OrderUpdateOneWithoutAppointmentNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutAppointmentNestedInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutBooked_AppointmentsNestedInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAppointmentsNestedInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled?: InputMaybe<AppointmentUpdateManyWithoutRescheduled_FromNestedInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_from?: InputMaybe<AppointmentUpdateOneWithoutRescheduledNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutInserted_ByInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  Order?: InputMaybe<OrderUpdateOneWithoutAppointmentNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutAppointmentNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAppointmentNestedInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAppointmentsNestedInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled?: InputMaybe<AppointmentUpdateManyWithoutRescheduled_FromNestedInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_from?: InputMaybe<AppointmentUpdateOneWithoutRescheduledNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutLocationInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  Order?: InputMaybe<OrderUpdateOneWithoutAppointmentNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutAppointmentNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAppointmentNestedInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutBooked_AppointmentsNestedInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled?: InputMaybe<AppointmentUpdateManyWithoutRescheduled_FromNestedInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_from?: InputMaybe<AppointmentUpdateOneWithoutRescheduledNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutNotificationInput = {
  Order?: InputMaybe<OrderUpdateOneWithoutAppointmentNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutAppointmentNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAppointmentNestedInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutBooked_AppointmentsNestedInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAppointmentsNestedInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled?: InputMaybe<AppointmentUpdateManyWithoutRescheduled_FromNestedInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_from?: InputMaybe<AppointmentUpdateOneWithoutRescheduledNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutOrderInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutAppointmentNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAppointmentNestedInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutBooked_AppointmentsNestedInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAppointmentsNestedInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled?: InputMaybe<AppointmentUpdateManyWithoutRescheduled_FromNestedInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_from?: InputMaybe<AppointmentUpdateOneWithoutRescheduledNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutRefundInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  Order?: InputMaybe<OrderUpdateOneWithoutAppointmentNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAppointmentNestedInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutBooked_AppointmentsNestedInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAppointmentsNestedInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled?: InputMaybe<AppointmentUpdateManyWithoutRescheduled_FromNestedInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_from?: InputMaybe<AppointmentUpdateOneWithoutRescheduledNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutRescheduledInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  Order?: InputMaybe<OrderUpdateOneWithoutAppointmentNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutAppointmentNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAppointmentNestedInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutBooked_AppointmentsNestedInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAppointmentsNestedInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_from?: InputMaybe<AppointmentUpdateOneWithoutRescheduledNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutRescheduled_FromInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  Order?: InputMaybe<OrderUpdateOneWithoutAppointmentNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutAppointmentNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAppointmentNestedInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutBooked_AppointmentsNestedInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAppointmentsNestedInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled?: InputMaybe<AppointmentUpdateManyWithoutRescheduled_FromNestedInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutUserInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  Order?: InputMaybe<OrderUpdateOneWithoutAppointmentNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutAppointmentNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAppointmentNestedInput>;
  cancellation_info?: InputMaybe<Scalars["JSON"]>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFieldUpdateOperationsInput>;
  checked_in_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  confirmed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutBooked_AppointmentsNestedInput>;
  is_blocked_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAppointmentsNestedInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled?: InputMaybe<AppointmentUpdateManyWithoutRescheduled_FromNestedInput>;
  rescheduled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rescheduled_from?: InputMaybe<AppointmentUpdateOneWithoutRescheduledNestedInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentUpsertWithWhereUniqueWithoutInserted_ByInput = {
  create: AppointmentCreateWithoutInserted_ByInput;
  update: AppointmentUpdateWithoutInserted_ByInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutLocationInput = {
  create: AppointmentCreateWithoutLocationInput;
  update: AppointmentUpdateWithoutLocationInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutRescheduled_FromInput = {
  create: AppointmentCreateWithoutRescheduled_FromInput;
  update: AppointmentUpdateWithoutRescheduled_FromInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutUserInput = {
  create: AppointmentCreateWithoutUserInput;
  update: AppointmentUpdateWithoutUserInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithoutAppointment_ServicesInput = {
  create: AppointmentCreateWithoutAppointment_ServicesInput;
  update: AppointmentUpdateWithoutAppointment_ServicesInput;
};

export type AppointmentUpsertWithoutNotificationInput = {
  create: AppointmentCreateWithoutNotificationInput;
  update: AppointmentUpdateWithoutNotificationInput;
};

export type AppointmentUpsertWithoutOrderInput = {
  create: AppointmentCreateWithoutOrderInput;
  update: AppointmentUpdateWithoutOrderInput;
};

export type AppointmentUpsertWithoutRefundInput = {
  create: AppointmentCreateWithoutRefundInput;
  update: AppointmentUpdateWithoutRefundInput;
};

export type AppointmentUpsertWithoutRescheduledInput = {
  create: AppointmentCreateWithoutRescheduledInput;
  update: AppointmentUpdateWithoutRescheduledInput;
};

export type AppointmentWhereInput = {
  AND?: InputMaybe<Array<AppointmentWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentWhereInput>>;
  Notification?: InputMaybe<NotificationListRelationFilter>;
  OR?: InputMaybe<Array<AppointmentWhereInput>>;
  Order?: InputMaybe<OrderRelationFilter>;
  Refund?: InputMaybe<RefundListRelationFilter>;
  appointment_services?: InputMaybe<AppointmentServiceListRelationFilter>;
  cancellation_info?: InputMaybe<JsonNullableFilter>;
  cancelled_at?: InputMaybe<DateTimeNullableFilter>;
  cancelled_type?: InputMaybe<EnumCancellationTypeFilter>;
  checked_in_at?: InputMaybe<DateTimeNullableFilter>;
  completed_at?: InputMaybe<DateTimeNullableFilter>;
  confirmed_at?: InputMaybe<DateTimeNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  end_time?: InputMaybe<DateTimeFilter>;
  full_amount?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  inserted_by?: InputMaybe<UserRelationFilter>;
  inserted_by_id?: InputMaybe<UuidFilter>;
  is_blocked_time?: InputMaybe<BoolFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  location_id?: InputMaybe<UuidFilter>;
  notes?: InputMaybe<StringNullableFilter>;
  reminded_at?: InputMaybe<DateTimeNullableFilter>;
  rescheduled?: InputMaybe<AppointmentListRelationFilter>;
  rescheduled_at?: InputMaybe<DateTimeNullableFilter>;
  rescheduled_from?: InputMaybe<AppointmentRelationFilter>;
  rescheduled_from_id?: InputMaybe<UuidNullableFilter>;
  start_time?: InputMaybe<DateTimeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type AppointmentWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type Artist = {
  __typename?: "Artist";
  AppointmentService: Array<AppointmentService>;
  _count?: Maybe<ArtistCount>;
  artist_instagram?: Maybe<ArtistInstagram>;
  availabilities: Array<ArtistAvailability>;
  bio?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  favourited_by: Array<FavoriteArtist>;
  id: Scalars["String"];
  instagram_id?: Maybe<Scalars["String"]>;
  rating_aggregate?: Maybe<Scalars["Float"]>;
  service_exclusions: Array<ArtistServiceExclusion>;
  txAddons: Array<TxAddon>;
  txServices: Array<TxService>;
  updated_at: Scalars["DateTime"];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars["String"]>;
};

export type ArtistAppointmentServiceArgs = {
  cursor?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type ArtistAvailabilitiesArgs = {
  cursor?: InputMaybe<ArtistAvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistAvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistAvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type ArtistFavourited_ByArgs = {
  cursor?: InputMaybe<FavoriteArtistWhereUniqueInput>;
  distinct?: InputMaybe<Array<FavoriteArtistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FavoriteArtistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type ArtistService_ExclusionsArgs = {
  cursor?: InputMaybe<ArtistServiceExclusionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistServiceExclusionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistServiceExclusionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type ArtistTxAddonsArgs = {
  cursor?: InputMaybe<TxAddonWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxAddonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxAddonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxAddonWhereInput>;
};

export type ArtistTxServicesArgs = {
  cursor?: InputMaybe<TxServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxServiceWhereInput>;
};

export type ArtistAvailability = {
  __typename?: "ArtistAvailability";
  artist: Artist;
  artist_id: Scalars["String"];
  availability: Availability;
  availability_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type ArtistAvailabilityCountAggregate = {
  __typename?: "ArtistAvailabilityCountAggregate";
  _all: Scalars["Int"];
  artist_id: Scalars["Int"];
  availability_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type ArtistAvailabilityCountOrderByAggregateInput = {
  artist_id?: InputMaybe<SortOrder>;
  availability_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistAvailabilityCreateInput = {
  artist: ArtistCreateNestedOneWithoutAvailabilitiesInput;
  availability: AvailabilityCreateNestedOneWithoutArtistsInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistAvailabilityCreateManyArtistInput = {
  availability_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistAvailabilityCreateManyArtistInputEnvelope = {
  data: Array<ArtistAvailabilityCreateManyArtistInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ArtistAvailabilityCreateManyAvailabilityInput = {
  artist_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistAvailabilityCreateManyAvailabilityInputEnvelope = {
  data: Array<ArtistAvailabilityCreateManyAvailabilityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ArtistAvailabilityCreateManyInput = {
  artist_id: Scalars["String"];
  availability_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistAvailabilityCreateNestedManyWithoutArtistInput = {
  connect?: InputMaybe<Array<ArtistAvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ArtistAvailabilityCreateOrConnectWithoutArtistInput>
  >;
  create?: InputMaybe<Array<ArtistAvailabilityCreateWithoutArtistInput>>;
  createMany?: InputMaybe<ArtistAvailabilityCreateManyArtistInputEnvelope>;
};

export type ArtistAvailabilityCreateNestedManyWithoutAvailabilityInput = {
  connect?: InputMaybe<Array<ArtistAvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ArtistAvailabilityCreateOrConnectWithoutAvailabilityInput>
  >;
  create?: InputMaybe<Array<ArtistAvailabilityCreateWithoutAvailabilityInput>>;
  createMany?: InputMaybe<ArtistAvailabilityCreateManyAvailabilityInputEnvelope>;
};

export type ArtistAvailabilityCreateOrConnectWithoutArtistInput = {
  create: ArtistAvailabilityCreateWithoutArtistInput;
  where: ArtistAvailabilityWhereUniqueInput;
};

export type ArtistAvailabilityCreateOrConnectWithoutAvailabilityInput = {
  create: ArtistAvailabilityCreateWithoutAvailabilityInput;
  where: ArtistAvailabilityWhereUniqueInput;
};

export type ArtistAvailabilityCreateWithoutArtistInput = {
  availability: AvailabilityCreateNestedOneWithoutArtistsInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistAvailabilityCreateWithoutAvailabilityInput = {
  artist: ArtistCreateNestedOneWithoutAvailabilitiesInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistAvailabilityGroupBy = {
  __typename?: "ArtistAvailabilityGroupBy";
  _count?: Maybe<ArtistAvailabilityCountAggregate>;
  _max?: Maybe<ArtistAvailabilityMaxAggregate>;
  _min?: Maybe<ArtistAvailabilityMinAggregate>;
  artist_id: Scalars["String"];
  availability_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type ArtistAvailabilityListRelationFilter = {
  every?: InputMaybe<ArtistAvailabilityWhereInput>;
  none?: InputMaybe<ArtistAvailabilityWhereInput>;
  some?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type ArtistAvailabilityMaxAggregate = {
  __typename?: "ArtistAvailabilityMaxAggregate";
  artist_id?: Maybe<Scalars["String"]>;
  availability_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ArtistAvailabilityMaxOrderByAggregateInput = {
  artist_id?: InputMaybe<SortOrder>;
  availability_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistAvailabilityMinAggregate = {
  __typename?: "ArtistAvailabilityMinAggregate";
  artist_id?: Maybe<Scalars["String"]>;
  availability_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ArtistAvailabilityMinOrderByAggregateInput = {
  artist_id?: InputMaybe<SortOrder>;
  availability_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistAvailabilityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ArtistAvailabilityOrderByWithAggregationInput = {
  _count?: InputMaybe<ArtistAvailabilityCountOrderByAggregateInput>;
  _max?: InputMaybe<ArtistAvailabilityMaxOrderByAggregateInput>;
  _min?: InputMaybe<ArtistAvailabilityMinOrderByAggregateInput>;
  artist_id?: InputMaybe<SortOrder>;
  availability_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistAvailabilityOrderByWithRelationInput = {
  artist?: InputMaybe<ArtistOrderByWithRelationInput>;
  artist_id?: InputMaybe<SortOrder>;
  availability?: InputMaybe<AvailabilityOrderByWithRelationInput>;
  availability_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum ArtistAvailabilityScalarFieldEnum {
  ArtistId = "artist_id",
  AvailabilityId = "availability_id",
  CreatedAt = "created_at",
  Id = "id",
  UpdatedAt = "updated_at"
}

export type ArtistAvailabilityScalarWhereInput = {
  AND?: InputMaybe<Array<ArtistAvailabilityScalarWhereInput>>;
  NOT?: InputMaybe<Array<ArtistAvailabilityScalarWhereInput>>;
  OR?: InputMaybe<Array<ArtistAvailabilityScalarWhereInput>>;
  artist_id?: InputMaybe<UuidFilter>;
  availability_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ArtistAvailabilityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ArtistAvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ArtistAvailabilityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ArtistAvailabilityScalarWhereWithAggregatesInput>>;
  artist_id?: InputMaybe<UuidWithAggregatesFilter>;
  availability_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ArtistAvailabilityUpdateInput = {
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutAvailabilitiesNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateOneRequiredWithoutArtistsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistAvailabilityUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistAvailabilityUpdateManyWithWhereWithoutArtistInput = {
  data: ArtistAvailabilityUpdateManyMutationInput;
  where: ArtistAvailabilityScalarWhereInput;
};

export type ArtistAvailabilityUpdateManyWithWhereWithoutAvailabilityInput = {
  data: ArtistAvailabilityUpdateManyMutationInput;
  where: ArtistAvailabilityScalarWhereInput;
};

export type ArtistAvailabilityUpdateManyWithoutArtistNestedInput = {
  connect?: InputMaybe<Array<ArtistAvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ArtistAvailabilityCreateOrConnectWithoutArtistInput>
  >;
  create?: InputMaybe<Array<ArtistAvailabilityCreateWithoutArtistInput>>;
  createMany?: InputMaybe<ArtistAvailabilityCreateManyArtistInputEnvelope>;
  delete?: InputMaybe<Array<ArtistAvailabilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ArtistAvailabilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ArtistAvailabilityWhereUniqueInput>>;
  set?: InputMaybe<Array<ArtistAvailabilityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ArtistAvailabilityUpdateWithWhereUniqueWithoutArtistInput>
  >;
  updateMany?: InputMaybe<
    Array<ArtistAvailabilityUpdateManyWithWhereWithoutArtistInput>
  >;
  upsert?: InputMaybe<
    Array<ArtistAvailabilityUpsertWithWhereUniqueWithoutArtistInput>
  >;
};

export type ArtistAvailabilityUpdateManyWithoutAvailabilityNestedInput = {
  connect?: InputMaybe<Array<ArtistAvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ArtistAvailabilityCreateOrConnectWithoutAvailabilityInput>
  >;
  create?: InputMaybe<Array<ArtistAvailabilityCreateWithoutAvailabilityInput>>;
  createMany?: InputMaybe<ArtistAvailabilityCreateManyAvailabilityInputEnvelope>;
  delete?: InputMaybe<Array<ArtistAvailabilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ArtistAvailabilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ArtistAvailabilityWhereUniqueInput>>;
  set?: InputMaybe<Array<ArtistAvailabilityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ArtistAvailabilityUpdateWithWhereUniqueWithoutAvailabilityInput>
  >;
  updateMany?: InputMaybe<
    Array<ArtistAvailabilityUpdateManyWithWhereWithoutAvailabilityInput>
  >;
  upsert?: InputMaybe<
    Array<ArtistAvailabilityUpsertWithWhereUniqueWithoutAvailabilityInput>
  >;
};

export type ArtistAvailabilityUpdateWithWhereUniqueWithoutArtistInput = {
  data: ArtistAvailabilityUpdateWithoutArtistInput;
  where: ArtistAvailabilityWhereUniqueInput;
};

export type ArtistAvailabilityUpdateWithWhereUniqueWithoutAvailabilityInput = {
  data: ArtistAvailabilityUpdateWithoutAvailabilityInput;
  where: ArtistAvailabilityWhereUniqueInput;
};

export type ArtistAvailabilityUpdateWithoutArtistInput = {
  availability?: InputMaybe<AvailabilityUpdateOneRequiredWithoutArtistsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistAvailabilityUpdateWithoutAvailabilityInput = {
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutAvailabilitiesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistAvailabilityUpsertWithWhereUniqueWithoutArtistInput = {
  create: ArtistAvailabilityCreateWithoutArtistInput;
  update: ArtistAvailabilityUpdateWithoutArtistInput;
  where: ArtistAvailabilityWhereUniqueInput;
};

export type ArtistAvailabilityUpsertWithWhereUniqueWithoutAvailabilityInput = {
  create: ArtistAvailabilityCreateWithoutAvailabilityInput;
  update: ArtistAvailabilityUpdateWithoutAvailabilityInput;
  where: ArtistAvailabilityWhereUniqueInput;
};

export type ArtistAvailabilityWhereInput = {
  AND?: InputMaybe<Array<ArtistAvailabilityWhereInput>>;
  NOT?: InputMaybe<Array<ArtistAvailabilityWhereInput>>;
  OR?: InputMaybe<Array<ArtistAvailabilityWhereInput>>;
  artist?: InputMaybe<ArtistRelationFilter>;
  artist_id?: InputMaybe<UuidFilter>;
  availability?: InputMaybe<AvailabilityRelationFilter>;
  availability_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ArtistAvailabilityWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type ArtistAvgAggregate = {
  __typename?: "ArtistAvgAggregate";
  rating_aggregate?: Maybe<Scalars["Float"]>;
};

export type ArtistAvgOrderByAggregateInput = {
  rating_aggregate?: InputMaybe<SortOrder>;
};

export type ArtistCount = {
  __typename?: "ArtistCount";
  AppointmentService: Scalars["Int"];
  availabilities: Scalars["Int"];
  favourited_by: Scalars["Int"];
  service_exclusions: Scalars["Int"];
  txAddons: Scalars["Int"];
  txServices: Scalars["Int"];
};

export type ArtistCountAppointmentServiceArgs = {
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type ArtistCountAvailabilitiesArgs = {
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type ArtistCountFavourited_ByArgs = {
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type ArtistCountService_ExclusionsArgs = {
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type ArtistCountTxAddonsArgs = {
  where?: InputMaybe<TxAddonWhereInput>;
};

export type ArtistCountTxServicesArgs = {
  where?: InputMaybe<TxServiceWhereInput>;
};

export type ArtistCountAggregate = {
  __typename?: "ArtistCountAggregate";
  _all: Scalars["Int"];
  bio: Scalars["Int"];
  cover_photo_url: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  instagram_id: Scalars["Int"];
  rating_aggregate: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type ArtistCountOrderByAggregateInput = {
  bio?: InputMaybe<SortOrder>;
  cover_photo_url?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram_id?: InputMaybe<SortOrder>;
  rating_aggregate?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type ArtistCreateInput = {
  AppointmentService?: InputMaybe<AppointmentServiceCreateNestedManyWithoutArtistInput>;
  artist_instagram?: InputMaybe<ArtistInstagramCreateNestedOneWithoutArtistInput>;
  availabilities?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutArtistInput>;
  bio?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  favourited_by?: InputMaybe<FavoriteArtistCreateNestedManyWithoutArtistInput>;
  id?: InputMaybe<Scalars["String"]>;
  rating_aggregate?: InputMaybe<Scalars["Float"]>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutArtistInput>;
  txAddons?: InputMaybe<TxAddonCreateNestedManyWithoutArtistInput>;
  txServices?: InputMaybe<TxServiceCreateNestedManyWithoutArtistInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutArtistInput>;
};

export type ArtistCreateManyInput = {
  bio?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_id?: InputMaybe<Scalars["String"]>;
  rating_aggregate?: InputMaybe<Scalars["Float"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id?: InputMaybe<Scalars["String"]>;
};

export type ArtistCreateNestedOneWithoutAppointmentServiceInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutAppointmentServiceInput>;
  create?: InputMaybe<ArtistCreateWithoutAppointmentServiceInput>;
};

export type ArtistCreateNestedOneWithoutArtist_InstagramInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutArtist_InstagramInput>;
  create?: InputMaybe<ArtistCreateWithoutArtist_InstagramInput>;
};

export type ArtistCreateNestedOneWithoutAvailabilitiesInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutAvailabilitiesInput>;
  create?: InputMaybe<ArtistCreateWithoutAvailabilitiesInput>;
};

export type ArtistCreateNestedOneWithoutFavourited_ByInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutFavourited_ByInput>;
  create?: InputMaybe<ArtistCreateWithoutFavourited_ByInput>;
};

export type ArtistCreateNestedOneWithoutService_ExclusionsInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutService_ExclusionsInput>;
  create?: InputMaybe<ArtistCreateWithoutService_ExclusionsInput>;
};

export type ArtistCreateNestedOneWithoutTxAddonsInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutTxAddonsInput>;
  create?: InputMaybe<ArtistCreateWithoutTxAddonsInput>;
};

export type ArtistCreateNestedOneWithoutTxServicesInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutTxServicesInput>;
  create?: InputMaybe<ArtistCreateWithoutTxServicesInput>;
};

export type ArtistCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ArtistCreateWithoutUserInput>;
};

export type ArtistCreateOrConnectWithoutAppointmentServiceInput = {
  create: ArtistCreateWithoutAppointmentServiceInput;
  where: ArtistWhereUniqueInput;
};

export type ArtistCreateOrConnectWithoutArtist_InstagramInput = {
  create: ArtistCreateWithoutArtist_InstagramInput;
  where: ArtistWhereUniqueInput;
};

export type ArtistCreateOrConnectWithoutAvailabilitiesInput = {
  create: ArtistCreateWithoutAvailabilitiesInput;
  where: ArtistWhereUniqueInput;
};

export type ArtistCreateOrConnectWithoutFavourited_ByInput = {
  create: ArtistCreateWithoutFavourited_ByInput;
  where: ArtistWhereUniqueInput;
};

export type ArtistCreateOrConnectWithoutService_ExclusionsInput = {
  create: ArtistCreateWithoutService_ExclusionsInput;
  where: ArtistWhereUniqueInput;
};

export type ArtistCreateOrConnectWithoutTxAddonsInput = {
  create: ArtistCreateWithoutTxAddonsInput;
  where: ArtistWhereUniqueInput;
};

export type ArtistCreateOrConnectWithoutTxServicesInput = {
  create: ArtistCreateWithoutTxServicesInput;
  where: ArtistWhereUniqueInput;
};

export type ArtistCreateOrConnectWithoutUserInput = {
  create: ArtistCreateWithoutUserInput;
  where: ArtistWhereUniqueInput;
};

export type ArtistCreateWithoutAppointmentServiceInput = {
  artist_instagram?: InputMaybe<ArtistInstagramCreateNestedOneWithoutArtistInput>;
  availabilities?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutArtistInput>;
  bio?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  favourited_by?: InputMaybe<FavoriteArtistCreateNestedManyWithoutArtistInput>;
  id?: InputMaybe<Scalars["String"]>;
  rating_aggregate?: InputMaybe<Scalars["Float"]>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutArtistInput>;
  txAddons?: InputMaybe<TxAddonCreateNestedManyWithoutArtistInput>;
  txServices?: InputMaybe<TxServiceCreateNestedManyWithoutArtistInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutArtistInput>;
};

export type ArtistCreateWithoutArtist_InstagramInput = {
  AppointmentService?: InputMaybe<AppointmentServiceCreateNestedManyWithoutArtistInput>;
  availabilities?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutArtistInput>;
  bio?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  favourited_by?: InputMaybe<FavoriteArtistCreateNestedManyWithoutArtistInput>;
  id?: InputMaybe<Scalars["String"]>;
  rating_aggregate?: InputMaybe<Scalars["Float"]>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutArtistInput>;
  txAddons?: InputMaybe<TxAddonCreateNestedManyWithoutArtistInput>;
  txServices?: InputMaybe<TxServiceCreateNestedManyWithoutArtistInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutArtistInput>;
};

export type ArtistCreateWithoutAvailabilitiesInput = {
  AppointmentService?: InputMaybe<AppointmentServiceCreateNestedManyWithoutArtistInput>;
  artist_instagram?: InputMaybe<ArtistInstagramCreateNestedOneWithoutArtistInput>;
  bio?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  favourited_by?: InputMaybe<FavoriteArtistCreateNestedManyWithoutArtistInput>;
  id?: InputMaybe<Scalars["String"]>;
  rating_aggregate?: InputMaybe<Scalars["Float"]>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutArtistInput>;
  txAddons?: InputMaybe<TxAddonCreateNestedManyWithoutArtistInput>;
  txServices?: InputMaybe<TxServiceCreateNestedManyWithoutArtistInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutArtistInput>;
};

export type ArtistCreateWithoutFavourited_ByInput = {
  AppointmentService?: InputMaybe<AppointmentServiceCreateNestedManyWithoutArtistInput>;
  artist_instagram?: InputMaybe<ArtistInstagramCreateNestedOneWithoutArtistInput>;
  availabilities?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutArtistInput>;
  bio?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  rating_aggregate?: InputMaybe<Scalars["Float"]>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutArtistInput>;
  txAddons?: InputMaybe<TxAddonCreateNestedManyWithoutArtistInput>;
  txServices?: InputMaybe<TxServiceCreateNestedManyWithoutArtistInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutArtistInput>;
};

export type ArtistCreateWithoutService_ExclusionsInput = {
  AppointmentService?: InputMaybe<AppointmentServiceCreateNestedManyWithoutArtistInput>;
  artist_instagram?: InputMaybe<ArtistInstagramCreateNestedOneWithoutArtistInput>;
  availabilities?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutArtistInput>;
  bio?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  favourited_by?: InputMaybe<FavoriteArtistCreateNestedManyWithoutArtistInput>;
  id?: InputMaybe<Scalars["String"]>;
  rating_aggregate?: InputMaybe<Scalars["Float"]>;
  txAddons?: InputMaybe<TxAddonCreateNestedManyWithoutArtistInput>;
  txServices?: InputMaybe<TxServiceCreateNestedManyWithoutArtistInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutArtistInput>;
};

export type ArtistCreateWithoutTxAddonsInput = {
  AppointmentService?: InputMaybe<AppointmentServiceCreateNestedManyWithoutArtistInput>;
  artist_instagram?: InputMaybe<ArtistInstagramCreateNestedOneWithoutArtistInput>;
  availabilities?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutArtistInput>;
  bio?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  favourited_by?: InputMaybe<FavoriteArtistCreateNestedManyWithoutArtistInput>;
  id?: InputMaybe<Scalars["String"]>;
  rating_aggregate?: InputMaybe<Scalars["Float"]>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutArtistInput>;
  txServices?: InputMaybe<TxServiceCreateNestedManyWithoutArtistInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutArtistInput>;
};

export type ArtistCreateWithoutTxServicesInput = {
  AppointmentService?: InputMaybe<AppointmentServiceCreateNestedManyWithoutArtistInput>;
  artist_instagram?: InputMaybe<ArtistInstagramCreateNestedOneWithoutArtistInput>;
  availabilities?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutArtistInput>;
  bio?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  favourited_by?: InputMaybe<FavoriteArtistCreateNestedManyWithoutArtistInput>;
  id?: InputMaybe<Scalars["String"]>;
  rating_aggregate?: InputMaybe<Scalars["Float"]>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutArtistInput>;
  txAddons?: InputMaybe<TxAddonCreateNestedManyWithoutArtistInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutArtistInput>;
};

export type ArtistCreateWithoutUserInput = {
  AppointmentService?: InputMaybe<AppointmentServiceCreateNestedManyWithoutArtistInput>;
  artist_instagram?: InputMaybe<ArtistInstagramCreateNestedOneWithoutArtistInput>;
  availabilities?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutArtistInput>;
  bio?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  favourited_by?: InputMaybe<FavoriteArtistCreateNestedManyWithoutArtistInput>;
  id?: InputMaybe<Scalars["String"]>;
  rating_aggregate?: InputMaybe<Scalars["Float"]>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutArtistInput>;
  txAddons?: InputMaybe<TxAddonCreateNestedManyWithoutArtistInput>;
  txServices?: InputMaybe<TxServiceCreateNestedManyWithoutArtistInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistGroupBy = {
  __typename?: "ArtistGroupBy";
  _avg?: Maybe<ArtistAvgAggregate>;
  _count?: Maybe<ArtistCountAggregate>;
  _max?: Maybe<ArtistMaxAggregate>;
  _min?: Maybe<ArtistMinAggregate>;
  _sum?: Maybe<ArtistSumAggregate>;
  bio?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  instagram_id?: Maybe<Scalars["String"]>;
  rating_aggregate?: Maybe<Scalars["Float"]>;
  updated_at: Scalars["DateTime"];
  user_id?: Maybe<Scalars["String"]>;
};

export type ArtistInstagram = {
  __typename?: "ArtistInstagram";
  access_token: Scalars["String"];
  artist?: Maybe<Artist>;
  created_at: Scalars["DateTime"];
  expires_in?: Maybe<Scalars["Int"]>;
  id: Scalars["String"];
  instagram_user_id?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type ArtistInstagramAvgAggregate = {
  __typename?: "ArtistInstagramAvgAggregate";
  expires_in?: Maybe<Scalars["Float"]>;
};

export type ArtistInstagramAvgOrderByAggregateInput = {
  expires_in?: InputMaybe<SortOrder>;
};

export type ArtistInstagramCountAggregate = {
  __typename?: "ArtistInstagramCountAggregate";
  _all: Scalars["Int"];
  access_token: Scalars["Int"];
  created_at: Scalars["Int"];
  expires_in: Scalars["Int"];
  id: Scalars["Int"];
  instagram_user_id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type ArtistInstagramCountOrderByAggregateInput = {
  access_token?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  expires_in?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram_user_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistInstagramCreateInput = {
  access_token: Scalars["String"];
  artist?: InputMaybe<ArtistCreateNestedOneWithoutArtist_InstagramInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  expires_in?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_user_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistInstagramCreateManyInput = {
  access_token: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  expires_in?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_user_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistInstagramCreateNestedOneWithoutArtistInput = {
  connect?: InputMaybe<ArtistInstagramWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistInstagramCreateOrConnectWithoutArtistInput>;
  create?: InputMaybe<ArtistInstagramCreateWithoutArtistInput>;
};

export type ArtistInstagramCreateOrConnectWithoutArtistInput = {
  create: ArtistInstagramCreateWithoutArtistInput;
  where: ArtistInstagramWhereUniqueInput;
};

export type ArtistInstagramCreateWithoutArtistInput = {
  access_token: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  expires_in?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_user_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistInstagramGroupBy = {
  __typename?: "ArtistInstagramGroupBy";
  _avg?: Maybe<ArtistInstagramAvgAggregate>;
  _count?: Maybe<ArtistInstagramCountAggregate>;
  _max?: Maybe<ArtistInstagramMaxAggregate>;
  _min?: Maybe<ArtistInstagramMinAggregate>;
  _sum?: Maybe<ArtistInstagramSumAggregate>;
  access_token: Scalars["String"];
  created_at: Scalars["DateTime"];
  expires_in?: Maybe<Scalars["Int"]>;
  id: Scalars["String"];
  instagram_user_id?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type ArtistInstagramMaxAggregate = {
  __typename?: "ArtistInstagramMaxAggregate";
  access_token?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  expires_in?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  instagram_user_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ArtistInstagramMaxOrderByAggregateInput = {
  access_token?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  expires_in?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram_user_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistInstagramMinAggregate = {
  __typename?: "ArtistInstagramMinAggregate";
  access_token?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  expires_in?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  instagram_user_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ArtistInstagramMinOrderByAggregateInput = {
  access_token?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  expires_in?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram_user_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistInstagramOrderByWithAggregationInput = {
  _avg?: InputMaybe<ArtistInstagramAvgOrderByAggregateInput>;
  _count?: InputMaybe<ArtistInstagramCountOrderByAggregateInput>;
  _max?: InputMaybe<ArtistInstagramMaxOrderByAggregateInput>;
  _min?: InputMaybe<ArtistInstagramMinOrderByAggregateInput>;
  _sum?: InputMaybe<ArtistInstagramSumOrderByAggregateInput>;
  access_token?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  expires_in?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  instagram_user_id?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistInstagramOrderByWithRelationInput = {
  access_token?: InputMaybe<SortOrder>;
  artist?: InputMaybe<ArtistOrderByWithRelationInput>;
  created_at?: InputMaybe<SortOrder>;
  expires_in?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  instagram_user_id?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistInstagramRelationFilter = {
  is?: InputMaybe<ArtistInstagramWhereInput>;
  isNot?: InputMaybe<ArtistInstagramWhereInput>;
};

export enum ArtistInstagramScalarFieldEnum {
  AccessToken = "access_token",
  CreatedAt = "created_at",
  ExpiresIn = "expires_in",
  Id = "id",
  InstagramUserId = "instagram_user_id",
  UpdatedAt = "updated_at"
}

export type ArtistInstagramScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ArtistInstagramScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ArtistInstagramScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ArtistInstagramScalarWhereWithAggregatesInput>>;
  access_token?: InputMaybe<StringWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  expires_in?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  instagram_user_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ArtistInstagramSumAggregate = {
  __typename?: "ArtistInstagramSumAggregate";
  expires_in?: Maybe<Scalars["Int"]>;
};

export type ArtistInstagramSumOrderByAggregateInput = {
  expires_in?: InputMaybe<SortOrder>;
};

export type ArtistInstagramUpdateInput = {
  access_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutArtist_InstagramNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires_in?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistInstagramUpdateManyMutationInput = {
  access_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires_in?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistInstagramUpdateOneWithoutArtistNestedInput = {
  connect?: InputMaybe<ArtistInstagramWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistInstagramCreateOrConnectWithoutArtistInput>;
  create?: InputMaybe<ArtistInstagramCreateWithoutArtistInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ArtistInstagramUpdateWithoutArtistInput>;
  upsert?: InputMaybe<ArtistInstagramUpsertWithoutArtistInput>;
};

export type ArtistInstagramUpdateWithoutArtistInput = {
  access_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires_in?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistInstagramUpsertWithoutArtistInput = {
  create: ArtistInstagramCreateWithoutArtistInput;
  update: ArtistInstagramUpdateWithoutArtistInput;
};

export type ArtistInstagramWhereInput = {
  AND?: InputMaybe<Array<ArtistInstagramWhereInput>>;
  NOT?: InputMaybe<Array<ArtistInstagramWhereInput>>;
  OR?: InputMaybe<Array<ArtistInstagramWhereInput>>;
  access_token?: InputMaybe<StringFilter>;
  artist?: InputMaybe<ArtistRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  expires_in?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  instagram_user_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ArtistInstagramWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  instagram_user_id?: InputMaybe<Scalars["String"]>;
};

export type ArtistMaxAggregate = {
  __typename?: "ArtistMaxAggregate";
  bio?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  instagram_id?: Maybe<Scalars["String"]>;
  rating_aggregate?: Maybe<Scalars["Float"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type ArtistMaxOrderByAggregateInput = {
  bio?: InputMaybe<SortOrder>;
  cover_photo_url?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram_id?: InputMaybe<SortOrder>;
  rating_aggregate?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type ArtistMinAggregate = {
  __typename?: "ArtistMinAggregate";
  bio?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  instagram_id?: Maybe<Scalars["String"]>;
  rating_aggregate?: Maybe<Scalars["Float"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type ArtistMinOrderByAggregateInput = {
  bio?: InputMaybe<SortOrder>;
  cover_photo_url?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram_id?: InputMaybe<SortOrder>;
  rating_aggregate?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type ArtistOrderByWithAggregationInput = {
  _avg?: InputMaybe<ArtistAvgOrderByAggregateInput>;
  _count?: InputMaybe<ArtistCountOrderByAggregateInput>;
  _max?: InputMaybe<ArtistMaxOrderByAggregateInput>;
  _min?: InputMaybe<ArtistMinOrderByAggregateInput>;
  _sum?: InputMaybe<ArtistSumOrderByAggregateInput>;
  bio?: InputMaybe<SortOrderInput>;
  cover_photo_url?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram_id?: InputMaybe<SortOrderInput>;
  rating_aggregate?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrderInput>;
};

export type ArtistOrderByWithRelationInput = {
  AppointmentService?: InputMaybe<AppointmentServiceOrderByRelationAggregateInput>;
  artist_instagram?: InputMaybe<ArtistInstagramOrderByWithRelationInput>;
  availabilities?: InputMaybe<ArtistAvailabilityOrderByRelationAggregateInput>;
  bio?: InputMaybe<SortOrderInput>;
  cover_photo_url?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  favourited_by?: InputMaybe<FavoriteArtistOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  instagram_id?: InputMaybe<SortOrderInput>;
  rating_aggregate?: InputMaybe<SortOrderInput>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionOrderByRelationAggregateInput>;
  txAddons?: InputMaybe<TxAddonOrderByRelationAggregateInput>;
  txServices?: InputMaybe<TxServiceOrderByRelationAggregateInput>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrderInput>;
};

export type ArtistRelationFilter = {
  is?: InputMaybe<ArtistWhereInput>;
  isNot?: InputMaybe<ArtistWhereInput>;
};

export enum ArtistScalarFieldEnum {
  Bio = "bio",
  CoverPhotoUrl = "cover_photo_url",
  CreatedAt = "created_at",
  Id = "id",
  InstagramId = "instagram_id",
  RatingAggregate = "rating_aggregate",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type ArtistScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ArtistScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ArtistScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ArtistScalarWhereWithAggregatesInput>>;
  bio?: InputMaybe<StringNullableWithAggregatesFilter>;
  cover_photo_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  instagram_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  rating_aggregate?: InputMaybe<FloatNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
};

export type ArtistServiceExclusion = {
  __typename?: "ArtistServiceExclusion";
  artist: Artist;
  artist_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  service: Service;
  service_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type ArtistServiceExclusionCountAggregate = {
  __typename?: "ArtistServiceExclusionCountAggregate";
  _all: Scalars["Int"];
  artist_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  service_id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type ArtistServiceExclusionCountOrderByAggregateInput = {
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistServiceExclusionCreateInput = {
  artist: ArtistCreateNestedOneWithoutService_ExclusionsInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  service: ServiceCreateNestedOneWithoutArtist_ExclusionsInput;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistServiceExclusionCreateManyArtistInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  service_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistServiceExclusionCreateManyArtistInputEnvelope = {
  data: Array<ArtistServiceExclusionCreateManyArtistInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ArtistServiceExclusionCreateManyInput = {
  artist_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  service_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistServiceExclusionCreateManyServiceInput = {
  artist_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistServiceExclusionCreateManyServiceInputEnvelope = {
  data: Array<ArtistServiceExclusionCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ArtistServiceExclusionCreateNestedManyWithoutArtistInput = {
  connect?: InputMaybe<Array<ArtistServiceExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ArtistServiceExclusionCreateOrConnectWithoutArtistInput>
  >;
  create?: InputMaybe<Array<ArtistServiceExclusionCreateWithoutArtistInput>>;
  createMany?: InputMaybe<ArtistServiceExclusionCreateManyArtistInputEnvelope>;
};

export type ArtistServiceExclusionCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<ArtistServiceExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ArtistServiceExclusionCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<ArtistServiceExclusionCreateWithoutServiceInput>>;
  createMany?: InputMaybe<ArtistServiceExclusionCreateManyServiceInputEnvelope>;
};

export type ArtistServiceExclusionCreateOrConnectWithoutArtistInput = {
  create: ArtistServiceExclusionCreateWithoutArtistInput;
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type ArtistServiceExclusionCreateOrConnectWithoutServiceInput = {
  create: ArtistServiceExclusionCreateWithoutServiceInput;
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type ArtistServiceExclusionCreateWithoutArtistInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  service: ServiceCreateNestedOneWithoutArtist_ExclusionsInput;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistServiceExclusionCreateWithoutServiceInput = {
  artist: ArtistCreateNestedOneWithoutService_ExclusionsInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ArtistServiceExclusionGroupBy = {
  __typename?: "ArtistServiceExclusionGroupBy";
  _count?: Maybe<ArtistServiceExclusionCountAggregate>;
  _max?: Maybe<ArtistServiceExclusionMaxAggregate>;
  _min?: Maybe<ArtistServiceExclusionMinAggregate>;
  artist_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  service_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type ArtistServiceExclusionListRelationFilter = {
  every?: InputMaybe<ArtistServiceExclusionWhereInput>;
  none?: InputMaybe<ArtistServiceExclusionWhereInput>;
  some?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type ArtistServiceExclusionMaxAggregate = {
  __typename?: "ArtistServiceExclusionMaxAggregate";
  artist_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ArtistServiceExclusionMaxOrderByAggregateInput = {
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistServiceExclusionMinAggregate = {
  __typename?: "ArtistServiceExclusionMinAggregate";
  artist_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ArtistServiceExclusionMinOrderByAggregateInput = {
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistServiceExclusionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ArtistServiceExclusionOrderByWithAggregationInput = {
  _count?: InputMaybe<ArtistServiceExclusionCountOrderByAggregateInput>;
  _max?: InputMaybe<ArtistServiceExclusionMaxOrderByAggregateInput>;
  _min?: InputMaybe<ArtistServiceExclusionMinOrderByAggregateInput>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ArtistServiceExclusionOrderByWithRelationInput = {
  artist?: InputMaybe<ArtistOrderByWithRelationInput>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum ArtistServiceExclusionScalarFieldEnum {
  ArtistId = "artist_id",
  CreatedAt = "created_at",
  Id = "id",
  ServiceId = "service_id",
  UpdatedAt = "updated_at"
}

export type ArtistServiceExclusionScalarWhereInput = {
  AND?: InputMaybe<Array<ArtistServiceExclusionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ArtistServiceExclusionScalarWhereInput>>;
  OR?: InputMaybe<Array<ArtistServiceExclusionScalarWhereInput>>;
  artist_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  service_id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ArtistServiceExclusionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ArtistServiceExclusionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ArtistServiceExclusionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ArtistServiceExclusionScalarWhereWithAggregatesInput>>;
  artist_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  service_id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ArtistServiceExclusionUpdateInput = {
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutService_ExclusionsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutArtist_ExclusionsNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistServiceExclusionUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistServiceExclusionUpdateManyWithWhereWithoutArtistInput = {
  data: ArtistServiceExclusionUpdateManyMutationInput;
  where: ArtistServiceExclusionScalarWhereInput;
};

export type ArtistServiceExclusionUpdateManyWithWhereWithoutServiceInput = {
  data: ArtistServiceExclusionUpdateManyMutationInput;
  where: ArtistServiceExclusionScalarWhereInput;
};

export type ArtistServiceExclusionUpdateManyWithoutArtistNestedInput = {
  connect?: InputMaybe<Array<ArtistServiceExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ArtistServiceExclusionCreateOrConnectWithoutArtistInput>
  >;
  create?: InputMaybe<Array<ArtistServiceExclusionCreateWithoutArtistInput>>;
  createMany?: InputMaybe<ArtistServiceExclusionCreateManyArtistInputEnvelope>;
  delete?: InputMaybe<Array<ArtistServiceExclusionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ArtistServiceExclusionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ArtistServiceExclusionWhereUniqueInput>>;
  set?: InputMaybe<Array<ArtistServiceExclusionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ArtistServiceExclusionUpdateWithWhereUniqueWithoutArtistInput>
  >;
  updateMany?: InputMaybe<
    Array<ArtistServiceExclusionUpdateManyWithWhereWithoutArtistInput>
  >;
  upsert?: InputMaybe<
    Array<ArtistServiceExclusionUpsertWithWhereUniqueWithoutArtistInput>
  >;
};

export type ArtistServiceExclusionUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<ArtistServiceExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ArtistServiceExclusionCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<ArtistServiceExclusionCreateWithoutServiceInput>>;
  createMany?: InputMaybe<ArtistServiceExclusionCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<ArtistServiceExclusionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ArtistServiceExclusionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ArtistServiceExclusionWhereUniqueInput>>;
  set?: InputMaybe<Array<ArtistServiceExclusionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ArtistServiceExclusionUpdateWithWhereUniqueWithoutServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<ArtistServiceExclusionUpdateManyWithWhereWithoutServiceInput>
  >;
  upsert?: InputMaybe<
    Array<ArtistServiceExclusionUpsertWithWhereUniqueWithoutServiceInput>
  >;
};

export type ArtistServiceExclusionUpdateWithWhereUniqueWithoutArtistInput = {
  data: ArtistServiceExclusionUpdateWithoutArtistInput;
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type ArtistServiceExclusionUpdateWithWhereUniqueWithoutServiceInput = {
  data: ArtistServiceExclusionUpdateWithoutServiceInput;
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type ArtistServiceExclusionUpdateWithoutArtistInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutArtist_ExclusionsNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistServiceExclusionUpdateWithoutServiceInput = {
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutService_ExclusionsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistServiceExclusionUpsertWithWhereUniqueWithoutArtistInput = {
  create: ArtistServiceExclusionCreateWithoutArtistInput;
  update: ArtistServiceExclusionUpdateWithoutArtistInput;
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type ArtistServiceExclusionUpsertWithWhereUniqueWithoutServiceInput = {
  create: ArtistServiceExclusionCreateWithoutServiceInput;
  update: ArtistServiceExclusionUpdateWithoutServiceInput;
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type ArtistServiceExclusionWhereInput = {
  AND?: InputMaybe<Array<ArtistServiceExclusionWhereInput>>;
  NOT?: InputMaybe<Array<ArtistServiceExclusionWhereInput>>;
  OR?: InputMaybe<Array<ArtistServiceExclusionWhereInput>>;
  artist?: InputMaybe<ArtistRelationFilter>;
  artist_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  service_id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ArtistServiceExclusionWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type ArtistSumAggregate = {
  __typename?: "ArtistSumAggregate";
  rating_aggregate?: Maybe<Scalars["Float"]>;
};

export type ArtistSumOrderByAggregateInput = {
  rating_aggregate?: InputMaybe<SortOrder>;
};

export type ArtistUpdateInput = {
  AppointmentService?: InputMaybe<AppointmentServiceUpdateManyWithoutArtistNestedInput>;
  artist_instagram?: InputMaybe<ArtistInstagramUpdateOneWithoutArtistNestedInput>;
  availabilities?: InputMaybe<ArtistAvailabilityUpdateManyWithoutArtistNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favourited_by?: InputMaybe<FavoriteArtistUpdateManyWithoutArtistNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating_aggregate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutArtistNestedInput>;
  txAddons?: InputMaybe<TxAddonUpdateManyWithoutArtistNestedInput>;
  txServices?: InputMaybe<TxServiceUpdateManyWithoutArtistNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutArtistNestedInput>;
};

export type ArtistUpdateManyMutationInput = {
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating_aggregate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistUpdateOneRequiredWithoutAppointmentServiceNestedInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutAppointmentServiceInput>;
  create?: InputMaybe<ArtistCreateWithoutAppointmentServiceInput>;
  update?: InputMaybe<ArtistUpdateWithoutAppointmentServiceInput>;
  upsert?: InputMaybe<ArtistUpsertWithoutAppointmentServiceInput>;
};

export type ArtistUpdateOneRequiredWithoutAvailabilitiesNestedInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutAvailabilitiesInput>;
  create?: InputMaybe<ArtistCreateWithoutAvailabilitiesInput>;
  update?: InputMaybe<ArtistUpdateWithoutAvailabilitiesInput>;
  upsert?: InputMaybe<ArtistUpsertWithoutAvailabilitiesInput>;
};

export type ArtistUpdateOneRequiredWithoutFavourited_ByNestedInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutFavourited_ByInput>;
  create?: InputMaybe<ArtistCreateWithoutFavourited_ByInput>;
  update?: InputMaybe<ArtistUpdateWithoutFavourited_ByInput>;
  upsert?: InputMaybe<ArtistUpsertWithoutFavourited_ByInput>;
};

export type ArtistUpdateOneRequiredWithoutService_ExclusionsNestedInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutService_ExclusionsInput>;
  create?: InputMaybe<ArtistCreateWithoutService_ExclusionsInput>;
  update?: InputMaybe<ArtistUpdateWithoutService_ExclusionsInput>;
  upsert?: InputMaybe<ArtistUpsertWithoutService_ExclusionsInput>;
};

export type ArtistUpdateOneWithoutArtist_InstagramNestedInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutArtist_InstagramInput>;
  create?: InputMaybe<ArtistCreateWithoutArtist_InstagramInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ArtistUpdateWithoutArtist_InstagramInput>;
  upsert?: InputMaybe<ArtistUpsertWithoutArtist_InstagramInput>;
};

export type ArtistUpdateOneWithoutTxAddonsNestedInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutTxAddonsInput>;
  create?: InputMaybe<ArtistCreateWithoutTxAddonsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ArtistUpdateWithoutTxAddonsInput>;
  upsert?: InputMaybe<ArtistUpsertWithoutTxAddonsInput>;
};

export type ArtistUpdateOneWithoutTxServicesNestedInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutTxServicesInput>;
  create?: InputMaybe<ArtistCreateWithoutTxServicesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ArtistUpdateWithoutTxServicesInput>;
  upsert?: InputMaybe<ArtistUpsertWithoutTxServicesInput>;
};

export type ArtistUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<ArtistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArtistCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ArtistCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ArtistUpdateWithoutUserInput>;
  upsert?: InputMaybe<ArtistUpsertWithoutUserInput>;
};

export type ArtistUpdateWithoutAppointmentServiceInput = {
  artist_instagram?: InputMaybe<ArtistInstagramUpdateOneWithoutArtistNestedInput>;
  availabilities?: InputMaybe<ArtistAvailabilityUpdateManyWithoutArtistNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favourited_by?: InputMaybe<FavoriteArtistUpdateManyWithoutArtistNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating_aggregate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutArtistNestedInput>;
  txAddons?: InputMaybe<TxAddonUpdateManyWithoutArtistNestedInput>;
  txServices?: InputMaybe<TxServiceUpdateManyWithoutArtistNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutArtistNestedInput>;
};

export type ArtistUpdateWithoutArtist_InstagramInput = {
  AppointmentService?: InputMaybe<AppointmentServiceUpdateManyWithoutArtistNestedInput>;
  availabilities?: InputMaybe<ArtistAvailabilityUpdateManyWithoutArtistNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favourited_by?: InputMaybe<FavoriteArtistUpdateManyWithoutArtistNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating_aggregate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutArtistNestedInput>;
  txAddons?: InputMaybe<TxAddonUpdateManyWithoutArtistNestedInput>;
  txServices?: InputMaybe<TxServiceUpdateManyWithoutArtistNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutArtistNestedInput>;
};

export type ArtistUpdateWithoutAvailabilitiesInput = {
  AppointmentService?: InputMaybe<AppointmentServiceUpdateManyWithoutArtistNestedInput>;
  artist_instagram?: InputMaybe<ArtistInstagramUpdateOneWithoutArtistNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favourited_by?: InputMaybe<FavoriteArtistUpdateManyWithoutArtistNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating_aggregate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutArtistNestedInput>;
  txAddons?: InputMaybe<TxAddonUpdateManyWithoutArtistNestedInput>;
  txServices?: InputMaybe<TxServiceUpdateManyWithoutArtistNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutArtistNestedInput>;
};

export type ArtistUpdateWithoutFavourited_ByInput = {
  AppointmentService?: InputMaybe<AppointmentServiceUpdateManyWithoutArtistNestedInput>;
  artist_instagram?: InputMaybe<ArtistInstagramUpdateOneWithoutArtistNestedInput>;
  availabilities?: InputMaybe<ArtistAvailabilityUpdateManyWithoutArtistNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating_aggregate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutArtistNestedInput>;
  txAddons?: InputMaybe<TxAddonUpdateManyWithoutArtistNestedInput>;
  txServices?: InputMaybe<TxServiceUpdateManyWithoutArtistNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutArtistNestedInput>;
};

export type ArtistUpdateWithoutService_ExclusionsInput = {
  AppointmentService?: InputMaybe<AppointmentServiceUpdateManyWithoutArtistNestedInput>;
  artist_instagram?: InputMaybe<ArtistInstagramUpdateOneWithoutArtistNestedInput>;
  availabilities?: InputMaybe<ArtistAvailabilityUpdateManyWithoutArtistNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favourited_by?: InputMaybe<FavoriteArtistUpdateManyWithoutArtistNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating_aggregate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  txAddons?: InputMaybe<TxAddonUpdateManyWithoutArtistNestedInput>;
  txServices?: InputMaybe<TxServiceUpdateManyWithoutArtistNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutArtistNestedInput>;
};

export type ArtistUpdateWithoutTxAddonsInput = {
  AppointmentService?: InputMaybe<AppointmentServiceUpdateManyWithoutArtistNestedInput>;
  artist_instagram?: InputMaybe<ArtistInstagramUpdateOneWithoutArtistNestedInput>;
  availabilities?: InputMaybe<ArtistAvailabilityUpdateManyWithoutArtistNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favourited_by?: InputMaybe<FavoriteArtistUpdateManyWithoutArtistNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating_aggregate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutArtistNestedInput>;
  txServices?: InputMaybe<TxServiceUpdateManyWithoutArtistNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutArtistNestedInput>;
};

export type ArtistUpdateWithoutTxServicesInput = {
  AppointmentService?: InputMaybe<AppointmentServiceUpdateManyWithoutArtistNestedInput>;
  artist_instagram?: InputMaybe<ArtistInstagramUpdateOneWithoutArtistNestedInput>;
  availabilities?: InputMaybe<ArtistAvailabilityUpdateManyWithoutArtistNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favourited_by?: InputMaybe<FavoriteArtistUpdateManyWithoutArtistNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating_aggregate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutArtistNestedInput>;
  txAddons?: InputMaybe<TxAddonUpdateManyWithoutArtistNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutArtistNestedInput>;
};

export type ArtistUpdateWithoutUserInput = {
  AppointmentService?: InputMaybe<AppointmentServiceUpdateManyWithoutArtistNestedInput>;
  artist_instagram?: InputMaybe<ArtistInstagramUpdateOneWithoutArtistNestedInput>;
  availabilities?: InputMaybe<ArtistAvailabilityUpdateManyWithoutArtistNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favourited_by?: InputMaybe<FavoriteArtistUpdateManyWithoutArtistNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating_aggregate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutArtistNestedInput>;
  txAddons?: InputMaybe<TxAddonUpdateManyWithoutArtistNestedInput>;
  txServices?: InputMaybe<TxServiceUpdateManyWithoutArtistNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ArtistUpsertWithoutAppointmentServiceInput = {
  create: ArtistCreateWithoutAppointmentServiceInput;
  update: ArtistUpdateWithoutAppointmentServiceInput;
};

export type ArtistUpsertWithoutArtist_InstagramInput = {
  create: ArtistCreateWithoutArtist_InstagramInput;
  update: ArtistUpdateWithoutArtist_InstagramInput;
};

export type ArtistUpsertWithoutAvailabilitiesInput = {
  create: ArtistCreateWithoutAvailabilitiesInput;
  update: ArtistUpdateWithoutAvailabilitiesInput;
};

export type ArtistUpsertWithoutFavourited_ByInput = {
  create: ArtistCreateWithoutFavourited_ByInput;
  update: ArtistUpdateWithoutFavourited_ByInput;
};

export type ArtistUpsertWithoutService_ExclusionsInput = {
  create: ArtistCreateWithoutService_ExclusionsInput;
  update: ArtistUpdateWithoutService_ExclusionsInput;
};

export type ArtistUpsertWithoutTxAddonsInput = {
  create: ArtistCreateWithoutTxAddonsInput;
  update: ArtistUpdateWithoutTxAddonsInput;
};

export type ArtistUpsertWithoutTxServicesInput = {
  create: ArtistCreateWithoutTxServicesInput;
  update: ArtistUpdateWithoutTxServicesInput;
};

export type ArtistUpsertWithoutUserInput = {
  create: ArtistCreateWithoutUserInput;
  update: ArtistUpdateWithoutUserInput;
};

export type ArtistWhereInput = {
  AND?: InputMaybe<Array<ArtistWhereInput>>;
  AppointmentService?: InputMaybe<AppointmentServiceListRelationFilter>;
  NOT?: InputMaybe<Array<ArtistWhereInput>>;
  OR?: InputMaybe<Array<ArtistWhereInput>>;
  artist_instagram?: InputMaybe<ArtistInstagramRelationFilter>;
  availabilities?: InputMaybe<ArtistAvailabilityListRelationFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  cover_photo_url?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  favourited_by?: InputMaybe<FavoriteArtistListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  instagram_id?: InputMaybe<UuidNullableFilter>;
  rating_aggregate?: InputMaybe<FloatNullableFilter>;
  service_exclusions?: InputMaybe<ArtistServiceExclusionListRelationFilter>;
  txAddons?: InputMaybe<TxAddonListRelationFilter>;
  txServices?: InputMaybe<TxServiceListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidNullableFilter>;
};

export type ArtistWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  instagram_id?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["String"]>;
};

export type Availability = {
  __typename?: "Availability";
  _count?: Maybe<AvailabilityCount>;
  artists: Array<ArtistAvailability>;
  created_at: Scalars["DateTime"];
  end_time: Scalars["DateTime"];
  group_availability?: Maybe<GroupAvailability>;
  group_availability_id?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  inserted_by: User;
  inserted_by_id: Scalars["String"];
  location: Location;
  location_id: Scalars["String"];
  recurring: Scalars["Boolean"];
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type AvailabilityArtistsArgs = {
  cursor?: InputMaybe<ArtistAvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistAvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistAvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type AvailabilityCount = {
  __typename?: "AvailabilityCount";
  artists: Scalars["Int"];
};

export type AvailabilityCountArtistsArgs = {
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type AvailabilityCountAggregate = {
  __typename?: "AvailabilityCountAggregate";
  _all: Scalars["Int"];
  created_at: Scalars["Int"];
  end_time: Scalars["Int"];
  group_availability_id: Scalars["Int"];
  id: Scalars["Int"];
  inserted_by_id: Scalars["Int"];
  location_id: Scalars["Int"];
  recurring: Scalars["Int"];
  start_time: Scalars["Int"];
  status: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type AvailabilityCountOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  group_availability_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inserted_by_id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  recurring?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type AvailabilityCreateInput = {
  artists?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutAvailabilityInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  group_availability?: InputMaybe<GroupAvailabilityCreateNestedOneWithoutAvailabilitiesInput>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutAvailabilityInput;
  location: LocationCreateNestedOneWithoutAvailabilityInput;
  recurring?: InputMaybe<Scalars["Boolean"]>;
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AvailabilityCreateManyGroup_AvailabilityInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  inserted_by_id: Scalars["String"];
  location_id: Scalars["String"];
  recurring?: InputMaybe<Scalars["Boolean"]>;
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AvailabilityCreateManyGroup_AvailabilityInputEnvelope = {
  data: Array<AvailabilityCreateManyGroup_AvailabilityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AvailabilityCreateManyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  group_availability_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by_id: Scalars["String"];
  location_id: Scalars["String"];
  recurring?: InputMaybe<Scalars["Boolean"]>;
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AvailabilityCreateManyInserted_ByInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  group_availability_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  location_id: Scalars["String"];
  recurring?: InputMaybe<Scalars["Boolean"]>;
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AvailabilityCreateManyInserted_ByInputEnvelope = {
  data: Array<AvailabilityCreateManyInserted_ByInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AvailabilityCreateManyLocationInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  group_availability_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by_id: Scalars["String"];
  recurring?: InputMaybe<Scalars["Boolean"]>;
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AvailabilityCreateManyLocationInputEnvelope = {
  data: Array<AvailabilityCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AvailabilityCreateNestedManyWithoutGroup_AvailabilityInput = {
  connect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AvailabilityCreateOrConnectWithoutGroup_AvailabilityInput>
  >;
  create?: InputMaybe<Array<AvailabilityCreateWithoutGroup_AvailabilityInput>>;
  createMany?: InputMaybe<AvailabilityCreateManyGroup_AvailabilityInputEnvelope>;
};

export type AvailabilityCreateNestedManyWithoutInserted_ByInput = {
  connect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AvailabilityCreateOrConnectWithoutInserted_ByInput>
  >;
  create?: InputMaybe<Array<AvailabilityCreateWithoutInserted_ByInput>>;
  createMany?: InputMaybe<AvailabilityCreateManyInserted_ByInputEnvelope>;
};

export type AvailabilityCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AvailabilityCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<AvailabilityCreateWithoutLocationInput>>;
  createMany?: InputMaybe<AvailabilityCreateManyLocationInputEnvelope>;
};

export type AvailabilityCreateNestedOneWithoutArtistsInput = {
  connect?: InputMaybe<AvailabilityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AvailabilityCreateOrConnectWithoutArtistsInput>;
  create?: InputMaybe<AvailabilityCreateWithoutArtistsInput>;
};

export type AvailabilityCreateOrConnectWithoutArtistsInput = {
  create: AvailabilityCreateWithoutArtistsInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityCreateOrConnectWithoutGroup_AvailabilityInput = {
  create: AvailabilityCreateWithoutGroup_AvailabilityInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityCreateOrConnectWithoutInserted_ByInput = {
  create: AvailabilityCreateWithoutInserted_ByInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityCreateOrConnectWithoutLocationInput = {
  create: AvailabilityCreateWithoutLocationInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityCreateWithoutArtistsInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  group_availability?: InputMaybe<GroupAvailabilityCreateNestedOneWithoutAvailabilitiesInput>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutAvailabilityInput;
  location: LocationCreateNestedOneWithoutAvailabilityInput;
  recurring?: InputMaybe<Scalars["Boolean"]>;
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AvailabilityCreateWithoutGroup_AvailabilityInput = {
  artists?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutAvailabilityInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutAvailabilityInput;
  location: LocationCreateNestedOneWithoutAvailabilityInput;
  recurring?: InputMaybe<Scalars["Boolean"]>;
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AvailabilityCreateWithoutInserted_ByInput = {
  artists?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutAvailabilityInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  group_availability?: InputMaybe<GroupAvailabilityCreateNestedOneWithoutAvailabilitiesInput>;
  id?: InputMaybe<Scalars["String"]>;
  location: LocationCreateNestedOneWithoutAvailabilityInput;
  recurring?: InputMaybe<Scalars["Boolean"]>;
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AvailabilityCreateWithoutLocationInput = {
  artists?: InputMaybe<ArtistAvailabilityCreateNestedManyWithoutAvailabilityInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  group_availability?: InputMaybe<GroupAvailabilityCreateNestedOneWithoutAvailabilitiesInput>;
  id?: InputMaybe<Scalars["String"]>;
  inserted_by: UserCreateNestedOneWithoutAvailabilityInput;
  recurring?: InputMaybe<Scalars["Boolean"]>;
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type AvailabilityGroupBy = {
  __typename?: "AvailabilityGroupBy";
  _count?: Maybe<AvailabilityCountAggregate>;
  _max?: Maybe<AvailabilityMaxAggregate>;
  _min?: Maybe<AvailabilityMinAggregate>;
  created_at: Scalars["DateTime"];
  end_time: Scalars["DateTime"];
  group_availability_id?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  inserted_by_id: Scalars["String"];
  location_id: Scalars["String"];
  recurring: Scalars["Boolean"];
  start_time: Scalars["DateTime"];
  status: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type AvailabilityListRelationFilter = {
  every?: InputMaybe<AvailabilityWhereInput>;
  none?: InputMaybe<AvailabilityWhereInput>;
  some?: InputMaybe<AvailabilityWhereInput>;
};

export type AvailabilityMaxAggregate = {
  __typename?: "AvailabilityMaxAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  end_time?: Maybe<Scalars["DateTime"]>;
  group_availability_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  inserted_by_id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["String"]>;
  recurring?: Maybe<Scalars["Boolean"]>;
  start_time?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type AvailabilityMaxOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  group_availability_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inserted_by_id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  recurring?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type AvailabilityMinAggregate = {
  __typename?: "AvailabilityMinAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  end_time?: Maybe<Scalars["DateTime"]>;
  group_availability_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  inserted_by_id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["String"]>;
  recurring?: Maybe<Scalars["Boolean"]>;
  start_time?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type AvailabilityMinOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  group_availability_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inserted_by_id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  recurring?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type AvailabilityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AvailabilityOrderByWithAggregationInput = {
  _count?: InputMaybe<AvailabilityCountOrderByAggregateInput>;
  _max?: InputMaybe<AvailabilityMaxOrderByAggregateInput>;
  _min?: InputMaybe<AvailabilityMinOrderByAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  group_availability_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inserted_by_id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  recurring?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type AvailabilityOrderByWithRelationInput = {
  artists?: InputMaybe<ArtistAvailabilityOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  group_availability?: InputMaybe<GroupAvailabilityOrderByWithRelationInput>;
  group_availability_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inserted_by?: InputMaybe<UserOrderByWithRelationInput>;
  inserted_by_id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByWithRelationInput>;
  location_id?: InputMaybe<SortOrder>;
  recurring?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type AvailabilityRelationFilter = {
  is?: InputMaybe<AvailabilityWhereInput>;
  isNot?: InputMaybe<AvailabilityWhereInput>;
};

export enum AvailabilityScalarFieldEnum {
  CreatedAt = "created_at",
  EndTime = "end_time",
  GroupAvailabilityId = "group_availability_id",
  Id = "id",
  InsertedById = "inserted_by_id",
  LocationId = "location_id",
  Recurring = "recurring",
  StartTime = "start_time",
  Status = "status",
  UpdatedAt = "updated_at"
}

export type AvailabilityScalarWhereInput = {
  AND?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  NOT?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  OR?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  end_time?: InputMaybe<DateTimeFilter>;
  group_availability_id?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  inserted_by_id?: InputMaybe<UuidFilter>;
  location_id?: InputMaybe<UuidFilter>;
  recurring?: InputMaybe<BoolFilter>;
  start_time?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type AvailabilityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AvailabilityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AvailabilityScalarWhereWithAggregatesInput>>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  end_time?: InputMaybe<DateTimeWithAggregatesFilter>;
  group_availability_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  inserted_by_id?: InputMaybe<UuidWithAggregatesFilter>;
  location_id?: InputMaybe<UuidWithAggregatesFilter>;
  recurring?: InputMaybe<BoolWithAggregatesFilter>;
  start_time?: InputMaybe<DateTimeWithAggregatesFilter>;
  status?: InputMaybe<StringWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type AvailabilityUpdateInput = {
  artists?: InputMaybe<ArtistAvailabilityUpdateManyWithoutAvailabilityNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group_availability?: InputMaybe<GroupAvailabilityUpdateOneWithoutAvailabilitiesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutAvailabilityNestedInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAvailabilityNestedInput>;
  recurring?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AvailabilityUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recurring?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AvailabilityUpdateManyWithWhereWithoutGroup_AvailabilityInput = {
  data: AvailabilityUpdateManyMutationInput;
  where: AvailabilityScalarWhereInput;
};

export type AvailabilityUpdateManyWithWhereWithoutInserted_ByInput = {
  data: AvailabilityUpdateManyMutationInput;
  where: AvailabilityScalarWhereInput;
};

export type AvailabilityUpdateManyWithWhereWithoutLocationInput = {
  data: AvailabilityUpdateManyMutationInput;
  where: AvailabilityScalarWhereInput;
};

export type AvailabilityUpdateManyWithoutGroup_AvailabilityNestedInput = {
  connect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AvailabilityCreateOrConnectWithoutGroup_AvailabilityInput>
  >;
  create?: InputMaybe<Array<AvailabilityCreateWithoutGroup_AvailabilityInput>>;
  createMany?: InputMaybe<AvailabilityCreateManyGroup_AvailabilityInputEnvelope>;
  delete?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  set?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AvailabilityUpdateWithWhereUniqueWithoutGroup_AvailabilityInput>
  >;
  updateMany?: InputMaybe<
    Array<AvailabilityUpdateManyWithWhereWithoutGroup_AvailabilityInput>
  >;
  upsert?: InputMaybe<
    Array<AvailabilityUpsertWithWhereUniqueWithoutGroup_AvailabilityInput>
  >;
};

export type AvailabilityUpdateManyWithoutInserted_ByNestedInput = {
  connect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AvailabilityCreateOrConnectWithoutInserted_ByInput>
  >;
  create?: InputMaybe<Array<AvailabilityCreateWithoutInserted_ByInput>>;
  createMany?: InputMaybe<AvailabilityCreateManyInserted_ByInputEnvelope>;
  delete?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  set?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AvailabilityUpdateWithWhereUniqueWithoutInserted_ByInput>
  >;
  updateMany?: InputMaybe<
    Array<AvailabilityUpdateManyWithWhereWithoutInserted_ByInput>
  >;
  upsert?: InputMaybe<
    Array<AvailabilityUpsertWithWhereUniqueWithoutInserted_ByInput>
  >;
};

export type AvailabilityUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AvailabilityCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<AvailabilityCreateWithoutLocationInput>>;
  createMany?: InputMaybe<AvailabilityCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  set?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AvailabilityUpdateWithWhereUniqueWithoutLocationInput>
  >;
  updateMany?: InputMaybe<
    Array<AvailabilityUpdateManyWithWhereWithoutLocationInput>
  >;
  upsert?: InputMaybe<
    Array<AvailabilityUpsertWithWhereUniqueWithoutLocationInput>
  >;
};

export type AvailabilityUpdateOneRequiredWithoutArtistsNestedInput = {
  connect?: InputMaybe<AvailabilityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AvailabilityCreateOrConnectWithoutArtistsInput>;
  create?: InputMaybe<AvailabilityCreateWithoutArtistsInput>;
  update?: InputMaybe<AvailabilityUpdateWithoutArtistsInput>;
  upsert?: InputMaybe<AvailabilityUpsertWithoutArtistsInput>;
};

export type AvailabilityUpdateWithWhereUniqueWithoutGroup_AvailabilityInput = {
  data: AvailabilityUpdateWithoutGroup_AvailabilityInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityUpdateWithWhereUniqueWithoutInserted_ByInput = {
  data: AvailabilityUpdateWithoutInserted_ByInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityUpdateWithWhereUniqueWithoutLocationInput = {
  data: AvailabilityUpdateWithoutLocationInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityUpdateWithoutArtistsInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group_availability?: InputMaybe<GroupAvailabilityUpdateOneWithoutAvailabilitiesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutAvailabilityNestedInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAvailabilityNestedInput>;
  recurring?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AvailabilityUpdateWithoutGroup_AvailabilityInput = {
  artists?: InputMaybe<ArtistAvailabilityUpdateManyWithoutAvailabilityNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutAvailabilityNestedInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAvailabilityNestedInput>;
  recurring?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AvailabilityUpdateWithoutInserted_ByInput = {
  artists?: InputMaybe<ArtistAvailabilityUpdateManyWithoutAvailabilityNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group_availability?: InputMaybe<GroupAvailabilityUpdateOneWithoutAvailabilitiesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAvailabilityNestedInput>;
  recurring?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AvailabilityUpdateWithoutLocationInput = {
  artists?: InputMaybe<ArtistAvailabilityUpdateManyWithoutAvailabilityNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group_availability?: InputMaybe<GroupAvailabilityUpdateOneWithoutAvailabilitiesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inserted_by?: InputMaybe<UserUpdateOneRequiredWithoutAvailabilityNestedInput>;
  recurring?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start_time?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AvailabilityUpsertWithWhereUniqueWithoutGroup_AvailabilityInput = {
  create: AvailabilityCreateWithoutGroup_AvailabilityInput;
  update: AvailabilityUpdateWithoutGroup_AvailabilityInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityUpsertWithWhereUniqueWithoutInserted_ByInput = {
  create: AvailabilityCreateWithoutInserted_ByInput;
  update: AvailabilityUpdateWithoutInserted_ByInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityUpsertWithWhereUniqueWithoutLocationInput = {
  create: AvailabilityCreateWithoutLocationInput;
  update: AvailabilityUpdateWithoutLocationInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityUpsertWithoutArtistsInput = {
  create: AvailabilityCreateWithoutArtistsInput;
  update: AvailabilityUpdateWithoutArtistsInput;
};

export type AvailabilityWhereInput = {
  AND?: InputMaybe<Array<AvailabilityWhereInput>>;
  NOT?: InputMaybe<Array<AvailabilityWhereInput>>;
  OR?: InputMaybe<Array<AvailabilityWhereInput>>;
  artists?: InputMaybe<ArtistAvailabilityListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  end_time?: InputMaybe<DateTimeFilter>;
  group_availability?: InputMaybe<GroupAvailabilityRelationFilter>;
  group_availability_id?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  inserted_by?: InputMaybe<UserRelationFilter>;
  inserted_by_id?: InputMaybe<UuidFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  location_id?: InputMaybe<UuidFilter>;
  recurring?: InputMaybe<BoolFilter>;
  start_time?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type AvailabilityWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type BillingDetails = {
  __typename?: "BillingDetails";
  address: Address;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Boolean"]>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export enum CancellationType {
  AdminNofees = "ADMIN_NOFEES",
  AdminReschedule = "ADMIN_RESCHEDULE",
  Standard = "STANDARD"
}

export type Card = {
  __typename?: "Card";
  brand: Scalars["String"];
  checks: CardChecks;
  country: Scalars["String"];
  exp_month: Scalars["Int"];
  exp_year: Scalars["Int"];
  fingerprint: Scalars["String"];
  funding: Scalars["String"];
  last4: Scalars["String"];
  networks: CardNetworks;
  wallet?: Maybe<Scalars["String"]>;
};

export type CardChecks = {
  __typename?: "CardChecks";
  address_line1_check?: Maybe<Scalars["String"]>;
  address_postal_code_check?: Maybe<Scalars["String"]>;
  cvc_check?: Maybe<Scalars["String"]>;
};

export type CardNetworks = {
  __typename?: "CardNetworks";
  available: Array<Scalars["String"]>;
  preferred?: Maybe<Scalars["String"]>;
};

export type CardOptions = {
  __typename?: "CardOptions";
  network?: Maybe<Scalars["String"]>;
  request_three_d_secure: Scalars["String"];
};

export type Company = {
  __typename?: "Company";
  Refund: Array<Refund>;
  _count?: Maybe<CompanyCount>;
  about?: Maybe<Scalars["String"]>;
  amenities: Array<CompanyAmenity>;
  amenities_enums: Array<AmenityEnum>;
  caption?: Maybe<Scalars["String"]>;
  company_payment_methods: Array<CompanyPaymentMethod>;
  contact_email?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  default_service?: Maybe<Service>;
  default_service_id?: Maybe<Scalars["String"]>;
  facebook_url?: Maybe<Scalars["String"]>;
  facility: Array<Facility>;
  id: Scalars["String"];
  instagram_url?: Maybe<Scalars["String"]>;
  late_cancellation_charge: Scalars["Float"];
  late_cancellation_hours: Scalars["Int"];
  locations: Array<Location>;
  logo_url?: Maybe<Scalars["String"]>;
  menu: Array<Menu>;
  menu_sections: Array<MenuSection>;
  name: Scalars["String"];
  no_show_charge: Scalars["Float"];
  owner: User;
  owner_id: Scalars["String"];
  payment_type: PaymentType;
  retail_tax_rate?: Maybe<Scalars["Float"]>;
  roles: Array<Role>;
  slug?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  stripe_access_token?: Maybe<Scalars["String"]>;
  stripe_charges_enabled?: Maybe<Scalars["Boolean"]>;
  stripe_details_submitted?: Maybe<Scalars["Boolean"]>;
  stripe_refresh_token?: Maybe<Scalars["String"]>;
  stripe_user_id?: Maybe<Scalars["String"]>;
  timeslot_spacing?: Maybe<Scalars["Int"]>;
  twitter_url?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
  user_descriptions: Array<CompanyUserDescription>;
  user_pays_fees: Scalars["Boolean"];
  users: Array<User>;
  website_url?: Maybe<Scalars["String"]>;
};

export type CompanyRefundArgs = {
  cursor?: InputMaybe<RefundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RefundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RefundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RefundWhereInput>;
};

export type CompanyAmenitiesArgs = {
  cursor?: InputMaybe<CompanyAmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyAmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type CompanyCompany_Payment_MethodsArgs = {
  cursor?: InputMaybe<CompanyPaymentMethodWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyPaymentMethodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyPaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type CompanyFacilityArgs = {
  cursor?: InputMaybe<FacilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<FacilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityWhereInput>;
};

export type CompanyLocationsArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationWhereInput>;
};

export type CompanyMenuArgs = {
  cursor?: InputMaybe<MenuWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuWhereInput>;
};

export type CompanyMenu_SectionsArgs = {
  cursor?: InputMaybe<MenuSectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuSectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuSectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type CompanyRolesArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RoleWhereInput>;
};

export type CompanyUser_DescriptionsArgs = {
  cursor?: InputMaybe<CompanyUserDescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyUserDescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyUserDescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type CompanyUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserWhereInput>;
};

export type CompanyAmenity = {
  __typename?: "CompanyAmenity";
  amenity: Amenity;
  amenity_id: Scalars["String"];
  company: Company;
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type CompanyAmenityAmenity_IdCompany_IdCompoundUniqueInput = {
  amenity_id: Scalars["String"];
  company_id: Scalars["String"];
};

export type CompanyAmenityCountAggregate = {
  __typename?: "CompanyAmenityCountAggregate";
  _all: Scalars["Int"];
  amenity_id: Scalars["Int"];
  company_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type CompanyAmenityCountOrderByAggregateInput = {
  amenity_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CompanyAmenityCreateInput = {
  amenity: AmenityCreateNestedOneWithoutCompaniesInput;
  company: CompanyCreateNestedOneWithoutAmenitiesInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyAmenityCreateManyAmenityInput = {
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyAmenityCreateManyAmenityInputEnvelope = {
  data: Array<CompanyAmenityCreateManyAmenityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CompanyAmenityCreateManyCompanyInput = {
  amenity_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyAmenityCreateManyCompanyInputEnvelope = {
  data: Array<CompanyAmenityCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CompanyAmenityCreateManyInput = {
  amenity_id: Scalars["String"];
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyAmenityCreateNestedManyWithoutAmenityInput = {
  connect?: InputMaybe<Array<CompanyAmenityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyAmenityCreateOrConnectWithoutAmenityInput>
  >;
  create?: InputMaybe<Array<CompanyAmenityCreateWithoutAmenityInput>>;
  createMany?: InputMaybe<CompanyAmenityCreateManyAmenityInputEnvelope>;
};

export type CompanyAmenityCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<CompanyAmenityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyAmenityCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<CompanyAmenityCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<CompanyAmenityCreateManyCompanyInputEnvelope>;
};

export type CompanyAmenityCreateOrConnectWithoutAmenityInput = {
  create: CompanyAmenityCreateWithoutAmenityInput;
  where: CompanyAmenityWhereUniqueInput;
};

export type CompanyAmenityCreateOrConnectWithoutCompanyInput = {
  create: CompanyAmenityCreateWithoutCompanyInput;
  where: CompanyAmenityWhereUniqueInput;
};

export type CompanyAmenityCreateWithoutAmenityInput = {
  company: CompanyCreateNestedOneWithoutAmenitiesInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyAmenityCreateWithoutCompanyInput = {
  amenity: AmenityCreateNestedOneWithoutCompaniesInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyAmenityGroupBy = {
  __typename?: "CompanyAmenityGroupBy";
  _count?: Maybe<CompanyAmenityCountAggregate>;
  _max?: Maybe<CompanyAmenityMaxAggregate>;
  _min?: Maybe<CompanyAmenityMinAggregate>;
  amenity_id: Scalars["String"];
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type CompanyAmenityListRelationFilter = {
  every?: InputMaybe<CompanyAmenityWhereInput>;
  none?: InputMaybe<CompanyAmenityWhereInput>;
  some?: InputMaybe<CompanyAmenityWhereInput>;
};

export type CompanyAmenityMaxAggregate = {
  __typename?: "CompanyAmenityMaxAggregate";
  amenity_id?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type CompanyAmenityMaxOrderByAggregateInput = {
  amenity_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CompanyAmenityMinAggregate = {
  __typename?: "CompanyAmenityMinAggregate";
  amenity_id?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type CompanyAmenityMinOrderByAggregateInput = {
  amenity_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CompanyAmenityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CompanyAmenityOrderByWithAggregationInput = {
  _count?: InputMaybe<CompanyAmenityCountOrderByAggregateInput>;
  _max?: InputMaybe<CompanyAmenityMaxOrderByAggregateInput>;
  _min?: InputMaybe<CompanyAmenityMinOrderByAggregateInput>;
  amenity_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CompanyAmenityOrderByWithRelationInput = {
  amenity?: InputMaybe<AmenityOrderByWithRelationInput>;
  amenity_id?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum CompanyAmenityScalarFieldEnum {
  AmenityId = "amenity_id",
  CompanyId = "company_id",
  CreatedAt = "created_at",
  Id = "id",
  UpdatedAt = "updated_at"
}

export type CompanyAmenityScalarWhereInput = {
  AND?: InputMaybe<Array<CompanyAmenityScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompanyAmenityScalarWhereInput>>;
  OR?: InputMaybe<Array<CompanyAmenityScalarWhereInput>>;
  amenity_id?: InputMaybe<UuidFilter>;
  company_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CompanyAmenityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CompanyAmenityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CompanyAmenityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CompanyAmenityScalarWhereWithAggregatesInput>>;
  amenity_id?: InputMaybe<UuidWithAggregatesFilter>;
  company_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type CompanyAmenityUpdateInput = {
  amenity?: InputMaybe<AmenityUpdateOneRequiredWithoutCompaniesNestedInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutAmenitiesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyAmenityUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyAmenityUpdateManyWithWhereWithoutAmenityInput = {
  data: CompanyAmenityUpdateManyMutationInput;
  where: CompanyAmenityScalarWhereInput;
};

export type CompanyAmenityUpdateManyWithWhereWithoutCompanyInput = {
  data: CompanyAmenityUpdateManyMutationInput;
  where: CompanyAmenityScalarWhereInput;
};

export type CompanyAmenityUpdateManyWithoutAmenityNestedInput = {
  connect?: InputMaybe<Array<CompanyAmenityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyAmenityCreateOrConnectWithoutAmenityInput>
  >;
  create?: InputMaybe<Array<CompanyAmenityCreateWithoutAmenityInput>>;
  createMany?: InputMaybe<CompanyAmenityCreateManyAmenityInputEnvelope>;
  delete?: InputMaybe<Array<CompanyAmenityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompanyAmenityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompanyAmenityWhereUniqueInput>>;
  set?: InputMaybe<Array<CompanyAmenityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CompanyAmenityUpdateWithWhereUniqueWithoutAmenityInput>
  >;
  updateMany?: InputMaybe<
    Array<CompanyAmenityUpdateManyWithWhereWithoutAmenityInput>
  >;
  upsert?: InputMaybe<
    Array<CompanyAmenityUpsertWithWhereUniqueWithoutAmenityInput>
  >;
};

export type CompanyAmenityUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<CompanyAmenityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyAmenityCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<CompanyAmenityCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<CompanyAmenityCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<CompanyAmenityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompanyAmenityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompanyAmenityWhereUniqueInput>>;
  set?: InputMaybe<Array<CompanyAmenityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CompanyAmenityUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: InputMaybe<
    Array<CompanyAmenityUpdateManyWithWhereWithoutCompanyInput>
  >;
  upsert?: InputMaybe<
    Array<CompanyAmenityUpsertWithWhereUniqueWithoutCompanyInput>
  >;
};

export type CompanyAmenityUpdateWithWhereUniqueWithoutAmenityInput = {
  data: CompanyAmenityUpdateWithoutAmenityInput;
  where: CompanyAmenityWhereUniqueInput;
};

export type CompanyAmenityUpdateWithWhereUniqueWithoutCompanyInput = {
  data: CompanyAmenityUpdateWithoutCompanyInput;
  where: CompanyAmenityWhereUniqueInput;
};

export type CompanyAmenityUpdateWithoutAmenityInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutAmenitiesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyAmenityUpdateWithoutCompanyInput = {
  amenity?: InputMaybe<AmenityUpdateOneRequiredWithoutCompaniesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyAmenityUpsertWithWhereUniqueWithoutAmenityInput = {
  create: CompanyAmenityCreateWithoutAmenityInput;
  update: CompanyAmenityUpdateWithoutAmenityInput;
  where: CompanyAmenityWhereUniqueInput;
};

export type CompanyAmenityUpsertWithWhereUniqueWithoutCompanyInput = {
  create: CompanyAmenityCreateWithoutCompanyInput;
  update: CompanyAmenityUpdateWithoutCompanyInput;
  where: CompanyAmenityWhereUniqueInput;
};

export type CompanyAmenityWhereInput = {
  AND?: InputMaybe<Array<CompanyAmenityWhereInput>>;
  NOT?: InputMaybe<Array<CompanyAmenityWhereInput>>;
  OR?: InputMaybe<Array<CompanyAmenityWhereInput>>;
  amenity?: InputMaybe<AmenityRelationFilter>;
  amenity_id?: InputMaybe<UuidFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  company_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CompanyAmenityWhereUniqueInput = {
  amenity_id_company_id?: InputMaybe<CompanyAmenityAmenity_IdCompany_IdCompoundUniqueInput>;
  id?: InputMaybe<Scalars["String"]>;
};

export type CompanyAvgAggregate = {
  __typename?: "CompanyAvgAggregate";
  late_cancellation_charge?: Maybe<Scalars["Float"]>;
  late_cancellation_hours?: Maybe<Scalars["Float"]>;
  no_show_charge?: Maybe<Scalars["Float"]>;
  retail_tax_rate?: Maybe<Scalars["Float"]>;
  timeslot_spacing?: Maybe<Scalars["Float"]>;
};

export type CompanyAvgOrderByAggregateInput = {
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  retail_tax_rate?: InputMaybe<SortOrder>;
  timeslot_spacing?: InputMaybe<SortOrder>;
};

export type CompanyCount = {
  __typename?: "CompanyCount";
  Refund: Scalars["Int"];
  amenities: Scalars["Int"];
  company_payment_methods: Scalars["Int"];
  facility: Scalars["Int"];
  locations: Scalars["Int"];
  menu: Scalars["Int"];
  menu_sections: Scalars["Int"];
  roles: Scalars["Int"];
  user_descriptions: Scalars["Int"];
  users: Scalars["Int"];
};

export type CompanyCountRefundArgs = {
  where?: InputMaybe<RefundWhereInput>;
};

export type CompanyCountAmenitiesArgs = {
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type CompanyCountCompany_Payment_MethodsArgs = {
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type CompanyCountFacilityArgs = {
  where?: InputMaybe<FacilityWhereInput>;
};

export type CompanyCountLocationsArgs = {
  where?: InputMaybe<LocationWhereInput>;
};

export type CompanyCountMenuArgs = {
  where?: InputMaybe<MenuWhereInput>;
};

export type CompanyCountMenu_SectionsArgs = {
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type CompanyCountRolesArgs = {
  where?: InputMaybe<RoleWhereInput>;
};

export type CompanyCountUser_DescriptionsArgs = {
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type CompanyCountUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CompanyCountAggregate = {
  __typename?: "CompanyCountAggregate";
  _all: Scalars["Int"];
  about: Scalars["Int"];
  amenities_enums: Scalars["Int"];
  caption: Scalars["Int"];
  contact_email: Scalars["Int"];
  cover_photo_url: Scalars["Int"];
  created_at: Scalars["Int"];
  default_service_id: Scalars["Int"];
  facebook_url: Scalars["Int"];
  id: Scalars["Int"];
  instagram_url: Scalars["Int"];
  late_cancellation_charge: Scalars["Int"];
  late_cancellation_hours: Scalars["Int"];
  logo_url: Scalars["Int"];
  name: Scalars["Int"];
  no_show_charge: Scalars["Int"];
  owner_id: Scalars["Int"];
  payment_type: Scalars["Int"];
  retail_tax_rate: Scalars["Int"];
  slug: Scalars["Int"];
  status: Scalars["Int"];
  stripe_access_token: Scalars["Int"];
  stripe_charges_enabled: Scalars["Int"];
  stripe_details_submitted: Scalars["Int"];
  stripe_refresh_token: Scalars["Int"];
  stripe_user_id: Scalars["Int"];
  timeslot_spacing: Scalars["Int"];
  twitter_url: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_pays_fees: Scalars["Int"];
  website_url: Scalars["Int"];
};

export type CompanyCountOrderByAggregateInput = {
  about?: InputMaybe<SortOrder>;
  amenities_enums?: InputMaybe<SortOrder>;
  caption?: InputMaybe<SortOrder>;
  contact_email?: InputMaybe<SortOrder>;
  cover_photo_url?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  default_service_id?: InputMaybe<SortOrder>;
  facebook_url?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram_url?: InputMaybe<SortOrder>;
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  logo_url?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  owner_id?: InputMaybe<SortOrder>;
  payment_type?: InputMaybe<SortOrder>;
  retail_tax_rate?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_access_token?: InputMaybe<SortOrder>;
  stripe_charges_enabled?: InputMaybe<SortOrder>;
  stripe_details_submitted?: InputMaybe<SortOrder>;
  stripe_refresh_token?: InputMaybe<SortOrder>;
  stripe_user_id?: InputMaybe<SortOrder>;
  timeslot_spacing?: InputMaybe<SortOrder>;
  twitter_url?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_pays_fees?: InputMaybe<SortOrder>;
  website_url?: InputMaybe<SortOrder>;
};

export type CompanyCreateInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateManyDefault_ServiceInput = {
  about?: InputMaybe<Scalars["String"]>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  logo_url?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner_id: Scalars["String"];
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateManyDefault_ServiceInputEnvelope = {
  data: Array<CompanyCreateManyDefault_ServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CompanyCreateManyInput = {
  about?: InputMaybe<Scalars["String"]>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service_id?: InputMaybe<Scalars["String"]>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  logo_url?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner_id: Scalars["String"];
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateNestedManyWithoutDefault_ServiceInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyCreateOrConnectWithoutDefault_ServiceInput>
  >;
  create?: InputMaybe<Array<CompanyCreateWithoutDefault_ServiceInput>>;
  createMany?: InputMaybe<CompanyCreateManyDefault_ServiceInputEnvelope>;
};

export type CompanyCreateNestedOneWithoutAmenitiesInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutAmenitiesInput>;
  create?: InputMaybe<CompanyCreateWithoutAmenitiesInput>;
};

export type CompanyCreateNestedOneWithoutCompany_Payment_MethodsInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutCompany_Payment_MethodsInput>;
  create?: InputMaybe<CompanyCreateWithoutCompany_Payment_MethodsInput>;
};

export type CompanyCreateNestedOneWithoutFacilityInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutFacilityInput>;
  create?: InputMaybe<CompanyCreateWithoutFacilityInput>;
};

export type CompanyCreateNestedOneWithoutLocationsInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutLocationsInput>;
  create?: InputMaybe<CompanyCreateWithoutLocationsInput>;
};

export type CompanyCreateNestedOneWithoutMenuInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutMenuInput>;
  create?: InputMaybe<CompanyCreateWithoutMenuInput>;
};

export type CompanyCreateNestedOneWithoutMenu_SectionsInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutMenu_SectionsInput>;
  create?: InputMaybe<CompanyCreateWithoutMenu_SectionsInput>;
};

export type CompanyCreateNestedOneWithoutOwnerInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutOwnerInput>;
  create?: InputMaybe<CompanyCreateWithoutOwnerInput>;
};

export type CompanyCreateNestedOneWithoutRefundInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutRefundInput>;
  create?: InputMaybe<CompanyCreateWithoutRefundInput>;
};

export type CompanyCreateNestedOneWithoutRolesInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutRolesInput>;
  create?: InputMaybe<CompanyCreateWithoutRolesInput>;
};

export type CompanyCreateNestedOneWithoutUser_DescriptionsInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutUser_DescriptionsInput>;
  create?: InputMaybe<CompanyCreateWithoutUser_DescriptionsInput>;
};

export type CompanyCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<CompanyCreateWithoutUsersInput>;
};

export type CompanyCreateOrConnectWithoutAmenitiesInput = {
  create: CompanyCreateWithoutAmenitiesInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutCompany_Payment_MethodsInput = {
  create: CompanyCreateWithoutCompany_Payment_MethodsInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutDefault_ServiceInput = {
  create: CompanyCreateWithoutDefault_ServiceInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutFacilityInput = {
  create: CompanyCreateWithoutFacilityInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutLocationsInput = {
  create: CompanyCreateWithoutLocationsInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutMenuInput = {
  create: CompanyCreateWithoutMenuInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutMenu_SectionsInput = {
  create: CompanyCreateWithoutMenu_SectionsInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutOwnerInput = {
  create: CompanyCreateWithoutOwnerInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutRefundInput = {
  create: CompanyCreateWithoutRefundInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutRolesInput = {
  create: CompanyCreateWithoutRolesInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutUser_DescriptionsInput = {
  create: CompanyCreateWithoutUser_DescriptionsInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutUsersInput = {
  create: CompanyCreateWithoutUsersInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateWithoutAmenitiesInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutCompany_Payment_MethodsInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutDefault_ServiceInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutFacilityInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutLocationsInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutMenuInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutMenu_SectionsInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutOwnerInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutRefundInput = {
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutRolesInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutUser_DescriptionsInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutJoined_CompanyInput>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateWithoutUsersInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutCompanyInput>;
  about?: InputMaybe<Scalars["String"]>;
  amenities?: InputMaybe<CompanyAmenityCreateNestedManyWithoutCompanyInput>;
  amenities_enums?: InputMaybe<CompanyCreateamenities_EnumsInput>;
  caption?: InputMaybe<Scalars["String"]>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutCompanyInput>;
  contact_email?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_service?: InputMaybe<ServiceCreateNestedOneWithoutCompaniesInput>;
  facebook_url?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutCompanyInput>;
  id?: InputMaybe<Scalars["String"]>;
  instagram_url?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutCompanyInput>;
  logo_url?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedManyWithoutCompanyInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutCompanyInput>;
  name: Scalars["String"];
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  owner: UserCreateNestedOneWithoutCompanyInput;
  payment_type?: InputMaybe<PaymentType>;
  retail_tax_rate?: InputMaybe<Scalars["Float"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutCompanyInput>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_access_token?: InputMaybe<Scalars["String"]>;
  stripe_charges_enabled?: InputMaybe<Scalars["Boolean"]>;
  stripe_details_submitted?: InputMaybe<Scalars["Boolean"]>;
  stripe_refresh_token?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
  timeslot_spacing?: InputMaybe<Scalars["Int"]>;
  twitter_url?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutCompanyInput>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
  website_url?: InputMaybe<Scalars["String"]>;
};

export type CompanyCreateamenities_EnumsInput = {
  set: Array<AmenityEnum>;
};

export type CompanyGroupBy = {
  __typename?: "CompanyGroupBy";
  _avg?: Maybe<CompanyAvgAggregate>;
  _count?: Maybe<CompanyCountAggregate>;
  _max?: Maybe<CompanyMaxAggregate>;
  _min?: Maybe<CompanyMinAggregate>;
  _sum?: Maybe<CompanySumAggregate>;
  about?: Maybe<Scalars["String"]>;
  amenities_enums?: Maybe<Array<AmenityEnum>>;
  caption?: Maybe<Scalars["String"]>;
  contact_email?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  default_service_id?: Maybe<Scalars["String"]>;
  facebook_url?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  instagram_url?: Maybe<Scalars["String"]>;
  late_cancellation_charge: Scalars["Float"];
  late_cancellation_hours: Scalars["Int"];
  logo_url?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  no_show_charge: Scalars["Float"];
  owner_id: Scalars["String"];
  payment_type: PaymentType;
  retail_tax_rate?: Maybe<Scalars["Float"]>;
  slug?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  stripe_access_token?: Maybe<Scalars["String"]>;
  stripe_charges_enabled?: Maybe<Scalars["Boolean"]>;
  stripe_details_submitted?: Maybe<Scalars["Boolean"]>;
  stripe_refresh_token?: Maybe<Scalars["String"]>;
  stripe_user_id?: Maybe<Scalars["String"]>;
  timeslot_spacing?: Maybe<Scalars["Int"]>;
  twitter_url?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
  user_pays_fees: Scalars["Boolean"];
  website_url?: Maybe<Scalars["String"]>;
};

export type CompanyListRelationFilter = {
  every?: InputMaybe<CompanyWhereInput>;
  none?: InputMaybe<CompanyWhereInput>;
  some?: InputMaybe<CompanyWhereInput>;
};

export type CompanyMaxAggregate = {
  __typename?: "CompanyMaxAggregate";
  about?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  contact_email?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  default_service_id?: Maybe<Scalars["String"]>;
  facebook_url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  instagram_url?: Maybe<Scalars["String"]>;
  late_cancellation_charge?: Maybe<Scalars["Float"]>;
  late_cancellation_hours?: Maybe<Scalars["Int"]>;
  logo_url?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  no_show_charge?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["String"]>;
  payment_type?: Maybe<PaymentType>;
  retail_tax_rate?: Maybe<Scalars["Float"]>;
  slug?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  stripe_access_token?: Maybe<Scalars["String"]>;
  stripe_charges_enabled?: Maybe<Scalars["Boolean"]>;
  stripe_details_submitted?: Maybe<Scalars["Boolean"]>;
  stripe_refresh_token?: Maybe<Scalars["String"]>;
  stripe_user_id?: Maybe<Scalars["String"]>;
  timeslot_spacing?: Maybe<Scalars["Int"]>;
  twitter_url?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_pays_fees?: Maybe<Scalars["Boolean"]>;
  website_url?: Maybe<Scalars["String"]>;
};

export type CompanyMaxOrderByAggregateInput = {
  about?: InputMaybe<SortOrder>;
  caption?: InputMaybe<SortOrder>;
  contact_email?: InputMaybe<SortOrder>;
  cover_photo_url?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  default_service_id?: InputMaybe<SortOrder>;
  facebook_url?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram_url?: InputMaybe<SortOrder>;
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  logo_url?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  owner_id?: InputMaybe<SortOrder>;
  payment_type?: InputMaybe<SortOrder>;
  retail_tax_rate?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_access_token?: InputMaybe<SortOrder>;
  stripe_charges_enabled?: InputMaybe<SortOrder>;
  stripe_details_submitted?: InputMaybe<SortOrder>;
  stripe_refresh_token?: InputMaybe<SortOrder>;
  stripe_user_id?: InputMaybe<SortOrder>;
  timeslot_spacing?: InputMaybe<SortOrder>;
  twitter_url?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_pays_fees?: InputMaybe<SortOrder>;
  website_url?: InputMaybe<SortOrder>;
};

export type CompanyMinAggregate = {
  __typename?: "CompanyMinAggregate";
  about?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  contact_email?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  default_service_id?: Maybe<Scalars["String"]>;
  facebook_url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  instagram_url?: Maybe<Scalars["String"]>;
  late_cancellation_charge?: Maybe<Scalars["Float"]>;
  late_cancellation_hours?: Maybe<Scalars["Int"]>;
  logo_url?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  no_show_charge?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["String"]>;
  payment_type?: Maybe<PaymentType>;
  retail_tax_rate?: Maybe<Scalars["Float"]>;
  slug?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  stripe_access_token?: Maybe<Scalars["String"]>;
  stripe_charges_enabled?: Maybe<Scalars["Boolean"]>;
  stripe_details_submitted?: Maybe<Scalars["Boolean"]>;
  stripe_refresh_token?: Maybe<Scalars["String"]>;
  stripe_user_id?: Maybe<Scalars["String"]>;
  timeslot_spacing?: Maybe<Scalars["Int"]>;
  twitter_url?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_pays_fees?: Maybe<Scalars["Boolean"]>;
  website_url?: Maybe<Scalars["String"]>;
};

export type CompanyMinOrderByAggregateInput = {
  about?: InputMaybe<SortOrder>;
  caption?: InputMaybe<SortOrder>;
  contact_email?: InputMaybe<SortOrder>;
  cover_photo_url?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  default_service_id?: InputMaybe<SortOrder>;
  facebook_url?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram_url?: InputMaybe<SortOrder>;
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  logo_url?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  owner_id?: InputMaybe<SortOrder>;
  payment_type?: InputMaybe<SortOrder>;
  retail_tax_rate?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_access_token?: InputMaybe<SortOrder>;
  stripe_charges_enabled?: InputMaybe<SortOrder>;
  stripe_details_submitted?: InputMaybe<SortOrder>;
  stripe_refresh_token?: InputMaybe<SortOrder>;
  stripe_user_id?: InputMaybe<SortOrder>;
  timeslot_spacing?: InputMaybe<SortOrder>;
  twitter_url?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_pays_fees?: InputMaybe<SortOrder>;
  website_url?: InputMaybe<SortOrder>;
};

export type CompanyOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CompanyOrderByWithAggregationInput = {
  _avg?: InputMaybe<CompanyAvgOrderByAggregateInput>;
  _count?: InputMaybe<CompanyCountOrderByAggregateInput>;
  _max?: InputMaybe<CompanyMaxOrderByAggregateInput>;
  _min?: InputMaybe<CompanyMinOrderByAggregateInput>;
  _sum?: InputMaybe<CompanySumOrderByAggregateInput>;
  about?: InputMaybe<SortOrderInput>;
  amenities_enums?: InputMaybe<SortOrder>;
  caption?: InputMaybe<SortOrderInput>;
  contact_email?: InputMaybe<SortOrderInput>;
  cover_photo_url?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  default_service_id?: InputMaybe<SortOrderInput>;
  facebook_url?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  instagram_url?: InputMaybe<SortOrderInput>;
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  logo_url?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  owner_id?: InputMaybe<SortOrder>;
  payment_type?: InputMaybe<SortOrder>;
  retail_tax_rate?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  stripe_access_token?: InputMaybe<SortOrderInput>;
  stripe_charges_enabled?: InputMaybe<SortOrderInput>;
  stripe_details_submitted?: InputMaybe<SortOrderInput>;
  stripe_refresh_token?: InputMaybe<SortOrderInput>;
  stripe_user_id?: InputMaybe<SortOrderInput>;
  timeslot_spacing?: InputMaybe<SortOrderInput>;
  twitter_url?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
  user_pays_fees?: InputMaybe<SortOrder>;
  website_url?: InputMaybe<SortOrderInput>;
};

export type CompanyOrderByWithRelationInput = {
  Refund?: InputMaybe<RefundOrderByRelationAggregateInput>;
  about?: InputMaybe<SortOrderInput>;
  amenities?: InputMaybe<CompanyAmenityOrderByRelationAggregateInput>;
  amenities_enums?: InputMaybe<SortOrder>;
  caption?: InputMaybe<SortOrderInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodOrderByRelationAggregateInput>;
  contact_email?: InputMaybe<SortOrderInput>;
  cover_photo_url?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  default_service?: InputMaybe<ServiceOrderByWithRelationInput>;
  default_service_id?: InputMaybe<SortOrderInput>;
  facebook_url?: InputMaybe<SortOrderInput>;
  facility?: InputMaybe<FacilityOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  instagram_url?: InputMaybe<SortOrderInput>;
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  locations?: InputMaybe<LocationOrderByRelationAggregateInput>;
  logo_url?: InputMaybe<SortOrderInput>;
  menu?: InputMaybe<MenuOrderByRelationAggregateInput>;
  menu_sections?: InputMaybe<MenuSectionOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  owner?: InputMaybe<UserOrderByWithRelationInput>;
  owner_id?: InputMaybe<SortOrder>;
  payment_type?: InputMaybe<SortOrder>;
  retail_tax_rate?: InputMaybe<SortOrderInput>;
  roles?: InputMaybe<RoleOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  stripe_access_token?: InputMaybe<SortOrderInput>;
  stripe_charges_enabled?: InputMaybe<SortOrderInput>;
  stripe_details_submitted?: InputMaybe<SortOrderInput>;
  stripe_refresh_token?: InputMaybe<SortOrderInput>;
  stripe_user_id?: InputMaybe<SortOrderInput>;
  timeslot_spacing?: InputMaybe<SortOrderInput>;
  twitter_url?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionOrderByRelationAggregateInput>;
  user_pays_fees?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
  website_url?: InputMaybe<SortOrderInput>;
};

export type CompanyPaymentMethod = {
  __typename?: "CompanyPaymentMethod";
  cloned_by_stripe_id: Scalars["String"];
  company: Company;
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  customer: Scalars["String"];
  id: Scalars["String"];
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  stripe_payment_method: StripePaymentMethod;
  updated_at: Scalars["DateTime"];
};

export type CompanyPaymentMethodCountAggregate = {
  __typename?: "CompanyPaymentMethodCountAggregate";
  _all: Scalars["Int"];
  cloned_by_stripe_id: Scalars["Int"];
  company_id: Scalars["Int"];
  created_at: Scalars["Int"];
  customer: Scalars["Int"];
  id: Scalars["Int"];
  object_json: Scalars["Int"];
  stripe_id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type CompanyPaymentMethodCountOrderByAggregateInput = {
  cloned_by_stripe_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  customer?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  object_json?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CompanyPaymentMethodCreateInput = {
  company: CompanyCreateNestedOneWithoutCompany_Payment_MethodsInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  customer: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  stripe_payment_method: StripePaymentMethodCreateNestedOneWithoutCompany_Payment_MethodsInput;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyPaymentMethodCreateManyCompanyInput = {
  cloned_by_stripe_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  customer: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyPaymentMethodCreateManyCompanyInputEnvelope = {
  data: Array<CompanyPaymentMethodCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CompanyPaymentMethodCreateManyInput = {
  cloned_by_stripe_id: Scalars["String"];
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  customer: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyPaymentMethodCreateManyStripe_Payment_MethodInput = {
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  customer: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyPaymentMethodCreateManyStripe_Payment_MethodInputEnvelope = {
  data: Array<CompanyPaymentMethodCreateManyStripe_Payment_MethodInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CompanyPaymentMethodCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<CompanyPaymentMethodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyPaymentMethodCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<CompanyPaymentMethodCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<CompanyPaymentMethodCreateManyCompanyInputEnvelope>;
};

export type CompanyPaymentMethodCreateNestedManyWithoutStripe_Payment_MethodInput =
  {
    connect?: InputMaybe<Array<CompanyPaymentMethodWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<CompanyPaymentMethodCreateOrConnectWithoutStripe_Payment_MethodInput>
    >;
    create?: InputMaybe<
      Array<CompanyPaymentMethodCreateWithoutStripe_Payment_MethodInput>
    >;
    createMany?: InputMaybe<CompanyPaymentMethodCreateManyStripe_Payment_MethodInputEnvelope>;
  };

export type CompanyPaymentMethodCreateOrConnectWithoutCompanyInput = {
  create: CompanyPaymentMethodCreateWithoutCompanyInput;
  where: CompanyPaymentMethodWhereUniqueInput;
};

export type CompanyPaymentMethodCreateOrConnectWithoutStripe_Payment_MethodInput =
  {
    create: CompanyPaymentMethodCreateWithoutStripe_Payment_MethodInput;
    where: CompanyPaymentMethodWhereUniqueInput;
  };

export type CompanyPaymentMethodCreateWithoutCompanyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  customer: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  stripe_payment_method: StripePaymentMethodCreateNestedOneWithoutCompany_Payment_MethodsInput;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyPaymentMethodCreateWithoutStripe_Payment_MethodInput = {
  company: CompanyCreateNestedOneWithoutCompany_Payment_MethodsInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  customer: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyPaymentMethodGroupBy = {
  __typename?: "CompanyPaymentMethodGroupBy";
  _count?: Maybe<CompanyPaymentMethodCountAggregate>;
  _max?: Maybe<CompanyPaymentMethodMaxAggregate>;
  _min?: Maybe<CompanyPaymentMethodMinAggregate>;
  cloned_by_stripe_id: Scalars["String"];
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  customer: Scalars["String"];
  id: Scalars["String"];
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type CompanyPaymentMethodListRelationFilter = {
  every?: InputMaybe<CompanyPaymentMethodWhereInput>;
  none?: InputMaybe<CompanyPaymentMethodWhereInput>;
  some?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type CompanyPaymentMethodMaxAggregate = {
  __typename?: "CompanyPaymentMethodMaxAggregate";
  cloned_by_stripe_id?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  customer?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  stripe_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type CompanyPaymentMethodMaxOrderByAggregateInput = {
  cloned_by_stripe_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  customer?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CompanyPaymentMethodMinAggregate = {
  __typename?: "CompanyPaymentMethodMinAggregate";
  cloned_by_stripe_id?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  customer?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  stripe_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type CompanyPaymentMethodMinOrderByAggregateInput = {
  cloned_by_stripe_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  customer?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CompanyPaymentMethodOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CompanyPaymentMethodOrderByWithAggregationInput = {
  _count?: InputMaybe<CompanyPaymentMethodCountOrderByAggregateInput>;
  _max?: InputMaybe<CompanyPaymentMethodMaxOrderByAggregateInput>;
  _min?: InputMaybe<CompanyPaymentMethodMinOrderByAggregateInput>;
  cloned_by_stripe_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  customer?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  object_json?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CompanyPaymentMethodOrderByWithRelationInput = {
  cloned_by_stripe_id?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  customer?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  object_json?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  stripe_payment_method?: InputMaybe<StripePaymentMethodOrderByWithRelationInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum CompanyPaymentMethodScalarFieldEnum {
  ClonedByStripeId = "cloned_by_stripe_id",
  CompanyId = "company_id",
  CreatedAt = "created_at",
  Customer = "customer",
  Id = "id",
  ObjectJson = "object_json",
  StripeId = "stripe_id",
  UpdatedAt = "updated_at"
}

export type CompanyPaymentMethodScalarWhereInput = {
  AND?: InputMaybe<Array<CompanyPaymentMethodScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompanyPaymentMethodScalarWhereInput>>;
  OR?: InputMaybe<Array<CompanyPaymentMethodScalarWhereInput>>;
  cloned_by_stripe_id?: InputMaybe<UuidFilter>;
  company_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  object_json?: InputMaybe<JsonFilter>;
  stripe_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CompanyPaymentMethodScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CompanyPaymentMethodScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CompanyPaymentMethodScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CompanyPaymentMethodScalarWhereWithAggregatesInput>>;
  cloned_by_stripe_id?: InputMaybe<UuidWithAggregatesFilter>;
  company_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  customer?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  object_json?: InputMaybe<JsonWithAggregatesFilter>;
  stripe_id?: InputMaybe<StringWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type CompanyPaymentMethodUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutCompany_Payment_MethodsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  object_json?: InputMaybe<Scalars["JSON"]>;
  stripe_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_payment_method?: InputMaybe<StripePaymentMethodUpdateOneRequiredWithoutCompany_Payment_MethodsNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyPaymentMethodUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  object_json?: InputMaybe<Scalars["JSON"]>;
  stripe_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyPaymentMethodUpdateManyWithWhereWithoutCompanyInput = {
  data: CompanyPaymentMethodUpdateManyMutationInput;
  where: CompanyPaymentMethodScalarWhereInput;
};

export type CompanyPaymentMethodUpdateManyWithWhereWithoutStripe_Payment_MethodInput =
  {
    data: CompanyPaymentMethodUpdateManyMutationInput;
    where: CompanyPaymentMethodScalarWhereInput;
  };

export type CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<CompanyPaymentMethodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyPaymentMethodCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<CompanyPaymentMethodCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<CompanyPaymentMethodCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<CompanyPaymentMethodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompanyPaymentMethodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompanyPaymentMethodWhereUniqueInput>>;
  set?: InputMaybe<Array<CompanyPaymentMethodWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CompanyPaymentMethodUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: InputMaybe<
    Array<CompanyPaymentMethodUpdateManyWithWhereWithoutCompanyInput>
  >;
  upsert?: InputMaybe<
    Array<CompanyPaymentMethodUpsertWithWhereUniqueWithoutCompanyInput>
  >;
};

export type CompanyPaymentMethodUpdateManyWithoutStripe_Payment_MethodNestedInput =
  {
    connect?: InputMaybe<Array<CompanyPaymentMethodWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<CompanyPaymentMethodCreateOrConnectWithoutStripe_Payment_MethodInput>
    >;
    create?: InputMaybe<
      Array<CompanyPaymentMethodCreateWithoutStripe_Payment_MethodInput>
    >;
    createMany?: InputMaybe<CompanyPaymentMethodCreateManyStripe_Payment_MethodInputEnvelope>;
    delete?: InputMaybe<Array<CompanyPaymentMethodWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CompanyPaymentMethodScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CompanyPaymentMethodWhereUniqueInput>>;
    set?: InputMaybe<Array<CompanyPaymentMethodWhereUniqueInput>>;
    update?: InputMaybe<
      Array<CompanyPaymentMethodUpdateWithWhereUniqueWithoutStripe_Payment_MethodInput>
    >;
    updateMany?: InputMaybe<
      Array<CompanyPaymentMethodUpdateManyWithWhereWithoutStripe_Payment_MethodInput>
    >;
    upsert?: InputMaybe<
      Array<CompanyPaymentMethodUpsertWithWhereUniqueWithoutStripe_Payment_MethodInput>
    >;
  };

export type CompanyPaymentMethodUpdateWithWhereUniqueWithoutCompanyInput = {
  data: CompanyPaymentMethodUpdateWithoutCompanyInput;
  where: CompanyPaymentMethodWhereUniqueInput;
};

export type CompanyPaymentMethodUpdateWithWhereUniqueWithoutStripe_Payment_MethodInput =
  {
    data: CompanyPaymentMethodUpdateWithoutStripe_Payment_MethodInput;
    where: CompanyPaymentMethodWhereUniqueInput;
  };

export type CompanyPaymentMethodUpdateWithoutCompanyInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  object_json?: InputMaybe<Scalars["JSON"]>;
  stripe_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_payment_method?: InputMaybe<StripePaymentMethodUpdateOneRequiredWithoutCompany_Payment_MethodsNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyPaymentMethodUpdateWithoutStripe_Payment_MethodInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutCompany_Payment_MethodsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  object_json?: InputMaybe<Scalars["JSON"]>;
  stripe_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyPaymentMethodUpsertWithWhereUniqueWithoutCompanyInput = {
  create: CompanyPaymentMethodCreateWithoutCompanyInput;
  update: CompanyPaymentMethodUpdateWithoutCompanyInput;
  where: CompanyPaymentMethodWhereUniqueInput;
};

export type CompanyPaymentMethodUpsertWithWhereUniqueWithoutStripe_Payment_MethodInput =
  {
    create: CompanyPaymentMethodCreateWithoutStripe_Payment_MethodInput;
    update: CompanyPaymentMethodUpdateWithoutStripe_Payment_MethodInput;
    where: CompanyPaymentMethodWhereUniqueInput;
  };

export type CompanyPaymentMethodWhereInput = {
  AND?: InputMaybe<Array<CompanyPaymentMethodWhereInput>>;
  NOT?: InputMaybe<Array<CompanyPaymentMethodWhereInput>>;
  OR?: InputMaybe<Array<CompanyPaymentMethodWhereInput>>;
  cloned_by_stripe_id?: InputMaybe<UuidFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  company_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  object_json?: InputMaybe<JsonFilter>;
  stripe_id?: InputMaybe<StringFilter>;
  stripe_payment_method?: InputMaybe<StripePaymentMethodRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CompanyPaymentMethodWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  stripe_id?: InputMaybe<Scalars["String"]>;
};

export type CompanyRelationFilter = {
  is?: InputMaybe<CompanyWhereInput>;
  isNot?: InputMaybe<CompanyWhereInput>;
};

export enum CompanyScalarFieldEnum {
  About = "about",
  AmenitiesEnums = "amenities_enums",
  Caption = "caption",
  ContactEmail = "contact_email",
  CoverPhotoUrl = "cover_photo_url",
  CreatedAt = "created_at",
  DefaultServiceId = "default_service_id",
  FacebookUrl = "facebook_url",
  Id = "id",
  InstagramUrl = "instagram_url",
  LateCancellationCharge = "late_cancellation_charge",
  LateCancellationHours = "late_cancellation_hours",
  LogoUrl = "logo_url",
  Name = "name",
  NoShowCharge = "no_show_charge",
  OwnerId = "owner_id",
  PaymentType = "payment_type",
  RetailTaxRate = "retail_tax_rate",
  Slug = "slug",
  Status = "status",
  StripeAccessToken = "stripe_access_token",
  StripeChargesEnabled = "stripe_charges_enabled",
  StripeDetailsSubmitted = "stripe_details_submitted",
  StripeRefreshToken = "stripe_refresh_token",
  StripeUserId = "stripe_user_id",
  TimeslotSpacing = "timeslot_spacing",
  TwitterUrl = "twitter_url",
  UpdatedAt = "updated_at",
  UserPaysFees = "user_pays_fees",
  WebsiteUrl = "website_url"
}

export type CompanyScalarWhereInput = {
  AND?: InputMaybe<Array<CompanyScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompanyScalarWhereInput>>;
  OR?: InputMaybe<Array<CompanyScalarWhereInput>>;
  about?: InputMaybe<StringNullableFilter>;
  amenities_enums?: InputMaybe<EnumAmenityEnumNullableListFilter>;
  caption?: InputMaybe<StringNullableFilter>;
  contact_email?: InputMaybe<StringNullableFilter>;
  cover_photo_url?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  default_service_id?: InputMaybe<UuidNullableFilter>;
  facebook_url?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  instagram_url?: InputMaybe<StringNullableFilter>;
  late_cancellation_charge?: InputMaybe<FloatFilter>;
  late_cancellation_hours?: InputMaybe<IntFilter>;
  logo_url?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  no_show_charge?: InputMaybe<FloatFilter>;
  owner_id?: InputMaybe<UuidFilter>;
  payment_type?: InputMaybe<EnumPaymentTypeFilter>;
  retail_tax_rate?: InputMaybe<FloatNullableFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<StringFilter>;
  stripe_access_token?: InputMaybe<StringNullableFilter>;
  stripe_charges_enabled?: InputMaybe<BoolNullableFilter>;
  stripe_details_submitted?: InputMaybe<BoolNullableFilter>;
  stripe_refresh_token?: InputMaybe<StringNullableFilter>;
  stripe_user_id?: InputMaybe<StringNullableFilter>;
  timeslot_spacing?: InputMaybe<IntNullableFilter>;
  twitter_url?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_pays_fees?: InputMaybe<BoolFilter>;
  website_url?: InputMaybe<StringNullableFilter>;
};

export type CompanyScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CompanyScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CompanyScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CompanyScalarWhereWithAggregatesInput>>;
  about?: InputMaybe<StringNullableWithAggregatesFilter>;
  amenities_enums?: InputMaybe<EnumAmenityEnumNullableListFilter>;
  caption?: InputMaybe<StringNullableWithAggregatesFilter>;
  contact_email?: InputMaybe<StringNullableWithAggregatesFilter>;
  cover_photo_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  default_service_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  facebook_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  instagram_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  late_cancellation_charge?: InputMaybe<FloatWithAggregatesFilter>;
  late_cancellation_hours?: InputMaybe<IntWithAggregatesFilter>;
  logo_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  no_show_charge?: InputMaybe<FloatWithAggregatesFilter>;
  owner_id?: InputMaybe<UuidWithAggregatesFilter>;
  payment_type?: InputMaybe<EnumPaymentTypeWithAggregatesFilter>;
  retail_tax_rate?: InputMaybe<FloatNullableWithAggregatesFilter>;
  slug?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<StringWithAggregatesFilter>;
  stripe_access_token?: InputMaybe<StringNullableWithAggregatesFilter>;
  stripe_charges_enabled?: InputMaybe<BoolNullableWithAggregatesFilter>;
  stripe_details_submitted?: InputMaybe<BoolNullableWithAggregatesFilter>;
  stripe_refresh_token?: InputMaybe<StringNullableWithAggregatesFilter>;
  stripe_user_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  timeslot_spacing?: InputMaybe<IntNullableWithAggregatesFilter>;
  twitter_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_pays_fees?: InputMaybe<BoolWithAggregatesFilter>;
  website_url?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type CompanySumAggregate = {
  __typename?: "CompanySumAggregate";
  late_cancellation_charge?: Maybe<Scalars["Float"]>;
  late_cancellation_hours?: Maybe<Scalars["Int"]>;
  no_show_charge?: Maybe<Scalars["Float"]>;
  retail_tax_rate?: Maybe<Scalars["Float"]>;
  timeslot_spacing?: Maybe<Scalars["Int"]>;
};

export type CompanySumOrderByAggregateInput = {
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  retail_tax_rate?: InputMaybe<SortOrder>;
  timeslot_spacing?: InputMaybe<SortOrder>;
};

export type CompanyUpdateInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateManyMutationInput = {
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateManyWithWhereWithoutDefault_ServiceInput = {
  data: CompanyUpdateManyMutationInput;
  where: CompanyScalarWhereInput;
};

export type CompanyUpdateManyWithoutDefault_ServiceNestedInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyCreateOrConnectWithoutDefault_ServiceInput>
  >;
  create?: InputMaybe<Array<CompanyCreateWithoutDefault_ServiceInput>>;
  createMany?: InputMaybe<CompanyCreateManyDefault_ServiceInputEnvelope>;
  delete?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompanyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  set?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CompanyUpdateWithWhereUniqueWithoutDefault_ServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<CompanyUpdateManyWithWhereWithoutDefault_ServiceInput>
  >;
  upsert?: InputMaybe<
    Array<CompanyUpsertWithWhereUniqueWithoutDefault_ServiceInput>
  >;
};

export type CompanyUpdateOneRequiredWithoutAmenitiesNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutAmenitiesInput>;
  create?: InputMaybe<CompanyCreateWithoutAmenitiesInput>;
  update?: InputMaybe<CompanyUpdateWithoutAmenitiesInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutAmenitiesInput>;
};

export type CompanyUpdateOneRequiredWithoutCompany_Payment_MethodsNestedInput =
  {
    connect?: InputMaybe<CompanyWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutCompany_Payment_MethodsInput>;
    create?: InputMaybe<CompanyCreateWithoutCompany_Payment_MethodsInput>;
    update?: InputMaybe<CompanyUpdateWithoutCompany_Payment_MethodsInput>;
    upsert?: InputMaybe<CompanyUpsertWithoutCompany_Payment_MethodsInput>;
  };

export type CompanyUpdateOneRequiredWithoutLocationsNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutLocationsInput>;
  create?: InputMaybe<CompanyCreateWithoutLocationsInput>;
  update?: InputMaybe<CompanyUpdateWithoutLocationsInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutLocationsInput>;
};

export type CompanyUpdateOneRequiredWithoutMenuNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutMenuInput>;
  create?: InputMaybe<CompanyCreateWithoutMenuInput>;
  update?: InputMaybe<CompanyUpdateWithoutMenuInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutMenuInput>;
};

export type CompanyUpdateOneRequiredWithoutRefundNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutRefundInput>;
  create?: InputMaybe<CompanyCreateWithoutRefundInput>;
  update?: InputMaybe<CompanyUpdateWithoutRefundInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutRefundInput>;
};

export type CompanyUpdateOneRequiredWithoutUser_DescriptionsNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutUser_DescriptionsInput>;
  create?: InputMaybe<CompanyCreateWithoutUser_DescriptionsInput>;
  update?: InputMaybe<CompanyUpdateWithoutUser_DescriptionsInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutUser_DescriptionsInput>;
};

export type CompanyUpdateOneWithoutFacilityNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutFacilityInput>;
  create?: InputMaybe<CompanyCreateWithoutFacilityInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<CompanyUpdateWithoutFacilityInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutFacilityInput>;
};

export type CompanyUpdateOneWithoutMenu_SectionsNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutMenu_SectionsInput>;
  create?: InputMaybe<CompanyCreateWithoutMenu_SectionsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<CompanyUpdateWithoutMenu_SectionsInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutMenu_SectionsInput>;
};

export type CompanyUpdateOneWithoutOwnerNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutOwnerInput>;
  create?: InputMaybe<CompanyCreateWithoutOwnerInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<CompanyUpdateWithoutOwnerInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutOwnerInput>;
};

export type CompanyUpdateOneWithoutRolesNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutRolesInput>;
  create?: InputMaybe<CompanyCreateWithoutRolesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<CompanyUpdateWithoutRolesInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutRolesInput>;
};

export type CompanyUpdateOneWithoutUsersNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<CompanyCreateWithoutUsersInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<CompanyUpdateWithoutUsersInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutUsersInput>;
};

export type CompanyUpdateWithWhereUniqueWithoutDefault_ServiceInput = {
  data: CompanyUpdateWithoutDefault_ServiceInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyUpdateWithoutAmenitiesInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutCompany_Payment_MethodsInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutDefault_ServiceInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutFacilityInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutLocationsInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutMenuInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutMenu_SectionsInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutOwnerInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutRefundInput = {
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutRolesInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutUser_DescriptionsInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutJoined_CompanyNestedInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateWithoutUsersInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutCompanyNestedInput>;
  about?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amenities?: InputMaybe<CompanyAmenityUpdateManyWithoutCompanyNestedInput>;
  amenities_enums?: InputMaybe<CompanyUpdateamenities_EnumsInput>;
  caption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutCompanyNestedInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_service?: InputMaybe<ServiceUpdateOneWithoutCompaniesNestedInput>;
  facebook_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutCompanyNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagram_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutCompanyNestedInput>;
  logo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateManyWithoutCompanyNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutCompanyNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCompanyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  retail_tax_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutCompanyNestedInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_charges_enabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_details_submitted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripe_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeslot_spacing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  twitter_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateamenities_EnumsInput = {
  push?: InputMaybe<Array<AmenityEnum>>;
  set?: InputMaybe<Array<AmenityEnum>>;
};

export type CompanyUpsertWithWhereUniqueWithoutDefault_ServiceInput = {
  create: CompanyCreateWithoutDefault_ServiceInput;
  update: CompanyUpdateWithoutDefault_ServiceInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyUpsertWithoutAmenitiesInput = {
  create: CompanyCreateWithoutAmenitiesInput;
  update: CompanyUpdateWithoutAmenitiesInput;
};

export type CompanyUpsertWithoutCompany_Payment_MethodsInput = {
  create: CompanyCreateWithoutCompany_Payment_MethodsInput;
  update: CompanyUpdateWithoutCompany_Payment_MethodsInput;
};

export type CompanyUpsertWithoutFacilityInput = {
  create: CompanyCreateWithoutFacilityInput;
  update: CompanyUpdateWithoutFacilityInput;
};

export type CompanyUpsertWithoutLocationsInput = {
  create: CompanyCreateWithoutLocationsInput;
  update: CompanyUpdateWithoutLocationsInput;
};

export type CompanyUpsertWithoutMenuInput = {
  create: CompanyCreateWithoutMenuInput;
  update: CompanyUpdateWithoutMenuInput;
};

export type CompanyUpsertWithoutMenu_SectionsInput = {
  create: CompanyCreateWithoutMenu_SectionsInput;
  update: CompanyUpdateWithoutMenu_SectionsInput;
};

export type CompanyUpsertWithoutOwnerInput = {
  create: CompanyCreateWithoutOwnerInput;
  update: CompanyUpdateWithoutOwnerInput;
};

export type CompanyUpsertWithoutRefundInput = {
  create: CompanyCreateWithoutRefundInput;
  update: CompanyUpdateWithoutRefundInput;
};

export type CompanyUpsertWithoutRolesInput = {
  create: CompanyCreateWithoutRolesInput;
  update: CompanyUpdateWithoutRolesInput;
};

export type CompanyUpsertWithoutUser_DescriptionsInput = {
  create: CompanyCreateWithoutUser_DescriptionsInput;
  update: CompanyUpdateWithoutUser_DescriptionsInput;
};

export type CompanyUpsertWithoutUsersInput = {
  create: CompanyCreateWithoutUsersInput;
  update: CompanyUpdateWithoutUsersInput;
};

export type CompanyUserDescription = {
  __typename?: "CompanyUserDescription";
  company: Company;
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
};

export type CompanyUserDescriptionCountAggregate = {
  __typename?: "CompanyUserDescriptionCountAggregate";
  _all: Scalars["Int"];
  company_id: Scalars["Int"];
  created_at: Scalars["Int"];
  description: Scalars["Int"];
  id: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type CompanyUserDescriptionCountOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type CompanyUserDescriptionCreateInput = {
  company: CompanyCreateNestedOneWithoutUser_DescriptionsInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutCompany_DescriptionsInput;
};

export type CompanyUserDescriptionCreateManyCompanyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type CompanyUserDescriptionCreateManyCompanyInputEnvelope = {
  data: Array<CompanyUserDescriptionCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CompanyUserDescriptionCreateManyInput = {
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type CompanyUserDescriptionCreateManyUserInput = {
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyUserDescriptionCreateManyUserInputEnvelope = {
  data: Array<CompanyUserDescriptionCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CompanyUserDescriptionCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<CompanyUserDescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyUserDescriptionCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<CompanyUserDescriptionCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<CompanyUserDescriptionCreateManyCompanyInputEnvelope>;
};

export type CompanyUserDescriptionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CompanyUserDescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyUserDescriptionCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<CompanyUserDescriptionCreateWithoutUserInput>>;
  createMany?: InputMaybe<CompanyUserDescriptionCreateManyUserInputEnvelope>;
};

export type CompanyUserDescriptionCreateOrConnectWithoutCompanyInput = {
  create: CompanyUserDescriptionCreateWithoutCompanyInput;
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type CompanyUserDescriptionCreateOrConnectWithoutUserInput = {
  create: CompanyUserDescriptionCreateWithoutUserInput;
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type CompanyUserDescriptionCreateWithoutCompanyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutCompany_DescriptionsInput;
};

export type CompanyUserDescriptionCreateWithoutUserInput = {
  company: CompanyCreateNestedOneWithoutUser_DescriptionsInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type CompanyUserDescriptionGroupBy = {
  __typename?: "CompanyUserDescriptionGroupBy";
  _count?: Maybe<CompanyUserDescriptionCountAggregate>;
  _max?: Maybe<CompanyUserDescriptionMaxAggregate>;
  _min?: Maybe<CompanyUserDescriptionMinAggregate>;
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
};

export type CompanyUserDescriptionListRelationFilter = {
  every?: InputMaybe<CompanyUserDescriptionWhereInput>;
  none?: InputMaybe<CompanyUserDescriptionWhereInput>;
  some?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type CompanyUserDescriptionMaxAggregate = {
  __typename?: "CompanyUserDescriptionMaxAggregate";
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type CompanyUserDescriptionMaxOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type CompanyUserDescriptionMinAggregate = {
  __typename?: "CompanyUserDescriptionMinAggregate";
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type CompanyUserDescriptionMinOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type CompanyUserDescriptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CompanyUserDescriptionOrderByWithAggregationInput = {
  _count?: InputMaybe<CompanyUserDescriptionCountOrderByAggregateInput>;
  _max?: InputMaybe<CompanyUserDescriptionMaxOrderByAggregateInput>;
  _min?: InputMaybe<CompanyUserDescriptionMinOrderByAggregateInput>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type CompanyUserDescriptionOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export enum CompanyUserDescriptionScalarFieldEnum {
  CompanyId = "company_id",
  CreatedAt = "created_at",
  Description = "description",
  Id = "id",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type CompanyUserDescriptionScalarWhereInput = {
  AND?: InputMaybe<Array<CompanyUserDescriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompanyUserDescriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<CompanyUserDescriptionScalarWhereInput>>;
  company_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type CompanyUserDescriptionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CompanyUserDescriptionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CompanyUserDescriptionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CompanyUserDescriptionScalarWhereWithAggregatesInput>>;
  company_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export type CompanyUserDescriptionUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutUser_DescriptionsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCompany_DescriptionsNestedInput>;
};

export type CompanyUserDescriptionUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyUserDescriptionUpdateManyWithWhereWithoutCompanyInput = {
  data: CompanyUserDescriptionUpdateManyMutationInput;
  where: CompanyUserDescriptionScalarWhereInput;
};

export type CompanyUserDescriptionUpdateManyWithWhereWithoutUserInput = {
  data: CompanyUserDescriptionUpdateManyMutationInput;
  where: CompanyUserDescriptionScalarWhereInput;
};

export type CompanyUserDescriptionUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<CompanyUserDescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyUserDescriptionCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<CompanyUserDescriptionCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<CompanyUserDescriptionCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<CompanyUserDescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompanyUserDescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompanyUserDescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<CompanyUserDescriptionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CompanyUserDescriptionUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: InputMaybe<
    Array<CompanyUserDescriptionUpdateManyWithWhereWithoutCompanyInput>
  >;
  upsert?: InputMaybe<
    Array<CompanyUserDescriptionUpsertWithWhereUniqueWithoutCompanyInput>
  >;
};

export type CompanyUserDescriptionUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<CompanyUserDescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CompanyUserDescriptionCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<CompanyUserDescriptionCreateWithoutUserInput>>;
  createMany?: InputMaybe<CompanyUserDescriptionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CompanyUserDescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompanyUserDescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompanyUserDescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<CompanyUserDescriptionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CompanyUserDescriptionUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<CompanyUserDescriptionUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<CompanyUserDescriptionUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type CompanyUserDescriptionUpdateWithWhereUniqueWithoutCompanyInput = {
  data: CompanyUserDescriptionUpdateWithoutCompanyInput;
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type CompanyUserDescriptionUpdateWithWhereUniqueWithoutUserInput = {
  data: CompanyUserDescriptionUpdateWithoutUserInput;
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type CompanyUserDescriptionUpdateWithoutCompanyInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCompany_DescriptionsNestedInput>;
};

export type CompanyUserDescriptionUpdateWithoutUserInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutUser_DescriptionsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyUserDescriptionUpsertWithWhereUniqueWithoutCompanyInput = {
  create: CompanyUserDescriptionCreateWithoutCompanyInput;
  update: CompanyUserDescriptionUpdateWithoutCompanyInput;
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type CompanyUserDescriptionUpsertWithWhereUniqueWithoutUserInput = {
  create: CompanyUserDescriptionCreateWithoutUserInput;
  update: CompanyUserDescriptionUpdateWithoutUserInput;
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type CompanyUserDescriptionUser_IdCompany_IdCompoundUniqueInput = {
  company_id: Scalars["String"];
  user_id: Scalars["String"];
};

export type CompanyUserDescriptionWhereInput = {
  AND?: InputMaybe<Array<CompanyUserDescriptionWhereInput>>;
  NOT?: InputMaybe<Array<CompanyUserDescriptionWhereInput>>;
  OR?: InputMaybe<Array<CompanyUserDescriptionWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  company_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type CompanyUserDescriptionWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  user_id_company_id?: InputMaybe<CompanyUserDescriptionUser_IdCompany_IdCompoundUniqueInput>;
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  Refund?: InputMaybe<RefundListRelationFilter>;
  about?: InputMaybe<StringNullableFilter>;
  amenities?: InputMaybe<CompanyAmenityListRelationFilter>;
  amenities_enums?: InputMaybe<EnumAmenityEnumNullableListFilter>;
  caption?: InputMaybe<StringNullableFilter>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodListRelationFilter>;
  contact_email?: InputMaybe<StringNullableFilter>;
  cover_photo_url?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  default_service?: InputMaybe<ServiceRelationFilter>;
  default_service_id?: InputMaybe<UuidNullableFilter>;
  facebook_url?: InputMaybe<StringNullableFilter>;
  facility?: InputMaybe<FacilityListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  instagram_url?: InputMaybe<StringNullableFilter>;
  late_cancellation_charge?: InputMaybe<FloatFilter>;
  late_cancellation_hours?: InputMaybe<IntFilter>;
  locations?: InputMaybe<LocationListRelationFilter>;
  logo_url?: InputMaybe<StringNullableFilter>;
  menu?: InputMaybe<MenuListRelationFilter>;
  menu_sections?: InputMaybe<MenuSectionListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  no_show_charge?: InputMaybe<FloatFilter>;
  owner?: InputMaybe<UserRelationFilter>;
  owner_id?: InputMaybe<UuidFilter>;
  payment_type?: InputMaybe<EnumPaymentTypeFilter>;
  retail_tax_rate?: InputMaybe<FloatNullableFilter>;
  roles?: InputMaybe<RoleListRelationFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<StringFilter>;
  stripe_access_token?: InputMaybe<StringNullableFilter>;
  stripe_charges_enabled?: InputMaybe<BoolNullableFilter>;
  stripe_details_submitted?: InputMaybe<BoolNullableFilter>;
  stripe_refresh_token?: InputMaybe<StringNullableFilter>;
  stripe_user_id?: InputMaybe<StringNullableFilter>;
  timeslot_spacing?: InputMaybe<IntNullableFilter>;
  twitter_url?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_descriptions?: InputMaybe<CompanyUserDescriptionListRelationFilter>;
  user_pays_fees?: InputMaybe<BoolFilter>;
  users?: InputMaybe<UserListRelationFilter>;
  website_url?: InputMaybe<StringNullableFilter>;
};

export type CompanyWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  owner_id?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  stripe_user_id?: InputMaybe<Scalars["String"]>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["DateTime"]>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars["Decimal"]>;
  gt?: InputMaybe<Scalars["Decimal"]>;
  gte?: InputMaybe<Scalars["Decimal"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]>>;
  lt?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type DecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars["Decimal"]>;
  gt?: InputMaybe<Scalars["Decimal"]>;
  gte?: InputMaybe<Scalars["Decimal"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]>>;
  lt?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type EnumAmenityEnumNullableListFilter = {
  equals?: InputMaybe<Array<AmenityEnum>>;
  has?: InputMaybe<AmenityEnum>;
  hasEvery?: InputMaybe<Array<AmenityEnum>>;
  hasSome?: InputMaybe<Array<AmenityEnum>>;
  isEmpty?: InputMaybe<Scalars["Boolean"]>;
};

export type EnumCancellationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CancellationType>;
};

export type EnumCancellationTypeFilter = {
  equals?: InputMaybe<CancellationType>;
  in?: InputMaybe<Array<CancellationType>>;
  not?: InputMaybe<NestedEnumCancellationTypeFilter>;
  notIn?: InputMaybe<Array<CancellationType>>;
};

export type EnumCancellationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCancellationTypeFilter>;
  _min?: InputMaybe<NestedEnumCancellationTypeFilter>;
  equals?: InputMaybe<CancellationType>;
  in?: InputMaybe<Array<CancellationType>>;
  not?: InputMaybe<NestedEnumCancellationTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CancellationType>>;
};

export type EnumNotificationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<NotificationType>;
};

export type EnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type EnumNotificationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNotificationTypeFilter>;
  _min?: InputMaybe<NestedEnumNotificationTypeFilter>;
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type EnumPaymentMethodFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentMethod>;
};

export type EnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumPaymentMethodWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentMethodFilter>;
  _min?: InputMaybe<NestedEnumPaymentMethodFilter>;
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumPaymentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentStatus>;
};

export type EnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type EnumPaymentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentStatusFilter>;
  _min?: InputMaybe<NestedEnumPaymentStatusFilter>;
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type EnumPaymentTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentType>;
};

export type EnumPaymentTypeFilter = {
  equals?: InputMaybe<PaymentType>;
  in?: InputMaybe<Array<PaymentType>>;
  not?: InputMaybe<NestedEnumPaymentTypeFilter>;
  notIn?: InputMaybe<Array<PaymentType>>;
};

export type EnumPaymentTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentTypeFilter>;
  _min?: InputMaybe<NestedEnumPaymentTypeFilter>;
  equals?: InputMaybe<PaymentType>;
  in?: InputMaybe<Array<PaymentType>>;
  not?: InputMaybe<NestedEnumPaymentTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentType>>;
};

export type EnumReminderTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ReminderType>;
};

export type EnumReminderTypeFilter = {
  equals?: InputMaybe<ReminderType>;
  in?: InputMaybe<Array<ReminderType>>;
  not?: InputMaybe<NestedEnumReminderTypeFilter>;
  notIn?: InputMaybe<Array<ReminderType>>;
};

export type EnumReminderTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumReminderTypeFilter>;
  _min?: InputMaybe<NestedEnumReminderTypeFilter>;
  equals?: InputMaybe<ReminderType>;
  in?: InputMaybe<Array<ReminderType>>;
  not?: InputMaybe<NestedEnumReminderTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ReminderType>>;
};

export type EnumRoleStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<RoleStatus>;
};

export type EnumRoleStatusFilter = {
  equals?: InputMaybe<RoleStatus>;
  in?: InputMaybe<Array<RoleStatus>>;
  not?: InputMaybe<NestedEnumRoleStatusFilter>;
  notIn?: InputMaybe<Array<RoleStatus>>;
};

export type EnumRoleStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRoleStatusFilter>;
  _min?: InputMaybe<NestedEnumRoleStatusFilter>;
  equals?: InputMaybe<RoleStatus>;
  in?: InputMaybe<Array<RoleStatus>>;
  not?: InputMaybe<NestedEnumRoleStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RoleStatus>>;
};

export type EnumRolesFieldUpdateOperationsInput = {
  set?: InputMaybe<Roles>;
};

export type EnumRolesFilter = {
  equals?: InputMaybe<Roles>;
  in?: InputMaybe<Array<Roles>>;
  not?: InputMaybe<NestedEnumRolesFilter>;
  notIn?: InputMaybe<Array<Roles>>;
};

export type EnumRolesWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRolesFilter>;
  _min?: InputMaybe<NestedEnumRolesFilter>;
  equals?: InputMaybe<Roles>;
  in?: InputMaybe<Array<Roles>>;
  not?: InputMaybe<NestedEnumRolesWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Roles>>;
};

export type EnumSchedulingPriorityNullableFilter = {
  equals?: InputMaybe<SchedulingPriority>;
  in?: InputMaybe<Array<SchedulingPriority>>;
  not?: InputMaybe<NestedEnumSchedulingPriorityNullableFilter>;
  notIn?: InputMaybe<Array<SchedulingPriority>>;
};

export type EnumSchedulingPriorityNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumSchedulingPriorityNullableFilter>;
  _min?: InputMaybe<NestedEnumSchedulingPriorityNullableFilter>;
  equals?: InputMaybe<SchedulingPriority>;
  in?: InputMaybe<Array<SchedulingPriority>>;
  not?: InputMaybe<NestedEnumSchedulingPriorityNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<SchedulingPriority>>;
};

export type EnumServiceCategoryFieldUpdateOperationsInput = {
  set?: InputMaybe<ServiceCategory>;
};

export type EnumServiceCategoryFilter = {
  equals?: InputMaybe<ServiceCategory>;
  in?: InputMaybe<Array<ServiceCategory>>;
  not?: InputMaybe<NestedEnumServiceCategoryFilter>;
  notIn?: InputMaybe<Array<ServiceCategory>>;
};

export type EnumServiceCategoryWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumServiceCategoryFilter>;
  _min?: InputMaybe<NestedEnumServiceCategoryFilter>;
  equals?: InputMaybe<ServiceCategory>;
  in?: InputMaybe<Array<ServiceCategory>>;
  not?: InputMaybe<NestedEnumServiceCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ServiceCategory>>;
};

export type EnumStripeTxStatusNullableFilter = {
  equals?: InputMaybe<StripeTxStatus>;
  in?: InputMaybe<Array<StripeTxStatus>>;
  not?: InputMaybe<NestedEnumStripeTxStatusNullableFilter>;
  notIn?: InputMaybe<Array<StripeTxStatus>>;
};

export type EnumStripeTxStatusNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumStripeTxStatusNullableFilter>;
  _min?: InputMaybe<NestedEnumStripeTxStatusNullableFilter>;
  equals?: InputMaybe<StripeTxStatus>;
  in?: InputMaybe<Array<StripeTxStatus>>;
  not?: InputMaybe<NestedEnumStripeTxStatusNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StripeTxStatus>>;
};

export type EnumStripeTxTypeNullableFilter = {
  equals?: InputMaybe<StripeTxType>;
  in?: InputMaybe<Array<StripeTxType>>;
  not?: InputMaybe<NestedEnumStripeTxTypeNullableFilter>;
  notIn?: InputMaybe<Array<StripeTxType>>;
};

export type EnumStripeTxTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumStripeTxTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumStripeTxTypeNullableFilter>;
  equals?: InputMaybe<StripeTxType>;
  in?: InputMaybe<Array<StripeTxType>>;
  not?: InputMaybe<NestedEnumStripeTxTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StripeTxType>>;
};

export type EnumTippingTypeNullableFilter = {
  equals?: InputMaybe<TippingType>;
  in?: InputMaybe<Array<TippingType>>;
  not?: InputMaybe<NestedEnumTippingTypeNullableFilter>;
  notIn?: InputMaybe<Array<TippingType>>;
};

export type EnumTippingTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumTippingTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumTippingTypeNullableFilter>;
  equals?: InputMaybe<TippingType>;
  in?: InputMaybe<Array<TippingType>>;
  not?: InputMaybe<NestedEnumTippingTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<TippingType>>;
};

export type Facility = {
  __typename?: "Facility";
  _count?: Maybe<FacilityCount>;
  capacity: Scalars["Int"];
  company?: Maybe<Company>;
  company_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  facility_services: Array<FacilityService>;
  id: Scalars["String"];
  location: Location;
  location_id: Scalars["String"];
  name: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type FacilityFacility_ServicesArgs = {
  cursor?: InputMaybe<FacilityServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<FacilityServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FacilityServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type FacilityAvgAggregate = {
  __typename?: "FacilityAvgAggregate";
  capacity?: Maybe<Scalars["Float"]>;
};

export type FacilityAvgOrderByAggregateInput = {
  capacity?: InputMaybe<SortOrder>;
};

export type FacilityCount = {
  __typename?: "FacilityCount";
  facility_services: Scalars["Int"];
};

export type FacilityCountFacility_ServicesArgs = {
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type FacilityCountAggregate = {
  __typename?: "FacilityCountAggregate";
  _all: Scalars["Int"];
  capacity: Scalars["Int"];
  company_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  location_id: Scalars["Int"];
  name: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type FacilityCountOrderByAggregateInput = {
  capacity?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type FacilityCreateInput = {
  capacity: Scalars["Int"];
  company?: InputMaybe<CompanyCreateNestedOneWithoutFacilityInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  facility_services?: InputMaybe<FacilityServiceCreateNestedManyWithoutFacilityInput>;
  id?: InputMaybe<Scalars["String"]>;
  location: LocationCreateNestedOneWithoutFacilityInput;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityCreateManyCompanyInput = {
  capacity: Scalars["Int"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location_id: Scalars["String"];
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityCreateManyCompanyInputEnvelope = {
  data: Array<FacilityCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type FacilityCreateManyInput = {
  capacity: Scalars["Int"];
  company_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location_id: Scalars["String"];
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityCreateManyLocationInput = {
  capacity: Scalars["Int"];
  company_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityCreateManyLocationInputEnvelope = {
  data: Array<FacilityCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type FacilityCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FacilityCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<FacilityCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<FacilityCreateManyCompanyInputEnvelope>;
};

export type FacilityCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FacilityCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<FacilityCreateWithoutLocationInput>>;
  createMany?: InputMaybe<FacilityCreateManyLocationInputEnvelope>;
};

export type FacilityCreateNestedOneWithoutFacility_ServicesInput = {
  connect?: InputMaybe<FacilityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FacilityCreateOrConnectWithoutFacility_ServicesInput>;
  create?: InputMaybe<FacilityCreateWithoutFacility_ServicesInput>;
};

export type FacilityCreateOrConnectWithoutCompanyInput = {
  create: FacilityCreateWithoutCompanyInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutFacility_ServicesInput = {
  create: FacilityCreateWithoutFacility_ServicesInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutLocationInput = {
  create: FacilityCreateWithoutLocationInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateWithoutCompanyInput = {
  capacity: Scalars["Int"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  facility_services?: InputMaybe<FacilityServiceCreateNestedManyWithoutFacilityInput>;
  id?: InputMaybe<Scalars["String"]>;
  location: LocationCreateNestedOneWithoutFacilityInput;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityCreateWithoutFacility_ServicesInput = {
  capacity: Scalars["Int"];
  company?: InputMaybe<CompanyCreateNestedOneWithoutFacilityInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location: LocationCreateNestedOneWithoutFacilityInput;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityCreateWithoutLocationInput = {
  capacity: Scalars["Int"];
  company?: InputMaybe<CompanyCreateNestedOneWithoutFacilityInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  facility_services?: InputMaybe<FacilityServiceCreateNestedManyWithoutFacilityInput>;
  id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityGroupBy = {
  __typename?: "FacilityGroupBy";
  _avg?: Maybe<FacilityAvgAggregate>;
  _count?: Maybe<FacilityCountAggregate>;
  _max?: Maybe<FacilityMaxAggregate>;
  _min?: Maybe<FacilityMinAggregate>;
  _sum?: Maybe<FacilitySumAggregate>;
  capacity: Scalars["Int"];
  company_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  location_id: Scalars["String"];
  name: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type FacilityListRelationFilter = {
  every?: InputMaybe<FacilityWhereInput>;
  none?: InputMaybe<FacilityWhereInput>;
  some?: InputMaybe<FacilityWhereInput>;
};

export type FacilityMaxAggregate = {
  __typename?: "FacilityMaxAggregate";
  capacity?: Maybe<Scalars["Int"]>;
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type FacilityMaxOrderByAggregateInput = {
  capacity?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type FacilityMinAggregate = {
  __typename?: "FacilityMinAggregate";
  capacity?: Maybe<Scalars["Int"]>;
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type FacilityMinOrderByAggregateInput = {
  capacity?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type FacilityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FacilityOrderByWithAggregationInput = {
  _avg?: InputMaybe<FacilityAvgOrderByAggregateInput>;
  _count?: InputMaybe<FacilityCountOrderByAggregateInput>;
  _max?: InputMaybe<FacilityMaxOrderByAggregateInput>;
  _min?: InputMaybe<FacilityMinOrderByAggregateInput>;
  _sum?: InputMaybe<FacilitySumOrderByAggregateInput>;
  capacity?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type FacilityOrderByWithRelationInput = {
  capacity?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  company_id?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  facility_services?: InputMaybe<FacilityServiceOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByWithRelationInput>;
  location_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type FacilityRelationFilter = {
  is?: InputMaybe<FacilityWhereInput>;
  isNot?: InputMaybe<FacilityWhereInput>;
};

export enum FacilityScalarFieldEnum {
  Capacity = "capacity",
  CompanyId = "company_id",
  CreatedAt = "created_at",
  Id = "id",
  LocationId = "location_id",
  Name = "name",
  UpdatedAt = "updated_at"
}

export type FacilityScalarWhereInput = {
  AND?: InputMaybe<Array<FacilityScalarWhereInput>>;
  NOT?: InputMaybe<Array<FacilityScalarWhereInput>>;
  OR?: InputMaybe<Array<FacilityScalarWhereInput>>;
  capacity?: InputMaybe<IntFilter>;
  company_id?: InputMaybe<UuidNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  location_id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type FacilityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<FacilityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<FacilityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<FacilityScalarWhereWithAggregatesInput>>;
  capacity?: InputMaybe<IntWithAggregatesFilter>;
  company_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  location_id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type FacilityService = {
  __typename?: "FacilityService";
  created_at: Scalars["DateTime"];
  facility?: Maybe<Facility>;
  facility_id?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  service?: Maybe<Service>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type FacilityServiceCountAggregate = {
  __typename?: "FacilityServiceCountAggregate";
  _all: Scalars["Int"];
  created_at: Scalars["Int"];
  facility_id: Scalars["Int"];
  id: Scalars["Int"];
  service_id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type FacilityServiceCountOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  facility_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type FacilityServiceCreateInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  facility?: InputMaybe<FacilityCreateNestedOneWithoutFacility_ServicesInput>;
  id?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutFacilitiesInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityServiceCreateManyFacilityInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  service_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityServiceCreateManyFacilityInputEnvelope = {
  data: Array<FacilityServiceCreateManyFacilityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type FacilityServiceCreateManyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  facility_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  service_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityServiceCreateManyServiceInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  facility_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityServiceCreateManyServiceInputEnvelope = {
  data: Array<FacilityServiceCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type FacilityServiceCreateNestedManyWithoutFacilityInput = {
  connect?: InputMaybe<Array<FacilityServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FacilityServiceCreateOrConnectWithoutFacilityInput>
  >;
  create?: InputMaybe<Array<FacilityServiceCreateWithoutFacilityInput>>;
  createMany?: InputMaybe<FacilityServiceCreateManyFacilityInputEnvelope>;
};

export type FacilityServiceCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<FacilityServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FacilityServiceCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<FacilityServiceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<FacilityServiceCreateManyServiceInputEnvelope>;
};

export type FacilityServiceCreateOrConnectWithoutFacilityInput = {
  create: FacilityServiceCreateWithoutFacilityInput;
  where: FacilityServiceWhereUniqueInput;
};

export type FacilityServiceCreateOrConnectWithoutServiceInput = {
  create: FacilityServiceCreateWithoutServiceInput;
  where: FacilityServiceWhereUniqueInput;
};

export type FacilityServiceCreateWithoutFacilityInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutFacilitiesInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityServiceCreateWithoutServiceInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  facility?: InputMaybe<FacilityCreateNestedOneWithoutFacility_ServicesInput>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FacilityServiceGroupBy = {
  __typename?: "FacilityServiceGroupBy";
  _count?: Maybe<FacilityServiceCountAggregate>;
  _max?: Maybe<FacilityServiceMaxAggregate>;
  _min?: Maybe<FacilityServiceMinAggregate>;
  created_at: Scalars["DateTime"];
  facility_id?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  service_id?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type FacilityServiceListRelationFilter = {
  every?: InputMaybe<FacilityServiceWhereInput>;
  none?: InputMaybe<FacilityServiceWhereInput>;
  some?: InputMaybe<FacilityServiceWhereInput>;
};

export type FacilityServiceMaxAggregate = {
  __typename?: "FacilityServiceMaxAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  facility_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type FacilityServiceMaxOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  facility_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type FacilityServiceMinAggregate = {
  __typename?: "FacilityServiceMinAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  facility_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type FacilityServiceMinOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  facility_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type FacilityServiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FacilityServiceOrderByWithAggregationInput = {
  _count?: InputMaybe<FacilityServiceCountOrderByAggregateInput>;
  _max?: InputMaybe<FacilityServiceMaxOrderByAggregateInput>;
  _min?: InputMaybe<FacilityServiceMinOrderByAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  facility_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type FacilityServiceOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  facility?: InputMaybe<FacilityOrderByWithRelationInput>;
  facility_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  service_id?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum FacilityServiceScalarFieldEnum {
  CreatedAt = "created_at",
  FacilityId = "facility_id",
  Id = "id",
  ServiceId = "service_id",
  UpdatedAt = "updated_at"
}

export type FacilityServiceScalarWhereInput = {
  AND?: InputMaybe<Array<FacilityServiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<FacilityServiceScalarWhereInput>>;
  OR?: InputMaybe<Array<FacilityServiceScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  facility_id?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  service_id?: InputMaybe<UuidNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type FacilityServiceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<FacilityServiceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<FacilityServiceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<FacilityServiceScalarWhereWithAggregatesInput>>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  facility_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  service_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type FacilityServiceUpdateInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateOneWithoutFacility_ServicesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutFacilitiesNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityServiceUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityServiceUpdateManyWithWhereWithoutFacilityInput = {
  data: FacilityServiceUpdateManyMutationInput;
  where: FacilityServiceScalarWhereInput;
};

export type FacilityServiceUpdateManyWithWhereWithoutServiceInput = {
  data: FacilityServiceUpdateManyMutationInput;
  where: FacilityServiceScalarWhereInput;
};

export type FacilityServiceUpdateManyWithoutFacilityNestedInput = {
  connect?: InputMaybe<Array<FacilityServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FacilityServiceCreateOrConnectWithoutFacilityInput>
  >;
  create?: InputMaybe<Array<FacilityServiceCreateWithoutFacilityInput>>;
  createMany?: InputMaybe<FacilityServiceCreateManyFacilityInputEnvelope>;
  delete?: InputMaybe<Array<FacilityServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FacilityServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FacilityServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<FacilityServiceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<FacilityServiceUpdateWithWhereUniqueWithoutFacilityInput>
  >;
  updateMany?: InputMaybe<
    Array<FacilityServiceUpdateManyWithWhereWithoutFacilityInput>
  >;
  upsert?: InputMaybe<
    Array<FacilityServiceUpsertWithWhereUniqueWithoutFacilityInput>
  >;
};

export type FacilityServiceUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<FacilityServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FacilityServiceCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<FacilityServiceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<FacilityServiceCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<FacilityServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FacilityServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FacilityServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<FacilityServiceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<FacilityServiceUpdateWithWhereUniqueWithoutServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<FacilityServiceUpdateManyWithWhereWithoutServiceInput>
  >;
  upsert?: InputMaybe<
    Array<FacilityServiceUpsertWithWhereUniqueWithoutServiceInput>
  >;
};

export type FacilityServiceUpdateWithWhereUniqueWithoutFacilityInput = {
  data: FacilityServiceUpdateWithoutFacilityInput;
  where: FacilityServiceWhereUniqueInput;
};

export type FacilityServiceUpdateWithWhereUniqueWithoutServiceInput = {
  data: FacilityServiceUpdateWithoutServiceInput;
  where: FacilityServiceWhereUniqueInput;
};

export type FacilityServiceUpdateWithoutFacilityInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutFacilitiesNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityServiceUpdateWithoutServiceInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateOneWithoutFacility_ServicesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityServiceUpsertWithWhereUniqueWithoutFacilityInput = {
  create: FacilityServiceCreateWithoutFacilityInput;
  update: FacilityServiceUpdateWithoutFacilityInput;
  where: FacilityServiceWhereUniqueInput;
};

export type FacilityServiceUpsertWithWhereUniqueWithoutServiceInput = {
  create: FacilityServiceCreateWithoutServiceInput;
  update: FacilityServiceUpdateWithoutServiceInput;
  where: FacilityServiceWhereUniqueInput;
};

export type FacilityServiceWhereInput = {
  AND?: InputMaybe<Array<FacilityServiceWhereInput>>;
  NOT?: InputMaybe<Array<FacilityServiceWhereInput>>;
  OR?: InputMaybe<Array<FacilityServiceWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  facility?: InputMaybe<FacilityRelationFilter>;
  facility_id?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  service_id?: InputMaybe<UuidNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type FacilityServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type FacilitySumAggregate = {
  __typename?: "FacilitySumAggregate";
  capacity?: Maybe<Scalars["Int"]>;
};

export type FacilitySumOrderByAggregateInput = {
  capacity?: InputMaybe<SortOrder>;
};

export type FacilityUpdateInput = {
  capacity?: InputMaybe<IntFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutFacilityNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facility_services?: InputMaybe<FacilityServiceUpdateManyWithoutFacilityNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutFacilityNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityUpdateManyMutationInput = {
  capacity?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityUpdateManyWithWhereWithoutCompanyInput = {
  data: FacilityUpdateManyMutationInput;
  where: FacilityScalarWhereInput;
};

export type FacilityUpdateManyWithWhereWithoutLocationInput = {
  data: FacilityUpdateManyMutationInput;
  where: FacilityScalarWhereInput;
};

export type FacilityUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FacilityCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<FacilityCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<FacilityCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<FacilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FacilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FacilityWhereUniqueInput>>;
  set?: InputMaybe<Array<FacilityWhereUniqueInput>>;
  update?: InputMaybe<Array<FacilityUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<
    Array<FacilityUpdateManyWithWhereWithoutCompanyInput>
  >;
  upsert?: InputMaybe<Array<FacilityUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type FacilityUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FacilityCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<FacilityCreateWithoutLocationInput>>;
  createMany?: InputMaybe<FacilityCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<FacilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FacilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FacilityWhereUniqueInput>>;
  set?: InputMaybe<Array<FacilityWhereUniqueInput>>;
  update?: InputMaybe<Array<FacilityUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: InputMaybe<
    Array<FacilityUpdateManyWithWhereWithoutLocationInput>
  >;
  upsert?: InputMaybe<Array<FacilityUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type FacilityUpdateOneWithoutFacility_ServicesNestedInput = {
  connect?: InputMaybe<FacilityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FacilityCreateOrConnectWithoutFacility_ServicesInput>;
  create?: InputMaybe<FacilityCreateWithoutFacility_ServicesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<FacilityUpdateWithoutFacility_ServicesInput>;
  upsert?: InputMaybe<FacilityUpsertWithoutFacility_ServicesInput>;
};

export type FacilityUpdateWithWhereUniqueWithoutCompanyInput = {
  data: FacilityUpdateWithoutCompanyInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpdateWithWhereUniqueWithoutLocationInput = {
  data: FacilityUpdateWithoutLocationInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpdateWithoutCompanyInput = {
  capacity?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facility_services?: InputMaybe<FacilityServiceUpdateManyWithoutFacilityNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutFacilityNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityUpdateWithoutFacility_ServicesInput = {
  capacity?: InputMaybe<IntFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutFacilityNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutFacilityNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityUpdateWithoutLocationInput = {
  capacity?: InputMaybe<IntFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutFacilityNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facility_services?: InputMaybe<FacilityServiceUpdateManyWithoutFacilityNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityUpsertWithWhereUniqueWithoutCompanyInput = {
  create: FacilityCreateWithoutCompanyInput;
  update: FacilityUpdateWithoutCompanyInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpsertWithWhereUniqueWithoutLocationInput = {
  create: FacilityCreateWithoutLocationInput;
  update: FacilityUpdateWithoutLocationInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpsertWithoutFacility_ServicesInput = {
  create: FacilityCreateWithoutFacility_ServicesInput;
  update: FacilityUpdateWithoutFacility_ServicesInput;
};

export type FacilityWhereInput = {
  AND?: InputMaybe<Array<FacilityWhereInput>>;
  NOT?: InputMaybe<Array<FacilityWhereInput>>;
  OR?: InputMaybe<Array<FacilityWhereInput>>;
  capacity?: InputMaybe<IntFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  company_id?: InputMaybe<UuidNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  facility_services?: InputMaybe<FacilityServiceListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  location_id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type FacilityWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type FavoriteArtist = {
  __typename?: "FavoriteArtist";
  artist: Artist;
  artist_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
};

export type FavoriteArtistCountAggregate = {
  __typename?: "FavoriteArtistCountAggregate";
  _all: Scalars["Int"];
  artist_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type FavoriteArtistCountOrderByAggregateInput = {
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type FavoriteArtistCreateInput = {
  artist: ArtistCreateNestedOneWithoutFavourited_ByInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutFavorite_ArtistsInput;
};

export type FavoriteArtistCreateManyArtistInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type FavoriteArtistCreateManyArtistInputEnvelope = {
  data: Array<FavoriteArtistCreateManyArtistInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type FavoriteArtistCreateManyInput = {
  artist_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type FavoriteArtistCreateManyUserInput = {
  artist_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FavoriteArtistCreateManyUserInputEnvelope = {
  data: Array<FavoriteArtistCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type FavoriteArtistCreateNestedManyWithoutArtistInput = {
  connect?: InputMaybe<Array<FavoriteArtistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FavoriteArtistCreateOrConnectWithoutArtistInput>
  >;
  create?: InputMaybe<Array<FavoriteArtistCreateWithoutArtistInput>>;
  createMany?: InputMaybe<FavoriteArtistCreateManyArtistInputEnvelope>;
};

export type FavoriteArtistCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<FavoriteArtistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FavoriteArtistCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<FavoriteArtistCreateWithoutUserInput>>;
  createMany?: InputMaybe<FavoriteArtistCreateManyUserInputEnvelope>;
};

export type FavoriteArtistCreateOrConnectWithoutArtistInput = {
  create: FavoriteArtistCreateWithoutArtistInput;
  where: FavoriteArtistWhereUniqueInput;
};

export type FavoriteArtistCreateOrConnectWithoutUserInput = {
  create: FavoriteArtistCreateWithoutUserInput;
  where: FavoriteArtistWhereUniqueInput;
};

export type FavoriteArtistCreateWithoutArtistInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutFavorite_ArtistsInput;
};

export type FavoriteArtistCreateWithoutUserInput = {
  artist: ArtistCreateNestedOneWithoutFavourited_ByInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type FavoriteArtistGroupBy = {
  __typename?: "FavoriteArtistGroupBy";
  _count?: Maybe<FavoriteArtistCountAggregate>;
  _max?: Maybe<FavoriteArtistMaxAggregate>;
  _min?: Maybe<FavoriteArtistMinAggregate>;
  artist_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
};

export type FavoriteArtistListRelationFilter = {
  every?: InputMaybe<FavoriteArtistWhereInput>;
  none?: InputMaybe<FavoriteArtistWhereInput>;
  some?: InputMaybe<FavoriteArtistWhereInput>;
};

export type FavoriteArtistMaxAggregate = {
  __typename?: "FavoriteArtistMaxAggregate";
  artist_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type FavoriteArtistMaxOrderByAggregateInput = {
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type FavoriteArtistMinAggregate = {
  __typename?: "FavoriteArtistMinAggregate";
  artist_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type FavoriteArtistMinOrderByAggregateInput = {
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type FavoriteArtistOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FavoriteArtistOrderByWithAggregationInput = {
  _count?: InputMaybe<FavoriteArtistCountOrderByAggregateInput>;
  _max?: InputMaybe<FavoriteArtistMaxOrderByAggregateInput>;
  _min?: InputMaybe<FavoriteArtistMinOrderByAggregateInput>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type FavoriteArtistOrderByWithRelationInput = {
  artist?: InputMaybe<ArtistOrderByWithRelationInput>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export enum FavoriteArtistScalarFieldEnum {
  ArtistId = "artist_id",
  CreatedAt = "created_at",
  Id = "id",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type FavoriteArtistScalarWhereInput = {
  AND?: InputMaybe<Array<FavoriteArtistScalarWhereInput>>;
  NOT?: InputMaybe<Array<FavoriteArtistScalarWhereInput>>;
  OR?: InputMaybe<Array<FavoriteArtistScalarWhereInput>>;
  artist_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type FavoriteArtistScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<FavoriteArtistScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<FavoriteArtistScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<FavoriteArtistScalarWhereWithAggregatesInput>>;
  artist_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export type FavoriteArtistUpdateInput = {
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutFavourited_ByNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutFavorite_ArtistsNestedInput>;
};

export type FavoriteArtistUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FavoriteArtistUpdateManyWithWhereWithoutArtistInput = {
  data: FavoriteArtistUpdateManyMutationInput;
  where: FavoriteArtistScalarWhereInput;
};

export type FavoriteArtistUpdateManyWithWhereWithoutUserInput = {
  data: FavoriteArtistUpdateManyMutationInput;
  where: FavoriteArtistScalarWhereInput;
};

export type FavoriteArtistUpdateManyWithoutArtistNestedInput = {
  connect?: InputMaybe<Array<FavoriteArtistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FavoriteArtistCreateOrConnectWithoutArtistInput>
  >;
  create?: InputMaybe<Array<FavoriteArtistCreateWithoutArtistInput>>;
  createMany?: InputMaybe<FavoriteArtistCreateManyArtistInputEnvelope>;
  delete?: InputMaybe<Array<FavoriteArtistWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FavoriteArtistScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FavoriteArtistWhereUniqueInput>>;
  set?: InputMaybe<Array<FavoriteArtistWhereUniqueInput>>;
  update?: InputMaybe<
    Array<FavoriteArtistUpdateWithWhereUniqueWithoutArtistInput>
  >;
  updateMany?: InputMaybe<
    Array<FavoriteArtistUpdateManyWithWhereWithoutArtistInput>
  >;
  upsert?: InputMaybe<
    Array<FavoriteArtistUpsertWithWhereUniqueWithoutArtistInput>
  >;
};

export type FavoriteArtistUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<FavoriteArtistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FavoriteArtistCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<FavoriteArtistCreateWithoutUserInput>>;
  createMany?: InputMaybe<FavoriteArtistCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<FavoriteArtistWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FavoriteArtistScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FavoriteArtistWhereUniqueInput>>;
  set?: InputMaybe<Array<FavoriteArtistWhereUniqueInput>>;
  update?: InputMaybe<
    Array<FavoriteArtistUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<FavoriteArtistUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<FavoriteArtistUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type FavoriteArtistUpdateWithWhereUniqueWithoutArtistInput = {
  data: FavoriteArtistUpdateWithoutArtistInput;
  where: FavoriteArtistWhereUniqueInput;
};

export type FavoriteArtistUpdateWithWhereUniqueWithoutUserInput = {
  data: FavoriteArtistUpdateWithoutUserInput;
  where: FavoriteArtistWhereUniqueInput;
};

export type FavoriteArtistUpdateWithoutArtistInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutFavorite_ArtistsNestedInput>;
};

export type FavoriteArtistUpdateWithoutUserInput = {
  artist?: InputMaybe<ArtistUpdateOneRequiredWithoutFavourited_ByNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FavoriteArtistUpsertWithWhereUniqueWithoutArtistInput = {
  create: FavoriteArtistCreateWithoutArtistInput;
  update: FavoriteArtistUpdateWithoutArtistInput;
  where: FavoriteArtistWhereUniqueInput;
};

export type FavoriteArtistUpsertWithWhereUniqueWithoutUserInput = {
  create: FavoriteArtistCreateWithoutUserInput;
  update: FavoriteArtistUpdateWithoutUserInput;
  where: FavoriteArtistWhereUniqueInput;
};

export type FavoriteArtistUser_IdArtist_IdCompoundUniqueInput = {
  artist_id: Scalars["String"];
  user_id: Scalars["String"];
};

export type FavoriteArtistWhereInput = {
  AND?: InputMaybe<Array<FavoriteArtistWhereInput>>;
  NOT?: InputMaybe<Array<FavoriteArtistWhereInput>>;
  OR?: InputMaybe<Array<FavoriteArtistWhereInput>>;
  artist?: InputMaybe<ArtistRelationFilter>;
  artist_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type FavoriteArtistWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  user_id_artist_id?: InputMaybe<FavoriteArtistUser_IdArtist_IdCompoundUniqueInput>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Float"]>;
  divide?: InputMaybe<Scalars["Float"]>;
  increment?: InputMaybe<Scalars["Float"]>;
  multiply?: InputMaybe<Scalars["Float"]>;
  set?: InputMaybe<Scalars["Float"]>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type FloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type GroupAvailability = {
  __typename?: "GroupAvailability";
  _count?: Maybe<GroupAvailabilityCount>;
  availabilities: Array<Availability>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type GroupAvailabilityAvailabilitiesArgs = {
  cursor?: InputMaybe<AvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type GroupAvailabilityCount = {
  __typename?: "GroupAvailabilityCount";
  availabilities: Scalars["Int"];
};

export type GroupAvailabilityCountAvailabilitiesArgs = {
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type GroupAvailabilityCountAggregate = {
  __typename?: "GroupAvailabilityCountAggregate";
  _all: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type GroupAvailabilityCountOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type GroupAvailabilityCreateInput = {
  availabilities?: InputMaybe<AvailabilityCreateNestedManyWithoutGroup_AvailabilityInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type GroupAvailabilityCreateManyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type GroupAvailabilityCreateNestedOneWithoutAvailabilitiesInput = {
  connect?: InputMaybe<GroupAvailabilityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GroupAvailabilityCreateOrConnectWithoutAvailabilitiesInput>;
  create?: InputMaybe<GroupAvailabilityCreateWithoutAvailabilitiesInput>;
};

export type GroupAvailabilityCreateOrConnectWithoutAvailabilitiesInput = {
  create: GroupAvailabilityCreateWithoutAvailabilitiesInput;
  where: GroupAvailabilityWhereUniqueInput;
};

export type GroupAvailabilityCreateWithoutAvailabilitiesInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type GroupAvailabilityGroupBy = {
  __typename?: "GroupAvailabilityGroupBy";
  _count?: Maybe<GroupAvailabilityCountAggregate>;
  _max?: Maybe<GroupAvailabilityMaxAggregate>;
  _min?: Maybe<GroupAvailabilityMinAggregate>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type GroupAvailabilityMaxAggregate = {
  __typename?: "GroupAvailabilityMaxAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type GroupAvailabilityMaxOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type GroupAvailabilityMinAggregate = {
  __typename?: "GroupAvailabilityMinAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type GroupAvailabilityMinOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type GroupAvailabilityOrderByWithAggregationInput = {
  _count?: InputMaybe<GroupAvailabilityCountOrderByAggregateInput>;
  _max?: InputMaybe<GroupAvailabilityMaxOrderByAggregateInput>;
  _min?: InputMaybe<GroupAvailabilityMinOrderByAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type GroupAvailabilityOrderByWithRelationInput = {
  availabilities?: InputMaybe<AvailabilityOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type GroupAvailabilityRelationFilter = {
  is?: InputMaybe<GroupAvailabilityWhereInput>;
  isNot?: InputMaybe<GroupAvailabilityWhereInput>;
};

export enum GroupAvailabilityScalarFieldEnum {
  CreatedAt = "created_at",
  Id = "id",
  UpdatedAt = "updated_at"
}

export type GroupAvailabilityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<GroupAvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<GroupAvailabilityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<GroupAvailabilityScalarWhereWithAggregatesInput>>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type GroupAvailabilityUpdateInput = {
  availabilities?: InputMaybe<AvailabilityUpdateManyWithoutGroup_AvailabilityNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GroupAvailabilityUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GroupAvailabilityUpdateOneWithoutAvailabilitiesNestedInput = {
  connect?: InputMaybe<GroupAvailabilityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GroupAvailabilityCreateOrConnectWithoutAvailabilitiesInput>;
  create?: InputMaybe<GroupAvailabilityCreateWithoutAvailabilitiesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<GroupAvailabilityUpdateWithoutAvailabilitiesInput>;
  upsert?: InputMaybe<GroupAvailabilityUpsertWithoutAvailabilitiesInput>;
};

export type GroupAvailabilityUpdateWithoutAvailabilitiesInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GroupAvailabilityUpsertWithoutAvailabilitiesInput = {
  create: GroupAvailabilityCreateWithoutAvailabilitiesInput;
  update: GroupAvailabilityUpdateWithoutAvailabilitiesInput;
};

export type GroupAvailabilityWhereInput = {
  AND?: InputMaybe<Array<GroupAvailabilityWhereInput>>;
  NOT?: InputMaybe<Array<GroupAvailabilityWhereInput>>;
  OR?: InputMaybe<Array<GroupAvailabilityWhereInput>>;
  availabilities?: InputMaybe<AvailabilityListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type GroupAvailabilityWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Int"]>;
  divide?: InputMaybe<Scalars["Int"]>;
  increment?: InputMaybe<Scalars["Int"]>;
  multiply?: InputMaybe<Scalars["Int"]>;
  set?: InputMaybe<Scalars["Int"]>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]>;
  equals?: InputMaybe<Scalars["JSON"]>;
  gt?: InputMaybe<Scalars["JSON"]>;
  gte?: InputMaybe<Scalars["JSON"]>;
  lt?: InputMaybe<Scalars["JSON"]>;
  lte?: InputMaybe<Scalars["JSON"]>;
  not?: InputMaybe<Scalars["JSON"]>;
  path?: InputMaybe<Array<Scalars["String"]>>;
  string_contains?: InputMaybe<Scalars["String"]>;
  string_ends_with?: InputMaybe<Scalars["String"]>;
  string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]>;
  equals?: InputMaybe<Scalars["JSON"]>;
  gt?: InputMaybe<Scalars["JSON"]>;
  gte?: InputMaybe<Scalars["JSON"]>;
  lt?: InputMaybe<Scalars["JSON"]>;
  lte?: InputMaybe<Scalars["JSON"]>;
  not?: InputMaybe<Scalars["JSON"]>;
  path?: InputMaybe<Array<Scalars["String"]>>;
  string_contains?: InputMaybe<Scalars["String"]>;
  string_ends_with?: InputMaybe<Scalars["String"]>;
  string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  array_contains?: InputMaybe<Scalars["JSON"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]>;
  equals?: InputMaybe<Scalars["JSON"]>;
  gt?: InputMaybe<Scalars["JSON"]>;
  gte?: InputMaybe<Scalars["JSON"]>;
  lt?: InputMaybe<Scalars["JSON"]>;
  lte?: InputMaybe<Scalars["JSON"]>;
  not?: InputMaybe<Scalars["JSON"]>;
  path?: InputMaybe<Array<Scalars["String"]>>;
  string_contains?: InputMaybe<Scalars["String"]>;
  string_ends_with?: InputMaybe<Scalars["String"]>;
  string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type JsonWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  array_contains?: InputMaybe<Scalars["JSON"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]>;
  equals?: InputMaybe<Scalars["JSON"]>;
  gt?: InputMaybe<Scalars["JSON"]>;
  gte?: InputMaybe<Scalars["JSON"]>;
  lt?: InputMaybe<Scalars["JSON"]>;
  lte?: InputMaybe<Scalars["JSON"]>;
  not?: InputMaybe<Scalars["JSON"]>;
  path?: InputMaybe<Array<Scalars["String"]>>;
  string_contains?: InputMaybe<Scalars["String"]>;
  string_ends_with?: InputMaybe<Scalars["String"]>;
  string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type LastPaymentError = {
  __typename?: "LastPaymentError";
  code: Scalars["String"];
  doc_url: Scalars["String"];
  message: Scalars["String"];
  payment_method: PaymentMethodForIntent;
  type: Scalars["String"];
};

export type Location = {
  __typename?: "Location";
  _count?: Maybe<LocationCount>;
  address?: Maybe<Scalars["String"]>;
  address_display?: Maybe<Scalars["String"]>;
  address_latitude?: Maybe<Scalars["Float"]>;
  address_longitude?: Maybe<Scalars["Float"]>;
  amenities: Array<LocationAmenity>;
  amenities_enums: Array<AmenityEnum>;
  appointments: Array<Appointment>;
  availability: Array<Availability>;
  city?: Maybe<Scalars["String"]>;
  company: Company;
  company_id: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  facility: Array<Facility>;
  favorited_by_users: Array<UserFavoriteLocation>;
  friday_hours_close?: Maybe<Scalars["DateTime"]>;
  friday_hours_open?: Maybe<Scalars["DateTime"]>;
  google_places_id?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  libphonenumber?: Maybe<Scalars["String"]>;
  menu?: Maybe<Menu>;
  menu_id?: Maybe<Scalars["String"]>;
  monday_hours_close?: Maybe<Scalars["DateTime"]>;
  monday_hours_open?: Maybe<Scalars["DateTime"]>;
  multi_person?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: Maybe<Scalars["String"]>;
  orders: Array<Order>;
  phone_number?: Maybe<Scalars["String"]>;
  saturday_hours_close?: Maybe<Scalars["DateTime"]>;
  saturday_hours_open?: Maybe<Scalars["DateTime"]>;
  slug?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  stripe_terminal_location_id?: Maybe<Scalars["String"]>;
  sunday_hours_close?: Maybe<Scalars["DateTime"]>;
  sunday_hours_open?: Maybe<Scalars["DateTime"]>;
  thursday_hours_close?: Maybe<Scalars["DateTime"]>;
  thursday_hours_open?: Maybe<Scalars["DateTime"]>;
  timezone: Scalars["String"];
  tuesday_hours_close?: Maybe<Scalars["DateTime"]>;
  tuesday_hours_open?: Maybe<Scalars["DateTime"]>;
  updated_at: Scalars["DateTime"];
  visible_on_homepage: Scalars["Boolean"];
  wednesday_hours_close?: Maybe<Scalars["DateTime"]>;
  wednesday_hours_open?: Maybe<Scalars["DateTime"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

export type LocationAmenitiesArgs = {
  cursor?: InputMaybe<LocationAmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationAmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type LocationAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type LocationAvailabilityArgs = {
  cursor?: InputMaybe<AvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type LocationFacilityArgs = {
  cursor?: InputMaybe<FacilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<FacilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityWhereInput>;
};

export type LocationFavorited_By_UsersArgs = {
  cursor?: InputMaybe<UserFavoriteLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserFavoriteLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserFavoriteLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type LocationOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<OrderWhereInput>;
};

export type LocationAmenity = {
  __typename?: "LocationAmenity";
  amenity: Amenity;
  amenity_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  location: Location;
  location_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type LocationAmenityAmenity_IdLocation_IdCompoundUniqueInput = {
  amenity_id: Scalars["String"];
  location_id: Scalars["String"];
};

export type LocationAmenityCountAggregate = {
  __typename?: "LocationAmenityCountAggregate";
  _all: Scalars["Int"];
  amenity_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  location_id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type LocationAmenityCountOrderByAggregateInput = {
  amenity_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type LocationAmenityCreateInput = {
  amenity: AmenityCreateNestedOneWithoutLocationsInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location: LocationCreateNestedOneWithoutAmenitiesInput;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type LocationAmenityCreateManyAmenityInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type LocationAmenityCreateManyAmenityInputEnvelope = {
  data: Array<LocationAmenityCreateManyAmenityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type LocationAmenityCreateManyInput = {
  amenity_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type LocationAmenityCreateManyLocationInput = {
  amenity_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type LocationAmenityCreateManyLocationInputEnvelope = {
  data: Array<LocationAmenityCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type LocationAmenityCreateNestedManyWithoutAmenityInput = {
  connect?: InputMaybe<Array<LocationAmenityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LocationAmenityCreateOrConnectWithoutAmenityInput>
  >;
  create?: InputMaybe<Array<LocationAmenityCreateWithoutAmenityInput>>;
  createMany?: InputMaybe<LocationAmenityCreateManyAmenityInputEnvelope>;
};

export type LocationAmenityCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<LocationAmenityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LocationAmenityCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<LocationAmenityCreateWithoutLocationInput>>;
  createMany?: InputMaybe<LocationAmenityCreateManyLocationInputEnvelope>;
};

export type LocationAmenityCreateOrConnectWithoutAmenityInput = {
  create: LocationAmenityCreateWithoutAmenityInput;
  where: LocationAmenityWhereUniqueInput;
};

export type LocationAmenityCreateOrConnectWithoutLocationInput = {
  create: LocationAmenityCreateWithoutLocationInput;
  where: LocationAmenityWhereUniqueInput;
};

export type LocationAmenityCreateWithoutAmenityInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location: LocationCreateNestedOneWithoutAmenitiesInput;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type LocationAmenityCreateWithoutLocationInput = {
  amenity: AmenityCreateNestedOneWithoutLocationsInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type LocationAmenityGroupBy = {
  __typename?: "LocationAmenityGroupBy";
  _count?: Maybe<LocationAmenityCountAggregate>;
  _max?: Maybe<LocationAmenityMaxAggregate>;
  _min?: Maybe<LocationAmenityMinAggregate>;
  amenity_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  location_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type LocationAmenityListRelationFilter = {
  every?: InputMaybe<LocationAmenityWhereInput>;
  none?: InputMaybe<LocationAmenityWhereInput>;
  some?: InputMaybe<LocationAmenityWhereInput>;
};

export type LocationAmenityMaxAggregate = {
  __typename?: "LocationAmenityMaxAggregate";
  amenity_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type LocationAmenityMaxOrderByAggregateInput = {
  amenity_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type LocationAmenityMinAggregate = {
  __typename?: "LocationAmenityMinAggregate";
  amenity_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type LocationAmenityMinOrderByAggregateInput = {
  amenity_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type LocationAmenityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LocationAmenityOrderByWithAggregationInput = {
  _count?: InputMaybe<LocationAmenityCountOrderByAggregateInput>;
  _max?: InputMaybe<LocationAmenityMaxOrderByAggregateInput>;
  _min?: InputMaybe<LocationAmenityMinOrderByAggregateInput>;
  amenity_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type LocationAmenityOrderByWithRelationInput = {
  amenity?: InputMaybe<AmenityOrderByWithRelationInput>;
  amenity_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByWithRelationInput>;
  location_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum LocationAmenityScalarFieldEnum {
  AmenityId = "amenity_id",
  CreatedAt = "created_at",
  Id = "id",
  LocationId = "location_id",
  UpdatedAt = "updated_at"
}

export type LocationAmenityScalarWhereInput = {
  AND?: InputMaybe<Array<LocationAmenityScalarWhereInput>>;
  NOT?: InputMaybe<Array<LocationAmenityScalarWhereInput>>;
  OR?: InputMaybe<Array<LocationAmenityScalarWhereInput>>;
  amenity_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  location_id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type LocationAmenityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LocationAmenityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LocationAmenityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LocationAmenityScalarWhereWithAggregatesInput>>;
  amenity_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  location_id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type LocationAmenityUpdateInput = {
  amenity?: InputMaybe<AmenityUpdateOneRequiredWithoutLocationsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAmenitiesNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LocationAmenityUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LocationAmenityUpdateManyWithWhereWithoutAmenityInput = {
  data: LocationAmenityUpdateManyMutationInput;
  where: LocationAmenityScalarWhereInput;
};

export type LocationAmenityUpdateManyWithWhereWithoutLocationInput = {
  data: LocationAmenityUpdateManyMutationInput;
  where: LocationAmenityScalarWhereInput;
};

export type LocationAmenityUpdateManyWithoutAmenityNestedInput = {
  connect?: InputMaybe<Array<LocationAmenityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LocationAmenityCreateOrConnectWithoutAmenityInput>
  >;
  create?: InputMaybe<Array<LocationAmenityCreateWithoutAmenityInput>>;
  createMany?: InputMaybe<LocationAmenityCreateManyAmenityInputEnvelope>;
  delete?: InputMaybe<Array<LocationAmenityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocationAmenityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocationAmenityWhereUniqueInput>>;
  set?: InputMaybe<Array<LocationAmenityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LocationAmenityUpdateWithWhereUniqueWithoutAmenityInput>
  >;
  updateMany?: InputMaybe<
    Array<LocationAmenityUpdateManyWithWhereWithoutAmenityInput>
  >;
  upsert?: InputMaybe<
    Array<LocationAmenityUpsertWithWhereUniqueWithoutAmenityInput>
  >;
};

export type LocationAmenityUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<LocationAmenityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LocationAmenityCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<LocationAmenityCreateWithoutLocationInput>>;
  createMany?: InputMaybe<LocationAmenityCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<LocationAmenityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocationAmenityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocationAmenityWhereUniqueInput>>;
  set?: InputMaybe<Array<LocationAmenityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LocationAmenityUpdateWithWhereUniqueWithoutLocationInput>
  >;
  updateMany?: InputMaybe<
    Array<LocationAmenityUpdateManyWithWhereWithoutLocationInput>
  >;
  upsert?: InputMaybe<
    Array<LocationAmenityUpsertWithWhereUniqueWithoutLocationInput>
  >;
};

export type LocationAmenityUpdateWithWhereUniqueWithoutAmenityInput = {
  data: LocationAmenityUpdateWithoutAmenityInput;
  where: LocationAmenityWhereUniqueInput;
};

export type LocationAmenityUpdateWithWhereUniqueWithoutLocationInput = {
  data: LocationAmenityUpdateWithoutLocationInput;
  where: LocationAmenityWhereUniqueInput;
};

export type LocationAmenityUpdateWithoutAmenityInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutAmenitiesNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LocationAmenityUpdateWithoutLocationInput = {
  amenity?: InputMaybe<AmenityUpdateOneRequiredWithoutLocationsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LocationAmenityUpsertWithWhereUniqueWithoutAmenityInput = {
  create: LocationAmenityCreateWithoutAmenityInput;
  update: LocationAmenityUpdateWithoutAmenityInput;
  where: LocationAmenityWhereUniqueInput;
};

export type LocationAmenityUpsertWithWhereUniqueWithoutLocationInput = {
  create: LocationAmenityCreateWithoutLocationInput;
  update: LocationAmenityUpdateWithoutLocationInput;
  where: LocationAmenityWhereUniqueInput;
};

export type LocationAmenityWhereInput = {
  AND?: InputMaybe<Array<LocationAmenityWhereInput>>;
  NOT?: InputMaybe<Array<LocationAmenityWhereInput>>;
  OR?: InputMaybe<Array<LocationAmenityWhereInput>>;
  amenity?: InputMaybe<AmenityRelationFilter>;
  amenity_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  location_id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type LocationAmenityWhereUniqueInput = {
  amenity_id_location_id?: InputMaybe<LocationAmenityAmenity_IdLocation_IdCompoundUniqueInput>;
  id?: InputMaybe<Scalars["String"]>;
};

export type LocationAvgAggregate = {
  __typename?: "LocationAvgAggregate";
  address_latitude?: Maybe<Scalars["Float"]>;
  address_longitude?: Maybe<Scalars["Float"]>;
  multi_person?: Maybe<Scalars["Float"]>;
};

export type LocationAvgOrderByAggregateInput = {
  address_latitude?: InputMaybe<SortOrder>;
  address_longitude?: InputMaybe<SortOrder>;
  multi_person?: InputMaybe<SortOrder>;
};

export type LocationCount = {
  __typename?: "LocationCount";
  amenities: Scalars["Int"];
  appointments: Scalars["Int"];
  availability: Scalars["Int"];
  facility: Scalars["Int"];
  favorited_by_users: Scalars["Int"];
  orders: Scalars["Int"];
};

export type LocationCountAmenitiesArgs = {
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type LocationCountAppointmentsArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};

export type LocationCountAvailabilityArgs = {
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type LocationCountFacilityArgs = {
  where?: InputMaybe<FacilityWhereInput>;
};

export type LocationCountFavorited_By_UsersArgs = {
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type LocationCountOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};

export type LocationCountAggregate = {
  __typename?: "LocationCountAggregate";
  _all: Scalars["Int"];
  address: Scalars["Int"];
  address_display: Scalars["Int"];
  address_latitude: Scalars["Int"];
  address_longitude: Scalars["Int"];
  amenities_enums: Scalars["Int"];
  city: Scalars["Int"];
  company_id: Scalars["Int"];
  country: Scalars["Int"];
  cover_photo_url: Scalars["Int"];
  created_at: Scalars["Int"];
  description: Scalars["Int"];
  friday_hours_close: Scalars["Int"];
  friday_hours_open: Scalars["Int"];
  google_places_id: Scalars["Int"];
  id: Scalars["Int"];
  libphonenumber: Scalars["Int"];
  menu_id: Scalars["Int"];
  monday_hours_close: Scalars["Int"];
  monday_hours_open: Scalars["Int"];
  multi_person: Scalars["Int"];
  name: Scalars["Int"];
  nickname: Scalars["Int"];
  phone_number: Scalars["Int"];
  saturday_hours_close: Scalars["Int"];
  saturday_hours_open: Scalars["Int"];
  slug: Scalars["Int"];
  state: Scalars["Int"];
  status: Scalars["Int"];
  stripe_terminal_location_id: Scalars["Int"];
  sunday_hours_close: Scalars["Int"];
  sunday_hours_open: Scalars["Int"];
  thursday_hours_close: Scalars["Int"];
  thursday_hours_open: Scalars["Int"];
  timezone: Scalars["Int"];
  tuesday_hours_close: Scalars["Int"];
  tuesday_hours_open: Scalars["Int"];
  updated_at: Scalars["Int"];
  visible_on_homepage: Scalars["Int"];
  wednesday_hours_close: Scalars["Int"];
  wednesday_hours_open: Scalars["Int"];
  zipcode: Scalars["Int"];
};

export type LocationCountOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  address_display?: InputMaybe<SortOrder>;
  address_latitude?: InputMaybe<SortOrder>;
  address_longitude?: InputMaybe<SortOrder>;
  amenities_enums?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  cover_photo_url?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  friday_hours_close?: InputMaybe<SortOrder>;
  friday_hours_open?: InputMaybe<SortOrder>;
  google_places_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  libphonenumber?: InputMaybe<SortOrder>;
  menu_id?: InputMaybe<SortOrder>;
  monday_hours_close?: InputMaybe<SortOrder>;
  monday_hours_open?: InputMaybe<SortOrder>;
  multi_person?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nickname?: InputMaybe<SortOrder>;
  phone_number?: InputMaybe<SortOrder>;
  saturday_hours_close?: InputMaybe<SortOrder>;
  saturday_hours_open?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_terminal_location_id?: InputMaybe<SortOrder>;
  sunday_hours_close?: InputMaybe<SortOrder>;
  sunday_hours_open?: InputMaybe<SortOrder>;
  thursday_hours_close?: InputMaybe<SortOrder>;
  thursday_hours_open?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  tuesday_hours_close?: InputMaybe<SortOrder>;
  tuesday_hours_open?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  visible_on_homepage?: InputMaybe<SortOrder>;
  wednesday_hours_close?: InputMaybe<SortOrder>;
  wednesday_hours_open?: InputMaybe<SortOrder>;
  zipcode?: InputMaybe<SortOrder>;
};

export type LocationCreateInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities?: InputMaybe<LocationAmenityCreateNestedManyWithoutLocationInput>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutLocationInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutLocationInput>;
  city?: InputMaybe<Scalars["String"]>;
  company: CompanyCreateNestedOneWithoutLocationsInput;
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutLocationInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutLocationInput>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutLocationsInput>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutLocationInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateManyCompanyInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  menu_id?: InputMaybe<Scalars["String"]>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateManyCompanyInputEnvelope = {
  data: Array<LocationCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type LocationCreateManyInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  city?: InputMaybe<Scalars["String"]>;
  company_id: Scalars["String"];
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  menu_id?: InputMaybe<Scalars["String"]>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateManyMenuInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  city?: InputMaybe<Scalars["String"]>;
  company_id: Scalars["String"];
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateManyMenuInputEnvelope = {
  data: Array<LocationCreateManyMenuInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type LocationCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LocationCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<LocationCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<LocationCreateManyCompanyInputEnvelope>;
};

export type LocationCreateNestedManyWithoutMenuInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutMenuInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutMenuInput>>;
  createMany?: InputMaybe<LocationCreateManyMenuInputEnvelope>;
};

export type LocationCreateNestedOneWithoutAmenitiesInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutAmenitiesInput>;
  create?: InputMaybe<LocationCreateWithoutAmenitiesInput>;
};

export type LocationCreateNestedOneWithoutAppointmentsInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<LocationCreateWithoutAppointmentsInput>;
};

export type LocationCreateNestedOneWithoutAvailabilityInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutAvailabilityInput>;
  create?: InputMaybe<LocationCreateWithoutAvailabilityInput>;
};

export type LocationCreateNestedOneWithoutFacilityInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutFacilityInput>;
  create?: InputMaybe<LocationCreateWithoutFacilityInput>;
};

export type LocationCreateNestedOneWithoutFavorited_By_UsersInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutFavorited_By_UsersInput>;
  create?: InputMaybe<LocationCreateWithoutFavorited_By_UsersInput>;
};

export type LocationCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<LocationCreateWithoutOrdersInput>;
};

export type LocationCreateOrConnectWithoutAmenitiesInput = {
  create: LocationCreateWithoutAmenitiesInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutAppointmentsInput = {
  create: LocationCreateWithoutAppointmentsInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutAvailabilityInput = {
  create: LocationCreateWithoutAvailabilityInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutCompanyInput = {
  create: LocationCreateWithoutCompanyInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutFacilityInput = {
  create: LocationCreateWithoutFacilityInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutFavorited_By_UsersInput = {
  create: LocationCreateWithoutFavorited_By_UsersInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutMenuInput = {
  create: LocationCreateWithoutMenuInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutOrdersInput = {
  create: LocationCreateWithoutOrdersInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateWithoutAmenitiesInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutLocationInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutLocationInput>;
  city?: InputMaybe<Scalars["String"]>;
  company: CompanyCreateNestedOneWithoutLocationsInput;
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutLocationInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutLocationInput>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutLocationsInput>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutLocationInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateWithoutAppointmentsInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities?: InputMaybe<LocationAmenityCreateNestedManyWithoutLocationInput>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutLocationInput>;
  city?: InputMaybe<Scalars["String"]>;
  company: CompanyCreateNestedOneWithoutLocationsInput;
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutLocationInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutLocationInput>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutLocationsInput>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutLocationInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateWithoutAvailabilityInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities?: InputMaybe<LocationAmenityCreateNestedManyWithoutLocationInput>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutLocationInput>;
  city?: InputMaybe<Scalars["String"]>;
  company: CompanyCreateNestedOneWithoutLocationsInput;
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutLocationInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutLocationInput>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutLocationsInput>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutLocationInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateWithoutCompanyInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities?: InputMaybe<LocationAmenityCreateNestedManyWithoutLocationInput>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutLocationInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutLocationInput>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutLocationInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutLocationInput>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutLocationsInput>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutLocationInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateWithoutFacilityInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities?: InputMaybe<LocationAmenityCreateNestedManyWithoutLocationInput>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutLocationInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutLocationInput>;
  city?: InputMaybe<Scalars["String"]>;
  company: CompanyCreateNestedOneWithoutLocationsInput;
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutLocationInput>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutLocationsInput>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutLocationInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateWithoutFavorited_By_UsersInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities?: InputMaybe<LocationAmenityCreateNestedManyWithoutLocationInput>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutLocationInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutLocationInput>;
  city?: InputMaybe<Scalars["String"]>;
  company: CompanyCreateNestedOneWithoutLocationsInput;
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutLocationInput>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutLocationsInput>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutLocationInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateWithoutMenuInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities?: InputMaybe<LocationAmenityCreateNestedManyWithoutLocationInput>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutLocationInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutLocationInput>;
  city?: InputMaybe<Scalars["String"]>;
  company: CompanyCreateNestedOneWithoutLocationsInput;
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutLocationInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutLocationInput>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutLocationInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateWithoutOrdersInput = {
  address?: InputMaybe<Scalars["String"]>;
  address_display?: InputMaybe<Scalars["String"]>;
  address_latitude?: InputMaybe<Scalars["Float"]>;
  address_longitude?: InputMaybe<Scalars["Float"]>;
  amenities?: InputMaybe<LocationAmenityCreateNestedManyWithoutLocationInput>;
  amenities_enums?: InputMaybe<LocationCreateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutLocationInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutLocationInput>;
  city?: InputMaybe<Scalars["String"]>;
  company: CompanyCreateNestedOneWithoutLocationsInput;
  country?: InputMaybe<Scalars["String"]>;
  cover_photo_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<FacilityCreateNestedManyWithoutLocationInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutLocationInput>;
  friday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  friday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  google_places_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutLocationsInput>;
  monday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  monday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  multi_person?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  saturday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  saturday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  slug?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_terminal_location_id?: InputMaybe<Scalars["String"]>;
  sunday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  sunday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  thursday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  tuesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  tuesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  visible_on_homepage?: InputMaybe<Scalars["Boolean"]>;
  wednesday_hours_close?: InputMaybe<Scalars["DateTime"]>;
  wednesday_hours_open?: InputMaybe<Scalars["DateTime"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type LocationCreateamenities_EnumsInput = {
  set: Array<AmenityEnum>;
};

export type LocationGroupBy = {
  __typename?: "LocationGroupBy";
  _avg?: Maybe<LocationAvgAggregate>;
  _count?: Maybe<LocationCountAggregate>;
  _max?: Maybe<LocationMaxAggregate>;
  _min?: Maybe<LocationMinAggregate>;
  _sum?: Maybe<LocationSumAggregate>;
  address?: Maybe<Scalars["String"]>;
  address_display?: Maybe<Scalars["String"]>;
  address_latitude?: Maybe<Scalars["Float"]>;
  address_longitude?: Maybe<Scalars["Float"]>;
  amenities_enums?: Maybe<Array<AmenityEnum>>;
  city?: Maybe<Scalars["String"]>;
  company_id: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  friday_hours_close?: Maybe<Scalars["DateTime"]>;
  friday_hours_open?: Maybe<Scalars["DateTime"]>;
  google_places_id?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  libphonenumber?: Maybe<Scalars["String"]>;
  menu_id?: Maybe<Scalars["String"]>;
  monday_hours_close?: Maybe<Scalars["DateTime"]>;
  monday_hours_open?: Maybe<Scalars["DateTime"]>;
  multi_person?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  nickname?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  saturday_hours_close?: Maybe<Scalars["DateTime"]>;
  saturday_hours_open?: Maybe<Scalars["DateTime"]>;
  slug?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  stripe_terminal_location_id?: Maybe<Scalars["String"]>;
  sunday_hours_close?: Maybe<Scalars["DateTime"]>;
  sunday_hours_open?: Maybe<Scalars["DateTime"]>;
  thursday_hours_close?: Maybe<Scalars["DateTime"]>;
  thursday_hours_open?: Maybe<Scalars["DateTime"]>;
  timezone: Scalars["String"];
  tuesday_hours_close?: Maybe<Scalars["DateTime"]>;
  tuesday_hours_open?: Maybe<Scalars["DateTime"]>;
  updated_at: Scalars["DateTime"];
  visible_on_homepage: Scalars["Boolean"];
  wednesday_hours_close?: Maybe<Scalars["DateTime"]>;
  wednesday_hours_open?: Maybe<Scalars["DateTime"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

export type LocationListRelationFilter = {
  every?: InputMaybe<LocationWhereInput>;
  none?: InputMaybe<LocationWhereInput>;
  some?: InputMaybe<LocationWhereInput>;
};

export type LocationMaxAggregate = {
  __typename?: "LocationMaxAggregate";
  address?: Maybe<Scalars["String"]>;
  address_display?: Maybe<Scalars["String"]>;
  address_latitude?: Maybe<Scalars["Float"]>;
  address_longitude?: Maybe<Scalars["Float"]>;
  city?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  friday_hours_close?: Maybe<Scalars["DateTime"]>;
  friday_hours_open?: Maybe<Scalars["DateTime"]>;
  google_places_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  libphonenumber?: Maybe<Scalars["String"]>;
  menu_id?: Maybe<Scalars["String"]>;
  monday_hours_close?: Maybe<Scalars["DateTime"]>;
  monday_hours_open?: Maybe<Scalars["DateTime"]>;
  multi_person?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  saturday_hours_close?: Maybe<Scalars["DateTime"]>;
  saturday_hours_open?: Maybe<Scalars["DateTime"]>;
  slug?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  stripe_terminal_location_id?: Maybe<Scalars["String"]>;
  sunday_hours_close?: Maybe<Scalars["DateTime"]>;
  sunday_hours_open?: Maybe<Scalars["DateTime"]>;
  thursday_hours_close?: Maybe<Scalars["DateTime"]>;
  thursday_hours_open?: Maybe<Scalars["DateTime"]>;
  timezone?: Maybe<Scalars["String"]>;
  tuesday_hours_close?: Maybe<Scalars["DateTime"]>;
  tuesday_hours_open?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  visible_on_homepage?: Maybe<Scalars["Boolean"]>;
  wednesday_hours_close?: Maybe<Scalars["DateTime"]>;
  wednesday_hours_open?: Maybe<Scalars["DateTime"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

export type LocationMaxOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  address_display?: InputMaybe<SortOrder>;
  address_latitude?: InputMaybe<SortOrder>;
  address_longitude?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  cover_photo_url?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  friday_hours_close?: InputMaybe<SortOrder>;
  friday_hours_open?: InputMaybe<SortOrder>;
  google_places_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  libphonenumber?: InputMaybe<SortOrder>;
  menu_id?: InputMaybe<SortOrder>;
  monday_hours_close?: InputMaybe<SortOrder>;
  monday_hours_open?: InputMaybe<SortOrder>;
  multi_person?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nickname?: InputMaybe<SortOrder>;
  phone_number?: InputMaybe<SortOrder>;
  saturday_hours_close?: InputMaybe<SortOrder>;
  saturday_hours_open?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_terminal_location_id?: InputMaybe<SortOrder>;
  sunday_hours_close?: InputMaybe<SortOrder>;
  sunday_hours_open?: InputMaybe<SortOrder>;
  thursday_hours_close?: InputMaybe<SortOrder>;
  thursday_hours_open?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  tuesday_hours_close?: InputMaybe<SortOrder>;
  tuesday_hours_open?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  visible_on_homepage?: InputMaybe<SortOrder>;
  wednesday_hours_close?: InputMaybe<SortOrder>;
  wednesday_hours_open?: InputMaybe<SortOrder>;
  zipcode?: InputMaybe<SortOrder>;
};

export type LocationMinAggregate = {
  __typename?: "LocationMinAggregate";
  address?: Maybe<Scalars["String"]>;
  address_display?: Maybe<Scalars["String"]>;
  address_latitude?: Maybe<Scalars["Float"]>;
  address_longitude?: Maybe<Scalars["Float"]>;
  city?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  cover_photo_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  friday_hours_close?: Maybe<Scalars["DateTime"]>;
  friday_hours_open?: Maybe<Scalars["DateTime"]>;
  google_places_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  libphonenumber?: Maybe<Scalars["String"]>;
  menu_id?: Maybe<Scalars["String"]>;
  monday_hours_close?: Maybe<Scalars["DateTime"]>;
  monday_hours_open?: Maybe<Scalars["DateTime"]>;
  multi_person?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  saturday_hours_close?: Maybe<Scalars["DateTime"]>;
  saturday_hours_open?: Maybe<Scalars["DateTime"]>;
  slug?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  stripe_terminal_location_id?: Maybe<Scalars["String"]>;
  sunday_hours_close?: Maybe<Scalars["DateTime"]>;
  sunday_hours_open?: Maybe<Scalars["DateTime"]>;
  thursday_hours_close?: Maybe<Scalars["DateTime"]>;
  thursday_hours_open?: Maybe<Scalars["DateTime"]>;
  timezone?: Maybe<Scalars["String"]>;
  tuesday_hours_close?: Maybe<Scalars["DateTime"]>;
  tuesday_hours_open?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  visible_on_homepage?: Maybe<Scalars["Boolean"]>;
  wednesday_hours_close?: Maybe<Scalars["DateTime"]>;
  wednesday_hours_open?: Maybe<Scalars["DateTime"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

export type LocationMinOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  address_display?: InputMaybe<SortOrder>;
  address_latitude?: InputMaybe<SortOrder>;
  address_longitude?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  cover_photo_url?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  friday_hours_close?: InputMaybe<SortOrder>;
  friday_hours_open?: InputMaybe<SortOrder>;
  google_places_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  libphonenumber?: InputMaybe<SortOrder>;
  menu_id?: InputMaybe<SortOrder>;
  monday_hours_close?: InputMaybe<SortOrder>;
  monday_hours_open?: InputMaybe<SortOrder>;
  multi_person?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nickname?: InputMaybe<SortOrder>;
  phone_number?: InputMaybe<SortOrder>;
  saturday_hours_close?: InputMaybe<SortOrder>;
  saturday_hours_open?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_terminal_location_id?: InputMaybe<SortOrder>;
  sunday_hours_close?: InputMaybe<SortOrder>;
  sunday_hours_open?: InputMaybe<SortOrder>;
  thursday_hours_close?: InputMaybe<SortOrder>;
  thursday_hours_open?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  tuesday_hours_close?: InputMaybe<SortOrder>;
  tuesday_hours_open?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  visible_on_homepage?: InputMaybe<SortOrder>;
  wednesday_hours_close?: InputMaybe<SortOrder>;
  wednesday_hours_open?: InputMaybe<SortOrder>;
  zipcode?: InputMaybe<SortOrder>;
};

export type LocationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LocationOrderByWithAggregationInput = {
  _avg?: InputMaybe<LocationAvgOrderByAggregateInput>;
  _count?: InputMaybe<LocationCountOrderByAggregateInput>;
  _max?: InputMaybe<LocationMaxOrderByAggregateInput>;
  _min?: InputMaybe<LocationMinOrderByAggregateInput>;
  _sum?: InputMaybe<LocationSumOrderByAggregateInput>;
  address?: InputMaybe<SortOrderInput>;
  address_display?: InputMaybe<SortOrderInput>;
  address_latitude?: InputMaybe<SortOrderInput>;
  address_longitude?: InputMaybe<SortOrderInput>;
  amenities_enums?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrderInput>;
  company_id?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrderInput>;
  cover_photo_url?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  friday_hours_close?: InputMaybe<SortOrderInput>;
  friday_hours_open?: InputMaybe<SortOrderInput>;
  google_places_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  libphonenumber?: InputMaybe<SortOrderInput>;
  menu_id?: InputMaybe<SortOrderInput>;
  monday_hours_close?: InputMaybe<SortOrderInput>;
  monday_hours_open?: InputMaybe<SortOrderInput>;
  multi_person?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  nickname?: InputMaybe<SortOrderInput>;
  phone_number?: InputMaybe<SortOrderInput>;
  saturday_hours_close?: InputMaybe<SortOrderInput>;
  saturday_hours_open?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  stripe_terminal_location_id?: InputMaybe<SortOrderInput>;
  sunday_hours_close?: InputMaybe<SortOrderInput>;
  sunday_hours_open?: InputMaybe<SortOrderInput>;
  thursday_hours_close?: InputMaybe<SortOrderInput>;
  thursday_hours_open?: InputMaybe<SortOrderInput>;
  timezone?: InputMaybe<SortOrder>;
  tuesday_hours_close?: InputMaybe<SortOrderInput>;
  tuesday_hours_open?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
  visible_on_homepage?: InputMaybe<SortOrder>;
  wednesday_hours_close?: InputMaybe<SortOrderInput>;
  wednesday_hours_open?: InputMaybe<SortOrderInput>;
  zipcode?: InputMaybe<SortOrderInput>;
};

export type LocationOrderByWithRelationInput = {
  address?: InputMaybe<SortOrderInput>;
  address_display?: InputMaybe<SortOrderInput>;
  address_latitude?: InputMaybe<SortOrderInput>;
  address_longitude?: InputMaybe<SortOrderInput>;
  amenities?: InputMaybe<LocationAmenityOrderByRelationAggregateInput>;
  amenities_enums?: InputMaybe<SortOrder>;
  appointments?: InputMaybe<AppointmentOrderByRelationAggregateInput>;
  availability?: InputMaybe<AvailabilityOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrderInput>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  company_id?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrderInput>;
  cover_photo_url?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  facility?: InputMaybe<FacilityOrderByRelationAggregateInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationOrderByRelationAggregateInput>;
  friday_hours_close?: InputMaybe<SortOrderInput>;
  friday_hours_open?: InputMaybe<SortOrderInput>;
  google_places_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  libphonenumber?: InputMaybe<SortOrderInput>;
  menu?: InputMaybe<MenuOrderByWithRelationInput>;
  menu_id?: InputMaybe<SortOrderInput>;
  monday_hours_close?: InputMaybe<SortOrderInput>;
  monday_hours_open?: InputMaybe<SortOrderInput>;
  multi_person?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  nickname?: InputMaybe<SortOrderInput>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  phone_number?: InputMaybe<SortOrderInput>;
  saturday_hours_close?: InputMaybe<SortOrderInput>;
  saturday_hours_open?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  stripe_terminal_location_id?: InputMaybe<SortOrderInput>;
  sunday_hours_close?: InputMaybe<SortOrderInput>;
  sunday_hours_open?: InputMaybe<SortOrderInput>;
  thursday_hours_close?: InputMaybe<SortOrderInput>;
  thursday_hours_open?: InputMaybe<SortOrderInput>;
  timezone?: InputMaybe<SortOrder>;
  tuesday_hours_close?: InputMaybe<SortOrderInput>;
  tuesday_hours_open?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
  visible_on_homepage?: InputMaybe<SortOrder>;
  wednesday_hours_close?: InputMaybe<SortOrderInput>;
  wednesday_hours_open?: InputMaybe<SortOrderInput>;
  zipcode?: InputMaybe<SortOrderInput>;
};

export type LocationRelationFilter = {
  is?: InputMaybe<LocationWhereInput>;
  isNot?: InputMaybe<LocationWhereInput>;
};

export enum LocationScalarFieldEnum {
  Address = "address",
  AddressDisplay = "address_display",
  AddressLatitude = "address_latitude",
  AddressLongitude = "address_longitude",
  AmenitiesEnums = "amenities_enums",
  City = "city",
  CompanyId = "company_id",
  Country = "country",
  CoverPhotoUrl = "cover_photo_url",
  CreatedAt = "created_at",
  Description = "description",
  FridayHoursClose = "friday_hours_close",
  FridayHoursOpen = "friday_hours_open",
  GooglePlacesId = "google_places_id",
  Id = "id",
  Libphonenumber = "libphonenumber",
  MenuId = "menu_id",
  MondayHoursClose = "monday_hours_close",
  MondayHoursOpen = "monday_hours_open",
  MultiPerson = "multi_person",
  Name = "name",
  Nickname = "nickname",
  PhoneNumber = "phone_number",
  SaturdayHoursClose = "saturday_hours_close",
  SaturdayHoursOpen = "saturday_hours_open",
  Slug = "slug",
  State = "state",
  Status = "status",
  StripeTerminalLocationId = "stripe_terminal_location_id",
  SundayHoursClose = "sunday_hours_close",
  SundayHoursOpen = "sunday_hours_open",
  ThursdayHoursClose = "thursday_hours_close",
  ThursdayHoursOpen = "thursday_hours_open",
  Timezone = "timezone",
  TuesdayHoursClose = "tuesday_hours_close",
  TuesdayHoursOpen = "tuesday_hours_open",
  UpdatedAt = "updated_at",
  VisibleOnHomepage = "visible_on_homepage",
  WednesdayHoursClose = "wednesday_hours_close",
  WednesdayHoursOpen = "wednesday_hours_open",
  Zipcode = "zipcode"
}

export type LocationScalarWhereInput = {
  AND?: InputMaybe<Array<LocationScalarWhereInput>>;
  NOT?: InputMaybe<Array<LocationScalarWhereInput>>;
  OR?: InputMaybe<Array<LocationScalarWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  address_display?: InputMaybe<StringNullableFilter>;
  address_latitude?: InputMaybe<FloatNullableFilter>;
  address_longitude?: InputMaybe<FloatNullableFilter>;
  amenities_enums?: InputMaybe<EnumAmenityEnumNullableListFilter>;
  city?: InputMaybe<StringNullableFilter>;
  company_id?: InputMaybe<UuidFilter>;
  country?: InputMaybe<StringNullableFilter>;
  cover_photo_url?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  friday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  friday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  google_places_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  libphonenumber?: InputMaybe<StringNullableFilter>;
  menu_id?: InputMaybe<UuidNullableFilter>;
  monday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  monday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  multi_person?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  nickname?: InputMaybe<StringNullableFilter>;
  phone_number?: InputMaybe<StringNullableFilter>;
  saturday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  saturday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<StringFilter>;
  stripe_terminal_location_id?: InputMaybe<StringNullableFilter>;
  sunday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  sunday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  thursday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  thursday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  timezone?: InputMaybe<StringFilter>;
  tuesday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  tuesday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible_on_homepage?: InputMaybe<BoolFilter>;
  wednesday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  wednesday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  zipcode?: InputMaybe<StringNullableFilter>;
};

export type LocationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LocationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LocationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LocationScalarWhereWithAggregatesInput>>;
  address?: InputMaybe<StringNullableWithAggregatesFilter>;
  address_display?: InputMaybe<StringNullableWithAggregatesFilter>;
  address_latitude?: InputMaybe<FloatNullableWithAggregatesFilter>;
  address_longitude?: InputMaybe<FloatNullableWithAggregatesFilter>;
  amenities_enums?: InputMaybe<EnumAmenityEnumNullableListFilter>;
  city?: InputMaybe<StringNullableWithAggregatesFilter>;
  company_id?: InputMaybe<UuidWithAggregatesFilter>;
  country?: InputMaybe<StringNullableWithAggregatesFilter>;
  cover_photo_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  friday_hours_close?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  friday_hours_open?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  google_places_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  libphonenumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  menu_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  monday_hours_close?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  monday_hours_open?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  multi_person?: InputMaybe<IntNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  nickname?: InputMaybe<StringNullableWithAggregatesFilter>;
  phone_number?: InputMaybe<StringNullableWithAggregatesFilter>;
  saturday_hours_close?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  saturday_hours_open?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  slug?: InputMaybe<StringNullableWithAggregatesFilter>;
  state?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<StringWithAggregatesFilter>;
  stripe_terminal_location_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  sunday_hours_close?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  sunday_hours_open?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  thursday_hours_close?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  thursday_hours_open?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  timezone?: InputMaybe<StringWithAggregatesFilter>;
  tuesday_hours_close?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  tuesday_hours_open?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  visible_on_homepage?: InputMaybe<BoolWithAggregatesFilter>;
  wednesday_hours_close?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  wednesday_hours_open?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  zipcode?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type LocationSumAggregate = {
  __typename?: "LocationSumAggregate";
  address_latitude?: Maybe<Scalars["Float"]>;
  address_longitude?: Maybe<Scalars["Float"]>;
  multi_person?: Maybe<Scalars["Int"]>;
};

export type LocationSumOrderByAggregateInput = {
  address_latitude?: InputMaybe<SortOrder>;
  address_longitude?: InputMaybe<SortOrder>;
  multi_person?: InputMaybe<SortOrder>;
};

export type LocationUpdateInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_display?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  address_longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  amenities?: InputMaybe<LocationAmenityUpdateManyWithoutLocationNestedInput>;
  amenities_enums?: InputMaybe<LocationUpdateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutLocationNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutLocationNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutLocationsNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutLocationNestedInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationUpdateManyWithoutLocationNestedInput>;
  friday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  friday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  google_places_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutLocationsNestedInput>;
  monday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  monday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  multi_person?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nickname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutLocationNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saturday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  saturday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_terminal_location_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sunday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sunday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tuesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tuesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_on_homepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wednesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wednesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zipcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateManyMutationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_display?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  address_longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  amenities_enums?: InputMaybe<LocationUpdateamenities_EnumsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  friday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  friday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  google_places_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  monday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  multi_person?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nickname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saturday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  saturday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_terminal_location_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sunday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sunday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tuesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tuesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_on_homepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wednesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wednesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zipcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateManyWithWhereWithoutCompanyInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateManyWithWhereWithoutMenuInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LocationCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<LocationCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<LocationCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  set?: InputMaybe<Array<LocationWhereUniqueInput>>;
  update?: InputMaybe<Array<LocationUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<
    Array<LocationUpdateManyWithWhereWithoutCompanyInput>
  >;
  upsert?: InputMaybe<Array<LocationUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type LocationUpdateManyWithoutMenuNestedInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutMenuInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutMenuInput>>;
  createMany?: InputMaybe<LocationCreateManyMenuInputEnvelope>;
  delete?: InputMaybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  set?: InputMaybe<Array<LocationWhereUniqueInput>>;
  update?: InputMaybe<Array<LocationUpdateWithWhereUniqueWithoutMenuInput>>;
  updateMany?: InputMaybe<Array<LocationUpdateManyWithWhereWithoutMenuInput>>;
  upsert?: InputMaybe<Array<LocationUpsertWithWhereUniqueWithoutMenuInput>>;
};

export type LocationUpdateOneRequiredWithoutAmenitiesNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutAmenitiesInput>;
  create?: InputMaybe<LocationCreateWithoutAmenitiesInput>;
  update?: InputMaybe<LocationUpdateWithoutAmenitiesInput>;
  upsert?: InputMaybe<LocationUpsertWithoutAmenitiesInput>;
};

export type LocationUpdateOneRequiredWithoutAppointmentsNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<LocationCreateWithoutAppointmentsInput>;
  update?: InputMaybe<LocationUpdateWithoutAppointmentsInput>;
  upsert?: InputMaybe<LocationUpsertWithoutAppointmentsInput>;
};

export type LocationUpdateOneRequiredWithoutAvailabilityNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutAvailabilityInput>;
  create?: InputMaybe<LocationCreateWithoutAvailabilityInput>;
  update?: InputMaybe<LocationUpdateWithoutAvailabilityInput>;
  upsert?: InputMaybe<LocationUpsertWithoutAvailabilityInput>;
};

export type LocationUpdateOneRequiredWithoutFacilityNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutFacilityInput>;
  create?: InputMaybe<LocationCreateWithoutFacilityInput>;
  update?: InputMaybe<LocationUpdateWithoutFacilityInput>;
  upsert?: InputMaybe<LocationUpsertWithoutFacilityInput>;
};

export type LocationUpdateOneRequiredWithoutFavorited_By_UsersNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutFavorited_By_UsersInput>;
  create?: InputMaybe<LocationCreateWithoutFavorited_By_UsersInput>;
  update?: InputMaybe<LocationUpdateWithoutFavorited_By_UsersInput>;
  upsert?: InputMaybe<LocationUpsertWithoutFavorited_By_UsersInput>;
};

export type LocationUpdateOneWithoutOrdersNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<LocationCreateWithoutOrdersInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<LocationUpdateWithoutOrdersInput>;
  upsert?: InputMaybe<LocationUpsertWithoutOrdersInput>;
};

export type LocationUpdateWithWhereUniqueWithoutCompanyInput = {
  data: LocationUpdateWithoutCompanyInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpdateWithWhereUniqueWithoutMenuInput = {
  data: LocationUpdateWithoutMenuInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpdateWithoutAmenitiesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_display?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  address_longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  amenities_enums?: InputMaybe<LocationUpdateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutLocationNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutLocationNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutLocationsNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutLocationNestedInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationUpdateManyWithoutLocationNestedInput>;
  friday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  friday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  google_places_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutLocationsNestedInput>;
  monday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  monday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  multi_person?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nickname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutLocationNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saturday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  saturday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_terminal_location_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sunday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sunday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tuesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tuesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_on_homepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wednesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wednesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zipcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutAppointmentsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_display?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  address_longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  amenities?: InputMaybe<LocationAmenityUpdateManyWithoutLocationNestedInput>;
  amenities_enums?: InputMaybe<LocationUpdateamenities_EnumsInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutLocationNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutLocationsNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutLocationNestedInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationUpdateManyWithoutLocationNestedInput>;
  friday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  friday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  google_places_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutLocationsNestedInput>;
  monday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  monday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  multi_person?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nickname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutLocationNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saturday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  saturday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_terminal_location_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sunday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sunday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tuesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tuesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_on_homepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wednesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wednesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zipcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutAvailabilityInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_display?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  address_longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  amenities?: InputMaybe<LocationAmenityUpdateManyWithoutLocationNestedInput>;
  amenities_enums?: InputMaybe<LocationUpdateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutLocationNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutLocationsNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutLocationNestedInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationUpdateManyWithoutLocationNestedInput>;
  friday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  friday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  google_places_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutLocationsNestedInput>;
  monday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  monday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  multi_person?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nickname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutLocationNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saturday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  saturday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_terminal_location_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sunday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sunday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tuesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tuesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_on_homepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wednesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wednesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zipcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutCompanyInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_display?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  address_longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  amenities?: InputMaybe<LocationAmenityUpdateManyWithoutLocationNestedInput>;
  amenities_enums?: InputMaybe<LocationUpdateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutLocationNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutLocationNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutLocationNestedInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationUpdateManyWithoutLocationNestedInput>;
  friday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  friday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  google_places_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutLocationsNestedInput>;
  monday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  monday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  multi_person?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nickname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutLocationNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saturday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  saturday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_terminal_location_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sunday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sunday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tuesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tuesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_on_homepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wednesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wednesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zipcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutFacilityInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_display?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  address_longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  amenities?: InputMaybe<LocationAmenityUpdateManyWithoutLocationNestedInput>;
  amenities_enums?: InputMaybe<LocationUpdateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutLocationNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutLocationNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutLocationsNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationUpdateManyWithoutLocationNestedInput>;
  friday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  friday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  google_places_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutLocationsNestedInput>;
  monday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  monday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  multi_person?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nickname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutLocationNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saturday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  saturday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_terminal_location_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sunday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sunday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tuesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tuesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_on_homepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wednesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wednesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zipcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutFavorited_By_UsersInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_display?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  address_longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  amenities?: InputMaybe<LocationAmenityUpdateManyWithoutLocationNestedInput>;
  amenities_enums?: InputMaybe<LocationUpdateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutLocationNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutLocationNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutLocationsNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutLocationNestedInput>;
  friday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  friday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  google_places_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutLocationsNestedInput>;
  monday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  monday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  multi_person?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nickname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutLocationNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saturday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  saturday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_terminal_location_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sunday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sunday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tuesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tuesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_on_homepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wednesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wednesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zipcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutMenuInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_display?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  address_longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  amenities?: InputMaybe<LocationAmenityUpdateManyWithoutLocationNestedInput>;
  amenities_enums?: InputMaybe<LocationUpdateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutLocationNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutLocationNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutLocationsNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutLocationNestedInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationUpdateManyWithoutLocationNestedInput>;
  friday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  friday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  google_places_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  monday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  multi_person?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nickname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutLocationNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saturday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  saturday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_terminal_location_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sunday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sunday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tuesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tuesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_on_homepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wednesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wednesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zipcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutOrdersInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_display?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  address_longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  amenities?: InputMaybe<LocationAmenityUpdateManyWithoutLocationNestedInput>;
  amenities_enums?: InputMaybe<LocationUpdateamenities_EnumsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutLocationNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutLocationNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutLocationsNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover_photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateManyWithoutLocationNestedInput>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationUpdateManyWithoutLocationNestedInput>;
  friday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  friday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  google_places_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutLocationsNestedInput>;
  monday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  monday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  multi_person?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nickname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saturday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  saturday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  slug?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_terminal_location_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sunday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sunday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  thursday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tuesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tuesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_on_homepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wednesday_hours_close?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wednesday_hours_open?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zipcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateamenities_EnumsInput = {
  push?: InputMaybe<Array<AmenityEnum>>;
  set?: InputMaybe<Array<AmenityEnum>>;
};

export type LocationUpsertWithWhereUniqueWithoutCompanyInput = {
  create: LocationCreateWithoutCompanyInput;
  update: LocationUpdateWithoutCompanyInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpsertWithWhereUniqueWithoutMenuInput = {
  create: LocationCreateWithoutMenuInput;
  update: LocationUpdateWithoutMenuInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpsertWithoutAmenitiesInput = {
  create: LocationCreateWithoutAmenitiesInput;
  update: LocationUpdateWithoutAmenitiesInput;
};

export type LocationUpsertWithoutAppointmentsInput = {
  create: LocationCreateWithoutAppointmentsInput;
  update: LocationUpdateWithoutAppointmentsInput;
};

export type LocationUpsertWithoutAvailabilityInput = {
  create: LocationCreateWithoutAvailabilityInput;
  update: LocationUpdateWithoutAvailabilityInput;
};

export type LocationUpsertWithoutFacilityInput = {
  create: LocationCreateWithoutFacilityInput;
  update: LocationUpdateWithoutFacilityInput;
};

export type LocationUpsertWithoutFavorited_By_UsersInput = {
  create: LocationCreateWithoutFavorited_By_UsersInput;
  update: LocationUpdateWithoutFavorited_By_UsersInput;
};

export type LocationUpsertWithoutOrdersInput = {
  create: LocationCreateWithoutOrdersInput;
  update: LocationUpdateWithoutOrdersInput;
};

export type LocationWhereInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  address_display?: InputMaybe<StringNullableFilter>;
  address_latitude?: InputMaybe<FloatNullableFilter>;
  address_longitude?: InputMaybe<FloatNullableFilter>;
  amenities?: InputMaybe<LocationAmenityListRelationFilter>;
  amenities_enums?: InputMaybe<EnumAmenityEnumNullableListFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  availability?: InputMaybe<AvailabilityListRelationFilter>;
  city?: InputMaybe<StringNullableFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  company_id?: InputMaybe<UuidFilter>;
  country?: InputMaybe<StringNullableFilter>;
  cover_photo_url?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  facility?: InputMaybe<FacilityListRelationFilter>;
  favorited_by_users?: InputMaybe<UserFavoriteLocationListRelationFilter>;
  friday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  friday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  google_places_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  libphonenumber?: InputMaybe<StringNullableFilter>;
  menu?: InputMaybe<MenuRelationFilter>;
  menu_id?: InputMaybe<UuidNullableFilter>;
  monday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  monday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  multi_person?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  nickname?: InputMaybe<StringNullableFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  phone_number?: InputMaybe<StringNullableFilter>;
  saturday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  saturday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<StringFilter>;
  stripe_terminal_location_id?: InputMaybe<StringNullableFilter>;
  sunday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  sunday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  thursday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  thursday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  timezone?: InputMaybe<StringFilter>;
  tuesday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  tuesday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible_on_homepage?: InputMaybe<BoolFilter>;
  wednesday_hours_close?: InputMaybe<DateTimeNullableFilter>;
  wednesday_hours_open?: InputMaybe<DateTimeNullableFilter>;
  zipcode?: InputMaybe<StringNullableFilter>;
};

export type LocationWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type Menu = {
  __typename?: "Menu";
  _count?: Maybe<MenuCount>;
  company: Company;
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  locations: Array<Location>;
  menu_sections: Array<MenuSection>;
  name: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type MenuLocationsArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationWhereInput>;
};

export type MenuMenu_SectionsArgs = {
  cursor?: InputMaybe<MenuSectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuSectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuSectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type MenuCount = {
  __typename?: "MenuCount";
  locations: Scalars["Int"];
  menu_sections: Scalars["Int"];
};

export type MenuCountLocationsArgs = {
  where?: InputMaybe<LocationWhereInput>;
};

export type MenuCountMenu_SectionsArgs = {
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type MenuCountAggregate = {
  __typename?: "MenuCountAggregate";
  _all: Scalars["Int"];
  company_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  name: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type MenuCountOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuCreateInput = {
  company: CompanyCreateNestedOneWithoutMenuInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutMenuInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutMenuInput>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuCreateManyCompanyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuCreateManyCompanyInputEnvelope = {
  data: Array<MenuCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MenuCreateManyInput = {
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<MenuCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<MenuCreateManyCompanyInputEnvelope>;
};

export type MenuCreateNestedOneWithoutLocationsInput = {
  connect?: InputMaybe<MenuWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MenuCreateOrConnectWithoutLocationsInput>;
  create?: InputMaybe<MenuCreateWithoutLocationsInput>;
};

export type MenuCreateNestedOneWithoutMenu_SectionsInput = {
  connect?: InputMaybe<MenuWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MenuCreateOrConnectWithoutMenu_SectionsInput>;
  create?: InputMaybe<MenuCreateWithoutMenu_SectionsInput>;
};

export type MenuCreateOrConnectWithoutCompanyInput = {
  create: MenuCreateWithoutCompanyInput;
  where: MenuWhereUniqueInput;
};

export type MenuCreateOrConnectWithoutLocationsInput = {
  create: MenuCreateWithoutLocationsInput;
  where: MenuWhereUniqueInput;
};

export type MenuCreateOrConnectWithoutMenu_SectionsInput = {
  create: MenuCreateWithoutMenu_SectionsInput;
  where: MenuWhereUniqueInput;
};

export type MenuCreateWithoutCompanyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutMenuInput>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutMenuInput>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuCreateWithoutLocationsInput = {
  company: CompanyCreateNestedOneWithoutMenuInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu_sections?: InputMaybe<MenuSectionCreateNestedManyWithoutMenuInput>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuCreateWithoutMenu_SectionsInput = {
  company: CompanyCreateNestedOneWithoutMenuInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutMenuInput>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuGroupBy = {
  __typename?: "MenuGroupBy";
  _count?: Maybe<MenuCountAggregate>;
  _max?: Maybe<MenuMaxAggregate>;
  _min?: Maybe<MenuMinAggregate>;
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  name: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type MenuListRelationFilter = {
  every?: InputMaybe<MenuWhereInput>;
  none?: InputMaybe<MenuWhereInput>;
  some?: InputMaybe<MenuWhereInput>;
};

export type MenuMaxAggregate = {
  __typename?: "MenuMaxAggregate";
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type MenuMaxOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuMinAggregate = {
  __typename?: "MenuMinAggregate";
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type MenuMinOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MenuOrderByWithAggregationInput = {
  _count?: InputMaybe<MenuCountOrderByAggregateInput>;
  _max?: InputMaybe<MenuMaxOrderByAggregateInput>;
  _min?: InputMaybe<MenuMinOrderByAggregateInput>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locations?: InputMaybe<LocationOrderByRelationAggregateInput>;
  menu_sections?: InputMaybe<MenuSectionOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuRelationFilter = {
  is?: InputMaybe<MenuWhereInput>;
  isNot?: InputMaybe<MenuWhereInput>;
};

export enum MenuScalarFieldEnum {
  CompanyId = "company_id",
  CreatedAt = "created_at",
  Id = "id",
  Name = "name",
  UpdatedAt = "updated_at"
}

export type MenuScalarWhereInput = {
  AND?: InputMaybe<Array<MenuScalarWhereInput>>;
  NOT?: InputMaybe<Array<MenuScalarWhereInput>>;
  OR?: InputMaybe<Array<MenuScalarWhereInput>>;
  company_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type MenuScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MenuScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MenuScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MenuScalarWhereWithAggregatesInput>>;
  company_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MenuSection = {
  __typename?: "MenuSection";
  _count?: Maybe<MenuSectionCount>;
  company?: Maybe<Company>;
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  menu?: Maybe<Menu>;
  menu_id?: Maybe<Scalars["String"]>;
  menu_section_services: Array<MenuSectionService>;
  name: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type MenuSectionMenu_Section_ServicesArgs = {
  cursor?: InputMaybe<MenuSectionServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuSectionServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuSectionServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type MenuSectionCount = {
  __typename?: "MenuSectionCount";
  menu_section_services: Scalars["Int"];
};

export type MenuSectionCountMenu_Section_ServicesArgs = {
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type MenuSectionCountAggregate = {
  __typename?: "MenuSectionCountAggregate";
  _all: Scalars["Int"];
  company_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  menu_id: Scalars["Int"];
  name: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type MenuSectionCountOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  menu_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuSectionCreateInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutMenu_SectionsInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutMenu_SectionsInput>;
  menu_section_services?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutMenu_SectionInput>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionCreateManyCompanyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu_id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionCreateManyCompanyInputEnvelope = {
  data: Array<MenuSectionCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MenuSectionCreateManyInput = {
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu_id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionCreateManyMenuInput = {
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionCreateManyMenuInputEnvelope = {
  data: Array<MenuSectionCreateManyMenuInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MenuSectionCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<MenuSectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MenuSectionCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<MenuSectionCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<MenuSectionCreateManyCompanyInputEnvelope>;
};

export type MenuSectionCreateNestedManyWithoutMenuInput = {
  connect?: InputMaybe<Array<MenuSectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MenuSectionCreateOrConnectWithoutMenuInput>
  >;
  create?: InputMaybe<Array<MenuSectionCreateWithoutMenuInput>>;
  createMany?: InputMaybe<MenuSectionCreateManyMenuInputEnvelope>;
};

export type MenuSectionCreateNestedOneWithoutMenu_Section_ServicesInput = {
  connect?: InputMaybe<MenuSectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MenuSectionCreateOrConnectWithoutMenu_Section_ServicesInput>;
  create?: InputMaybe<MenuSectionCreateWithoutMenu_Section_ServicesInput>;
};

export type MenuSectionCreateOrConnectWithoutCompanyInput = {
  create: MenuSectionCreateWithoutCompanyInput;
  where: MenuSectionWhereUniqueInput;
};

export type MenuSectionCreateOrConnectWithoutMenuInput = {
  create: MenuSectionCreateWithoutMenuInput;
  where: MenuSectionWhereUniqueInput;
};

export type MenuSectionCreateOrConnectWithoutMenu_Section_ServicesInput = {
  create: MenuSectionCreateWithoutMenu_Section_ServicesInput;
  where: MenuSectionWhereUniqueInput;
};

export type MenuSectionCreateWithoutCompanyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutMenu_SectionsInput>;
  menu_section_services?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutMenu_SectionInput>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionCreateWithoutMenuInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutMenu_SectionsInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu_section_services?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutMenu_SectionInput>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionCreateWithoutMenu_Section_ServicesInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutMenu_SectionsInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu?: InputMaybe<MenuCreateNestedOneWithoutMenu_SectionsInput>;
  name: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionGroupBy = {
  __typename?: "MenuSectionGroupBy";
  _count?: Maybe<MenuSectionCountAggregate>;
  _max?: Maybe<MenuSectionMaxAggregate>;
  _min?: Maybe<MenuSectionMinAggregate>;
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  menu_id?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type MenuSectionListRelationFilter = {
  every?: InputMaybe<MenuSectionWhereInput>;
  none?: InputMaybe<MenuSectionWhereInput>;
  some?: InputMaybe<MenuSectionWhereInput>;
};

export type MenuSectionMaxAggregate = {
  __typename?: "MenuSectionMaxAggregate";
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  menu_id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type MenuSectionMaxOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  menu_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuSectionMinAggregate = {
  __typename?: "MenuSectionMinAggregate";
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  menu_id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type MenuSectionMinOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  menu_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuSectionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MenuSectionOrderByWithAggregationInput = {
  _count?: InputMaybe<MenuSectionCountOrderByAggregateInput>;
  _max?: InputMaybe<MenuSectionMaxOrderByAggregateInput>;
  _min?: InputMaybe<MenuSectionMinOrderByAggregateInput>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  menu_id?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuSectionOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  menu?: InputMaybe<MenuOrderByWithRelationInput>;
  menu_id?: InputMaybe<SortOrderInput>;
  menu_section_services?: InputMaybe<MenuSectionServiceOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuSectionRelationFilter = {
  is?: InputMaybe<MenuSectionWhereInput>;
  isNot?: InputMaybe<MenuSectionWhereInput>;
};

export enum MenuSectionScalarFieldEnum {
  CompanyId = "company_id",
  CreatedAt = "created_at",
  Id = "id",
  MenuId = "menu_id",
  Name = "name",
  UpdatedAt = "updated_at"
}

export type MenuSectionScalarWhereInput = {
  AND?: InputMaybe<Array<MenuSectionScalarWhereInput>>;
  NOT?: InputMaybe<Array<MenuSectionScalarWhereInput>>;
  OR?: InputMaybe<Array<MenuSectionScalarWhereInput>>;
  company_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  menu_id?: InputMaybe<UuidNullableFilter>;
  name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type MenuSectionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MenuSectionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MenuSectionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MenuSectionScalarWhereWithAggregatesInput>>;
  company_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  menu_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MenuSectionService = {
  __typename?: "MenuSectionService";
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  menu_section?: Maybe<MenuSection>;
  menu_section_id?: Maybe<Scalars["String"]>;
  service?: Maybe<Service>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type MenuSectionServiceCountAggregate = {
  __typename?: "MenuSectionServiceCountAggregate";
  _all: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  menu_section_id: Scalars["Int"];
  service_id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type MenuSectionServiceCountOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  menu_section_id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuSectionServiceCreateInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu_section?: InputMaybe<MenuSectionCreateNestedOneWithoutMenu_Section_ServicesInput>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutMenu_SectionsInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionServiceCreateManyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu_section_id?: InputMaybe<Scalars["String"]>;
  service_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionServiceCreateManyMenu_SectionInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  service_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionServiceCreateManyMenu_SectionInputEnvelope = {
  data: Array<MenuSectionServiceCreateManyMenu_SectionInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MenuSectionServiceCreateManyServiceInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu_section_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionServiceCreateManyServiceInputEnvelope = {
  data: Array<MenuSectionServiceCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MenuSectionServiceCreateNestedManyWithoutMenu_SectionInput = {
  connect?: InputMaybe<Array<MenuSectionServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MenuSectionServiceCreateOrConnectWithoutMenu_SectionInput>
  >;
  create?: InputMaybe<Array<MenuSectionServiceCreateWithoutMenu_SectionInput>>;
  createMany?: InputMaybe<MenuSectionServiceCreateManyMenu_SectionInputEnvelope>;
};

export type MenuSectionServiceCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<MenuSectionServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MenuSectionServiceCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<MenuSectionServiceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<MenuSectionServiceCreateManyServiceInputEnvelope>;
};

export type MenuSectionServiceCreateOrConnectWithoutMenu_SectionInput = {
  create: MenuSectionServiceCreateWithoutMenu_SectionInput;
  where: MenuSectionServiceWhereUniqueInput;
};

export type MenuSectionServiceCreateOrConnectWithoutServiceInput = {
  create: MenuSectionServiceCreateWithoutServiceInput;
  where: MenuSectionServiceWhereUniqueInput;
};

export type MenuSectionServiceCreateWithoutMenu_SectionInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutMenu_SectionsInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionServiceCreateWithoutServiceInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  menu_section?: InputMaybe<MenuSectionCreateNestedOneWithoutMenu_Section_ServicesInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuSectionServiceGroupBy = {
  __typename?: "MenuSectionServiceGroupBy";
  _count?: Maybe<MenuSectionServiceCountAggregate>;
  _max?: Maybe<MenuSectionServiceMaxAggregate>;
  _min?: Maybe<MenuSectionServiceMinAggregate>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  menu_section_id?: Maybe<Scalars["String"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type MenuSectionServiceListRelationFilter = {
  every?: InputMaybe<MenuSectionServiceWhereInput>;
  none?: InputMaybe<MenuSectionServiceWhereInput>;
  some?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type MenuSectionServiceMaxAggregate = {
  __typename?: "MenuSectionServiceMaxAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  menu_section_id?: Maybe<Scalars["String"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type MenuSectionServiceMaxOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  menu_section_id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuSectionServiceMinAggregate = {
  __typename?: "MenuSectionServiceMinAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  menu_section_id?: Maybe<Scalars["String"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type MenuSectionServiceMinOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  menu_section_id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuSectionServiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MenuSectionServiceOrderByWithAggregationInput = {
  _count?: InputMaybe<MenuSectionServiceCountOrderByAggregateInput>;
  _max?: InputMaybe<MenuSectionServiceMaxOrderByAggregateInput>;
  _min?: InputMaybe<MenuSectionServiceMinOrderByAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  menu_section_id?: InputMaybe<SortOrderInput>;
  service_id?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type MenuSectionServiceOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  menu_section?: InputMaybe<MenuSectionOrderByWithRelationInput>;
  menu_section_id?: InputMaybe<SortOrderInput>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  service_id?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum MenuSectionServiceScalarFieldEnum {
  CreatedAt = "created_at",
  Id = "id",
  MenuSectionId = "menu_section_id",
  ServiceId = "service_id",
  UpdatedAt = "updated_at"
}

export type MenuSectionServiceScalarWhereInput = {
  AND?: InputMaybe<Array<MenuSectionServiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<MenuSectionServiceScalarWhereInput>>;
  OR?: InputMaybe<Array<MenuSectionServiceScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  menu_section_id?: InputMaybe<UuidNullableFilter>;
  service_id?: InputMaybe<UuidNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type MenuSectionServiceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MenuSectionServiceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MenuSectionServiceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MenuSectionServiceScalarWhereWithAggregatesInput>>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  menu_section_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  service_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MenuSectionServiceUpdateInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  menu_section?: InputMaybe<MenuSectionUpdateOneWithoutMenu_Section_ServicesNestedInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutMenu_SectionsNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuSectionServiceUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuSectionServiceUpdateManyWithWhereWithoutMenu_SectionInput = {
  data: MenuSectionServiceUpdateManyMutationInput;
  where: MenuSectionServiceScalarWhereInput;
};

export type MenuSectionServiceUpdateManyWithWhereWithoutServiceInput = {
  data: MenuSectionServiceUpdateManyMutationInput;
  where: MenuSectionServiceScalarWhereInput;
};

export type MenuSectionServiceUpdateManyWithoutMenu_SectionNestedInput = {
  connect?: InputMaybe<Array<MenuSectionServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MenuSectionServiceCreateOrConnectWithoutMenu_SectionInput>
  >;
  create?: InputMaybe<Array<MenuSectionServiceCreateWithoutMenu_SectionInput>>;
  createMany?: InputMaybe<MenuSectionServiceCreateManyMenu_SectionInputEnvelope>;
  delete?: InputMaybe<Array<MenuSectionServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MenuSectionServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MenuSectionServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<MenuSectionServiceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<MenuSectionServiceUpdateWithWhereUniqueWithoutMenu_SectionInput>
  >;
  updateMany?: InputMaybe<
    Array<MenuSectionServiceUpdateManyWithWhereWithoutMenu_SectionInput>
  >;
  upsert?: InputMaybe<
    Array<MenuSectionServiceUpsertWithWhereUniqueWithoutMenu_SectionInput>
  >;
};

export type MenuSectionServiceUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<MenuSectionServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MenuSectionServiceCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<MenuSectionServiceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<MenuSectionServiceCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<MenuSectionServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MenuSectionServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MenuSectionServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<MenuSectionServiceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<MenuSectionServiceUpdateWithWhereUniqueWithoutServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<MenuSectionServiceUpdateManyWithWhereWithoutServiceInput>
  >;
  upsert?: InputMaybe<
    Array<MenuSectionServiceUpsertWithWhereUniqueWithoutServiceInput>
  >;
};

export type MenuSectionServiceUpdateWithWhereUniqueWithoutMenu_SectionInput = {
  data: MenuSectionServiceUpdateWithoutMenu_SectionInput;
  where: MenuSectionServiceWhereUniqueInput;
};

export type MenuSectionServiceUpdateWithWhereUniqueWithoutServiceInput = {
  data: MenuSectionServiceUpdateWithoutServiceInput;
  where: MenuSectionServiceWhereUniqueInput;
};

export type MenuSectionServiceUpdateWithoutMenu_SectionInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutMenu_SectionsNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuSectionServiceUpdateWithoutServiceInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  menu_section?: InputMaybe<MenuSectionUpdateOneWithoutMenu_Section_ServicesNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuSectionServiceUpsertWithWhereUniqueWithoutMenu_SectionInput = {
  create: MenuSectionServiceCreateWithoutMenu_SectionInput;
  update: MenuSectionServiceUpdateWithoutMenu_SectionInput;
  where: MenuSectionServiceWhereUniqueInput;
};

export type MenuSectionServiceUpsertWithWhereUniqueWithoutServiceInput = {
  create: MenuSectionServiceCreateWithoutServiceInput;
  update: MenuSectionServiceUpdateWithoutServiceInput;
  where: MenuSectionServiceWhereUniqueInput;
};

export type MenuSectionServiceWhereInput = {
  AND?: InputMaybe<Array<MenuSectionServiceWhereInput>>;
  NOT?: InputMaybe<Array<MenuSectionServiceWhereInput>>;
  OR?: InputMaybe<Array<MenuSectionServiceWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  menu_section?: InputMaybe<MenuSectionRelationFilter>;
  menu_section_id?: InputMaybe<UuidNullableFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  service_id?: InputMaybe<UuidNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type MenuSectionServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type MenuSectionUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutMenu_SectionsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutMenu_SectionsNestedInput>;
  menu_section_services?: InputMaybe<MenuSectionServiceUpdateManyWithoutMenu_SectionNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuSectionUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuSectionUpdateManyWithWhereWithoutCompanyInput = {
  data: MenuSectionUpdateManyMutationInput;
  where: MenuSectionScalarWhereInput;
};

export type MenuSectionUpdateManyWithWhereWithoutMenuInput = {
  data: MenuSectionUpdateManyMutationInput;
  where: MenuSectionScalarWhereInput;
};

export type MenuSectionUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<MenuSectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MenuSectionCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<MenuSectionCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<MenuSectionCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<MenuSectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MenuSectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MenuSectionWhereUniqueInput>>;
  set?: InputMaybe<Array<MenuSectionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<MenuSectionUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: InputMaybe<
    Array<MenuSectionUpdateManyWithWhereWithoutCompanyInput>
  >;
  upsert?: InputMaybe<
    Array<MenuSectionUpsertWithWhereUniqueWithoutCompanyInput>
  >;
};

export type MenuSectionUpdateManyWithoutMenuNestedInput = {
  connect?: InputMaybe<Array<MenuSectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MenuSectionCreateOrConnectWithoutMenuInput>
  >;
  create?: InputMaybe<Array<MenuSectionCreateWithoutMenuInput>>;
  createMany?: InputMaybe<MenuSectionCreateManyMenuInputEnvelope>;
  delete?: InputMaybe<Array<MenuSectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MenuSectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MenuSectionWhereUniqueInput>>;
  set?: InputMaybe<Array<MenuSectionWhereUniqueInput>>;
  update?: InputMaybe<Array<MenuSectionUpdateWithWhereUniqueWithoutMenuInput>>;
  updateMany?: InputMaybe<
    Array<MenuSectionUpdateManyWithWhereWithoutMenuInput>
  >;
  upsert?: InputMaybe<Array<MenuSectionUpsertWithWhereUniqueWithoutMenuInput>>;
};

export type MenuSectionUpdateOneWithoutMenu_Section_ServicesNestedInput = {
  connect?: InputMaybe<MenuSectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MenuSectionCreateOrConnectWithoutMenu_Section_ServicesInput>;
  create?: InputMaybe<MenuSectionCreateWithoutMenu_Section_ServicesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<MenuSectionUpdateWithoutMenu_Section_ServicesInput>;
  upsert?: InputMaybe<MenuSectionUpsertWithoutMenu_Section_ServicesInput>;
};

export type MenuSectionUpdateWithWhereUniqueWithoutCompanyInput = {
  data: MenuSectionUpdateWithoutCompanyInput;
  where: MenuSectionWhereUniqueInput;
};

export type MenuSectionUpdateWithWhereUniqueWithoutMenuInput = {
  data: MenuSectionUpdateWithoutMenuInput;
  where: MenuSectionWhereUniqueInput;
};

export type MenuSectionUpdateWithoutCompanyInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutMenu_SectionsNestedInput>;
  menu_section_services?: InputMaybe<MenuSectionServiceUpdateManyWithoutMenu_SectionNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuSectionUpdateWithoutMenuInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutMenu_SectionsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  menu_section_services?: InputMaybe<MenuSectionServiceUpdateManyWithoutMenu_SectionNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuSectionUpdateWithoutMenu_Section_ServicesInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutMenu_SectionsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneWithoutMenu_SectionsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuSectionUpsertWithWhereUniqueWithoutCompanyInput = {
  create: MenuSectionCreateWithoutCompanyInput;
  update: MenuSectionUpdateWithoutCompanyInput;
  where: MenuSectionWhereUniqueInput;
};

export type MenuSectionUpsertWithWhereUniqueWithoutMenuInput = {
  create: MenuSectionCreateWithoutMenuInput;
  update: MenuSectionUpdateWithoutMenuInput;
  where: MenuSectionWhereUniqueInput;
};

export type MenuSectionUpsertWithoutMenu_Section_ServicesInput = {
  create: MenuSectionCreateWithoutMenu_Section_ServicesInput;
  update: MenuSectionUpdateWithoutMenu_Section_ServicesInput;
};

export type MenuSectionWhereInput = {
  AND?: InputMaybe<Array<MenuSectionWhereInput>>;
  NOT?: InputMaybe<Array<MenuSectionWhereInput>>;
  OR?: InputMaybe<Array<MenuSectionWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  company_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  menu?: InputMaybe<MenuRelationFilter>;
  menu_id?: InputMaybe<UuidNullableFilter>;
  menu_section_services?: InputMaybe<MenuSectionServiceListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type MenuSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type MenuUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutMenuNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutMenuNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutMenuNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuUpdateManyWithWhereWithoutCompanyInput = {
  data: MenuUpdateManyMutationInput;
  where: MenuScalarWhereInput;
};

export type MenuUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<MenuCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<MenuCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<MenuWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MenuScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  set?: InputMaybe<Array<MenuWhereUniqueInput>>;
  update?: InputMaybe<Array<MenuUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<MenuUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<MenuUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type MenuUpdateOneWithoutLocationsNestedInput = {
  connect?: InputMaybe<MenuWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MenuCreateOrConnectWithoutLocationsInput>;
  create?: InputMaybe<MenuCreateWithoutLocationsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<MenuUpdateWithoutLocationsInput>;
  upsert?: InputMaybe<MenuUpsertWithoutLocationsInput>;
};

export type MenuUpdateOneWithoutMenu_SectionsNestedInput = {
  connect?: InputMaybe<MenuWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MenuCreateOrConnectWithoutMenu_SectionsInput>;
  create?: InputMaybe<MenuCreateWithoutMenu_SectionsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<MenuUpdateWithoutMenu_SectionsInput>;
  upsert?: InputMaybe<MenuUpsertWithoutMenu_SectionsInput>;
};

export type MenuUpdateWithWhereUniqueWithoutCompanyInput = {
  data: MenuUpdateWithoutCompanyInput;
  where: MenuWhereUniqueInput;
};

export type MenuUpdateWithoutCompanyInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutMenuNestedInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutMenuNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuUpdateWithoutLocationsInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutMenuNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionUpdateManyWithoutMenuNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuUpdateWithoutMenu_SectionsInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutMenuNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutMenuNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuUpsertWithWhereUniqueWithoutCompanyInput = {
  create: MenuCreateWithoutCompanyInput;
  update: MenuUpdateWithoutCompanyInput;
  where: MenuWhereUniqueInput;
};

export type MenuUpsertWithoutLocationsInput = {
  create: MenuCreateWithoutLocationsInput;
  update: MenuUpdateWithoutLocationsInput;
};

export type MenuUpsertWithoutMenu_SectionsInput = {
  create: MenuCreateWithoutMenu_SectionsInput;
  update: MenuUpdateWithoutMenu_SectionsInput;
};

export type MenuWhereInput = {
  AND?: InputMaybe<Array<MenuWhereInput>>;
  NOT?: InputMaybe<Array<MenuWhereInput>>;
  OR?: InputMaybe<Array<MenuWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  company_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  locations?: InputMaybe<LocationListRelationFilter>;
  menu_sections?: InputMaybe<MenuSectionListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type MenuWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createManyAmenity: AffectedRowsOutput;
  createManyAppointment: AffectedRowsOutput;
  createManyAppointmentService: AffectedRowsOutput;
  createManyArtist: AffectedRowsOutput;
  createManyArtistAvailability: AffectedRowsOutput;
  createManyArtistInstagram: AffectedRowsOutput;
  createManyArtistServiceExclusion: AffectedRowsOutput;
  createManyAvailability: AffectedRowsOutput;
  createManyCompany: AffectedRowsOutput;
  createManyCompanyAmenity: AffectedRowsOutput;
  createManyCompanyPaymentMethod: AffectedRowsOutput;
  createManyCompanyUserDescription: AffectedRowsOutput;
  createManyFacility: AffectedRowsOutput;
  createManyFacilityService: AffectedRowsOutput;
  createManyFavoriteArtist: AffectedRowsOutput;
  createManyGroupAvailability: AffectedRowsOutput;
  createManyLocation: AffectedRowsOutput;
  createManyLocationAmenity: AffectedRowsOutput;
  createManyMenu: AffectedRowsOutput;
  createManyMenuSection: AffectedRowsOutput;
  createManyMenuSectionService: AffectedRowsOutput;
  createManyNotification: AffectedRowsOutput;
  createManyOrder: AffectedRowsOutput;
  createManyPlatform: AffectedRowsOutput;
  createManyRefund: AffectedRowsOutput;
  createManyReminderSetting: AffectedRowsOutput;
  createManyRole: AffectedRowsOutput;
  createManyService: AffectedRowsOutput;
  createManyServiceAddOn: AffectedRowsOutput;
  createManyStripePaymentMethod: AffectedRowsOutput;
  createManyTransaction: AffectedRowsOutput;
  createManyTxAddon: AffectedRowsOutput;
  createManyTxCancellationPolicy: AffectedRowsOutput;
  createManyTxItem: AffectedRowsOutput;
  createManyTxService: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createManyUserFavoriteLocation: AffectedRowsOutput;
  createOneAmenity: Amenity;
  createOneAppointment: Appointment;
  createOneAppointmentService: AppointmentService;
  createOneArtist: Artist;
  createOneArtistAvailability: ArtistAvailability;
  createOneArtistInstagram: ArtistInstagram;
  createOneArtistServiceExclusion: ArtistServiceExclusion;
  createOneAvailability: Availability;
  createOneCompany: Company;
  createOneCompanyAmenity: CompanyAmenity;
  createOneCompanyPaymentMethod: CompanyPaymentMethod;
  createOneCompanyUserDescription: CompanyUserDescription;
  createOneFacility: Facility;
  createOneFacilityService: FacilityService;
  createOneFavoriteArtist: FavoriteArtist;
  createOneGroupAvailability: GroupAvailability;
  createOneLocation: Location;
  createOneLocationAmenity: LocationAmenity;
  createOneMenu: Menu;
  createOneMenuSection: MenuSection;
  createOneMenuSectionService: MenuSectionService;
  createOneNotification: Notification;
  createOneOrder: Order;
  createOnePlatform: Platform;
  createOneRefund: Refund;
  createOneReminderSetting: ReminderSetting;
  createOneRole: Role;
  createOneService: Service;
  createOneServiceAddOn: ServiceAddOn;
  createOneStripePaymentMethod: StripePaymentMethod;
  createOneTransaction: Transaction;
  createOneTxAddon: TxAddon;
  createOneTxCancellationPolicy: TxCancellationPolicy;
  createOneTxItem: TxItem;
  createOneTxService: TxService;
  createOneUser: User;
  createOneUserFavoriteLocation: UserFavoriteLocation;
  deleteManyAmenity: AffectedRowsOutput;
  deleteManyAppointment: AffectedRowsOutput;
  deleteManyAppointmentService: AffectedRowsOutput;
  deleteManyArtist: AffectedRowsOutput;
  deleteManyArtistAvailability: AffectedRowsOutput;
  deleteManyArtistInstagram: AffectedRowsOutput;
  deleteManyArtistServiceExclusion: AffectedRowsOutput;
  deleteManyAvailability: AffectedRowsOutput;
  deleteManyCompany: AffectedRowsOutput;
  deleteManyCompanyAmenity: AffectedRowsOutput;
  deleteManyCompanyPaymentMethod: AffectedRowsOutput;
  deleteManyCompanyUserDescription: AffectedRowsOutput;
  deleteManyFacility: AffectedRowsOutput;
  deleteManyFacilityService: AffectedRowsOutput;
  deleteManyFavoriteArtist: AffectedRowsOutput;
  deleteManyGroupAvailability: AffectedRowsOutput;
  deleteManyLocation: AffectedRowsOutput;
  deleteManyLocationAmenity: AffectedRowsOutput;
  deleteManyMenu: AffectedRowsOutput;
  deleteManyMenuSection: AffectedRowsOutput;
  deleteManyMenuSectionService: AffectedRowsOutput;
  deleteManyNotification: AffectedRowsOutput;
  deleteManyOrder: AffectedRowsOutput;
  deleteManyPlatform: AffectedRowsOutput;
  deleteManyRefund: AffectedRowsOutput;
  deleteManyReminderSetting: AffectedRowsOutput;
  deleteManyRole: AffectedRowsOutput;
  deleteManyService: AffectedRowsOutput;
  deleteManyServiceAddOn: AffectedRowsOutput;
  deleteManyStripePaymentMethod: AffectedRowsOutput;
  deleteManyTransaction: AffectedRowsOutput;
  deleteManyTxAddon: AffectedRowsOutput;
  deleteManyTxCancellationPolicy: AffectedRowsOutput;
  deleteManyTxItem: AffectedRowsOutput;
  deleteManyTxService: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteManyUserFavoriteLocation: AffectedRowsOutput;
  deleteOneAmenity?: Maybe<Amenity>;
  deleteOneAppointment?: Maybe<Appointment>;
  deleteOneAppointmentService?: Maybe<AppointmentService>;
  deleteOneArtist?: Maybe<Artist>;
  deleteOneArtistAvailability?: Maybe<ArtistAvailability>;
  deleteOneArtistInstagram?: Maybe<ArtistInstagram>;
  deleteOneArtistServiceExclusion?: Maybe<ArtistServiceExclusion>;
  deleteOneAvailability?: Maybe<Availability>;
  deleteOneCompany?: Maybe<Company>;
  deleteOneCompanyAmenity?: Maybe<CompanyAmenity>;
  deleteOneCompanyPaymentMethod?: Maybe<CompanyPaymentMethod>;
  deleteOneCompanyUserDescription?: Maybe<CompanyUserDescription>;
  deleteOneFacility?: Maybe<Facility>;
  deleteOneFacilityService?: Maybe<FacilityService>;
  deleteOneFavoriteArtist?: Maybe<FavoriteArtist>;
  deleteOneGroupAvailability?: Maybe<GroupAvailability>;
  deleteOneLocation?: Maybe<Location>;
  deleteOneLocationAmenity?: Maybe<LocationAmenity>;
  deleteOneMenu?: Maybe<Menu>;
  deleteOneMenuSection?: Maybe<MenuSection>;
  deleteOneMenuSectionService?: Maybe<MenuSectionService>;
  deleteOneNotification?: Maybe<Notification>;
  deleteOneOrder?: Maybe<Order>;
  deleteOnePlatform?: Maybe<Platform>;
  deleteOneRefund?: Maybe<Refund>;
  deleteOneReminderSetting?: Maybe<ReminderSetting>;
  deleteOneRole?: Maybe<Role>;
  deleteOneService?: Maybe<Service>;
  deleteOneServiceAddOn?: Maybe<ServiceAddOn>;
  deleteOneStripePaymentMethod?: Maybe<StripePaymentMethod>;
  deleteOneTransaction?: Maybe<Transaction>;
  deleteOneTxAddon?: Maybe<TxAddon>;
  deleteOneTxCancellationPolicy?: Maybe<TxCancellationPolicy>;
  deleteOneTxItem?: Maybe<TxItem>;
  deleteOneTxService?: Maybe<TxService>;
  deleteOneUser?: Maybe<User>;
  deleteOneUserFavoriteLocation?: Maybe<UserFavoriteLocation>;
  updateManyAmenity: AffectedRowsOutput;
  updateManyAppointment: AffectedRowsOutput;
  updateManyAppointmentService: AffectedRowsOutput;
  updateManyArtist: AffectedRowsOutput;
  updateManyArtistAvailability: AffectedRowsOutput;
  updateManyArtistInstagram: AffectedRowsOutput;
  updateManyArtistServiceExclusion: AffectedRowsOutput;
  updateManyAvailability: AffectedRowsOutput;
  updateManyCompany: AffectedRowsOutput;
  updateManyCompanyAmenity: AffectedRowsOutput;
  updateManyCompanyPaymentMethod: AffectedRowsOutput;
  updateManyCompanyUserDescription: AffectedRowsOutput;
  updateManyFacility: AffectedRowsOutput;
  updateManyFacilityService: AffectedRowsOutput;
  updateManyFavoriteArtist: AffectedRowsOutput;
  updateManyGroupAvailability: AffectedRowsOutput;
  updateManyLocation: AffectedRowsOutput;
  updateManyLocationAmenity: AffectedRowsOutput;
  updateManyMenu: AffectedRowsOutput;
  updateManyMenuSection: AffectedRowsOutput;
  updateManyMenuSectionService: AffectedRowsOutput;
  updateManyNotification: AffectedRowsOutput;
  updateManyOrder: AffectedRowsOutput;
  updateManyPlatform: AffectedRowsOutput;
  updateManyRefund: AffectedRowsOutput;
  updateManyReminderSetting: AffectedRowsOutput;
  updateManyRole: AffectedRowsOutput;
  updateManyService: AffectedRowsOutput;
  updateManyServiceAddOn: AffectedRowsOutput;
  updateManyStripePaymentMethod: AffectedRowsOutput;
  updateManyTransaction: AffectedRowsOutput;
  updateManyTxAddon: AffectedRowsOutput;
  updateManyTxCancellationPolicy: AffectedRowsOutput;
  updateManyTxItem: AffectedRowsOutput;
  updateManyTxService: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateManyUserFavoriteLocation: AffectedRowsOutput;
  updateOneAmenity?: Maybe<Amenity>;
  updateOneAppointment?: Maybe<Appointment>;
  updateOneAppointmentService?: Maybe<AppointmentService>;
  updateOneArtist?: Maybe<Artist>;
  updateOneArtistAvailability?: Maybe<ArtistAvailability>;
  updateOneArtistInstagram?: Maybe<ArtistInstagram>;
  updateOneArtistServiceExclusion?: Maybe<ArtistServiceExclusion>;
  updateOneAvailability?: Maybe<Availability>;
  updateOneCompany?: Maybe<Company>;
  updateOneCompanyAmenity?: Maybe<CompanyAmenity>;
  updateOneCompanyPaymentMethod?: Maybe<CompanyPaymentMethod>;
  updateOneCompanyUserDescription?: Maybe<CompanyUserDescription>;
  updateOneFacility?: Maybe<Facility>;
  updateOneFacilityService?: Maybe<FacilityService>;
  updateOneFavoriteArtist?: Maybe<FavoriteArtist>;
  updateOneGroupAvailability?: Maybe<GroupAvailability>;
  updateOneLocation?: Maybe<Location>;
  updateOneLocationAmenity?: Maybe<LocationAmenity>;
  updateOneMenu?: Maybe<Menu>;
  updateOneMenuSection?: Maybe<MenuSection>;
  updateOneMenuSectionService?: Maybe<MenuSectionService>;
  updateOneNotification?: Maybe<Notification>;
  updateOneOrder?: Maybe<Order>;
  updateOnePlatform?: Maybe<Platform>;
  updateOneRefund?: Maybe<Refund>;
  updateOneReminderSetting?: Maybe<ReminderSetting>;
  updateOneRole?: Maybe<Role>;
  updateOneService?: Maybe<Service>;
  updateOneServiceAddOn?: Maybe<ServiceAddOn>;
  updateOneStripePaymentMethod?: Maybe<StripePaymentMethod>;
  updateOneTransaction?: Maybe<Transaction>;
  updateOneTxAddon?: Maybe<TxAddon>;
  updateOneTxCancellationPolicy?: Maybe<TxCancellationPolicy>;
  updateOneTxItem?: Maybe<TxItem>;
  updateOneTxService?: Maybe<TxService>;
  updateOneUser?: Maybe<User>;
  updateOneUserFavoriteLocation?: Maybe<UserFavoriteLocation>;
  upsertOneAmenity: Amenity;
  upsertOneAppointment: Appointment;
  upsertOneAppointmentService: AppointmentService;
  upsertOneArtist: Artist;
  upsertOneArtistAvailability: ArtistAvailability;
  upsertOneArtistInstagram: ArtistInstagram;
  upsertOneArtistServiceExclusion: ArtistServiceExclusion;
  upsertOneAvailability: Availability;
  upsertOneCompany: Company;
  upsertOneCompanyAmenity: CompanyAmenity;
  upsertOneCompanyPaymentMethod: CompanyPaymentMethod;
  upsertOneCompanyUserDescription: CompanyUserDescription;
  upsertOneFacility: Facility;
  upsertOneFacilityService: FacilityService;
  upsertOneFavoriteArtist: FavoriteArtist;
  upsertOneGroupAvailability: GroupAvailability;
  upsertOneLocation: Location;
  upsertOneLocationAmenity: LocationAmenity;
  upsertOneMenu: Menu;
  upsertOneMenuSection: MenuSection;
  upsertOneMenuSectionService: MenuSectionService;
  upsertOneNotification: Notification;
  upsertOneOrder: Order;
  upsertOnePlatform: Platform;
  upsertOneRefund: Refund;
  upsertOneReminderSetting: ReminderSetting;
  upsertOneRole: Role;
  upsertOneService: Service;
  upsertOneServiceAddOn: ServiceAddOn;
  upsertOneStripePaymentMethod: StripePaymentMethod;
  upsertOneTransaction: Transaction;
  upsertOneTxAddon: TxAddon;
  upsertOneTxCancellationPolicy: TxCancellationPolicy;
  upsertOneTxItem: TxItem;
  upsertOneTxService: TxService;
  upsertOneUser: User;
  upsertOneUserFavoriteLocation: UserFavoriteLocation;
};

export type MutationCreateManyAmenityArgs = {
  data: Array<AmenityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyAppointmentArgs = {
  data: Array<AppointmentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyAppointmentServiceArgs = {
  data: Array<AppointmentServiceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyArtistArgs = {
  data: Array<ArtistCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyArtistAvailabilityArgs = {
  data: Array<ArtistAvailabilityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyArtistInstagramArgs = {
  data: Array<ArtistInstagramCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyArtistServiceExclusionArgs = {
  data: Array<ArtistServiceExclusionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyAvailabilityArgs = {
  data: Array<AvailabilityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCompanyArgs = {
  data: Array<CompanyCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCompanyAmenityArgs = {
  data: Array<CompanyAmenityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCompanyPaymentMethodArgs = {
  data: Array<CompanyPaymentMethodCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCompanyUserDescriptionArgs = {
  data: Array<CompanyUserDescriptionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyFacilityArgs = {
  data: Array<FacilityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyFacilityServiceArgs = {
  data: Array<FacilityServiceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyFavoriteArtistArgs = {
  data: Array<FavoriteArtistCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyGroupAvailabilityArgs = {
  data: Array<GroupAvailabilityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyLocationArgs = {
  data: Array<LocationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyLocationAmenityArgs = {
  data: Array<LocationAmenityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyMenuArgs = {
  data: Array<MenuCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyMenuSectionArgs = {
  data: Array<MenuSectionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyMenuSectionServiceArgs = {
  data: Array<MenuSectionServiceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyNotificationArgs = {
  data: Array<NotificationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOrderArgs = {
  data: Array<OrderCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyPlatformArgs = {
  data: Array<PlatformCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyRefundArgs = {
  data: Array<RefundCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyReminderSettingArgs = {
  data: Array<ReminderSettingCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyRoleArgs = {
  data: Array<RoleCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyServiceArgs = {
  data: Array<ServiceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyServiceAddOnArgs = {
  data: Array<ServiceAddOnCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyStripePaymentMethodArgs = {
  data: Array<StripePaymentMethodCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyTransactionArgs = {
  data: Array<TransactionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyTxAddonArgs = {
  data: Array<TxAddonCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyTxCancellationPolicyArgs = {
  data: Array<TxCancellationPolicyCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyTxItemArgs = {
  data: Array<TxItemCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyTxServiceArgs = {
  data: Array<TxServiceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyUserFavoriteLocationArgs = {
  data: Array<UserFavoriteLocationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateOneAmenityArgs = {
  data: AmenityCreateInput;
};

export type MutationCreateOneAppointmentArgs = {
  data: AppointmentCreateInput;
};

export type MutationCreateOneAppointmentServiceArgs = {
  data: AppointmentServiceCreateInput;
};

export type MutationCreateOneArtistArgs = {
  data?: InputMaybe<ArtistCreateInput>;
};

export type MutationCreateOneArtistAvailabilityArgs = {
  data: ArtistAvailabilityCreateInput;
};

export type MutationCreateOneArtistInstagramArgs = {
  data: ArtistInstagramCreateInput;
};

export type MutationCreateOneArtistServiceExclusionArgs = {
  data: ArtistServiceExclusionCreateInput;
};

export type MutationCreateOneAvailabilityArgs = {
  data: AvailabilityCreateInput;
};

export type MutationCreateOneCompanyArgs = {
  data: CompanyCreateInput;
};

export type MutationCreateOneCompanyAmenityArgs = {
  data: CompanyAmenityCreateInput;
};

export type MutationCreateOneCompanyPaymentMethodArgs = {
  data: CompanyPaymentMethodCreateInput;
};

export type MutationCreateOneCompanyUserDescriptionArgs = {
  data: CompanyUserDescriptionCreateInput;
};

export type MutationCreateOneFacilityArgs = {
  data: FacilityCreateInput;
};

export type MutationCreateOneFacilityServiceArgs = {
  data?: InputMaybe<FacilityServiceCreateInput>;
};

export type MutationCreateOneFavoriteArtistArgs = {
  data: FavoriteArtistCreateInput;
};

export type MutationCreateOneGroupAvailabilityArgs = {
  data?: InputMaybe<GroupAvailabilityCreateInput>;
};

export type MutationCreateOneLocationArgs = {
  data: LocationCreateInput;
};

export type MutationCreateOneLocationAmenityArgs = {
  data: LocationAmenityCreateInput;
};

export type MutationCreateOneMenuArgs = {
  data: MenuCreateInput;
};

export type MutationCreateOneMenuSectionArgs = {
  data: MenuSectionCreateInput;
};

export type MutationCreateOneMenuSectionServiceArgs = {
  data?: InputMaybe<MenuSectionServiceCreateInput>;
};

export type MutationCreateOneNotificationArgs = {
  data: NotificationCreateInput;
};

export type MutationCreateOneOrderArgs = {
  data: OrderCreateInput;
};

export type MutationCreateOnePlatformArgs = {
  data?: InputMaybe<PlatformCreateInput>;
};

export type MutationCreateOneRefundArgs = {
  data: RefundCreateInput;
};

export type MutationCreateOneReminderSettingArgs = {
  data: ReminderSettingCreateInput;
};

export type MutationCreateOneRoleArgs = {
  data: RoleCreateInput;
};

export type MutationCreateOneServiceArgs = {
  data: ServiceCreateInput;
};

export type MutationCreateOneServiceAddOnArgs = {
  data: ServiceAddOnCreateInput;
};

export type MutationCreateOneStripePaymentMethodArgs = {
  data: StripePaymentMethodCreateInput;
};

export type MutationCreateOneTransactionArgs = {
  data: TransactionCreateInput;
};

export type MutationCreateOneTxAddonArgs = {
  data: TxAddonCreateInput;
};

export type MutationCreateOneTxCancellationPolicyArgs = {
  data: TxCancellationPolicyCreateInput;
};

export type MutationCreateOneTxItemArgs = {
  data: TxItemCreateInput;
};

export type MutationCreateOneTxServiceArgs = {
  data: TxServiceCreateInput;
};

export type MutationCreateOneUserArgs = {
  data?: InputMaybe<UserCreateInput>;
};

export type MutationCreateOneUserFavoriteLocationArgs = {
  data: UserFavoriteLocationCreateInput;
};

export type MutationDeleteManyAmenityArgs = {
  where?: InputMaybe<AmenityWhereInput>;
};

export type MutationDeleteManyAppointmentArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};

export type MutationDeleteManyAppointmentServiceArgs = {
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type MutationDeleteManyArtistArgs = {
  where?: InputMaybe<ArtistWhereInput>;
};

export type MutationDeleteManyArtistAvailabilityArgs = {
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type MutationDeleteManyArtistInstagramArgs = {
  where?: InputMaybe<ArtistInstagramWhereInput>;
};

export type MutationDeleteManyArtistServiceExclusionArgs = {
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type MutationDeleteManyAvailabilityArgs = {
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type MutationDeleteManyCompanyArgs = {
  where?: InputMaybe<CompanyWhereInput>;
};

export type MutationDeleteManyCompanyAmenityArgs = {
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type MutationDeleteManyCompanyPaymentMethodArgs = {
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type MutationDeleteManyCompanyUserDescriptionArgs = {
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type MutationDeleteManyFacilityArgs = {
  where?: InputMaybe<FacilityWhereInput>;
};

export type MutationDeleteManyFacilityServiceArgs = {
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type MutationDeleteManyFavoriteArtistArgs = {
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type MutationDeleteManyGroupAvailabilityArgs = {
  where?: InputMaybe<GroupAvailabilityWhereInput>;
};

export type MutationDeleteManyLocationArgs = {
  where?: InputMaybe<LocationWhereInput>;
};

export type MutationDeleteManyLocationAmenityArgs = {
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type MutationDeleteManyMenuArgs = {
  where?: InputMaybe<MenuWhereInput>;
};

export type MutationDeleteManyMenuSectionArgs = {
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type MutationDeleteManyMenuSectionServiceArgs = {
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type MutationDeleteManyNotificationArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};

export type MutationDeleteManyOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};

export type MutationDeleteManyPlatformArgs = {
  where?: InputMaybe<PlatformWhereInput>;
};

export type MutationDeleteManyRefundArgs = {
  where?: InputMaybe<RefundWhereInput>;
};

export type MutationDeleteManyReminderSettingArgs = {
  where?: InputMaybe<ReminderSettingWhereInput>;
};

export type MutationDeleteManyRoleArgs = {
  where?: InputMaybe<RoleWhereInput>;
};

export type MutationDeleteManyServiceArgs = {
  where?: InputMaybe<ServiceWhereInput>;
};

export type MutationDeleteManyServiceAddOnArgs = {
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type MutationDeleteManyStripePaymentMethodArgs = {
  where?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type MutationDeleteManyTransactionArgs = {
  where?: InputMaybe<TransactionWhereInput>;
};

export type MutationDeleteManyTxAddonArgs = {
  where?: InputMaybe<TxAddonWhereInput>;
};

export type MutationDeleteManyTxCancellationPolicyArgs = {
  where?: InputMaybe<TxCancellationPolicyWhereInput>;
};

export type MutationDeleteManyTxItemArgs = {
  where?: InputMaybe<TxItemWhereInput>;
};

export type MutationDeleteManyTxServiceArgs = {
  where?: InputMaybe<TxServiceWhereInput>;
};

export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type MutationDeleteManyUserFavoriteLocationArgs = {
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type MutationDeleteOneAmenityArgs = {
  where: AmenityWhereUniqueInput;
};

export type MutationDeleteOneAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};

export type MutationDeleteOneAppointmentServiceArgs = {
  where: AppointmentServiceWhereUniqueInput;
};

export type MutationDeleteOneArtistArgs = {
  where: ArtistWhereUniqueInput;
};

export type MutationDeleteOneArtistAvailabilityArgs = {
  where: ArtistAvailabilityWhereUniqueInput;
};

export type MutationDeleteOneArtistInstagramArgs = {
  where: ArtistInstagramWhereUniqueInput;
};

export type MutationDeleteOneArtistServiceExclusionArgs = {
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type MutationDeleteOneAvailabilityArgs = {
  where: AvailabilityWhereUniqueInput;
};

export type MutationDeleteOneCompanyArgs = {
  where: CompanyWhereUniqueInput;
};

export type MutationDeleteOneCompanyAmenityArgs = {
  where: CompanyAmenityWhereUniqueInput;
};

export type MutationDeleteOneCompanyPaymentMethodArgs = {
  where: CompanyPaymentMethodWhereUniqueInput;
};

export type MutationDeleteOneCompanyUserDescriptionArgs = {
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type MutationDeleteOneFacilityArgs = {
  where: FacilityWhereUniqueInput;
};

export type MutationDeleteOneFacilityServiceArgs = {
  where: FacilityServiceWhereUniqueInput;
};

export type MutationDeleteOneFavoriteArtistArgs = {
  where: FavoriteArtistWhereUniqueInput;
};

export type MutationDeleteOneGroupAvailabilityArgs = {
  where: GroupAvailabilityWhereUniqueInput;
};

export type MutationDeleteOneLocationArgs = {
  where: LocationWhereUniqueInput;
};

export type MutationDeleteOneLocationAmenityArgs = {
  where: LocationAmenityWhereUniqueInput;
};

export type MutationDeleteOneMenuArgs = {
  where: MenuWhereUniqueInput;
};

export type MutationDeleteOneMenuSectionArgs = {
  where: MenuSectionWhereUniqueInput;
};

export type MutationDeleteOneMenuSectionServiceArgs = {
  where: MenuSectionServiceWhereUniqueInput;
};

export type MutationDeleteOneNotificationArgs = {
  where: NotificationWhereUniqueInput;
};

export type MutationDeleteOneOrderArgs = {
  where: OrderWhereUniqueInput;
};

export type MutationDeleteOnePlatformArgs = {
  where: PlatformWhereUniqueInput;
};

export type MutationDeleteOneRefundArgs = {
  where: RefundWhereUniqueInput;
};

export type MutationDeleteOneReminderSettingArgs = {
  where: ReminderSettingWhereUniqueInput;
};

export type MutationDeleteOneRoleArgs = {
  where: RoleWhereUniqueInput;
};

export type MutationDeleteOneServiceArgs = {
  where: ServiceWhereUniqueInput;
};

export type MutationDeleteOneServiceAddOnArgs = {
  where: ServiceAddOnWhereUniqueInput;
};

export type MutationDeleteOneStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
};

export type MutationDeleteOneTransactionArgs = {
  where: TransactionWhereUniqueInput;
};

export type MutationDeleteOneTxAddonArgs = {
  where: TxAddonWhereUniqueInput;
};

export type MutationDeleteOneTxCancellationPolicyArgs = {
  where: TxCancellationPolicyWhereUniqueInput;
};

export type MutationDeleteOneTxItemArgs = {
  where: TxItemWhereUniqueInput;
};

export type MutationDeleteOneTxServiceArgs = {
  where: TxServiceWhereUniqueInput;
};

export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};

export type MutationDeleteOneUserFavoriteLocationArgs = {
  where: UserFavoriteLocationWhereUniqueInput;
};

export type MutationUpdateManyAmenityArgs = {
  data: AmenityUpdateManyMutationInput;
  where?: InputMaybe<AmenityWhereInput>;
};

export type MutationUpdateManyAppointmentArgs = {
  data: AppointmentUpdateManyMutationInput;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type MutationUpdateManyAppointmentServiceArgs = {
  data: AppointmentServiceUpdateManyMutationInput;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type MutationUpdateManyArtistArgs = {
  data: ArtistUpdateManyMutationInput;
  where?: InputMaybe<ArtistWhereInput>;
};

export type MutationUpdateManyArtistAvailabilityArgs = {
  data: ArtistAvailabilityUpdateManyMutationInput;
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type MutationUpdateManyArtistInstagramArgs = {
  data: ArtistInstagramUpdateManyMutationInput;
  where?: InputMaybe<ArtistInstagramWhereInput>;
};

export type MutationUpdateManyArtistServiceExclusionArgs = {
  data: ArtistServiceExclusionUpdateManyMutationInput;
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type MutationUpdateManyAvailabilityArgs = {
  data: AvailabilityUpdateManyMutationInput;
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type MutationUpdateManyCompanyArgs = {
  data: CompanyUpdateManyMutationInput;
  where?: InputMaybe<CompanyWhereInput>;
};

export type MutationUpdateManyCompanyAmenityArgs = {
  data: CompanyAmenityUpdateManyMutationInput;
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type MutationUpdateManyCompanyPaymentMethodArgs = {
  data: CompanyPaymentMethodUpdateManyMutationInput;
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type MutationUpdateManyCompanyUserDescriptionArgs = {
  data: CompanyUserDescriptionUpdateManyMutationInput;
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type MutationUpdateManyFacilityArgs = {
  data: FacilityUpdateManyMutationInput;
  where?: InputMaybe<FacilityWhereInput>;
};

export type MutationUpdateManyFacilityServiceArgs = {
  data: FacilityServiceUpdateManyMutationInput;
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type MutationUpdateManyFavoriteArtistArgs = {
  data: FavoriteArtistUpdateManyMutationInput;
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type MutationUpdateManyGroupAvailabilityArgs = {
  data: GroupAvailabilityUpdateManyMutationInput;
  where?: InputMaybe<GroupAvailabilityWhereInput>;
};

export type MutationUpdateManyLocationArgs = {
  data: LocationUpdateManyMutationInput;
  where?: InputMaybe<LocationWhereInput>;
};

export type MutationUpdateManyLocationAmenityArgs = {
  data: LocationAmenityUpdateManyMutationInput;
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type MutationUpdateManyMenuArgs = {
  data: MenuUpdateManyMutationInput;
  where?: InputMaybe<MenuWhereInput>;
};

export type MutationUpdateManyMenuSectionArgs = {
  data: MenuSectionUpdateManyMutationInput;
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type MutationUpdateManyMenuSectionServiceArgs = {
  data: MenuSectionServiceUpdateManyMutationInput;
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type MutationUpdateManyNotificationArgs = {
  data: NotificationUpdateManyMutationInput;
  where?: InputMaybe<NotificationWhereInput>;
};

export type MutationUpdateManyOrderArgs = {
  data: OrderUpdateManyMutationInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type MutationUpdateManyPlatformArgs = {
  data: PlatformUpdateManyMutationInput;
  where?: InputMaybe<PlatformWhereInput>;
};

export type MutationUpdateManyRefundArgs = {
  data: RefundUpdateManyMutationInput;
  where?: InputMaybe<RefundWhereInput>;
};

export type MutationUpdateManyReminderSettingArgs = {
  data: ReminderSettingUpdateManyMutationInput;
  where?: InputMaybe<ReminderSettingWhereInput>;
};

export type MutationUpdateManyRoleArgs = {
  data: RoleUpdateManyMutationInput;
  where?: InputMaybe<RoleWhereInput>;
};

export type MutationUpdateManyServiceArgs = {
  data: ServiceUpdateManyMutationInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type MutationUpdateManyServiceAddOnArgs = {
  data: ServiceAddOnUpdateManyMutationInput;
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type MutationUpdateManyStripePaymentMethodArgs = {
  data: StripePaymentMethodUpdateManyMutationInput;
  where?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type MutationUpdateManyTransactionArgs = {
  data: TransactionUpdateManyMutationInput;
  where?: InputMaybe<TransactionWhereInput>;
};

export type MutationUpdateManyTxAddonArgs = {
  data: TxAddonUpdateManyMutationInput;
  where?: InputMaybe<TxAddonWhereInput>;
};

export type MutationUpdateManyTxCancellationPolicyArgs = {
  data: TxCancellationPolicyUpdateManyMutationInput;
  where?: InputMaybe<TxCancellationPolicyWhereInput>;
};

export type MutationUpdateManyTxItemArgs = {
  data: TxItemUpdateManyMutationInput;
  where?: InputMaybe<TxItemWhereInput>;
};

export type MutationUpdateManyTxServiceArgs = {
  data: TxServiceUpdateManyMutationInput;
  where?: InputMaybe<TxServiceWhereInput>;
};

export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};

export type MutationUpdateManyUserFavoriteLocationArgs = {
  data: UserFavoriteLocationUpdateManyMutationInput;
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type MutationUpdateOneAmenityArgs = {
  data: AmenityUpdateInput;
  where: AmenityWhereUniqueInput;
};

export type MutationUpdateOneAppointmentArgs = {
  data: AppointmentUpdateInput;
  where: AppointmentWhereUniqueInput;
};

export type MutationUpdateOneAppointmentServiceArgs = {
  data: AppointmentServiceUpdateInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type MutationUpdateOneArtistArgs = {
  data: ArtistUpdateInput;
  where: ArtistWhereUniqueInput;
};

export type MutationUpdateOneArtistAvailabilityArgs = {
  data: ArtistAvailabilityUpdateInput;
  where: ArtistAvailabilityWhereUniqueInput;
};

export type MutationUpdateOneArtistInstagramArgs = {
  data: ArtistInstagramUpdateInput;
  where: ArtistInstagramWhereUniqueInput;
};

export type MutationUpdateOneArtistServiceExclusionArgs = {
  data: ArtistServiceExclusionUpdateInput;
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type MutationUpdateOneAvailabilityArgs = {
  data: AvailabilityUpdateInput;
  where: AvailabilityWhereUniqueInput;
};

export type MutationUpdateOneCompanyArgs = {
  data: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
};

export type MutationUpdateOneCompanyAmenityArgs = {
  data: CompanyAmenityUpdateInput;
  where: CompanyAmenityWhereUniqueInput;
};

export type MutationUpdateOneCompanyPaymentMethodArgs = {
  data: CompanyPaymentMethodUpdateInput;
  where: CompanyPaymentMethodWhereUniqueInput;
};

export type MutationUpdateOneCompanyUserDescriptionArgs = {
  data: CompanyUserDescriptionUpdateInput;
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type MutationUpdateOneFacilityArgs = {
  data: FacilityUpdateInput;
  where: FacilityWhereUniqueInput;
};

export type MutationUpdateOneFacilityServiceArgs = {
  data: FacilityServiceUpdateInput;
  where: FacilityServiceWhereUniqueInput;
};

export type MutationUpdateOneFavoriteArtistArgs = {
  data: FavoriteArtistUpdateInput;
  where: FavoriteArtistWhereUniqueInput;
};

export type MutationUpdateOneGroupAvailabilityArgs = {
  data: GroupAvailabilityUpdateInput;
  where: GroupAvailabilityWhereUniqueInput;
};

export type MutationUpdateOneLocationArgs = {
  data: LocationUpdateInput;
  where: LocationWhereUniqueInput;
};

export type MutationUpdateOneLocationAmenityArgs = {
  data: LocationAmenityUpdateInput;
  where: LocationAmenityWhereUniqueInput;
};

export type MutationUpdateOneMenuArgs = {
  data: MenuUpdateInput;
  where: MenuWhereUniqueInput;
};

export type MutationUpdateOneMenuSectionArgs = {
  data: MenuSectionUpdateInput;
  where: MenuSectionWhereUniqueInput;
};

export type MutationUpdateOneMenuSectionServiceArgs = {
  data: MenuSectionServiceUpdateInput;
  where: MenuSectionServiceWhereUniqueInput;
};

export type MutationUpdateOneNotificationArgs = {
  data: NotificationUpdateInput;
  where: NotificationWhereUniqueInput;
};

export type MutationUpdateOneOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};

export type MutationUpdateOnePlatformArgs = {
  data: PlatformUpdateInput;
  where: PlatformWhereUniqueInput;
};

export type MutationUpdateOneRefundArgs = {
  data: RefundUpdateInput;
  where: RefundWhereUniqueInput;
};

export type MutationUpdateOneReminderSettingArgs = {
  data: ReminderSettingUpdateInput;
  where: ReminderSettingWhereUniqueInput;
};

export type MutationUpdateOneRoleArgs = {
  data: RoleUpdateInput;
  where: RoleWhereUniqueInput;
};

export type MutationUpdateOneServiceArgs = {
  data: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};

export type MutationUpdateOneServiceAddOnArgs = {
  data: ServiceAddOnUpdateInput;
  where: ServiceAddOnWhereUniqueInput;
};

export type MutationUpdateOneStripePaymentMethodArgs = {
  data: StripePaymentMethodUpdateInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type MutationUpdateOneTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};

export type MutationUpdateOneTxAddonArgs = {
  data: TxAddonUpdateInput;
  where: TxAddonWhereUniqueInput;
};

export type MutationUpdateOneTxCancellationPolicyArgs = {
  data: TxCancellationPolicyUpdateInput;
  where: TxCancellationPolicyWhereUniqueInput;
};

export type MutationUpdateOneTxItemArgs = {
  data: TxItemUpdateInput;
  where: TxItemWhereUniqueInput;
};

export type MutationUpdateOneTxServiceArgs = {
  data: TxServiceUpdateInput;
  where: TxServiceWhereUniqueInput;
};

export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationUpdateOneUserFavoriteLocationArgs = {
  data: UserFavoriteLocationUpdateInput;
  where: UserFavoriteLocationWhereUniqueInput;
};

export type MutationUpsertOneAmenityArgs = {
  create: AmenityCreateInput;
  update: AmenityUpdateInput;
  where: AmenityWhereUniqueInput;
};

export type MutationUpsertOneAppointmentArgs = {
  create: AppointmentCreateInput;
  update: AppointmentUpdateInput;
  where: AppointmentWhereUniqueInput;
};

export type MutationUpsertOneAppointmentServiceArgs = {
  create: AppointmentServiceCreateInput;
  update: AppointmentServiceUpdateInput;
  where: AppointmentServiceWhereUniqueInput;
};

export type MutationUpsertOneArtistArgs = {
  create: ArtistCreateInput;
  update: ArtistUpdateInput;
  where: ArtistWhereUniqueInput;
};

export type MutationUpsertOneArtistAvailabilityArgs = {
  create: ArtistAvailabilityCreateInput;
  update: ArtistAvailabilityUpdateInput;
  where: ArtistAvailabilityWhereUniqueInput;
};

export type MutationUpsertOneArtistInstagramArgs = {
  create: ArtistInstagramCreateInput;
  update: ArtistInstagramUpdateInput;
  where: ArtistInstagramWhereUniqueInput;
};

export type MutationUpsertOneArtistServiceExclusionArgs = {
  create: ArtistServiceExclusionCreateInput;
  update: ArtistServiceExclusionUpdateInput;
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type MutationUpsertOneAvailabilityArgs = {
  create: AvailabilityCreateInput;
  update: AvailabilityUpdateInput;
  where: AvailabilityWhereUniqueInput;
};

export type MutationUpsertOneCompanyArgs = {
  create: CompanyCreateInput;
  update: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
};

export type MutationUpsertOneCompanyAmenityArgs = {
  create: CompanyAmenityCreateInput;
  update: CompanyAmenityUpdateInput;
  where: CompanyAmenityWhereUniqueInput;
};

export type MutationUpsertOneCompanyPaymentMethodArgs = {
  create: CompanyPaymentMethodCreateInput;
  update: CompanyPaymentMethodUpdateInput;
  where: CompanyPaymentMethodWhereUniqueInput;
};

export type MutationUpsertOneCompanyUserDescriptionArgs = {
  create: CompanyUserDescriptionCreateInput;
  update: CompanyUserDescriptionUpdateInput;
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type MutationUpsertOneFacilityArgs = {
  create: FacilityCreateInput;
  update: FacilityUpdateInput;
  where: FacilityWhereUniqueInput;
};

export type MutationUpsertOneFacilityServiceArgs = {
  create: FacilityServiceCreateInput;
  update: FacilityServiceUpdateInput;
  where: FacilityServiceWhereUniqueInput;
};

export type MutationUpsertOneFavoriteArtistArgs = {
  create: FavoriteArtistCreateInput;
  update: FavoriteArtistUpdateInput;
  where: FavoriteArtistWhereUniqueInput;
};

export type MutationUpsertOneGroupAvailabilityArgs = {
  create: GroupAvailabilityCreateInput;
  update: GroupAvailabilityUpdateInput;
  where: GroupAvailabilityWhereUniqueInput;
};

export type MutationUpsertOneLocationArgs = {
  create: LocationCreateInput;
  update: LocationUpdateInput;
  where: LocationWhereUniqueInput;
};

export type MutationUpsertOneLocationAmenityArgs = {
  create: LocationAmenityCreateInput;
  update: LocationAmenityUpdateInput;
  where: LocationAmenityWhereUniqueInput;
};

export type MutationUpsertOneMenuArgs = {
  create: MenuCreateInput;
  update: MenuUpdateInput;
  where: MenuWhereUniqueInput;
};

export type MutationUpsertOneMenuSectionArgs = {
  create: MenuSectionCreateInput;
  update: MenuSectionUpdateInput;
  where: MenuSectionWhereUniqueInput;
};

export type MutationUpsertOneMenuSectionServiceArgs = {
  create: MenuSectionServiceCreateInput;
  update: MenuSectionServiceUpdateInput;
  where: MenuSectionServiceWhereUniqueInput;
};

export type MutationUpsertOneNotificationArgs = {
  create: NotificationCreateInput;
  update: NotificationUpdateInput;
  where: NotificationWhereUniqueInput;
};

export type MutationUpsertOneOrderArgs = {
  create: OrderCreateInput;
  update: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};

export type MutationUpsertOnePlatformArgs = {
  create: PlatformCreateInput;
  update: PlatformUpdateInput;
  where: PlatformWhereUniqueInput;
};

export type MutationUpsertOneRefundArgs = {
  create: RefundCreateInput;
  update: RefundUpdateInput;
  where: RefundWhereUniqueInput;
};

export type MutationUpsertOneReminderSettingArgs = {
  create: ReminderSettingCreateInput;
  update: ReminderSettingUpdateInput;
  where: ReminderSettingWhereUniqueInput;
};

export type MutationUpsertOneRoleArgs = {
  create: RoleCreateInput;
  update: RoleUpdateInput;
  where: RoleWhereUniqueInput;
};

export type MutationUpsertOneServiceArgs = {
  create: ServiceCreateInput;
  update: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};

export type MutationUpsertOneServiceAddOnArgs = {
  create: ServiceAddOnCreateInput;
  update: ServiceAddOnUpdateInput;
  where: ServiceAddOnWhereUniqueInput;
};

export type MutationUpsertOneStripePaymentMethodArgs = {
  create: StripePaymentMethodCreateInput;
  update: StripePaymentMethodUpdateInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type MutationUpsertOneTransactionArgs = {
  create: TransactionCreateInput;
  update: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};

export type MutationUpsertOneTxAddonArgs = {
  create: TxAddonCreateInput;
  update: TxAddonUpdateInput;
  where: TxAddonWhereUniqueInput;
};

export type MutationUpsertOneTxCancellationPolicyArgs = {
  create: TxCancellationPolicyCreateInput;
  update: TxCancellationPolicyUpdateInput;
  where: TxCancellationPolicyWhereUniqueInput;
};

export type MutationUpsertOneTxItemArgs = {
  create: TxItemCreateInput;
  update: TxItemUpdateInput;
  where: TxItemWhereUniqueInput;
};

export type MutationUpsertOneTxServiceArgs = {
  create: TxServiceCreateInput;
  update: TxServiceUpdateInput;
  where: TxServiceWhereUniqueInput;
};

export type MutationUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationUpsertOneUserFavoriteLocationArgs = {
  create: UserFavoriteLocationCreateInput;
  update: UserFavoriteLocationUpdateInput;
  where: UserFavoriteLocationWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars["Decimal"]>;
  gt?: InputMaybe<Scalars["Decimal"]>;
  gte?: InputMaybe<Scalars["Decimal"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]>>;
  lt?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type NestedDecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars["Decimal"]>;
  gt?: InputMaybe<Scalars["Decimal"]>;
  gte?: InputMaybe<Scalars["Decimal"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]>>;
  lt?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type NestedEnumCancellationTypeFilter = {
  equals?: InputMaybe<CancellationType>;
  in?: InputMaybe<Array<CancellationType>>;
  not?: InputMaybe<NestedEnumCancellationTypeFilter>;
  notIn?: InputMaybe<Array<CancellationType>>;
};

export type NestedEnumCancellationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCancellationTypeFilter>;
  _min?: InputMaybe<NestedEnumCancellationTypeFilter>;
  equals?: InputMaybe<CancellationType>;
  in?: InputMaybe<Array<CancellationType>>;
  not?: InputMaybe<NestedEnumCancellationTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CancellationType>>;
};

export type NestedEnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type NestedEnumNotificationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNotificationTypeFilter>;
  _min?: InputMaybe<NestedEnumNotificationTypeFilter>;
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type NestedEnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type NestedEnumPaymentMethodWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentMethodFilter>;
  _min?: InputMaybe<NestedEnumPaymentMethodFilter>;
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type NestedEnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type NestedEnumPaymentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentStatusFilter>;
  _min?: InputMaybe<NestedEnumPaymentStatusFilter>;
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type NestedEnumPaymentTypeFilter = {
  equals?: InputMaybe<PaymentType>;
  in?: InputMaybe<Array<PaymentType>>;
  not?: InputMaybe<NestedEnumPaymentTypeFilter>;
  notIn?: InputMaybe<Array<PaymentType>>;
};

export type NestedEnumPaymentTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentTypeFilter>;
  _min?: InputMaybe<NestedEnumPaymentTypeFilter>;
  equals?: InputMaybe<PaymentType>;
  in?: InputMaybe<Array<PaymentType>>;
  not?: InputMaybe<NestedEnumPaymentTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentType>>;
};

export type NestedEnumReminderTypeFilter = {
  equals?: InputMaybe<ReminderType>;
  in?: InputMaybe<Array<ReminderType>>;
  not?: InputMaybe<NestedEnumReminderTypeFilter>;
  notIn?: InputMaybe<Array<ReminderType>>;
};

export type NestedEnumReminderTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumReminderTypeFilter>;
  _min?: InputMaybe<NestedEnumReminderTypeFilter>;
  equals?: InputMaybe<ReminderType>;
  in?: InputMaybe<Array<ReminderType>>;
  not?: InputMaybe<NestedEnumReminderTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ReminderType>>;
};

export type NestedEnumRoleStatusFilter = {
  equals?: InputMaybe<RoleStatus>;
  in?: InputMaybe<Array<RoleStatus>>;
  not?: InputMaybe<NestedEnumRoleStatusFilter>;
  notIn?: InputMaybe<Array<RoleStatus>>;
};

export type NestedEnumRoleStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRoleStatusFilter>;
  _min?: InputMaybe<NestedEnumRoleStatusFilter>;
  equals?: InputMaybe<RoleStatus>;
  in?: InputMaybe<Array<RoleStatus>>;
  not?: InputMaybe<NestedEnumRoleStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RoleStatus>>;
};

export type NestedEnumRolesFilter = {
  equals?: InputMaybe<Roles>;
  in?: InputMaybe<Array<Roles>>;
  not?: InputMaybe<NestedEnumRolesFilter>;
  notIn?: InputMaybe<Array<Roles>>;
};

export type NestedEnumRolesWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRolesFilter>;
  _min?: InputMaybe<NestedEnumRolesFilter>;
  equals?: InputMaybe<Roles>;
  in?: InputMaybe<Array<Roles>>;
  not?: InputMaybe<NestedEnumRolesWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Roles>>;
};

export type NestedEnumSchedulingPriorityNullableFilter = {
  equals?: InputMaybe<SchedulingPriority>;
  in?: InputMaybe<Array<SchedulingPriority>>;
  not?: InputMaybe<NestedEnumSchedulingPriorityNullableFilter>;
  notIn?: InputMaybe<Array<SchedulingPriority>>;
};

export type NestedEnumSchedulingPriorityNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumSchedulingPriorityNullableFilter>;
  _min?: InputMaybe<NestedEnumSchedulingPriorityNullableFilter>;
  equals?: InputMaybe<SchedulingPriority>;
  in?: InputMaybe<Array<SchedulingPriority>>;
  not?: InputMaybe<NestedEnumSchedulingPriorityNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<SchedulingPriority>>;
};

export type NestedEnumServiceCategoryFilter = {
  equals?: InputMaybe<ServiceCategory>;
  in?: InputMaybe<Array<ServiceCategory>>;
  not?: InputMaybe<NestedEnumServiceCategoryFilter>;
  notIn?: InputMaybe<Array<ServiceCategory>>;
};

export type NestedEnumServiceCategoryWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumServiceCategoryFilter>;
  _min?: InputMaybe<NestedEnumServiceCategoryFilter>;
  equals?: InputMaybe<ServiceCategory>;
  in?: InputMaybe<Array<ServiceCategory>>;
  not?: InputMaybe<NestedEnumServiceCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ServiceCategory>>;
};

export type NestedEnumStripeTxStatusNullableFilter = {
  equals?: InputMaybe<StripeTxStatus>;
  in?: InputMaybe<Array<StripeTxStatus>>;
  not?: InputMaybe<NestedEnumStripeTxStatusNullableFilter>;
  notIn?: InputMaybe<Array<StripeTxStatus>>;
};

export type NestedEnumStripeTxStatusNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumStripeTxStatusNullableFilter>;
  _min?: InputMaybe<NestedEnumStripeTxStatusNullableFilter>;
  equals?: InputMaybe<StripeTxStatus>;
  in?: InputMaybe<Array<StripeTxStatus>>;
  not?: InputMaybe<NestedEnumStripeTxStatusNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StripeTxStatus>>;
};

export type NestedEnumStripeTxTypeNullableFilter = {
  equals?: InputMaybe<StripeTxType>;
  in?: InputMaybe<Array<StripeTxType>>;
  not?: InputMaybe<NestedEnumStripeTxTypeNullableFilter>;
  notIn?: InputMaybe<Array<StripeTxType>>;
};

export type NestedEnumStripeTxTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumStripeTxTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumStripeTxTypeNullableFilter>;
  equals?: InputMaybe<StripeTxType>;
  in?: InputMaybe<Array<StripeTxType>>;
  not?: InputMaybe<NestedEnumStripeTxTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StripeTxType>>;
};

export type NestedEnumTippingTypeNullableFilter = {
  equals?: InputMaybe<TippingType>;
  in?: InputMaybe<Array<TippingType>>;
  not?: InputMaybe<NestedEnumTippingTypeNullableFilter>;
  notIn?: InputMaybe<Array<TippingType>>;
};

export type NestedEnumTippingTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumTippingTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumTippingTypeNullableFilter>;
  equals?: InputMaybe<TippingType>;
  in?: InputMaybe<Array<TippingType>>;
  not?: InputMaybe<NestedEnumTippingTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<TippingType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type NestedFloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedJsonFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]>;
  equals?: InputMaybe<Scalars["JSON"]>;
  gt?: InputMaybe<Scalars["JSON"]>;
  gte?: InputMaybe<Scalars["JSON"]>;
  lt?: InputMaybe<Scalars["JSON"]>;
  lte?: InputMaybe<Scalars["JSON"]>;
  not?: InputMaybe<Scalars["JSON"]>;
  path?: InputMaybe<Array<Scalars["String"]>>;
  string_contains?: InputMaybe<Scalars["String"]>;
  string_ends_with?: InputMaybe<Scalars["String"]>;
  string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type NestedJsonNullableFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]>;
  equals?: InputMaybe<Scalars["JSON"]>;
  gt?: InputMaybe<Scalars["JSON"]>;
  gte?: InputMaybe<Scalars["JSON"]>;
  lt?: InputMaybe<Scalars["JSON"]>;
  lte?: InputMaybe<Scalars["JSON"]>;
  not?: InputMaybe<Scalars["JSON"]>;
  path?: InputMaybe<Array<Scalars["String"]>>;
  string_contains?: InputMaybe<Scalars["String"]>;
  string_ends_with?: InputMaybe<Scalars["String"]>;
  string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedUuidFilter = {
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type NestedUuidNullableFilter = {
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type NestedUuidNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedUuidNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type NestedUuidWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedUuidWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type Notification = {
  __typename?: "Notification";
  appointment?: Maybe<Appointment>;
  appointment_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  type: NotificationType;
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
};

export type NotificationCountAggregate = {
  __typename?: "NotificationCountAggregate";
  _all: Scalars["Int"];
  appointment_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  type: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type NotificationCountOrderByAggregateInput = {
  appointment_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type NotificationCreateInput = {
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutNotificationInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutNotificationsInput;
};

export type NotificationCreateManyAppointmentInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type NotificationCreateManyAppointmentInputEnvelope = {
  data: Array<NotificationCreateManyAppointmentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type NotificationCreateManyInput = {
  appointment_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type NotificationCreateManyUserInput = {
  appointment_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type NotificationCreateManyUserInputEnvelope = {
  data: Array<NotificationCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type NotificationCreateNestedManyWithoutAppointmentInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<NotificationCreateOrConnectWithoutAppointmentInput>
  >;
  create?: InputMaybe<Array<NotificationCreateWithoutAppointmentInput>>;
  createMany?: InputMaybe<NotificationCreateManyAppointmentInputEnvelope>;
};

export type NotificationCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<NotificationCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<NotificationCreateWithoutUserInput>>;
  createMany?: InputMaybe<NotificationCreateManyUserInputEnvelope>;
};

export type NotificationCreateOrConnectWithoutAppointmentInput = {
  create: NotificationCreateWithoutAppointmentInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateOrConnectWithoutUserInput = {
  create: NotificationCreateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateWithoutAppointmentInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutNotificationsInput;
};

export type NotificationCreateWithoutUserInput = {
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutNotificationInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type NotificationGroupBy = {
  __typename?: "NotificationGroupBy";
  _count?: Maybe<NotificationCountAggregate>;
  _max?: Maybe<NotificationMaxAggregate>;
  _min?: Maybe<NotificationMinAggregate>;
  appointment_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  type: NotificationType;
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
};

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export type NotificationMaxAggregate = {
  __typename?: "NotificationMaxAggregate";
  appointment_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<NotificationType>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type NotificationMaxOrderByAggregateInput = {
  appointment_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type NotificationMinAggregate = {
  __typename?: "NotificationMinAggregate";
  appointment_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<NotificationType>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type NotificationMinOrderByAggregateInput = {
  appointment_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type NotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationOrderByWithAggregationInput = {
  _count?: InputMaybe<NotificationCountOrderByAggregateInput>;
  _max?: InputMaybe<NotificationMaxOrderByAggregateInput>;
  _min?: InputMaybe<NotificationMinOrderByAggregateInput>;
  appointment_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type NotificationOrderByWithRelationInput = {
  appointment?: InputMaybe<AppointmentOrderByWithRelationInput>;
  appointment_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export enum NotificationScalarFieldEnum {
  AppointmentId = "appointment_id",
  CreatedAt = "created_at",
  Id = "id",
  Type = "type",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type NotificationScalarWhereInput = {
  AND?: InputMaybe<Array<NotificationScalarWhereInput>>;
  NOT?: InputMaybe<Array<NotificationScalarWhereInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereInput>>;
  appointment_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  type?: InputMaybe<EnumNotificationTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type NotificationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<NotificationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<NotificationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereWithAggregatesInput>>;
  appointment_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  type?: InputMaybe<EnumNotificationTypeWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export enum NotificationType {
  AppointmentReminder = "APPOINTMENT_REMINDER"
}

export type NotificationUpdateInput = {
  appointment?: InputMaybe<AppointmentUpdateOneWithoutNotificationNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutNotificationsNestedInput>;
};

export type NotificationUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NotificationUpdateManyWithWhereWithoutAppointmentInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithWhereWithoutUserInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithoutAppointmentNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<NotificationCreateOrConnectWithoutAppointmentInput>
  >;
  create?: InputMaybe<Array<NotificationCreateWithoutAppointmentInput>>;
  createMany?: InputMaybe<NotificationCreateManyAppointmentInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<NotificationUpdateWithWhereUniqueWithoutAppointmentInput>
  >;
  updateMany?: InputMaybe<
    Array<NotificationUpdateManyWithWhereWithoutAppointmentInput>
  >;
  upsert?: InputMaybe<
    Array<NotificationUpsertWithWhereUniqueWithoutAppointmentInput>
  >;
};

export type NotificationUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<NotificationCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<NotificationCreateWithoutUserInput>>;
  createMany?: InputMaybe<NotificationCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<
    Array<NotificationUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutUserInput>>;
};

export type NotificationUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: NotificationUpdateWithoutAppointmentInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithWhereUniqueWithoutUserInput = {
  data: NotificationUpdateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithoutAppointmentInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutNotificationsNestedInput>;
};

export type NotificationUpdateWithoutUserInput = {
  appointment?: InputMaybe<AppointmentUpdateOneWithoutNotificationNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NotificationUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: NotificationCreateWithoutAppointmentInput;
  update: NotificationUpdateWithoutAppointmentInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutUserInput = {
  create: NotificationCreateWithoutUserInput;
  update: NotificationUpdateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  appointment?: InputMaybe<AppointmentRelationFilter>;
  appointment_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  type?: InputMaybe<EnumNotificationTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type NotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Boolean"]>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["DateTime"]>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Decimal"]>;
  divide?: InputMaybe<Scalars["Decimal"]>;
  increment?: InputMaybe<Scalars["Decimal"]>;
  multiply?: InputMaybe<Scalars["Decimal"]>;
  set?: InputMaybe<Scalars["Decimal"]>;
};

export type NullableEnumSchedulingPriorityFieldUpdateOperationsInput = {
  set?: InputMaybe<SchedulingPriority>;
};

export type NullableEnumStripeTxStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<StripeTxStatus>;
};

export type NullableEnumStripeTxTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<StripeTxType>;
};

export type NullableEnumTippingTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TippingType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Float"]>;
  divide?: InputMaybe<Scalars["Float"]>;
  increment?: InputMaybe<Scalars["Float"]>;
  multiply?: InputMaybe<Scalars["Float"]>;
  set?: InputMaybe<Scalars["Float"]>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Int"]>;
  divide?: InputMaybe<Scalars["Int"]>;
  increment?: InputMaybe<Scalars["Int"]>;
  multiply?: InputMaybe<Scalars["Int"]>;
  set?: InputMaybe<Scalars["Int"]>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]>;
};

export enum NullsOrder {
  First = "first",
  Last = "last"
}

export type Order = {
  __typename?: "Order";
  _count?: Maybe<OrderCount>;
  amount_remaining: Scalars["Float"];
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars["String"]>;
  cancelled_at?: Maybe<Scalars["DateTime"]>;
  created_at: Scalars["DateTime"];
  full_amount?: Maybe<Scalars["Float"]>;
  id: Scalars["String"];
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars["String"]>;
  payment_status: PaymentStatus;
  refunded_at?: Maybe<Scalars["DateTime"]>;
  tip_amount: Scalars["Float"];
  transactions: Array<Transaction>;
  tx_addons: Array<TxAddon>;
  tx_cancellation_policy?: Maybe<TxCancellationPolicy>;
  tx_items: Array<TxItem>;
  tx_services: Array<TxService>;
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
};

export type OrderTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type OrderTx_AddonsArgs = {
  cursor?: InputMaybe<TxAddonWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxAddonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxAddonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxAddonWhereInput>;
};

export type OrderTx_ItemsArgs = {
  cursor?: InputMaybe<TxItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxItemWhereInput>;
};

export type OrderTx_ServicesArgs = {
  cursor?: InputMaybe<TxServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxServiceWhereInput>;
};

export type OrderAvgAggregate = {
  __typename?: "OrderAvgAggregate";
  amount_remaining?: Maybe<Scalars["Float"]>;
  full_amount?: Maybe<Scalars["Float"]>;
  tip_amount?: Maybe<Scalars["Float"]>;
};

export type OrderAvgOrderByAggregateInput = {
  amount_remaining?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrder>;
  tip_amount?: InputMaybe<SortOrder>;
};

export type OrderCount = {
  __typename?: "OrderCount";
  transactions: Scalars["Int"];
  tx_addons: Scalars["Int"];
  tx_items: Scalars["Int"];
  tx_services: Scalars["Int"];
};

export type OrderCountTransactionsArgs = {
  where?: InputMaybe<TransactionWhereInput>;
};

export type OrderCountTx_AddonsArgs = {
  where?: InputMaybe<TxAddonWhereInput>;
};

export type OrderCountTx_ItemsArgs = {
  where?: InputMaybe<TxItemWhereInput>;
};

export type OrderCountTx_ServicesArgs = {
  where?: InputMaybe<TxServiceWhereInput>;
};

export type OrderCountAggregate = {
  __typename?: "OrderCountAggregate";
  _all: Scalars["Int"];
  amount_remaining: Scalars["Int"];
  appointmentId: Scalars["Int"];
  cancelled_at: Scalars["Int"];
  created_at: Scalars["Int"];
  full_amount: Scalars["Int"];
  id: Scalars["Int"];
  location_id: Scalars["Int"];
  payment_status: Scalars["Int"];
  refunded_at: Scalars["Int"];
  tip_amount: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type OrderCountOrderByAggregateInput = {
  amount_remaining?: InputMaybe<SortOrder>;
  appointmentId?: InputMaybe<SortOrder>;
  cancelled_at?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  payment_status?: InputMaybe<SortOrder>;
  refunded_at?: InputMaybe<SortOrder>;
  tip_amount?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type OrderCreateInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutOrderInput>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<LocationCreateNestedOneWithoutOrdersInput>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutOrderInput>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutOrderInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyCreateNestedOneWithoutOrderInput>;
  tx_items?: InputMaybe<TxItemCreateNestedManyWithoutOrderInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutOrderInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutOrderInput;
};

export type OrderCreateManyInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointmentId?: InputMaybe<Scalars["String"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  location_id?: InputMaybe<Scalars["String"]>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type OrderCreateManyLocationInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointmentId?: InputMaybe<Scalars["String"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type OrderCreateManyLocationInputEnvelope = {
  data: Array<OrderCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OrderCreateManyUserInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointmentId?: InputMaybe<Scalars["String"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  location_id?: InputMaybe<Scalars["String"]>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type OrderCreateManyUserInputEnvelope = {
  data: Array<OrderCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OrderCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutLocationInput>>;
  createMany?: InputMaybe<OrderCreateManyLocationInputEnvelope>;
};

export type OrderCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutUserInput>>;
  createMany?: InputMaybe<OrderCreateManyUserInputEnvelope>;
};

export type OrderCreateNestedOneWithoutAppointmentInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutAppointmentInput>;
  create?: InputMaybe<OrderCreateWithoutAppointmentInput>;
};

export type OrderCreateNestedOneWithoutTransactionsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<OrderCreateWithoutTransactionsInput>;
};

export type OrderCreateNestedOneWithoutTx_AddonsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTx_AddonsInput>;
  create?: InputMaybe<OrderCreateWithoutTx_AddonsInput>;
};

export type OrderCreateNestedOneWithoutTx_Cancellation_PolicyInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTx_Cancellation_PolicyInput>;
  create?: InputMaybe<OrderCreateWithoutTx_Cancellation_PolicyInput>;
};

export type OrderCreateNestedOneWithoutTx_ItemsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTx_ItemsInput>;
  create?: InputMaybe<OrderCreateWithoutTx_ItemsInput>;
};

export type OrderCreateNestedOneWithoutTx_ServicesInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTx_ServicesInput>;
  create?: InputMaybe<OrderCreateWithoutTx_ServicesInput>;
};

export type OrderCreateOrConnectWithoutAppointmentInput = {
  create: OrderCreateWithoutAppointmentInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutLocationInput = {
  create: OrderCreateWithoutLocationInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutTransactionsInput = {
  create: OrderCreateWithoutTransactionsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutTx_AddonsInput = {
  create: OrderCreateWithoutTx_AddonsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutTx_Cancellation_PolicyInput = {
  create: OrderCreateWithoutTx_Cancellation_PolicyInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutTx_ItemsInput = {
  create: OrderCreateWithoutTx_ItemsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutTx_ServicesInput = {
  create: OrderCreateWithoutTx_ServicesInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutUserInput = {
  create: OrderCreateWithoutUserInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutAppointmentInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<LocationCreateNestedOneWithoutOrdersInput>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutOrderInput>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutOrderInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyCreateNestedOneWithoutOrderInput>;
  tx_items?: InputMaybe<TxItemCreateNestedManyWithoutOrderInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutOrderInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutOrderInput;
};

export type OrderCreateWithoutLocationInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutOrderInput>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutOrderInput>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutOrderInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyCreateNestedOneWithoutOrderInput>;
  tx_items?: InputMaybe<TxItemCreateNestedManyWithoutOrderInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutOrderInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutOrderInput;
};

export type OrderCreateWithoutTransactionsInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutOrderInput>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<LocationCreateNestedOneWithoutOrdersInput>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutOrderInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyCreateNestedOneWithoutOrderInput>;
  tx_items?: InputMaybe<TxItemCreateNestedManyWithoutOrderInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutOrderInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutOrderInput;
};

export type OrderCreateWithoutTx_AddonsInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutOrderInput>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<LocationCreateNestedOneWithoutOrdersInput>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutOrderInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyCreateNestedOneWithoutOrderInput>;
  tx_items?: InputMaybe<TxItemCreateNestedManyWithoutOrderInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutOrderInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutOrderInput;
};

export type OrderCreateWithoutTx_Cancellation_PolicyInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutOrderInput>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<LocationCreateNestedOneWithoutOrdersInput>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutOrderInput>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutOrderInput>;
  tx_items?: InputMaybe<TxItemCreateNestedManyWithoutOrderInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutOrderInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutOrderInput;
};

export type OrderCreateWithoutTx_ItemsInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutOrderInput>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<LocationCreateNestedOneWithoutOrdersInput>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutOrderInput>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutOrderInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyCreateNestedOneWithoutOrderInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutOrderInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutOrderInput;
};

export type OrderCreateWithoutTx_ServicesInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutOrderInput>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<LocationCreateNestedOneWithoutOrdersInput>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutOrderInput>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutOrderInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyCreateNestedOneWithoutOrderInput>;
  tx_items?: InputMaybe<TxItemCreateNestedManyWithoutOrderInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutOrderInput;
};

export type OrderCreateWithoutUserInput = {
  amount_remaining?: InputMaybe<Scalars["Float"]>;
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutOrderInput>;
  cancelled_at?: InputMaybe<Scalars["DateTime"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  full_amount?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<LocationCreateNestedOneWithoutOrdersInput>;
  payment_status: PaymentStatus;
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
  tip_amount?: InputMaybe<Scalars["Float"]>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutOrderInput>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutOrderInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyCreateNestedOneWithoutOrderInput>;
  tx_items?: InputMaybe<TxItemCreateNestedManyWithoutOrderInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutOrderInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type OrderGroupBy = {
  __typename?: "OrderGroupBy";
  _avg?: Maybe<OrderAvgAggregate>;
  _count?: Maybe<OrderCountAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
  amount_remaining: Scalars["Float"];
  appointmentId?: Maybe<Scalars["String"]>;
  cancelled_at?: Maybe<Scalars["DateTime"]>;
  created_at: Scalars["DateTime"];
  full_amount?: Maybe<Scalars["Float"]>;
  id: Scalars["String"];
  location_id?: Maybe<Scalars["String"]>;
  payment_status: PaymentStatus;
  refunded_at?: Maybe<Scalars["DateTime"]>;
  tip_amount: Scalars["Float"];
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
};

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderMaxAggregate = {
  __typename?: "OrderMaxAggregate";
  amount_remaining?: Maybe<Scalars["Float"]>;
  appointmentId?: Maybe<Scalars["String"]>;
  cancelled_at?: Maybe<Scalars["DateTime"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  full_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["String"]>;
  payment_status?: Maybe<PaymentStatus>;
  refunded_at?: Maybe<Scalars["DateTime"]>;
  tip_amount?: Maybe<Scalars["Float"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type OrderMaxOrderByAggregateInput = {
  amount_remaining?: InputMaybe<SortOrder>;
  appointmentId?: InputMaybe<SortOrder>;
  cancelled_at?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  payment_status?: InputMaybe<SortOrder>;
  refunded_at?: InputMaybe<SortOrder>;
  tip_amount?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type OrderMinAggregate = {
  __typename?: "OrderMinAggregate";
  amount_remaining?: Maybe<Scalars["Float"]>;
  appointmentId?: Maybe<Scalars["String"]>;
  cancelled_at?: Maybe<Scalars["DateTime"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  full_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["String"]>;
  payment_status?: Maybe<PaymentStatus>;
  refunded_at?: Maybe<Scalars["DateTime"]>;
  tip_amount?: Maybe<Scalars["Float"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type OrderMinOrderByAggregateInput = {
  amount_remaining?: InputMaybe<SortOrder>;
  appointmentId?: InputMaybe<SortOrder>;
  cancelled_at?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  payment_status?: InputMaybe<SortOrder>;
  refunded_at?: InputMaybe<SortOrder>;
  tip_amount?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithAggregationInput = {
  _avg?: InputMaybe<OrderAvgOrderByAggregateInput>;
  _count?: InputMaybe<OrderCountOrderByAggregateInput>;
  _max?: InputMaybe<OrderMaxOrderByAggregateInput>;
  _min?: InputMaybe<OrderMinOrderByAggregateInput>;
  _sum?: InputMaybe<OrderSumOrderByAggregateInput>;
  amount_remaining?: InputMaybe<SortOrder>;
  appointmentId?: InputMaybe<SortOrderInput>;
  cancelled_at?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrderInput>;
  payment_status?: InputMaybe<SortOrder>;
  refunded_at?: InputMaybe<SortOrderInput>;
  tip_amount?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithRelationInput = {
  amount_remaining?: InputMaybe<SortOrder>;
  appointment?: InputMaybe<AppointmentOrderByWithRelationInput>;
  appointmentId?: InputMaybe<SortOrderInput>;
  cancelled_at?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByWithRelationInput>;
  location_id?: InputMaybe<SortOrderInput>;
  payment_status?: InputMaybe<SortOrder>;
  refunded_at?: InputMaybe<SortOrderInput>;
  tip_amount?: InputMaybe<SortOrder>;
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  tx_addons?: InputMaybe<TxAddonOrderByRelationAggregateInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyOrderByWithRelationInput>;
  tx_items?: InputMaybe<TxItemOrderByRelationAggregateInput>;
  tx_services?: InputMaybe<TxServiceOrderByRelationAggregateInput>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export type OrderRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  AmountRemaining = "amount_remaining",
  AppointmentId = "appointmentId",
  CancelledAt = "cancelled_at",
  CreatedAt = "created_at",
  FullAmount = "full_amount",
  Id = "id",
  LocationId = "location_id",
  PaymentStatus = "payment_status",
  RefundedAt = "refunded_at",
  TipAmount = "tip_amount",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type OrderScalarWhereInput = {
  AND?: InputMaybe<Array<OrderScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderScalarWhereInput>>;
  amount_remaining?: InputMaybe<FloatFilter>;
  appointmentId?: InputMaybe<UuidNullableFilter>;
  cancelled_at?: InputMaybe<DateTimeNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  full_amount?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  location_id?: InputMaybe<UuidNullableFilter>;
  payment_status?: InputMaybe<EnumPaymentStatusFilter>;
  refunded_at?: InputMaybe<DateTimeNullableFilter>;
  tip_amount?: InputMaybe<FloatFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type OrderScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<OrderScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<OrderScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<OrderScalarWhereWithAggregatesInput>>;
  amount_remaining?: InputMaybe<FloatWithAggregatesFilter>;
  appointmentId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  cancelled_at?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  full_amount?: InputMaybe<FloatNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  location_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  payment_status?: InputMaybe<EnumPaymentStatusWithAggregatesFilter>;
  refunded_at?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  tip_amount?: InputMaybe<FloatWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export type OrderSumAggregate = {
  __typename?: "OrderSumAggregate";
  amount_remaining?: Maybe<Scalars["Float"]>;
  full_amount?: Maybe<Scalars["Float"]>;
  tip_amount?: Maybe<Scalars["Float"]>;
};

export type OrderSumOrderByAggregateInput = {
  amount_remaining?: InputMaybe<SortOrder>;
  full_amount?: InputMaybe<SortOrder>;
  tip_amount?: InputMaybe<SortOrder>;
};

export type OrderUpdateInput = {
  amount_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutOrderNestedInput>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutOrdersNestedInput>;
  payment_status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  refunded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tip_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutOrderNestedInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutOrderNestedInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyUpdateOneWithoutOrderNestedInput>;
  tx_items?: InputMaybe<TxItemUpdateManyWithoutOrderNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutOrderNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
};

export type OrderUpdateManyMutationInput = {
  amount_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  payment_status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  refunded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tip_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateManyWithWhereWithoutLocationInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutUserInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutLocationInput>>;
  createMany?: InputMaybe<OrderCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutLocationInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type OrderUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutUserInput>>;
  createMany?: InputMaybe<OrderCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutUserInput>>;
};

export type OrderUpdateOneRequiredWithoutTransactionsNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<OrderCreateWithoutTransactionsInput>;
  update?: InputMaybe<OrderUpdateWithoutTransactionsInput>;
  upsert?: InputMaybe<OrderUpsertWithoutTransactionsInput>;
};

export type OrderUpdateOneWithoutAppointmentNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutAppointmentInput>;
  create?: InputMaybe<OrderCreateWithoutAppointmentInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<OrderUpdateWithoutAppointmentInput>;
  upsert?: InputMaybe<OrderUpsertWithoutAppointmentInput>;
};

export type OrderUpdateOneWithoutTx_AddonsNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTx_AddonsInput>;
  create?: InputMaybe<OrderCreateWithoutTx_AddonsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<OrderUpdateWithoutTx_AddonsInput>;
  upsert?: InputMaybe<OrderUpsertWithoutTx_AddonsInput>;
};

export type OrderUpdateOneWithoutTx_Cancellation_PolicyNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTx_Cancellation_PolicyInput>;
  create?: InputMaybe<OrderCreateWithoutTx_Cancellation_PolicyInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<OrderUpdateWithoutTx_Cancellation_PolicyInput>;
  upsert?: InputMaybe<OrderUpsertWithoutTx_Cancellation_PolicyInput>;
};

export type OrderUpdateOneWithoutTx_ItemsNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTx_ItemsInput>;
  create?: InputMaybe<OrderCreateWithoutTx_ItemsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<OrderUpdateWithoutTx_ItemsInput>;
  upsert?: InputMaybe<OrderUpsertWithoutTx_ItemsInput>;
};

export type OrderUpdateOneWithoutTx_ServicesNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTx_ServicesInput>;
  create?: InputMaybe<OrderCreateWithoutTx_ServicesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<OrderUpdateWithoutTx_ServicesInput>;
  upsert?: InputMaybe<OrderUpsertWithoutTx_ServicesInput>;
};

export type OrderUpdateWithWhereUniqueWithoutLocationInput = {
  data: OrderUpdateWithoutLocationInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutUserInput = {
  data: OrderUpdateWithoutUserInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithoutAppointmentInput = {
  amount_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutOrdersNestedInput>;
  payment_status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  refunded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tip_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutOrderNestedInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutOrderNestedInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyUpdateOneWithoutOrderNestedInput>;
  tx_items?: InputMaybe<TxItemUpdateManyWithoutOrderNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutOrderNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutLocationInput = {
  amount_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutOrderNestedInput>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  payment_status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  refunded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tip_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutOrderNestedInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutOrderNestedInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyUpdateOneWithoutOrderNestedInput>;
  tx_items?: InputMaybe<TxItemUpdateManyWithoutOrderNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutOrderNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutTransactionsInput = {
  amount_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutOrderNestedInput>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutOrdersNestedInput>;
  payment_status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  refunded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tip_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutOrderNestedInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyUpdateOneWithoutOrderNestedInput>;
  tx_items?: InputMaybe<TxItemUpdateManyWithoutOrderNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutOrderNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutTx_AddonsInput = {
  amount_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutOrderNestedInput>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutOrdersNestedInput>;
  payment_status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  refunded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tip_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutOrderNestedInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyUpdateOneWithoutOrderNestedInput>;
  tx_items?: InputMaybe<TxItemUpdateManyWithoutOrderNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutOrderNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutTx_Cancellation_PolicyInput = {
  amount_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutOrderNestedInput>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutOrdersNestedInput>;
  payment_status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  refunded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tip_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutOrderNestedInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutOrderNestedInput>;
  tx_items?: InputMaybe<TxItemUpdateManyWithoutOrderNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutOrderNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutTx_ItemsInput = {
  amount_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutOrderNestedInput>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutOrdersNestedInput>;
  payment_status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  refunded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tip_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutOrderNestedInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutOrderNestedInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyUpdateOneWithoutOrderNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutOrderNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutTx_ServicesInput = {
  amount_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutOrderNestedInput>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutOrdersNestedInput>;
  payment_status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  refunded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tip_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutOrderNestedInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutOrderNestedInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyUpdateOneWithoutOrderNestedInput>;
  tx_items?: InputMaybe<TxItemUpdateManyWithoutOrderNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutUserInput = {
  amount_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutOrderNestedInput>;
  cancelled_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  full_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutOrdersNestedInput>;
  payment_status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  refunded_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tip_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutOrderNestedInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutOrderNestedInput>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyUpdateOneWithoutOrderNestedInput>;
  tx_items?: InputMaybe<TxItemUpdateManyWithoutOrderNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutOrderNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpsertWithWhereUniqueWithoutLocationInput = {
  create: OrderCreateWithoutLocationInput;
  update: OrderUpdateWithoutLocationInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutUserInput = {
  create: OrderCreateWithoutUserInput;
  update: OrderUpdateWithoutUserInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithoutAppointmentInput = {
  create: OrderCreateWithoutAppointmentInput;
  update: OrderUpdateWithoutAppointmentInput;
};

export type OrderUpsertWithoutTransactionsInput = {
  create: OrderCreateWithoutTransactionsInput;
  update: OrderUpdateWithoutTransactionsInput;
};

export type OrderUpsertWithoutTx_AddonsInput = {
  create: OrderCreateWithoutTx_AddonsInput;
  update: OrderUpdateWithoutTx_AddonsInput;
};

export type OrderUpsertWithoutTx_Cancellation_PolicyInput = {
  create: OrderCreateWithoutTx_Cancellation_PolicyInput;
  update: OrderUpdateWithoutTx_Cancellation_PolicyInput;
};

export type OrderUpsertWithoutTx_ItemsInput = {
  create: OrderCreateWithoutTx_ItemsInput;
  update: OrderUpdateWithoutTx_ItemsInput;
};

export type OrderUpsertWithoutTx_ServicesInput = {
  create: OrderCreateWithoutTx_ServicesInput;
  update: OrderUpdateWithoutTx_ServicesInput;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  amount_remaining?: InputMaybe<FloatFilter>;
  appointment?: InputMaybe<AppointmentRelationFilter>;
  appointmentId?: InputMaybe<UuidNullableFilter>;
  cancelled_at?: InputMaybe<DateTimeNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  full_amount?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  location_id?: InputMaybe<UuidNullableFilter>;
  payment_status?: InputMaybe<EnumPaymentStatusFilter>;
  refunded_at?: InputMaybe<DateTimeNullableFilter>;
  tip_amount?: InputMaybe<FloatFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  tx_addons?: InputMaybe<TxAddonListRelationFilter>;
  tx_cancellation_policy?: InputMaybe<TxCancellationPolicyRelationFilter>;
  tx_items?: InputMaybe<TxItemListRelationFilter>;
  tx_services?: InputMaybe<TxServiceListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type OrderWhereUniqueInput = {
  appointmentId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type PaymentIntent = {
  __typename?: "PaymentIntent";
  amount: Scalars["Int"];
  amount_capturable: Scalars["Int"];
  amount_received: Scalars["Int"];
  application: Scalars["String"];
  application_fee_amount?: Maybe<Scalars["Int"]>;
  canceled_at?: Maybe<Scalars["Int"]>;
  cancellation_reason?: Maybe<Scalars["String"]>;
  capture_method: Scalars["String"];
  client_secret: Scalars["String"];
  confirmation_method: Scalars["String"];
  created: Scalars["Int"];
  currency: Scalars["String"];
  customer: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["String"];
  invoice?: Maybe<Scalars["String"]>;
  last_payment_error?: Maybe<LastPaymentError>;
  livemode: Scalars["Boolean"];
  object: Scalars["String"];
  payment_method_options: PaymentMethodOptions;
  payment_method_types: Array<Scalars["String"]>;
  processing?: Maybe<Scalars["Boolean"]>;
  receipt_email?: Maybe<Scalars["String"]>;
  review?: Maybe<Scalars["String"]>;
  setup_future_usage: Scalars["String"];
  shipping?: Maybe<Scalars["String"]>;
  statement_descriptor?: Maybe<Scalars["String"]>;
  statement_descriptor_suffix?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  transfer_data?: Maybe<Scalars["String"]>;
  transfer_group?: Maybe<Scalars["String"]>;
};

export enum PaymentMethod {
  Cash = "CASH",
  Giftcard = "GIFTCARD",
  Pos = "POS",
  Refund = "REFUND",
  Stripe = "STRIPE"
}

export type PaymentMethodForIntent = {
  __typename?: "PaymentMethodForIntent";
  billing_details: BillingDetails;
  card: Card;
  created: Scalars["Int"];
  customer: Scalars["String"];
  id: Scalars["String"];
  livemode: Scalars["Boolean"];
  object: Scalars["String"];
  type: Scalars["String"];
};

export type PaymentMethodOptions = {
  __typename?: "PaymentMethodOptions";
  card: CardOptions;
};

export enum PaymentStatus {
  Cancelled = "CANCELLED",
  FullyPaid = "FULLY_PAID",
  PaidUpfront = "PAID_UPFRONT",
  PartialPayment = "PARTIAL_PAYMENT",
  PaymentFailed = "PAYMENT_FAILED",
  PayInStore = "PAY_IN_STORE",
  Refunded = "REFUNDED"
}

export enum PaymentType {
  PartialPayUpfront = "PARTIAL_PAY_UPFRONT",
  PayFullUpfront = "PAY_FULL_UPFRONT",
  PayInStore = "PAY_IN_STORE"
}

export type Platform = {
  __typename?: "Platform";
  amount_billed_per_appointment?: Maybe<Scalars["Decimal"]>;
  appointment_reminder_hours_before?: Maybe<Scalars["Int"]>;
  approved_emails: Scalars["String"];
  contact_email?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  feature_flag_restricted_sign_up?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  percent_billed_from_pos?: Maybe<Scalars["Decimal"]>;
  service_price_per_month?: Maybe<Scalars["Decimal"]>;
  updated_at: Scalars["DateTime"];
};

export type PlatformAvgAggregate = {
  __typename?: "PlatformAvgAggregate";
  amount_billed_per_appointment?: Maybe<Scalars["Decimal"]>;
  appointment_reminder_hours_before?: Maybe<Scalars["Float"]>;
  percent_billed_from_pos?: Maybe<Scalars["Decimal"]>;
  service_price_per_month?: Maybe<Scalars["Decimal"]>;
};

export type PlatformAvgOrderByAggregateInput = {
  amount_billed_per_appointment?: InputMaybe<SortOrder>;
  appointment_reminder_hours_before?: InputMaybe<SortOrder>;
  percent_billed_from_pos?: InputMaybe<SortOrder>;
  service_price_per_month?: InputMaybe<SortOrder>;
};

export type PlatformCountAggregate = {
  __typename?: "PlatformCountAggregate";
  _all: Scalars["Int"];
  amount_billed_per_appointment: Scalars["Int"];
  appointment_reminder_hours_before: Scalars["Int"];
  approved_emails: Scalars["Int"];
  contact_email: Scalars["Int"];
  created_at: Scalars["Int"];
  feature_flag_restricted_sign_up: Scalars["Int"];
  id: Scalars["Int"];
  percent_billed_from_pos: Scalars["Int"];
  service_price_per_month: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type PlatformCountOrderByAggregateInput = {
  amount_billed_per_appointment?: InputMaybe<SortOrder>;
  appointment_reminder_hours_before?: InputMaybe<SortOrder>;
  approved_emails?: InputMaybe<SortOrder>;
  contact_email?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  feature_flag_restricted_sign_up?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percent_billed_from_pos?: InputMaybe<SortOrder>;
  service_price_per_month?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type PlatformCreateInput = {
  amount_billed_per_appointment?: InputMaybe<Scalars["Decimal"]>;
  appointment_reminder_hours_before?: InputMaybe<Scalars["Int"]>;
  approved_emails?: InputMaybe<Scalars["String"]>;
  contact_email?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  feature_flag_restricted_sign_up?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  percent_billed_from_pos?: InputMaybe<Scalars["Decimal"]>;
  service_price_per_month?: InputMaybe<Scalars["Decimal"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type PlatformCreateManyInput = {
  amount_billed_per_appointment?: InputMaybe<Scalars["Decimal"]>;
  appointment_reminder_hours_before?: InputMaybe<Scalars["Int"]>;
  approved_emails?: InputMaybe<Scalars["String"]>;
  contact_email?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  feature_flag_restricted_sign_up?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  percent_billed_from_pos?: InputMaybe<Scalars["Decimal"]>;
  service_price_per_month?: InputMaybe<Scalars["Decimal"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type PlatformGroupBy = {
  __typename?: "PlatformGroupBy";
  _avg?: Maybe<PlatformAvgAggregate>;
  _count?: Maybe<PlatformCountAggregate>;
  _max?: Maybe<PlatformMaxAggregate>;
  _min?: Maybe<PlatformMinAggregate>;
  _sum?: Maybe<PlatformSumAggregate>;
  amount_billed_per_appointment?: Maybe<Scalars["Decimal"]>;
  appointment_reminder_hours_before?: Maybe<Scalars["Int"]>;
  approved_emails: Scalars["String"];
  contact_email?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  feature_flag_restricted_sign_up?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  percent_billed_from_pos?: Maybe<Scalars["Decimal"]>;
  service_price_per_month?: Maybe<Scalars["Decimal"]>;
  updated_at: Scalars["DateTime"];
};

export type PlatformMaxAggregate = {
  __typename?: "PlatformMaxAggregate";
  amount_billed_per_appointment?: Maybe<Scalars["Decimal"]>;
  appointment_reminder_hours_before?: Maybe<Scalars["Int"]>;
  approved_emails?: Maybe<Scalars["String"]>;
  contact_email?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  feature_flag_restricted_sign_up?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  percent_billed_from_pos?: Maybe<Scalars["Decimal"]>;
  service_price_per_month?: Maybe<Scalars["Decimal"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type PlatformMaxOrderByAggregateInput = {
  amount_billed_per_appointment?: InputMaybe<SortOrder>;
  appointment_reminder_hours_before?: InputMaybe<SortOrder>;
  approved_emails?: InputMaybe<SortOrder>;
  contact_email?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  feature_flag_restricted_sign_up?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percent_billed_from_pos?: InputMaybe<SortOrder>;
  service_price_per_month?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type PlatformMinAggregate = {
  __typename?: "PlatformMinAggregate";
  amount_billed_per_appointment?: Maybe<Scalars["Decimal"]>;
  appointment_reminder_hours_before?: Maybe<Scalars["Int"]>;
  approved_emails?: Maybe<Scalars["String"]>;
  contact_email?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  feature_flag_restricted_sign_up?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  percent_billed_from_pos?: Maybe<Scalars["Decimal"]>;
  service_price_per_month?: Maybe<Scalars["Decimal"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type PlatformMinOrderByAggregateInput = {
  amount_billed_per_appointment?: InputMaybe<SortOrder>;
  appointment_reminder_hours_before?: InputMaybe<SortOrder>;
  approved_emails?: InputMaybe<SortOrder>;
  contact_email?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  feature_flag_restricted_sign_up?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percent_billed_from_pos?: InputMaybe<SortOrder>;
  service_price_per_month?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type PlatformOrderByWithAggregationInput = {
  _avg?: InputMaybe<PlatformAvgOrderByAggregateInput>;
  _count?: InputMaybe<PlatformCountOrderByAggregateInput>;
  _max?: InputMaybe<PlatformMaxOrderByAggregateInput>;
  _min?: InputMaybe<PlatformMinOrderByAggregateInput>;
  _sum?: InputMaybe<PlatformSumOrderByAggregateInput>;
  amount_billed_per_appointment?: InputMaybe<SortOrderInput>;
  appointment_reminder_hours_before?: InputMaybe<SortOrderInput>;
  approved_emails?: InputMaybe<SortOrder>;
  contact_email?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  feature_flag_restricted_sign_up?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  percent_billed_from_pos?: InputMaybe<SortOrderInput>;
  service_price_per_month?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type PlatformOrderByWithRelationInput = {
  amount_billed_per_appointment?: InputMaybe<SortOrderInput>;
  appointment_reminder_hours_before?: InputMaybe<SortOrderInput>;
  approved_emails?: InputMaybe<SortOrder>;
  contact_email?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  feature_flag_restricted_sign_up?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  percent_billed_from_pos?: InputMaybe<SortOrderInput>;
  service_price_per_month?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum PlatformScalarFieldEnum {
  AmountBilledPerAppointment = "amount_billed_per_appointment",
  AppointmentReminderHoursBefore = "appointment_reminder_hours_before",
  ApprovedEmails = "approved_emails",
  ContactEmail = "contact_email",
  CreatedAt = "created_at",
  FeatureFlagRestrictedSignUp = "feature_flag_restricted_sign_up",
  Id = "id",
  PercentBilledFromPos = "percent_billed_from_pos",
  ServicePricePerMonth = "service_price_per_month",
  UpdatedAt = "updated_at"
}

export type PlatformScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PlatformScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PlatformScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PlatformScalarWhereWithAggregatesInput>>;
  amount_billed_per_appointment?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  appointment_reminder_hours_before?: InputMaybe<IntNullableWithAggregatesFilter>;
  approved_emails?: InputMaybe<StringWithAggregatesFilter>;
  contact_email?: InputMaybe<StringNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  feature_flag_restricted_sign_up?: InputMaybe<BoolNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  percent_billed_from_pos?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  service_price_per_month?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PlatformSumAggregate = {
  __typename?: "PlatformSumAggregate";
  amount_billed_per_appointment?: Maybe<Scalars["Decimal"]>;
  appointment_reminder_hours_before?: Maybe<Scalars["Int"]>;
  percent_billed_from_pos?: Maybe<Scalars["Decimal"]>;
  service_price_per_month?: Maybe<Scalars["Decimal"]>;
};

export type PlatformSumOrderByAggregateInput = {
  amount_billed_per_appointment?: InputMaybe<SortOrder>;
  appointment_reminder_hours_before?: InputMaybe<SortOrder>;
  percent_billed_from_pos?: InputMaybe<SortOrder>;
  service_price_per_month?: InputMaybe<SortOrder>;
};

export type PlatformUpdateInput = {
  amount_billed_per_appointment?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  appointment_reminder_hours_before?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  approved_emails?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feature_flag_restricted_sign_up?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  percent_billed_from_pos?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  service_price_per_month?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlatformUpdateManyMutationInput = {
  amount_billed_per_appointment?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  appointment_reminder_hours_before?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  approved_emails?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feature_flag_restricted_sign_up?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  percent_billed_from_pos?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  service_price_per_month?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlatformWhereInput = {
  AND?: InputMaybe<Array<PlatformWhereInput>>;
  NOT?: InputMaybe<Array<PlatformWhereInput>>;
  OR?: InputMaybe<Array<PlatformWhereInput>>;
  amount_billed_per_appointment?: InputMaybe<DecimalNullableFilter>;
  appointment_reminder_hours_before?: InputMaybe<IntNullableFilter>;
  approved_emails?: InputMaybe<StringFilter>;
  contact_email?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  feature_flag_restricted_sign_up?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  percent_billed_from_pos?: InputMaybe<DecimalNullableFilter>;
  service_price_per_month?: InputMaybe<DecimalNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type PlatformWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  aggregateAmenity: AggregateAmenity;
  aggregateAppointment: AggregateAppointment;
  aggregateAppointmentService: AggregateAppointmentService;
  aggregateArtist: AggregateArtist;
  aggregateArtistAvailability: AggregateArtistAvailability;
  aggregateArtistInstagram: AggregateArtistInstagram;
  aggregateArtistServiceExclusion: AggregateArtistServiceExclusion;
  aggregateAvailability: AggregateAvailability;
  aggregateCompany: AggregateCompany;
  aggregateCompanyAmenity: AggregateCompanyAmenity;
  aggregateCompanyPaymentMethod: AggregateCompanyPaymentMethod;
  aggregateCompanyUserDescription: AggregateCompanyUserDescription;
  aggregateFacility: AggregateFacility;
  aggregateFacilityService: AggregateFacilityService;
  aggregateFavoriteArtist: AggregateFavoriteArtist;
  aggregateGroupAvailability: AggregateGroupAvailability;
  aggregateLocation: AggregateLocation;
  aggregateLocationAmenity: AggregateLocationAmenity;
  aggregateMenu: AggregateMenu;
  aggregateMenuSection: AggregateMenuSection;
  aggregateMenuSectionService: AggregateMenuSectionService;
  aggregateNotification: AggregateNotification;
  aggregateOrder: AggregateOrder;
  aggregatePlatform: AggregatePlatform;
  aggregateRefund: AggregateRefund;
  aggregateReminderSetting: AggregateReminderSetting;
  aggregateRole: AggregateRole;
  aggregateService: AggregateService;
  aggregateServiceAddOn: AggregateServiceAddOn;
  aggregateStripePaymentMethod: AggregateStripePaymentMethod;
  aggregateTransaction: AggregateTransaction;
  aggregateTxAddon: AggregateTxAddon;
  aggregateTxCancellationPolicy: AggregateTxCancellationPolicy;
  aggregateTxItem: AggregateTxItem;
  aggregateTxService: AggregateTxService;
  aggregateUser: AggregateUser;
  aggregateUserFavoriteLocation: AggregateUserFavoriteLocation;
  amenities: Array<Amenity>;
  amenity?: Maybe<Amenity>;
  appointment?: Maybe<Appointment>;
  appointmentService?: Maybe<AppointmentService>;
  appointmentServices: Array<AppointmentService>;
  appointments: Array<Appointment>;
  artist?: Maybe<Artist>;
  artistAvailabilities: Array<ArtistAvailability>;
  artistAvailability?: Maybe<ArtistAvailability>;
  artistInstagram?: Maybe<ArtistInstagram>;
  artistInstagrams: Array<ArtistInstagram>;
  artistServiceExclusion?: Maybe<ArtistServiceExclusion>;
  artistServiceExclusions: Array<ArtistServiceExclusion>;
  artists: Array<Artist>;
  availabilities: Array<Availability>;
  availability?: Maybe<Availability>;
  companies: Array<Company>;
  company?: Maybe<Company>;
  companyAmenities: Array<CompanyAmenity>;
  companyAmenity?: Maybe<CompanyAmenity>;
  companyPaymentMethod?: Maybe<CompanyPaymentMethod>;
  companyPaymentMethods: Array<CompanyPaymentMethod>;
  companyUserDescription?: Maybe<CompanyUserDescription>;
  companyUserDescriptions: Array<CompanyUserDescription>;
  facilities: Array<Facility>;
  facility?: Maybe<Facility>;
  facilityService?: Maybe<FacilityService>;
  facilityServices: Array<FacilityService>;
  favoriteArtist?: Maybe<FavoriteArtist>;
  favoriteArtists: Array<FavoriteArtist>;
  findFirstAmenity?: Maybe<Amenity>;
  findFirstAmenityOrThrow?: Maybe<Amenity>;
  findFirstAppointment?: Maybe<Appointment>;
  findFirstAppointmentOrThrow?: Maybe<Appointment>;
  findFirstAppointmentService?: Maybe<AppointmentService>;
  findFirstAppointmentServiceOrThrow?: Maybe<AppointmentService>;
  findFirstArtist?: Maybe<Artist>;
  findFirstArtistAvailability?: Maybe<ArtistAvailability>;
  findFirstArtistAvailabilityOrThrow?: Maybe<ArtistAvailability>;
  findFirstArtistInstagram?: Maybe<ArtistInstagram>;
  findFirstArtistInstagramOrThrow?: Maybe<ArtistInstagram>;
  findFirstArtistOrThrow?: Maybe<Artist>;
  findFirstArtistServiceExclusion?: Maybe<ArtistServiceExclusion>;
  findFirstArtistServiceExclusionOrThrow?: Maybe<ArtistServiceExclusion>;
  findFirstAvailability?: Maybe<Availability>;
  findFirstAvailabilityOrThrow?: Maybe<Availability>;
  findFirstCompany?: Maybe<Company>;
  findFirstCompanyAmenity?: Maybe<CompanyAmenity>;
  findFirstCompanyAmenityOrThrow?: Maybe<CompanyAmenity>;
  findFirstCompanyOrThrow?: Maybe<Company>;
  findFirstCompanyPaymentMethod?: Maybe<CompanyPaymentMethod>;
  findFirstCompanyPaymentMethodOrThrow?: Maybe<CompanyPaymentMethod>;
  findFirstCompanyUserDescription?: Maybe<CompanyUserDescription>;
  findFirstCompanyUserDescriptionOrThrow?: Maybe<CompanyUserDescription>;
  findFirstFacility?: Maybe<Facility>;
  findFirstFacilityOrThrow?: Maybe<Facility>;
  findFirstFacilityService?: Maybe<FacilityService>;
  findFirstFacilityServiceOrThrow?: Maybe<FacilityService>;
  findFirstFavoriteArtist?: Maybe<FavoriteArtist>;
  findFirstFavoriteArtistOrThrow?: Maybe<FavoriteArtist>;
  findFirstGroupAvailability?: Maybe<GroupAvailability>;
  findFirstGroupAvailabilityOrThrow?: Maybe<GroupAvailability>;
  findFirstLocation?: Maybe<Location>;
  findFirstLocationAmenity?: Maybe<LocationAmenity>;
  findFirstLocationAmenityOrThrow?: Maybe<LocationAmenity>;
  findFirstLocationOrThrow?: Maybe<Location>;
  findFirstMenu?: Maybe<Menu>;
  findFirstMenuOrThrow?: Maybe<Menu>;
  findFirstMenuSection?: Maybe<MenuSection>;
  findFirstMenuSectionOrThrow?: Maybe<MenuSection>;
  findFirstMenuSectionService?: Maybe<MenuSectionService>;
  findFirstMenuSectionServiceOrThrow?: Maybe<MenuSectionService>;
  findFirstNotification?: Maybe<Notification>;
  findFirstNotificationOrThrow?: Maybe<Notification>;
  findFirstOrder?: Maybe<Order>;
  findFirstOrderOrThrow?: Maybe<Order>;
  findFirstPlatform?: Maybe<Platform>;
  findFirstPlatformOrThrow?: Maybe<Platform>;
  findFirstRefund?: Maybe<Refund>;
  findFirstRefundOrThrow?: Maybe<Refund>;
  findFirstReminderSetting?: Maybe<ReminderSetting>;
  findFirstReminderSettingOrThrow?: Maybe<ReminderSetting>;
  findFirstRole?: Maybe<Role>;
  findFirstRoleOrThrow?: Maybe<Role>;
  findFirstService?: Maybe<Service>;
  findFirstServiceAddOn?: Maybe<ServiceAddOn>;
  findFirstServiceAddOnOrThrow?: Maybe<ServiceAddOn>;
  findFirstServiceOrThrow?: Maybe<Service>;
  findFirstStripePaymentMethod?: Maybe<StripePaymentMethod>;
  findFirstStripePaymentMethodOrThrow?: Maybe<StripePaymentMethod>;
  findFirstTransaction?: Maybe<Transaction>;
  findFirstTransactionOrThrow?: Maybe<Transaction>;
  findFirstTxAddon?: Maybe<TxAddon>;
  findFirstTxAddonOrThrow?: Maybe<TxAddon>;
  findFirstTxCancellationPolicy?: Maybe<TxCancellationPolicy>;
  findFirstTxCancellationPolicyOrThrow?: Maybe<TxCancellationPolicy>;
  findFirstTxItem?: Maybe<TxItem>;
  findFirstTxItemOrThrow?: Maybe<TxItem>;
  findFirstTxService?: Maybe<TxService>;
  findFirstTxServiceOrThrow?: Maybe<TxService>;
  findFirstUser?: Maybe<User>;
  findFirstUserFavoriteLocation?: Maybe<UserFavoriteLocation>;
  findFirstUserFavoriteLocationOrThrow?: Maybe<UserFavoriteLocation>;
  findFirstUserOrThrow?: Maybe<User>;
  getAmenity?: Maybe<Amenity>;
  getAppointment?: Maybe<Appointment>;
  getAppointmentService?: Maybe<AppointmentService>;
  getArtist?: Maybe<Artist>;
  getArtistAvailability?: Maybe<ArtistAvailability>;
  getArtistInstagram?: Maybe<ArtistInstagram>;
  getArtistServiceExclusion?: Maybe<ArtistServiceExclusion>;
  getAvailability?: Maybe<Availability>;
  getCompany?: Maybe<Company>;
  getCompanyAmenity?: Maybe<CompanyAmenity>;
  getCompanyPaymentMethod?: Maybe<CompanyPaymentMethod>;
  getCompanyUserDescription?: Maybe<CompanyUserDescription>;
  getFacility?: Maybe<Facility>;
  getFacilityService?: Maybe<FacilityService>;
  getFavoriteArtist?: Maybe<FavoriteArtist>;
  getGroupAvailability?: Maybe<GroupAvailability>;
  getLocation?: Maybe<Location>;
  getLocationAmenity?: Maybe<LocationAmenity>;
  getMenu?: Maybe<Menu>;
  getMenuSection?: Maybe<MenuSection>;
  getMenuSectionService?: Maybe<MenuSectionService>;
  getNotification?: Maybe<Notification>;
  getOrder?: Maybe<Order>;
  getOrderDetails: Order;
  getPlatform?: Maybe<Platform>;
  getRefund?: Maybe<Refund>;
  getReminderSetting?: Maybe<ReminderSetting>;
  getRole?: Maybe<Role>;
  getService?: Maybe<Service>;
  getServiceAddOn?: Maybe<ServiceAddOn>;
  getStripePaymentMethod?: Maybe<StripePaymentMethod>;
  getTransaction?: Maybe<Transaction>;
  getTxAddon?: Maybe<TxAddon>;
  getTxCancellationPolicy?: Maybe<TxCancellationPolicy>;
  getTxItem?: Maybe<TxItem>;
  getTxService?: Maybe<TxService>;
  getUser?: Maybe<User>;
  getUserFavoriteLocation?: Maybe<UserFavoriteLocation>;
  groupAvailabilities: Array<GroupAvailability>;
  groupAvailability?: Maybe<GroupAvailability>;
  groupByAmenity: Array<AmenityGroupBy>;
  groupByAppointment: Array<AppointmentGroupBy>;
  groupByAppointmentService: Array<AppointmentServiceGroupBy>;
  groupByArtist: Array<ArtistGroupBy>;
  groupByArtistAvailability: Array<ArtistAvailabilityGroupBy>;
  groupByArtistInstagram: Array<ArtistInstagramGroupBy>;
  groupByArtistServiceExclusion: Array<ArtistServiceExclusionGroupBy>;
  groupByAvailability: Array<AvailabilityGroupBy>;
  groupByCompany: Array<CompanyGroupBy>;
  groupByCompanyAmenity: Array<CompanyAmenityGroupBy>;
  groupByCompanyPaymentMethod: Array<CompanyPaymentMethodGroupBy>;
  groupByCompanyUserDescription: Array<CompanyUserDescriptionGroupBy>;
  groupByFacility: Array<FacilityGroupBy>;
  groupByFacilityService: Array<FacilityServiceGroupBy>;
  groupByFavoriteArtist: Array<FavoriteArtistGroupBy>;
  groupByGroupAvailability: Array<GroupAvailabilityGroupBy>;
  groupByLocation: Array<LocationGroupBy>;
  groupByLocationAmenity: Array<LocationAmenityGroupBy>;
  groupByMenu: Array<MenuGroupBy>;
  groupByMenuSection: Array<MenuSectionGroupBy>;
  groupByMenuSectionService: Array<MenuSectionServiceGroupBy>;
  groupByNotification: Array<NotificationGroupBy>;
  groupByOrder: Array<OrderGroupBy>;
  groupByPlatform: Array<PlatformGroupBy>;
  groupByRefund: Array<RefundGroupBy>;
  groupByReminderSetting: Array<ReminderSettingGroupBy>;
  groupByRole: Array<RoleGroupBy>;
  groupByService: Array<ServiceGroupBy>;
  groupByServiceAddOn: Array<ServiceAddOnGroupBy>;
  groupByStripePaymentMethod: Array<StripePaymentMethodGroupBy>;
  groupByTransaction: Array<TransactionGroupBy>;
  groupByTxAddon: Array<TxAddonGroupBy>;
  groupByTxCancellationPolicy: Array<TxCancellationPolicyGroupBy>;
  groupByTxItem: Array<TxItemGroupBy>;
  groupByTxService: Array<TxServiceGroupBy>;
  groupByUser: Array<UserGroupBy>;
  groupByUserFavoriteLocation: Array<UserFavoriteLocationGroupBy>;
  location?: Maybe<Location>;
  locationAmenities: Array<LocationAmenity>;
  locationAmenity?: Maybe<LocationAmenity>;
  locations: Array<Location>;
  menu?: Maybe<Menu>;
  menuSection?: Maybe<MenuSection>;
  menuSectionService?: Maybe<MenuSectionService>;
  menuSectionServices: Array<MenuSectionService>;
  menuSections: Array<MenuSection>;
  menus: Array<Menu>;
  notification?: Maybe<Notification>;
  notifications: Array<Notification>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  platform?: Maybe<Platform>;
  platforms: Array<Platform>;
  refund?: Maybe<Refund>;
  refunds: Array<Refund>;
  reminderSetting?: Maybe<ReminderSetting>;
  reminderSettings: Array<ReminderSetting>;
  retrievePaymentIntentAndLocation: RetrievePaymentIntentAndLocationOutput;
  role?: Maybe<Role>;
  roles: Array<Role>;
  service?: Maybe<Service>;
  serviceAddOn?: Maybe<ServiceAddOn>;
  serviceAddOns: Array<ServiceAddOn>;
  services: Array<Service>;
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  stripePaymentMethods: Array<StripePaymentMethod>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  txAddon?: Maybe<TxAddon>;
  txAddons: Array<TxAddon>;
  txCancellationPolicies: Array<TxCancellationPolicy>;
  txCancellationPolicy?: Maybe<TxCancellationPolicy>;
  txItem?: Maybe<TxItem>;
  txItems: Array<TxItem>;
  txService?: Maybe<TxService>;
  txServices: Array<TxService>;
  user?: Maybe<User>;
  userFavoriteLocation?: Maybe<UserFavoriteLocation>;
  userFavoriteLocations: Array<UserFavoriteLocation>;
  users: Array<User>;
};

export type QueryAggregateAmenityArgs = {
  cursor?: InputMaybe<AmenityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AmenityWhereInput>;
};

export type QueryAggregateAppointmentArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type QueryAggregateAppointmentServiceArgs = {
  cursor?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AppointmentServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type QueryAggregateArtistArgs = {
  cursor?: InputMaybe<ArtistWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ArtistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistWhereInput>;
};

export type QueryAggregateArtistAvailabilityArgs = {
  cursor?: InputMaybe<ArtistAvailabilityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ArtistAvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type QueryAggregateArtistInstagramArgs = {
  cursor?: InputMaybe<ArtistInstagramWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ArtistInstagramOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistInstagramWhereInput>;
};

export type QueryAggregateArtistServiceExclusionArgs = {
  cursor?: InputMaybe<ArtistServiceExclusionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ArtistServiceExclusionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type QueryAggregateAvailabilityArgs = {
  cursor?: InputMaybe<AvailabilityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type QueryAggregateCompanyArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CompanyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyWhereInput>;
};

export type QueryAggregateCompanyAmenityArgs = {
  cursor?: InputMaybe<CompanyAmenityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CompanyAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type QueryAggregateCompanyPaymentMethodArgs = {
  cursor?: InputMaybe<CompanyPaymentMethodWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CompanyPaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type QueryAggregateCompanyUserDescriptionArgs = {
  cursor?: InputMaybe<CompanyUserDescriptionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CompanyUserDescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type QueryAggregateFacilityArgs = {
  cursor?: InputMaybe<FacilityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityWhereInput>;
};

export type QueryAggregateFacilityServiceArgs = {
  cursor?: InputMaybe<FacilityServiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FacilityServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type QueryAggregateFavoriteArtistArgs = {
  cursor?: InputMaybe<FavoriteArtistWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FavoriteArtistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type QueryAggregateGroupAvailabilityArgs = {
  cursor?: InputMaybe<GroupAvailabilityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<GroupAvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GroupAvailabilityWhereInput>;
};

export type QueryAggregateLocationArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryAggregateLocationAmenityArgs = {
  cursor?: InputMaybe<LocationAmenityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LocationAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type QueryAggregateMenuArgs = {
  cursor?: InputMaybe<MenuWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MenuOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuWhereInput>;
};

export type QueryAggregateMenuSectionArgs = {
  cursor?: InputMaybe<MenuSectionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MenuSectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type QueryAggregateMenuSectionServiceArgs = {
  cursor?: InputMaybe<MenuSectionServiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MenuSectionServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type QueryAggregateNotificationArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<NotificationWhereInput>;
};

export type QueryAggregateOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<OrderWhereInput>;
};

export type QueryAggregatePlatformArgs = {
  cursor?: InputMaybe<PlatformWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PlatformOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PlatformWhereInput>;
};

export type QueryAggregateRefundArgs = {
  cursor?: InputMaybe<RefundWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RefundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RefundWhereInput>;
};

export type QueryAggregateReminderSettingArgs = {
  cursor?: InputMaybe<ReminderSettingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReminderSettingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ReminderSettingWhereInput>;
};

export type QueryAggregateRoleArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RoleWhereInput>;
};

export type QueryAggregateServiceArgs = {
  cursor?: InputMaybe<ServiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceWhereInput>;
};

export type QueryAggregateServiceAddOnArgs = {
  cursor?: InputMaybe<ServiceAddOnWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ServiceAddOnOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type QueryAggregateStripePaymentMethodArgs = {
  cursor?: InputMaybe<StripePaymentMethodWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StripePaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type QueryAggregateTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryAggregateTxAddonArgs = {
  cursor?: InputMaybe<TxAddonWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TxAddonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxAddonWhereInput>;
};

export type QueryAggregateTxCancellationPolicyArgs = {
  cursor?: InputMaybe<TxCancellationPolicyWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TxCancellationPolicyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxCancellationPolicyWhereInput>;
};

export type QueryAggregateTxItemArgs = {
  cursor?: InputMaybe<TxItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TxItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxItemWhereInput>;
};

export type QueryAggregateTxServiceArgs = {
  cursor?: InputMaybe<TxServiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TxServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxServiceWhereInput>;
};

export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryAggregateUserFavoriteLocationArgs = {
  cursor?: InputMaybe<UserFavoriteLocationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserFavoriteLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type QueryAmenitiesArgs = {
  cursor?: InputMaybe<AmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AmenityWhereInput>;
};

export type QueryAmenityArgs = {
  where: AmenityWhereUniqueInput;
};

export type QueryAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};

export type QueryAppointmentServiceArgs = {
  where: AppointmentServiceWhereUniqueInput;
};

export type QueryAppointmentServicesArgs = {
  cursor?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type QueryAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type QueryArtistArgs = {
  where: ArtistWhereUniqueInput;
};

export type QueryArtistAvailabilitiesArgs = {
  cursor?: InputMaybe<ArtistAvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistAvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistAvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type QueryArtistAvailabilityArgs = {
  where: ArtistAvailabilityWhereUniqueInput;
};

export type QueryArtistInstagramArgs = {
  where: ArtistInstagramWhereUniqueInput;
};

export type QueryArtistInstagramsArgs = {
  cursor?: InputMaybe<ArtistInstagramWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistInstagramScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistInstagramOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistInstagramWhereInput>;
};

export type QueryArtistServiceExclusionArgs = {
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type QueryArtistServiceExclusionsArgs = {
  cursor?: InputMaybe<ArtistServiceExclusionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistServiceExclusionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistServiceExclusionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type QueryArtistsArgs = {
  cursor?: InputMaybe<ArtistWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistWhereInput>;
};

export type QueryAvailabilitiesArgs = {
  cursor?: InputMaybe<AvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type QueryAvailabilityArgs = {
  where: AvailabilityWhereUniqueInput;
};

export type QueryCompaniesArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyWhereInput>;
};

export type QueryCompanyArgs = {
  where: CompanyWhereUniqueInput;
};

export type QueryCompanyAmenitiesArgs = {
  cursor?: InputMaybe<CompanyAmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyAmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type QueryCompanyAmenityArgs = {
  where: CompanyAmenityWhereUniqueInput;
};

export type QueryCompanyPaymentMethodArgs = {
  where: CompanyPaymentMethodWhereUniqueInput;
};

export type QueryCompanyPaymentMethodsArgs = {
  cursor?: InputMaybe<CompanyPaymentMethodWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyPaymentMethodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyPaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type QueryCompanyUserDescriptionArgs = {
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type QueryCompanyUserDescriptionsArgs = {
  cursor?: InputMaybe<CompanyUserDescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyUserDescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyUserDescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type QueryFacilitiesArgs = {
  cursor?: InputMaybe<FacilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<FacilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityWhereInput>;
};

export type QueryFacilityArgs = {
  where: FacilityWhereUniqueInput;
};

export type QueryFacilityServiceArgs = {
  where: FacilityServiceWhereUniqueInput;
};

export type QueryFacilityServicesArgs = {
  cursor?: InputMaybe<FacilityServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<FacilityServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FacilityServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type QueryFavoriteArtistArgs = {
  where: FavoriteArtistWhereUniqueInput;
};

export type QueryFavoriteArtistsArgs = {
  cursor?: InputMaybe<FavoriteArtistWhereUniqueInput>;
  distinct?: InputMaybe<Array<FavoriteArtistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FavoriteArtistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type QueryFindFirstAmenityArgs = {
  cursor?: InputMaybe<AmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AmenityWhereInput>;
};

export type QueryFindFirstAmenityOrThrowArgs = {
  cursor?: InputMaybe<AmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AmenityWhereInput>;
};

export type QueryFindFirstAppointmentArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type QueryFindFirstAppointmentOrThrowArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type QueryFindFirstAppointmentServiceArgs = {
  cursor?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type QueryFindFirstAppointmentServiceOrThrowArgs = {
  cursor?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type QueryFindFirstArtistArgs = {
  cursor?: InputMaybe<ArtistWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistWhereInput>;
};

export type QueryFindFirstArtistAvailabilityArgs = {
  cursor?: InputMaybe<ArtistAvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistAvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistAvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type QueryFindFirstArtistAvailabilityOrThrowArgs = {
  cursor?: InputMaybe<ArtistAvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistAvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistAvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type QueryFindFirstArtistInstagramArgs = {
  cursor?: InputMaybe<ArtistInstagramWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistInstagramScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistInstagramOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistInstagramWhereInput>;
};

export type QueryFindFirstArtistInstagramOrThrowArgs = {
  cursor?: InputMaybe<ArtistInstagramWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistInstagramScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistInstagramOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistInstagramWhereInput>;
};

export type QueryFindFirstArtistOrThrowArgs = {
  cursor?: InputMaybe<ArtistWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistWhereInput>;
};

export type QueryFindFirstArtistServiceExclusionArgs = {
  cursor?: InputMaybe<ArtistServiceExclusionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistServiceExclusionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistServiceExclusionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type QueryFindFirstArtistServiceExclusionOrThrowArgs = {
  cursor?: InputMaybe<ArtistServiceExclusionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistServiceExclusionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistServiceExclusionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type QueryFindFirstAvailabilityArgs = {
  cursor?: InputMaybe<AvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type QueryFindFirstAvailabilityOrThrowArgs = {
  cursor?: InputMaybe<AvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type QueryFindFirstCompanyArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyWhereInput>;
};

export type QueryFindFirstCompanyAmenityArgs = {
  cursor?: InputMaybe<CompanyAmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyAmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type QueryFindFirstCompanyAmenityOrThrowArgs = {
  cursor?: InputMaybe<CompanyAmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyAmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type QueryFindFirstCompanyOrThrowArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyWhereInput>;
};

export type QueryFindFirstCompanyPaymentMethodArgs = {
  cursor?: InputMaybe<CompanyPaymentMethodWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyPaymentMethodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyPaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type QueryFindFirstCompanyPaymentMethodOrThrowArgs = {
  cursor?: InputMaybe<CompanyPaymentMethodWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyPaymentMethodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyPaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type QueryFindFirstCompanyUserDescriptionArgs = {
  cursor?: InputMaybe<CompanyUserDescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyUserDescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyUserDescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type QueryFindFirstCompanyUserDescriptionOrThrowArgs = {
  cursor?: InputMaybe<CompanyUserDescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyUserDescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyUserDescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type QueryFindFirstFacilityArgs = {
  cursor?: InputMaybe<FacilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<FacilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityWhereInput>;
};

export type QueryFindFirstFacilityOrThrowArgs = {
  cursor?: InputMaybe<FacilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<FacilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityWhereInput>;
};

export type QueryFindFirstFacilityServiceArgs = {
  cursor?: InputMaybe<FacilityServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<FacilityServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FacilityServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type QueryFindFirstFacilityServiceOrThrowArgs = {
  cursor?: InputMaybe<FacilityServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<FacilityServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FacilityServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type QueryFindFirstFavoriteArtistArgs = {
  cursor?: InputMaybe<FavoriteArtistWhereUniqueInput>;
  distinct?: InputMaybe<Array<FavoriteArtistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FavoriteArtistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type QueryFindFirstFavoriteArtistOrThrowArgs = {
  cursor?: InputMaybe<FavoriteArtistWhereUniqueInput>;
  distinct?: InputMaybe<Array<FavoriteArtistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FavoriteArtistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type QueryFindFirstGroupAvailabilityArgs = {
  cursor?: InputMaybe<GroupAvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupAvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupAvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GroupAvailabilityWhereInput>;
};

export type QueryFindFirstGroupAvailabilityOrThrowArgs = {
  cursor?: InputMaybe<GroupAvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupAvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupAvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GroupAvailabilityWhereInput>;
};

export type QueryFindFirstLocationArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryFindFirstLocationAmenityArgs = {
  cursor?: InputMaybe<LocationAmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationAmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type QueryFindFirstLocationAmenityOrThrowArgs = {
  cursor?: InputMaybe<LocationAmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationAmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type QueryFindFirstLocationOrThrowArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryFindFirstMenuArgs = {
  cursor?: InputMaybe<MenuWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuWhereInput>;
};

export type QueryFindFirstMenuOrThrowArgs = {
  cursor?: InputMaybe<MenuWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuWhereInput>;
};

export type QueryFindFirstMenuSectionArgs = {
  cursor?: InputMaybe<MenuSectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuSectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuSectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type QueryFindFirstMenuSectionOrThrowArgs = {
  cursor?: InputMaybe<MenuSectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuSectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuSectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type QueryFindFirstMenuSectionServiceArgs = {
  cursor?: InputMaybe<MenuSectionServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuSectionServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuSectionServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type QueryFindFirstMenuSectionServiceOrThrowArgs = {
  cursor?: InputMaybe<MenuSectionServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuSectionServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuSectionServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type QueryFindFirstNotificationArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<NotificationWhereInput>;
};

export type QueryFindFirstNotificationOrThrowArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<NotificationWhereInput>;
};

export type QueryFindFirstOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<OrderWhereInput>;
};

export type QueryFindFirstOrderOrThrowArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<OrderWhereInput>;
};

export type QueryFindFirstPlatformArgs = {
  cursor?: InputMaybe<PlatformWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlatformScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlatformOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PlatformWhereInput>;
};

export type QueryFindFirstPlatformOrThrowArgs = {
  cursor?: InputMaybe<PlatformWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlatformScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlatformOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PlatformWhereInput>;
};

export type QueryFindFirstRefundArgs = {
  cursor?: InputMaybe<RefundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RefundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RefundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RefundWhereInput>;
};

export type QueryFindFirstRefundOrThrowArgs = {
  cursor?: InputMaybe<RefundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RefundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RefundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RefundWhereInput>;
};

export type QueryFindFirstReminderSettingArgs = {
  cursor?: InputMaybe<ReminderSettingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReminderSettingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReminderSettingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ReminderSettingWhereInput>;
};

export type QueryFindFirstReminderSettingOrThrowArgs = {
  cursor?: InputMaybe<ReminderSettingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReminderSettingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReminderSettingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ReminderSettingWhereInput>;
};

export type QueryFindFirstRoleArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RoleWhereInput>;
};

export type QueryFindFirstRoleOrThrowArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RoleWhereInput>;
};

export type QueryFindFirstServiceArgs = {
  cursor?: InputMaybe<ServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceWhereInput>;
};

export type QueryFindFirstServiceAddOnArgs = {
  cursor?: InputMaybe<ServiceAddOnWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceAddOnScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceAddOnOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type QueryFindFirstServiceAddOnOrThrowArgs = {
  cursor?: InputMaybe<ServiceAddOnWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceAddOnScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceAddOnOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type QueryFindFirstServiceOrThrowArgs = {
  cursor?: InputMaybe<ServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceWhereInput>;
};

export type QueryFindFirstStripePaymentMethodArgs = {
  cursor?: InputMaybe<StripePaymentMethodWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripePaymentMethodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripePaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type QueryFindFirstStripePaymentMethodOrThrowArgs = {
  cursor?: InputMaybe<StripePaymentMethodWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripePaymentMethodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripePaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type QueryFindFirstTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryFindFirstTransactionOrThrowArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryFindFirstTxAddonArgs = {
  cursor?: InputMaybe<TxAddonWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxAddonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxAddonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxAddonWhereInput>;
};

export type QueryFindFirstTxAddonOrThrowArgs = {
  cursor?: InputMaybe<TxAddonWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxAddonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxAddonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxAddonWhereInput>;
};

export type QueryFindFirstTxCancellationPolicyArgs = {
  cursor?: InputMaybe<TxCancellationPolicyWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxCancellationPolicyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxCancellationPolicyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxCancellationPolicyWhereInput>;
};

export type QueryFindFirstTxCancellationPolicyOrThrowArgs = {
  cursor?: InputMaybe<TxCancellationPolicyWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxCancellationPolicyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxCancellationPolicyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxCancellationPolicyWhereInput>;
};

export type QueryFindFirstTxItemArgs = {
  cursor?: InputMaybe<TxItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxItemWhereInput>;
};

export type QueryFindFirstTxItemOrThrowArgs = {
  cursor?: InputMaybe<TxItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxItemWhereInput>;
};

export type QueryFindFirstTxServiceArgs = {
  cursor?: InputMaybe<TxServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxServiceWhereInput>;
};

export type QueryFindFirstTxServiceOrThrowArgs = {
  cursor?: InputMaybe<TxServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxServiceWhereInput>;
};

export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryFindFirstUserFavoriteLocationArgs = {
  cursor?: InputMaybe<UserFavoriteLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserFavoriteLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserFavoriteLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type QueryFindFirstUserFavoriteLocationOrThrowArgs = {
  cursor?: InputMaybe<UserFavoriteLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserFavoriteLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserFavoriteLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type QueryFindFirstUserOrThrowArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryGetAmenityArgs = {
  where: AmenityWhereUniqueInput;
};

export type QueryGetAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};

export type QueryGetAppointmentServiceArgs = {
  where: AppointmentServiceWhereUniqueInput;
};

export type QueryGetArtistArgs = {
  where: ArtistWhereUniqueInput;
};

export type QueryGetArtistAvailabilityArgs = {
  where: ArtistAvailabilityWhereUniqueInput;
};

export type QueryGetArtistInstagramArgs = {
  where: ArtistInstagramWhereUniqueInput;
};

export type QueryGetArtistServiceExclusionArgs = {
  where: ArtistServiceExclusionWhereUniqueInput;
};

export type QueryGetAvailabilityArgs = {
  where: AvailabilityWhereUniqueInput;
};

export type QueryGetCompanyArgs = {
  where: CompanyWhereUniqueInput;
};

export type QueryGetCompanyAmenityArgs = {
  where: CompanyAmenityWhereUniqueInput;
};

export type QueryGetCompanyPaymentMethodArgs = {
  where: CompanyPaymentMethodWhereUniqueInput;
};

export type QueryGetCompanyUserDescriptionArgs = {
  where: CompanyUserDescriptionWhereUniqueInput;
};

export type QueryGetFacilityArgs = {
  where: FacilityWhereUniqueInput;
};

export type QueryGetFacilityServiceArgs = {
  where: FacilityServiceWhereUniqueInput;
};

export type QueryGetFavoriteArtistArgs = {
  where: FavoriteArtistWhereUniqueInput;
};

export type QueryGetGroupAvailabilityArgs = {
  where: GroupAvailabilityWhereUniqueInput;
};

export type QueryGetLocationArgs = {
  where: LocationWhereUniqueInput;
};

export type QueryGetLocationAmenityArgs = {
  where: LocationAmenityWhereUniqueInput;
};

export type QueryGetMenuArgs = {
  where: MenuWhereUniqueInput;
};

export type QueryGetMenuSectionArgs = {
  where: MenuSectionWhereUniqueInput;
};

export type QueryGetMenuSectionServiceArgs = {
  where: MenuSectionServiceWhereUniqueInput;
};

export type QueryGetNotificationArgs = {
  where: NotificationWhereUniqueInput;
};

export type QueryGetOrderArgs = {
  where: OrderWhereUniqueInput;
};

export type QueryGetOrderDetailsArgs = {
  id: Scalars["String"];
};

export type QueryGetPlatformArgs = {
  where: PlatformWhereUniqueInput;
};

export type QueryGetRefundArgs = {
  where: RefundWhereUniqueInput;
};

export type QueryGetReminderSettingArgs = {
  where: ReminderSettingWhereUniqueInput;
};

export type QueryGetRoleArgs = {
  where: RoleWhereUniqueInput;
};

export type QueryGetServiceArgs = {
  where: ServiceWhereUniqueInput;
};

export type QueryGetServiceAddOnArgs = {
  where: ServiceAddOnWhereUniqueInput;
};

export type QueryGetStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
};

export type QueryGetTransactionArgs = {
  where: TransactionWhereUniqueInput;
};

export type QueryGetTxAddonArgs = {
  where: TxAddonWhereUniqueInput;
};

export type QueryGetTxCancellationPolicyArgs = {
  where: TxCancellationPolicyWhereUniqueInput;
};

export type QueryGetTxItemArgs = {
  where: TxItemWhereUniqueInput;
};

export type QueryGetTxServiceArgs = {
  where: TxServiceWhereUniqueInput;
};

export type QueryGetUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryGetUserFavoriteLocationArgs = {
  where: UserFavoriteLocationWhereUniqueInput;
};

export type QueryGroupAvailabilitiesArgs = {
  cursor?: InputMaybe<GroupAvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupAvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupAvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GroupAvailabilityWhereInput>;
};

export type QueryGroupAvailabilityArgs = {
  where: GroupAvailabilityWhereUniqueInput;
};

export type QueryGroupByAmenityArgs = {
  by: Array<AmenityScalarFieldEnum>;
  having?: InputMaybe<AmenityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AmenityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AmenityWhereInput>;
};

export type QueryGroupByAppointmentArgs = {
  by: Array<AppointmentScalarFieldEnum>;
  having?: InputMaybe<AppointmentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type QueryGroupByAppointmentServiceArgs = {
  by: Array<AppointmentServiceScalarFieldEnum>;
  having?: InputMaybe<AppointmentServiceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AppointmentServiceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type QueryGroupByArtistArgs = {
  by: Array<ArtistScalarFieldEnum>;
  having?: InputMaybe<ArtistScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ArtistOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistWhereInput>;
};

export type QueryGroupByArtistAvailabilityArgs = {
  by: Array<ArtistAvailabilityScalarFieldEnum>;
  having?: InputMaybe<ArtistAvailabilityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ArtistAvailabilityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistAvailabilityWhereInput>;
};

export type QueryGroupByArtistInstagramArgs = {
  by: Array<ArtistInstagramScalarFieldEnum>;
  having?: InputMaybe<ArtistInstagramScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ArtistInstagramOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistInstagramWhereInput>;
};

export type QueryGroupByArtistServiceExclusionArgs = {
  by: Array<ArtistServiceExclusionScalarFieldEnum>;
  having?: InputMaybe<ArtistServiceExclusionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<
    Array<ArtistServiceExclusionOrderByWithAggregationInput>
  >;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type QueryGroupByAvailabilityArgs = {
  by: Array<AvailabilityScalarFieldEnum>;
  having?: InputMaybe<AvailabilityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AvailabilityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type QueryGroupByCompanyArgs = {
  by: Array<CompanyScalarFieldEnum>;
  having?: InputMaybe<CompanyScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CompanyOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyWhereInput>;
};

export type QueryGroupByCompanyAmenityArgs = {
  by: Array<CompanyAmenityScalarFieldEnum>;
  having?: InputMaybe<CompanyAmenityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CompanyAmenityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyAmenityWhereInput>;
};

export type QueryGroupByCompanyPaymentMethodArgs = {
  by: Array<CompanyPaymentMethodScalarFieldEnum>;
  having?: InputMaybe<CompanyPaymentMethodScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CompanyPaymentMethodOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type QueryGroupByCompanyUserDescriptionArgs = {
  by: Array<CompanyUserDescriptionScalarFieldEnum>;
  having?: InputMaybe<CompanyUserDescriptionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<
    Array<CompanyUserDescriptionOrderByWithAggregationInput>
  >;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type QueryGroupByFacilityArgs = {
  by: Array<FacilityScalarFieldEnum>;
  having?: InputMaybe<FacilityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<FacilityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityWhereInput>;
};

export type QueryGroupByFacilityServiceArgs = {
  by: Array<FacilityServiceScalarFieldEnum>;
  having?: InputMaybe<FacilityServiceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<FacilityServiceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type QueryGroupByFavoriteArtistArgs = {
  by: Array<FavoriteArtistScalarFieldEnum>;
  having?: InputMaybe<FavoriteArtistScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<FavoriteArtistOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type QueryGroupByGroupAvailabilityArgs = {
  by: Array<GroupAvailabilityScalarFieldEnum>;
  having?: InputMaybe<GroupAvailabilityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<GroupAvailabilityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GroupAvailabilityWhereInput>;
};

export type QueryGroupByLocationArgs = {
  by: Array<LocationScalarFieldEnum>;
  having?: InputMaybe<LocationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LocationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryGroupByLocationAmenityArgs = {
  by: Array<LocationAmenityScalarFieldEnum>;
  having?: InputMaybe<LocationAmenityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LocationAmenityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type QueryGroupByMenuArgs = {
  by: Array<MenuScalarFieldEnum>;
  having?: InputMaybe<MenuScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MenuOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuWhereInput>;
};

export type QueryGroupByMenuSectionArgs = {
  by: Array<MenuSectionScalarFieldEnum>;
  having?: InputMaybe<MenuSectionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MenuSectionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type QueryGroupByMenuSectionServiceArgs = {
  by: Array<MenuSectionServiceScalarFieldEnum>;
  having?: InputMaybe<MenuSectionServiceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MenuSectionServiceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type QueryGroupByNotificationArgs = {
  by: Array<NotificationScalarFieldEnum>;
  having?: InputMaybe<NotificationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<NotificationWhereInput>;
};

export type QueryGroupByOrderArgs = {
  by: Array<OrderScalarFieldEnum>;
  having?: InputMaybe<OrderScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<OrderWhereInput>;
};

export type QueryGroupByPlatformArgs = {
  by: Array<PlatformScalarFieldEnum>;
  having?: InputMaybe<PlatformScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PlatformOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PlatformWhereInput>;
};

export type QueryGroupByRefundArgs = {
  by: Array<RefundScalarFieldEnum>;
  having?: InputMaybe<RefundScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RefundOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RefundWhereInput>;
};

export type QueryGroupByReminderSettingArgs = {
  by: Array<ReminderSettingScalarFieldEnum>;
  having?: InputMaybe<ReminderSettingScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ReminderSettingOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ReminderSettingWhereInput>;
};

export type QueryGroupByRoleArgs = {
  by: Array<RoleScalarFieldEnum>;
  having?: InputMaybe<RoleScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RoleOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RoleWhereInput>;
};

export type QueryGroupByServiceArgs = {
  by: Array<ServiceScalarFieldEnum>;
  having?: InputMaybe<ServiceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ServiceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceWhereInput>;
};

export type QueryGroupByServiceAddOnArgs = {
  by: Array<ServiceAddOnScalarFieldEnum>;
  having?: InputMaybe<ServiceAddOnScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ServiceAddOnOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type QueryGroupByStripePaymentMethodArgs = {
  by: Array<StripePaymentMethodScalarFieldEnum>;
  having?: InputMaybe<StripePaymentMethodScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StripePaymentMethodOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type QueryGroupByTransactionArgs = {
  by: Array<TransactionScalarFieldEnum>;
  having?: InputMaybe<TransactionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryGroupByTxAddonArgs = {
  by: Array<TxAddonScalarFieldEnum>;
  having?: InputMaybe<TxAddonScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TxAddonOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxAddonWhereInput>;
};

export type QueryGroupByTxCancellationPolicyArgs = {
  by: Array<TxCancellationPolicyScalarFieldEnum>;
  having?: InputMaybe<TxCancellationPolicyScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TxCancellationPolicyOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxCancellationPolicyWhereInput>;
};

export type QueryGroupByTxItemArgs = {
  by: Array<TxItemScalarFieldEnum>;
  having?: InputMaybe<TxItemScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TxItemOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxItemWhereInput>;
};

export type QueryGroupByTxServiceArgs = {
  by: Array<TxServiceScalarFieldEnum>;
  having?: InputMaybe<TxServiceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TxServiceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxServiceWhereInput>;
};

export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryGroupByUserFavoriteLocationArgs = {
  by: Array<UserFavoriteLocationScalarFieldEnum>;
  having?: InputMaybe<UserFavoriteLocationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserFavoriteLocationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type QueryLocationArgs = {
  where: LocationWhereUniqueInput;
};

export type QueryLocationAmenitiesArgs = {
  cursor?: InputMaybe<LocationAmenityWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationAmenityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationAmenityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationAmenityWhereInput>;
};

export type QueryLocationAmenityArgs = {
  where: LocationAmenityWhereUniqueInput;
};

export type QueryLocationsArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryMenuArgs = {
  where: MenuWhereUniqueInput;
};

export type QueryMenuSectionArgs = {
  where: MenuSectionWhereUniqueInput;
};

export type QueryMenuSectionServiceArgs = {
  where: MenuSectionServiceWhereUniqueInput;
};

export type QueryMenuSectionServicesArgs = {
  cursor?: InputMaybe<MenuSectionServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuSectionServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuSectionServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type QueryMenuSectionsArgs = {
  cursor?: InputMaybe<MenuSectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuSectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuSectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionWhereInput>;
};

export type QueryMenusArgs = {
  cursor?: InputMaybe<MenuWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuWhereInput>;
};

export type QueryNotificationArgs = {
  where: NotificationWhereUniqueInput;
};

export type QueryNotificationsArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<NotificationWhereInput>;
};

export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};

export type QueryOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<OrderWhereInput>;
};

export type QueryPlatformArgs = {
  where: PlatformWhereUniqueInput;
};

export type QueryPlatformsArgs = {
  cursor?: InputMaybe<PlatformWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlatformScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlatformOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PlatformWhereInput>;
};

export type QueryRefundArgs = {
  where: RefundWhereUniqueInput;
};

export type QueryRefundsArgs = {
  cursor?: InputMaybe<RefundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RefundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RefundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RefundWhereInput>;
};

export type QueryReminderSettingArgs = {
  where: ReminderSettingWhereUniqueInput;
};

export type QueryReminderSettingsArgs = {
  cursor?: InputMaybe<ReminderSettingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReminderSettingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReminderSettingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ReminderSettingWhereInput>;
};

export type QueryRetrievePaymentIntentAndLocationArgs = {
  id: Scalars["String"];
};

export type QueryRoleArgs = {
  where: RoleWhereUniqueInput;
};

export type QueryRolesArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RoleWhereInput>;
};

export type QueryServiceArgs = {
  where: ServiceWhereUniqueInput;
};

export type QueryServiceAddOnArgs = {
  where: ServiceAddOnWhereUniqueInput;
};

export type QueryServiceAddOnsArgs = {
  cursor?: InputMaybe<ServiceAddOnWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceAddOnScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceAddOnOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type QueryServicesArgs = {
  cursor?: InputMaybe<ServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceWhereInput>;
};

export type QueryStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
};

export type QueryStripePaymentMethodsArgs = {
  cursor?: InputMaybe<StripePaymentMethodWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripePaymentMethodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripePaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type QueryTransactionArgs = {
  where: TransactionWhereUniqueInput;
};

export type QueryTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryTxAddonArgs = {
  where: TxAddonWhereUniqueInput;
};

export type QueryTxAddonsArgs = {
  cursor?: InputMaybe<TxAddonWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxAddonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxAddonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxAddonWhereInput>;
};

export type QueryTxCancellationPoliciesArgs = {
  cursor?: InputMaybe<TxCancellationPolicyWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxCancellationPolicyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxCancellationPolicyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxCancellationPolicyWhereInput>;
};

export type QueryTxCancellationPolicyArgs = {
  where: TxCancellationPolicyWhereUniqueInput;
};

export type QueryTxItemArgs = {
  where: TxItemWhereUniqueInput;
};

export type QueryTxItemsArgs = {
  cursor?: InputMaybe<TxItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxItemWhereInput>;
};

export type QueryTxServiceArgs = {
  where: TxServiceWhereUniqueInput;
};

export type QueryTxServicesArgs = {
  cursor?: InputMaybe<TxServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxServiceWhereInput>;
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryUserFavoriteLocationArgs = {
  where: UserFavoriteLocationWhereUniqueInput;
};

export type QueryUserFavoriteLocationsArgs = {
  cursor?: InputMaybe<UserFavoriteLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserFavoriteLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserFavoriteLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = "default",
  Insensitive = "insensitive"
}

export type Refund = {
  __typename?: "Refund";
  amount: Scalars["Float"];
  appointment?: Maybe<Appointment>;
  appointment_id?: Maybe<Scalars["String"]>;
  company: Company;
  company_id: Scalars["String"];
  consumed: Scalars["Boolean"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  transaction?: Maybe<Transaction>;
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
};

export type RefundAvgAggregate = {
  __typename?: "RefundAvgAggregate";
  amount?: Maybe<Scalars["Float"]>;
};

export type RefundAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type RefundCountAggregate = {
  __typename?: "RefundCountAggregate";
  _all: Scalars["Int"];
  amount: Scalars["Int"];
  appointment_id: Scalars["Int"];
  company_id: Scalars["Int"];
  consumed: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type RefundCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  appointment_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  consumed?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type RefundCreateInput = {
  amount: Scalars["Float"];
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutRefundInput>;
  company: CompanyCreateNestedOneWithoutRefundInput;
  consumed: Scalars["Boolean"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  transaction?: InputMaybe<TransactionCreateNestedOneWithoutRefundInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutRefundInput;
};

export type RefundCreateManyAppointmentInput = {
  amount: Scalars["Float"];
  company_id: Scalars["String"];
  consumed: Scalars["Boolean"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type RefundCreateManyAppointmentInputEnvelope = {
  data: Array<RefundCreateManyAppointmentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RefundCreateManyCompanyInput = {
  amount: Scalars["Float"];
  appointment_id?: InputMaybe<Scalars["String"]>;
  consumed: Scalars["Boolean"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type RefundCreateManyCompanyInputEnvelope = {
  data: Array<RefundCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RefundCreateManyInput = {
  amount: Scalars["Float"];
  appointment_id?: InputMaybe<Scalars["String"]>;
  company_id: Scalars["String"];
  consumed: Scalars["Boolean"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type RefundCreateManyUserInput = {
  amount: Scalars["Float"];
  appointment_id?: InputMaybe<Scalars["String"]>;
  company_id: Scalars["String"];
  consumed: Scalars["Boolean"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type RefundCreateManyUserInputEnvelope = {
  data: Array<RefundCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RefundCreateNestedManyWithoutAppointmentInput = {
  connect?: InputMaybe<Array<RefundWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RefundCreateOrConnectWithoutAppointmentInput>
  >;
  create?: InputMaybe<Array<RefundCreateWithoutAppointmentInput>>;
  createMany?: InputMaybe<RefundCreateManyAppointmentInputEnvelope>;
};

export type RefundCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<RefundWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RefundCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<RefundCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<RefundCreateManyCompanyInputEnvelope>;
};

export type RefundCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<RefundWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RefundCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<RefundCreateWithoutUserInput>>;
  createMany?: InputMaybe<RefundCreateManyUserInputEnvelope>;
};

export type RefundCreateNestedOneWithoutTransactionInput = {
  connect?: InputMaybe<RefundWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RefundCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<RefundCreateWithoutTransactionInput>;
};

export type RefundCreateOrConnectWithoutAppointmentInput = {
  create: RefundCreateWithoutAppointmentInput;
  where: RefundWhereUniqueInput;
};

export type RefundCreateOrConnectWithoutCompanyInput = {
  create: RefundCreateWithoutCompanyInput;
  where: RefundWhereUniqueInput;
};

export type RefundCreateOrConnectWithoutTransactionInput = {
  create: RefundCreateWithoutTransactionInput;
  where: RefundWhereUniqueInput;
};

export type RefundCreateOrConnectWithoutUserInput = {
  create: RefundCreateWithoutUserInput;
  where: RefundWhereUniqueInput;
};

export type RefundCreateWithoutAppointmentInput = {
  amount: Scalars["Float"];
  company: CompanyCreateNestedOneWithoutRefundInput;
  consumed: Scalars["Boolean"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  transaction?: InputMaybe<TransactionCreateNestedOneWithoutRefundInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutRefundInput;
};

export type RefundCreateWithoutCompanyInput = {
  amount: Scalars["Float"];
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutRefundInput>;
  consumed: Scalars["Boolean"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  transaction?: InputMaybe<TransactionCreateNestedOneWithoutRefundInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutRefundInput;
};

export type RefundCreateWithoutTransactionInput = {
  amount: Scalars["Float"];
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutRefundInput>;
  company: CompanyCreateNestedOneWithoutRefundInput;
  consumed: Scalars["Boolean"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutRefundInput;
};

export type RefundCreateWithoutUserInput = {
  amount: Scalars["Float"];
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutRefundInput>;
  company: CompanyCreateNestedOneWithoutRefundInput;
  consumed: Scalars["Boolean"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  transaction?: InputMaybe<TransactionCreateNestedOneWithoutRefundInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type RefundGroupBy = {
  __typename?: "RefundGroupBy";
  _avg?: Maybe<RefundAvgAggregate>;
  _count?: Maybe<RefundCountAggregate>;
  _max?: Maybe<RefundMaxAggregate>;
  _min?: Maybe<RefundMinAggregate>;
  _sum?: Maybe<RefundSumAggregate>;
  amount: Scalars["Float"];
  appointment_id?: Maybe<Scalars["String"]>;
  company_id: Scalars["String"];
  consumed: Scalars["Boolean"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
};

export type RefundListRelationFilter = {
  every?: InputMaybe<RefundWhereInput>;
  none?: InputMaybe<RefundWhereInput>;
  some?: InputMaybe<RefundWhereInput>;
};

export type RefundMaxAggregate = {
  __typename?: "RefundMaxAggregate";
  amount?: Maybe<Scalars["Float"]>;
  appointment_id?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["String"]>;
  consumed?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type RefundMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  appointment_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  consumed?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type RefundMinAggregate = {
  __typename?: "RefundMinAggregate";
  amount?: Maybe<Scalars["Float"]>;
  appointment_id?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["String"]>;
  consumed?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type RefundMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  appointment_id?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  consumed?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type RefundOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RefundOrderByWithAggregationInput = {
  _avg?: InputMaybe<RefundAvgOrderByAggregateInput>;
  _count?: InputMaybe<RefundCountOrderByAggregateInput>;
  _max?: InputMaybe<RefundMaxOrderByAggregateInput>;
  _min?: InputMaybe<RefundMinOrderByAggregateInput>;
  _sum?: InputMaybe<RefundSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  appointment_id?: InputMaybe<SortOrderInput>;
  company_id?: InputMaybe<SortOrder>;
  consumed?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type RefundOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  appointment?: InputMaybe<AppointmentOrderByWithRelationInput>;
  appointment_id?: InputMaybe<SortOrderInput>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  company_id?: InputMaybe<SortOrder>;
  consumed?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transaction?: InputMaybe<TransactionOrderByWithRelationInput>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export type RefundRelationFilter = {
  is?: InputMaybe<RefundWhereInput>;
  isNot?: InputMaybe<RefundWhereInput>;
};

export enum RefundScalarFieldEnum {
  Amount = "amount",
  AppointmentId = "appointment_id",
  CompanyId = "company_id",
  Consumed = "consumed",
  CreatedAt = "created_at",
  Id = "id",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type RefundScalarWhereInput = {
  AND?: InputMaybe<Array<RefundScalarWhereInput>>;
  NOT?: InputMaybe<Array<RefundScalarWhereInput>>;
  OR?: InputMaybe<Array<RefundScalarWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  appointment_id?: InputMaybe<UuidNullableFilter>;
  company_id?: InputMaybe<UuidFilter>;
  consumed?: InputMaybe<BoolFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type RefundScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RefundScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RefundScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RefundScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<FloatWithAggregatesFilter>;
  appointment_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  company_id?: InputMaybe<UuidWithAggregatesFilter>;
  consumed?: InputMaybe<BoolWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export type RefundSumAggregate = {
  __typename?: "RefundSumAggregate";
  amount?: Maybe<Scalars["Float"]>;
};

export type RefundSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type RefundUpdateInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutRefundNestedInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutRefundNestedInput>;
  consumed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  transaction?: InputMaybe<TransactionUpdateOneWithoutRefundNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutRefundNestedInput>;
};

export type RefundUpdateManyMutationInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  consumed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RefundUpdateManyWithWhereWithoutAppointmentInput = {
  data: RefundUpdateManyMutationInput;
  where: RefundScalarWhereInput;
};

export type RefundUpdateManyWithWhereWithoutCompanyInput = {
  data: RefundUpdateManyMutationInput;
  where: RefundScalarWhereInput;
};

export type RefundUpdateManyWithWhereWithoutUserInput = {
  data: RefundUpdateManyMutationInput;
  where: RefundScalarWhereInput;
};

export type RefundUpdateManyWithoutAppointmentNestedInput = {
  connect?: InputMaybe<Array<RefundWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RefundCreateOrConnectWithoutAppointmentInput>
  >;
  create?: InputMaybe<Array<RefundCreateWithoutAppointmentInput>>;
  createMany?: InputMaybe<RefundCreateManyAppointmentInputEnvelope>;
  delete?: InputMaybe<Array<RefundWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RefundScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RefundWhereUniqueInput>>;
  set?: InputMaybe<Array<RefundWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RefundUpdateWithWhereUniqueWithoutAppointmentInput>
  >;
  updateMany?: InputMaybe<
    Array<RefundUpdateManyWithWhereWithoutAppointmentInput>
  >;
  upsert?: InputMaybe<
    Array<RefundUpsertWithWhereUniqueWithoutAppointmentInput>
  >;
};

export type RefundUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<RefundWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RefundCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<RefundCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<RefundCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<RefundWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RefundScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RefundWhereUniqueInput>>;
  set?: InputMaybe<Array<RefundWhereUniqueInput>>;
  update?: InputMaybe<Array<RefundUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<RefundUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<RefundUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type RefundUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<RefundWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RefundCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<RefundCreateWithoutUserInput>>;
  createMany?: InputMaybe<RefundCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<RefundWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RefundScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RefundWhereUniqueInput>>;
  set?: InputMaybe<Array<RefundWhereUniqueInput>>;
  update?: InputMaybe<Array<RefundUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<RefundUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<RefundUpsertWithWhereUniqueWithoutUserInput>>;
};

export type RefundUpdateOneWithoutTransactionNestedInput = {
  connect?: InputMaybe<RefundWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RefundCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<RefundCreateWithoutTransactionInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<RefundUpdateWithoutTransactionInput>;
  upsert?: InputMaybe<RefundUpsertWithoutTransactionInput>;
};

export type RefundUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: RefundUpdateWithoutAppointmentInput;
  where: RefundWhereUniqueInput;
};

export type RefundUpdateWithWhereUniqueWithoutCompanyInput = {
  data: RefundUpdateWithoutCompanyInput;
  where: RefundWhereUniqueInput;
};

export type RefundUpdateWithWhereUniqueWithoutUserInput = {
  data: RefundUpdateWithoutUserInput;
  where: RefundWhereUniqueInput;
};

export type RefundUpdateWithoutAppointmentInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutRefundNestedInput>;
  consumed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  transaction?: InputMaybe<TransactionUpdateOneWithoutRefundNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutRefundNestedInput>;
};

export type RefundUpdateWithoutCompanyInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutRefundNestedInput>;
  consumed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  transaction?: InputMaybe<TransactionUpdateOneWithoutRefundNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutRefundNestedInput>;
};

export type RefundUpdateWithoutTransactionInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutRefundNestedInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutRefundNestedInput>;
  consumed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutRefundNestedInput>;
};

export type RefundUpdateWithoutUserInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  appointment?: InputMaybe<AppointmentUpdateOneWithoutRefundNestedInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutRefundNestedInput>;
  consumed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  transaction?: InputMaybe<TransactionUpdateOneWithoutRefundNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RefundUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: RefundCreateWithoutAppointmentInput;
  update: RefundUpdateWithoutAppointmentInput;
  where: RefundWhereUniqueInput;
};

export type RefundUpsertWithWhereUniqueWithoutCompanyInput = {
  create: RefundCreateWithoutCompanyInput;
  update: RefundUpdateWithoutCompanyInput;
  where: RefundWhereUniqueInput;
};

export type RefundUpsertWithWhereUniqueWithoutUserInput = {
  create: RefundCreateWithoutUserInput;
  update: RefundUpdateWithoutUserInput;
  where: RefundWhereUniqueInput;
};

export type RefundUpsertWithoutTransactionInput = {
  create: RefundCreateWithoutTransactionInput;
  update: RefundUpdateWithoutTransactionInput;
};

export type RefundWhereInput = {
  AND?: InputMaybe<Array<RefundWhereInput>>;
  NOT?: InputMaybe<Array<RefundWhereInput>>;
  OR?: InputMaybe<Array<RefundWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  appointment?: InputMaybe<AppointmentRelationFilter>;
  appointment_id?: InputMaybe<UuidNullableFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  company_id?: InputMaybe<UuidFilter>;
  consumed?: InputMaybe<BoolFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  transaction?: InputMaybe<TransactionRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type RefundWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type ReminderSetting = {
  __typename?: "ReminderSetting";
  created_at: Scalars["DateTime"];
  enabled: Scalars["Boolean"];
  id: Scalars["String"];
  type: ReminderType;
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
  value: Scalars["String"];
};

export type ReminderSettingCountAggregate = {
  __typename?: "ReminderSettingCountAggregate";
  _all: Scalars["Int"];
  created_at: Scalars["Int"];
  enabled: Scalars["Int"];
  id: Scalars["Int"];
  type: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
  value: Scalars["Int"];
};

export type ReminderSettingCountOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ReminderSettingCreateInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  enabled: Scalars["Boolean"];
  id?: InputMaybe<Scalars["String"]>;
  type: ReminderType;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutReminder_SettingsInput;
  value: Scalars["String"];
};

export type ReminderSettingCreateManyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  enabled: Scalars["Boolean"];
  id?: InputMaybe<Scalars["String"]>;
  type: ReminderType;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
  value: Scalars["String"];
};

export type ReminderSettingCreateManyUserInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  enabled: Scalars["Boolean"];
  id?: InputMaybe<Scalars["String"]>;
  type: ReminderType;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  value: Scalars["String"];
};

export type ReminderSettingCreateManyUserInputEnvelope = {
  data: Array<ReminderSettingCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ReminderSettingCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ReminderSettingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ReminderSettingCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<ReminderSettingCreateWithoutUserInput>>;
  createMany?: InputMaybe<ReminderSettingCreateManyUserInputEnvelope>;
};

export type ReminderSettingCreateOrConnectWithoutUserInput = {
  create: ReminderSettingCreateWithoutUserInput;
  where: ReminderSettingWhereUniqueInput;
};

export type ReminderSettingCreateWithoutUserInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  enabled: Scalars["Boolean"];
  id?: InputMaybe<Scalars["String"]>;
  type: ReminderType;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  value: Scalars["String"];
};

export type ReminderSettingGroupBy = {
  __typename?: "ReminderSettingGroupBy";
  _count?: Maybe<ReminderSettingCountAggregate>;
  _max?: Maybe<ReminderSettingMaxAggregate>;
  _min?: Maybe<ReminderSettingMinAggregate>;
  created_at: Scalars["DateTime"];
  enabled: Scalars["Boolean"];
  id: Scalars["String"];
  type: ReminderType;
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
  value: Scalars["String"];
};

export type ReminderSettingListRelationFilter = {
  every?: InputMaybe<ReminderSettingWhereInput>;
  none?: InputMaybe<ReminderSettingWhereInput>;
  some?: InputMaybe<ReminderSettingWhereInput>;
};

export type ReminderSettingMaxAggregate = {
  __typename?: "ReminderSettingMaxAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<ReminderType>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type ReminderSettingMaxOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ReminderSettingMinAggregate = {
  __typename?: "ReminderSettingMinAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<ReminderType>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type ReminderSettingMinOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ReminderSettingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReminderSettingOrderByWithAggregationInput = {
  _count?: InputMaybe<ReminderSettingCountOrderByAggregateInput>;
  _max?: InputMaybe<ReminderSettingMaxOrderByAggregateInput>;
  _min?: InputMaybe<ReminderSettingMinOrderByAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ReminderSettingOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum ReminderSettingScalarFieldEnum {
  CreatedAt = "created_at",
  Enabled = "enabled",
  Id = "id",
  Type = "type",
  UpdatedAt = "updated_at",
  UserId = "user_id",
  Value = "value"
}

export type ReminderSettingScalarWhereInput = {
  AND?: InputMaybe<Array<ReminderSettingScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReminderSettingScalarWhereInput>>;
  OR?: InputMaybe<Array<ReminderSettingScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<UuidFilter>;
  type?: InputMaybe<EnumReminderTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
  value?: InputMaybe<StringFilter>;
};

export type ReminderSettingScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ReminderSettingScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ReminderSettingScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ReminderSettingScalarWhereWithAggregatesInput>>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  enabled?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  type?: InputMaybe<EnumReminderTypeWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
  value?: InputMaybe<StringWithAggregatesFilter>;
};

export type ReminderSettingUpdateInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumReminderTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutReminder_SettingsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ReminderSettingUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumReminderTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ReminderSettingUpdateManyWithWhereWithoutUserInput = {
  data: ReminderSettingUpdateManyMutationInput;
  where: ReminderSettingScalarWhereInput;
};

export type ReminderSettingUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ReminderSettingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ReminderSettingCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<ReminderSettingCreateWithoutUserInput>>;
  createMany?: InputMaybe<ReminderSettingCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ReminderSettingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReminderSettingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReminderSettingWhereUniqueInput>>;
  set?: InputMaybe<Array<ReminderSettingWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ReminderSettingUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<ReminderSettingUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<ReminderSettingUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type ReminderSettingUpdateWithWhereUniqueWithoutUserInput = {
  data: ReminderSettingUpdateWithoutUserInput;
  where: ReminderSettingWhereUniqueInput;
};

export type ReminderSettingUpdateWithoutUserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumReminderTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ReminderSettingUpsertWithWhereUniqueWithoutUserInput = {
  create: ReminderSettingCreateWithoutUserInput;
  update: ReminderSettingUpdateWithoutUserInput;
  where: ReminderSettingWhereUniqueInput;
};

export type ReminderSettingWhereInput = {
  AND?: InputMaybe<Array<ReminderSettingWhereInput>>;
  NOT?: InputMaybe<Array<ReminderSettingWhereInput>>;
  OR?: InputMaybe<Array<ReminderSettingWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<UuidFilter>;
  type?: InputMaybe<EnumReminderTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
  value?: InputMaybe<StringFilter>;
};

export type ReminderSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export enum ReminderType {
  Email = "EMAIL",
  Sms = "SMS"
}

export type RetrievePaymentIntentAndLocationOutput = {
  __typename?: "RetrievePaymentIntentAndLocationOutput";
  location: TerminalLocation;
  paymentIntent: PaymentIntent;
};

export type Role = {
  __typename?: "Role";
  company?: Maybe<Company>;
  company_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  role: Roles;
  status: RoleStatus;
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
};

export type RoleCompany_IdUser_IdCompoundUniqueInput = {
  company_id: Scalars["String"];
  user_id: Scalars["String"];
};

export type RoleCountAggregate = {
  __typename?: "RoleCountAggregate";
  _all: Scalars["Int"];
  company_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  role: Scalars["Int"];
  status: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type RoleCountOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type RoleCreateInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutRolesInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  role: Roles;
  status?: InputMaybe<RoleStatus>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutRoleInput;
};

export type RoleCreateManyCompanyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  role: Roles;
  status?: InputMaybe<RoleStatus>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type RoleCreateManyCompanyInputEnvelope = {
  data: Array<RoleCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RoleCreateManyInput = {
  company_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  role: Roles;
  status?: InputMaybe<RoleStatus>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type RoleCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<RoleCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<RoleCreateManyCompanyInputEnvelope>;
};

export type RoleCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<RoleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoleCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<RoleCreateWithoutUserInput>;
};

export type RoleCreateOrConnectWithoutCompanyInput = {
  create: RoleCreateWithoutCompanyInput;
  where: RoleWhereUniqueInput;
};

export type RoleCreateOrConnectWithoutUserInput = {
  create: RoleCreateWithoutUserInput;
  where: RoleWhereUniqueInput;
};

export type RoleCreateWithoutCompanyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  role: Roles;
  status?: InputMaybe<RoleStatus>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutRoleInput;
};

export type RoleCreateWithoutUserInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutRolesInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  role: Roles;
  status?: InputMaybe<RoleStatus>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type RoleGroupBy = {
  __typename?: "RoleGroupBy";
  _count?: Maybe<RoleCountAggregate>;
  _max?: Maybe<RoleMaxAggregate>;
  _min?: Maybe<RoleMinAggregate>;
  company_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  role: Roles;
  status: RoleStatus;
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
};

export type RoleListRelationFilter = {
  every?: InputMaybe<RoleWhereInput>;
  none?: InputMaybe<RoleWhereInput>;
  some?: InputMaybe<RoleWhereInput>;
};

export type RoleMaxAggregate = {
  __typename?: "RoleMaxAggregate";
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  role?: Maybe<Roles>;
  status?: Maybe<RoleStatus>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type RoleMaxOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type RoleMinAggregate = {
  __typename?: "RoleMinAggregate";
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  role?: Maybe<Roles>;
  status?: Maybe<RoleStatus>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type RoleMinOrderByAggregateInput = {
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type RoleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RoleOrderByWithAggregationInput = {
  _count?: InputMaybe<RoleCountOrderByAggregateInput>;
  _max?: InputMaybe<RoleMaxOrderByAggregateInput>;
  _min?: InputMaybe<RoleMinOrderByAggregateInput>;
  company_id?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type RoleOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  company_id?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export type RoleRelationFilter = {
  is?: InputMaybe<RoleWhereInput>;
  isNot?: InputMaybe<RoleWhereInput>;
};

export enum RoleScalarFieldEnum {
  CompanyId = "company_id",
  CreatedAt = "created_at",
  Id = "id",
  Role = "role",
  Status = "status",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type RoleScalarWhereInput = {
  AND?: InputMaybe<Array<RoleScalarWhereInput>>;
  NOT?: InputMaybe<Array<RoleScalarWhereInput>>;
  OR?: InputMaybe<Array<RoleScalarWhereInput>>;
  company_id?: InputMaybe<UuidNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  role?: InputMaybe<EnumRolesFilter>;
  status?: InputMaybe<EnumRoleStatusFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type RoleScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RoleScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RoleScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RoleScalarWhereWithAggregatesInput>>;
  company_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  role?: InputMaybe<EnumRolesWithAggregatesFilter>;
  status?: InputMaybe<EnumRoleStatusWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export enum RoleStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE"
}

export type RoleUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutRolesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRolesFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumRoleStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutRoleNestedInput>;
};

export type RoleUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRolesFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumRoleStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RoleUpdateManyWithWhereWithoutCompanyInput = {
  data: RoleUpdateManyMutationInput;
  where: RoleScalarWhereInput;
};

export type RoleUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<RoleCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<RoleCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<RoleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  set?: InputMaybe<Array<RoleWhereUniqueInput>>;
  update?: InputMaybe<Array<RoleUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<RoleUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<RoleUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type RoleUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<RoleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoleCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<RoleCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<RoleUpdateWithoutUserInput>;
  upsert?: InputMaybe<RoleUpsertWithoutUserInput>;
};

export type RoleUpdateWithWhereUniqueWithoutCompanyInput = {
  data: RoleUpdateWithoutCompanyInput;
  where: RoleWhereUniqueInput;
};

export type RoleUpdateWithoutCompanyInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRolesFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumRoleStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutRoleNestedInput>;
};

export type RoleUpdateWithoutUserInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutRolesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRolesFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumRoleStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RoleUpsertWithWhereUniqueWithoutCompanyInput = {
  create: RoleCreateWithoutCompanyInput;
  update: RoleUpdateWithoutCompanyInput;
  where: RoleWhereUniqueInput;
};

export type RoleUpsertWithoutUserInput = {
  create: RoleCreateWithoutUserInput;
  update: RoleUpdateWithoutUserInput;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  company_id?: InputMaybe<UuidNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  role?: InputMaybe<EnumRolesFilter>;
  status?: InputMaybe<EnumRoleStatusFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type RoleWhereUniqueInput = {
  company_id_user_id?: InputMaybe<RoleCompany_IdUser_IdCompoundUniqueInput>;
  id?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["String"]>;
};

export enum Roles {
  Artist = "ARTIST",
  Manager = "MANAGER",
  Owner = "OWNER",
  Super = "SUPER",
  User = "USER"
}

export enum SchedulingPriority {
  After = "AFTER",
  Before = "BEFORE",
  During = "DURING"
}

export type Service = {
  __typename?: "Service";
  _count?: Maybe<ServiceCount>;
  add_ons: Array<ServiceAddOn>;
  appointment_services: Array<AppointmentService>;
  artist_exclusions: Array<ArtistServiceExclusion>;
  category: ServiceCategory;
  choice_is_required: Scalars["Boolean"];
  clean_up_time?: Maybe<Scalars["Int"]>;
  companies: Array<Company>;
  company_id: Scalars["String"];
  core_services: Array<ServiceAddOn>;
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  facilities: Array<FacilityService>;
  id: Scalars["String"];
  is_addon: Scalars["Boolean"];
  menu_sections: Array<MenuSectionService>;
  name: Scalars["String"];
  photo_1_url?: Maybe<Scalars["String"]>;
  photo_2_url?: Maybe<Scalars["String"]>;
  photo_3_url?: Maybe<Scalars["String"]>;
  photo_4_url?: Maybe<Scalars["String"]>;
  photo_5_url?: Maybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: Maybe<SchedulingPriority>;
  special_conditions?: Maybe<Scalars["String"]>;
  tx_addons: Array<TxAddon>;
  tx_services: Array<TxService>;
  updated_at: Scalars["DateTime"];
};

export type ServiceAdd_OnsArgs = {
  cursor?: InputMaybe<ServiceAddOnWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceAddOnScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceAddOnOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type ServiceAppointment_ServicesArgs = {
  cursor?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type ServiceArtist_ExclusionsArgs = {
  cursor?: InputMaybe<ArtistServiceExclusionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArtistServiceExclusionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArtistServiceExclusionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type ServiceCompaniesArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyWhereInput>;
};

export type ServiceCore_ServicesArgs = {
  cursor?: InputMaybe<ServiceAddOnWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceAddOnScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceAddOnOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type ServiceFacilitiesArgs = {
  cursor?: InputMaybe<FacilityServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<FacilityServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FacilityServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type ServiceMenu_SectionsArgs = {
  cursor?: InputMaybe<MenuSectionServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<MenuSectionServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MenuSectionServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type ServiceTx_AddonsArgs = {
  cursor?: InputMaybe<TxAddonWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxAddonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxAddonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxAddonWhereInput>;
};

export type ServiceTx_ServicesArgs = {
  cursor?: InputMaybe<TxServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<TxServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TxServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TxServiceWhereInput>;
};

export type ServiceAddOn = {
  __typename?: "ServiceAddOn";
  _count?: Maybe<ServiceAddOnCount>;
  add_on: Service;
  add_on_service_id: Scalars["String"];
  appointment_services: Array<AppointmentService>;
  core_service: Service;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  service_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type ServiceAddOnAppointment_ServicesArgs = {
  cursor?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type ServiceAddOnCount = {
  __typename?: "ServiceAddOnCount";
  appointment_services: Scalars["Int"];
};

export type ServiceAddOnCountAppointment_ServicesArgs = {
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type ServiceAddOnCountAggregate = {
  __typename?: "ServiceAddOnCountAggregate";
  _all: Scalars["Int"];
  add_on_service_id: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  service_id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type ServiceAddOnCountOrderByAggregateInput = {
  add_on_service_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ServiceAddOnCreateInput = {
  add_on: ServiceCreateNestedOneWithoutAdd_OnsInput;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAdd_OnsInput>;
  core_service: ServiceCreateNestedOneWithoutCore_ServicesInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceAddOnCreateManyAdd_OnInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  service_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceAddOnCreateManyAdd_OnInputEnvelope = {
  data: Array<ServiceAddOnCreateManyAdd_OnInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ServiceAddOnCreateManyCore_ServiceInput = {
  add_on_service_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceAddOnCreateManyCore_ServiceInputEnvelope = {
  data: Array<ServiceAddOnCreateManyCore_ServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ServiceAddOnCreateManyInput = {
  add_on_service_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  service_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceAddOnCreateNestedManyWithoutAdd_OnInput = {
  connect?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceAddOnCreateOrConnectWithoutAdd_OnInput>
  >;
  create?: InputMaybe<Array<ServiceAddOnCreateWithoutAdd_OnInput>>;
  createMany?: InputMaybe<ServiceAddOnCreateManyAdd_OnInputEnvelope>;
};

export type ServiceAddOnCreateNestedManyWithoutAppointment_ServicesInput = {
  connect?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceAddOnCreateOrConnectWithoutAppointment_ServicesInput>
  >;
  create?: InputMaybe<
    Array<ServiceAddOnCreateWithoutAppointment_ServicesInput>
  >;
};

export type ServiceAddOnCreateNestedManyWithoutCore_ServiceInput = {
  connect?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceAddOnCreateOrConnectWithoutCore_ServiceInput>
  >;
  create?: InputMaybe<Array<ServiceAddOnCreateWithoutCore_ServiceInput>>;
  createMany?: InputMaybe<ServiceAddOnCreateManyCore_ServiceInputEnvelope>;
};

export type ServiceAddOnCreateOrConnectWithoutAdd_OnInput = {
  create: ServiceAddOnCreateWithoutAdd_OnInput;
  where: ServiceAddOnWhereUniqueInput;
};

export type ServiceAddOnCreateOrConnectWithoutAppointment_ServicesInput = {
  create: ServiceAddOnCreateWithoutAppointment_ServicesInput;
  where: ServiceAddOnWhereUniqueInput;
};

export type ServiceAddOnCreateOrConnectWithoutCore_ServiceInput = {
  create: ServiceAddOnCreateWithoutCore_ServiceInput;
  where: ServiceAddOnWhereUniqueInput;
};

export type ServiceAddOnCreateWithoutAdd_OnInput = {
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAdd_OnsInput>;
  core_service: ServiceCreateNestedOneWithoutCore_ServicesInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceAddOnCreateWithoutAppointment_ServicesInput = {
  add_on: ServiceCreateNestedOneWithoutAdd_OnsInput;
  core_service: ServiceCreateNestedOneWithoutCore_ServicesInput;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceAddOnCreateWithoutCore_ServiceInput = {
  add_on: ServiceCreateNestedOneWithoutAdd_OnsInput;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutAdd_OnsInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceAddOnGroupBy = {
  __typename?: "ServiceAddOnGroupBy";
  _count?: Maybe<ServiceAddOnCountAggregate>;
  _max?: Maybe<ServiceAddOnMaxAggregate>;
  _min?: Maybe<ServiceAddOnMinAggregate>;
  add_on_service_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  service_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type ServiceAddOnListRelationFilter = {
  every?: InputMaybe<ServiceAddOnWhereInput>;
  none?: InputMaybe<ServiceAddOnWhereInput>;
  some?: InputMaybe<ServiceAddOnWhereInput>;
};

export type ServiceAddOnMaxAggregate = {
  __typename?: "ServiceAddOnMaxAggregate";
  add_on_service_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ServiceAddOnMaxOrderByAggregateInput = {
  add_on_service_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ServiceAddOnMinAggregate = {
  __typename?: "ServiceAddOnMinAggregate";
  add_on_service_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ServiceAddOnMinOrderByAggregateInput = {
  add_on_service_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ServiceAddOnOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ServiceAddOnOrderByWithAggregationInput = {
  _count?: InputMaybe<ServiceAddOnCountOrderByAggregateInput>;
  _max?: InputMaybe<ServiceAddOnMaxOrderByAggregateInput>;
  _min?: InputMaybe<ServiceAddOnMinOrderByAggregateInput>;
  add_on_service_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ServiceAddOnOrderByWithRelationInput = {
  add_on?: InputMaybe<ServiceOrderByWithRelationInput>;
  add_on_service_id?: InputMaybe<SortOrder>;
  appointment_services?: InputMaybe<AppointmentServiceOrderByRelationAggregateInput>;
  core_service?: InputMaybe<ServiceOrderByWithRelationInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum ServiceAddOnScalarFieldEnum {
  AddOnServiceId = "add_on_service_id",
  CreatedAt = "created_at",
  Id = "id",
  ServiceId = "service_id",
  UpdatedAt = "updated_at"
}

export type ServiceAddOnScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceAddOnScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceAddOnScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceAddOnScalarWhereInput>>;
  add_on_service_id?: InputMaybe<UuidFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  service_id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ServiceAddOnScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ServiceAddOnScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ServiceAddOnScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ServiceAddOnScalarWhereWithAggregatesInput>>;
  add_on_service_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  service_id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ServiceAddOnUpdateInput = {
  add_on?: InputMaybe<ServiceUpdateOneRequiredWithoutAdd_OnsNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAdd_OnsNestedInput>;
  core_service?: InputMaybe<ServiceUpdateOneRequiredWithoutCore_ServicesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceAddOnUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceAddOnUpdateManyWithWhereWithoutAdd_OnInput = {
  data: ServiceAddOnUpdateManyMutationInput;
  where: ServiceAddOnScalarWhereInput;
};

export type ServiceAddOnUpdateManyWithWhereWithoutAppointment_ServicesInput = {
  data: ServiceAddOnUpdateManyMutationInput;
  where: ServiceAddOnScalarWhereInput;
};

export type ServiceAddOnUpdateManyWithWhereWithoutCore_ServiceInput = {
  data: ServiceAddOnUpdateManyMutationInput;
  where: ServiceAddOnScalarWhereInput;
};

export type ServiceAddOnUpdateManyWithoutAdd_OnNestedInput = {
  connect?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceAddOnCreateOrConnectWithoutAdd_OnInput>
  >;
  create?: InputMaybe<Array<ServiceAddOnCreateWithoutAdd_OnInput>>;
  createMany?: InputMaybe<ServiceAddOnCreateManyAdd_OnInputEnvelope>;
  delete?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceAddOnScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceAddOnUpdateWithWhereUniqueWithoutAdd_OnInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceAddOnUpdateManyWithWhereWithoutAdd_OnInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceAddOnUpsertWithWhereUniqueWithoutAdd_OnInput>
  >;
};

export type ServiceAddOnUpdateManyWithoutAppointment_ServicesNestedInput = {
  connect?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceAddOnCreateOrConnectWithoutAppointment_ServicesInput>
  >;
  create?: InputMaybe<
    Array<ServiceAddOnCreateWithoutAppointment_ServicesInput>
  >;
  delete?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceAddOnScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceAddOnUpdateWithWhereUniqueWithoutAppointment_ServicesInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceAddOnUpdateManyWithWhereWithoutAppointment_ServicesInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceAddOnUpsertWithWhereUniqueWithoutAppointment_ServicesInput>
  >;
};

export type ServiceAddOnUpdateManyWithoutCore_ServiceNestedInput = {
  connect?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceAddOnCreateOrConnectWithoutCore_ServiceInput>
  >;
  create?: InputMaybe<Array<ServiceAddOnCreateWithoutCore_ServiceInput>>;
  createMany?: InputMaybe<ServiceAddOnCreateManyCore_ServiceInputEnvelope>;
  delete?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceAddOnScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceAddOnWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceAddOnUpdateWithWhereUniqueWithoutCore_ServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceAddOnUpdateManyWithWhereWithoutCore_ServiceInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceAddOnUpsertWithWhereUniqueWithoutCore_ServiceInput>
  >;
};

export type ServiceAddOnUpdateWithWhereUniqueWithoutAdd_OnInput = {
  data: ServiceAddOnUpdateWithoutAdd_OnInput;
  where: ServiceAddOnWhereUniqueInput;
};

export type ServiceAddOnUpdateWithWhereUniqueWithoutAppointment_ServicesInput =
  {
    data: ServiceAddOnUpdateWithoutAppointment_ServicesInput;
    where: ServiceAddOnWhereUniqueInput;
  };

export type ServiceAddOnUpdateWithWhereUniqueWithoutCore_ServiceInput = {
  data: ServiceAddOnUpdateWithoutCore_ServiceInput;
  where: ServiceAddOnWhereUniqueInput;
};

export type ServiceAddOnUpdateWithoutAdd_OnInput = {
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAdd_OnsNestedInput>;
  core_service?: InputMaybe<ServiceUpdateOneRequiredWithoutCore_ServicesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceAddOnUpdateWithoutAppointment_ServicesInput = {
  add_on?: InputMaybe<ServiceUpdateOneRequiredWithoutAdd_OnsNestedInput>;
  core_service?: InputMaybe<ServiceUpdateOneRequiredWithoutCore_ServicesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceAddOnUpdateWithoutCore_ServiceInput = {
  add_on?: InputMaybe<ServiceUpdateOneRequiredWithoutAdd_OnsNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutAdd_OnsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceAddOnUpsertWithWhereUniqueWithoutAdd_OnInput = {
  create: ServiceAddOnCreateWithoutAdd_OnInput;
  update: ServiceAddOnUpdateWithoutAdd_OnInput;
  where: ServiceAddOnWhereUniqueInput;
};

export type ServiceAddOnUpsertWithWhereUniqueWithoutAppointment_ServicesInput =
  {
    create: ServiceAddOnCreateWithoutAppointment_ServicesInput;
    update: ServiceAddOnUpdateWithoutAppointment_ServicesInput;
    where: ServiceAddOnWhereUniqueInput;
  };

export type ServiceAddOnUpsertWithWhereUniqueWithoutCore_ServiceInput = {
  create: ServiceAddOnCreateWithoutCore_ServiceInput;
  update: ServiceAddOnUpdateWithoutCore_ServiceInput;
  where: ServiceAddOnWhereUniqueInput;
};

export type ServiceAddOnWhereInput = {
  AND?: InputMaybe<Array<ServiceAddOnWhereInput>>;
  NOT?: InputMaybe<Array<ServiceAddOnWhereInput>>;
  OR?: InputMaybe<Array<ServiceAddOnWhereInput>>;
  add_on?: InputMaybe<ServiceRelationFilter>;
  add_on_service_id?: InputMaybe<UuidFilter>;
  appointment_services?: InputMaybe<AppointmentServiceListRelationFilter>;
  core_service?: InputMaybe<ServiceRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  service_id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ServiceAddOnWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type ServiceAvgAggregate = {
  __typename?: "ServiceAvgAggregate";
  clean_up_time?: Maybe<Scalars["Float"]>;
  duration?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type ServiceAvgOrderByAggregateInput = {
  clean_up_time?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export enum ServiceCategory {
  Manicure = "MANICURE",
  Other = "OTHER",
  Pedicure = "PEDICURE"
}

export type ServiceCount = {
  __typename?: "ServiceCount";
  add_ons: Scalars["Int"];
  appointment_services: Scalars["Int"];
  artist_exclusions: Scalars["Int"];
  companies: Scalars["Int"];
  core_services: Scalars["Int"];
  facilities: Scalars["Int"];
  menu_sections: Scalars["Int"];
  tx_addons: Scalars["Int"];
  tx_services: Scalars["Int"];
};

export type ServiceCountAdd_OnsArgs = {
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type ServiceCountAppointment_ServicesArgs = {
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type ServiceCountArtist_ExclusionsArgs = {
  where?: InputMaybe<ArtistServiceExclusionWhereInput>;
};

export type ServiceCountCompaniesArgs = {
  where?: InputMaybe<CompanyWhereInput>;
};

export type ServiceCountCore_ServicesArgs = {
  where?: InputMaybe<ServiceAddOnWhereInput>;
};

export type ServiceCountFacilitiesArgs = {
  where?: InputMaybe<FacilityServiceWhereInput>;
};

export type ServiceCountMenu_SectionsArgs = {
  where?: InputMaybe<MenuSectionServiceWhereInput>;
};

export type ServiceCountTx_AddonsArgs = {
  where?: InputMaybe<TxAddonWhereInput>;
};

export type ServiceCountTx_ServicesArgs = {
  where?: InputMaybe<TxServiceWhereInput>;
};

export type ServiceCountAggregate = {
  __typename?: "ServiceCountAggregate";
  _all: Scalars["Int"];
  category: Scalars["Int"];
  choice_is_required: Scalars["Int"];
  clean_up_time: Scalars["Int"];
  company_id: Scalars["Int"];
  created_at: Scalars["Int"];
  description: Scalars["Int"];
  duration: Scalars["Int"];
  id: Scalars["Int"];
  is_addon: Scalars["Int"];
  name: Scalars["Int"];
  photo_1_url: Scalars["Int"];
  photo_2_url: Scalars["Int"];
  photo_3_url: Scalars["Int"];
  photo_4_url: Scalars["Int"];
  photo_5_url: Scalars["Int"];
  price: Scalars["Int"];
  scheduling_priority: Scalars["Int"];
  special_conditions: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type ServiceCountOrderByAggregateInput = {
  category?: InputMaybe<SortOrder>;
  choice_is_required?: InputMaybe<SortOrder>;
  clean_up_time?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_addon?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  photo_1_url?: InputMaybe<SortOrder>;
  photo_2_url?: InputMaybe<SortOrder>;
  photo_3_url?: InputMaybe<SortOrder>;
  photo_4_url?: InputMaybe<SortOrder>;
  photo_5_url?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  scheduling_priority?: InputMaybe<SortOrder>;
  special_conditions?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ServiceCreateInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAdd_OnInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutServiceInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutServiceInput>;
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  companies?: InputMaybe<CompanyCreateNestedManyWithoutDefault_ServiceInput>;
  company_id: Scalars["String"];
  core_services?: InputMaybe<ServiceAddOnCreateNestedManyWithoutCore_ServiceInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  facilities?: InputMaybe<FacilityServiceCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  menu_sections?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAddonInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceCreateManyInput = {
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceCreateNestedOneWithoutAdd_OnsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutAdd_OnsInput>;
  create?: InputMaybe<ServiceCreateWithoutAdd_OnsInput>;
};

export type ServiceCreateNestedOneWithoutAppointment_ServicesInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutAppointment_ServicesInput>;
  create?: InputMaybe<ServiceCreateWithoutAppointment_ServicesInput>;
};

export type ServiceCreateNestedOneWithoutArtist_ExclusionsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutArtist_ExclusionsInput>;
  create?: InputMaybe<ServiceCreateWithoutArtist_ExclusionsInput>;
};

export type ServiceCreateNestedOneWithoutCompaniesInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutCompaniesInput>;
  create?: InputMaybe<ServiceCreateWithoutCompaniesInput>;
};

export type ServiceCreateNestedOneWithoutCore_ServicesInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutCore_ServicesInput>;
  create?: InputMaybe<ServiceCreateWithoutCore_ServicesInput>;
};

export type ServiceCreateNestedOneWithoutFacilitiesInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutFacilitiesInput>;
  create?: InputMaybe<ServiceCreateWithoutFacilitiesInput>;
};

export type ServiceCreateNestedOneWithoutMenu_SectionsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutMenu_SectionsInput>;
  create?: InputMaybe<ServiceCreateWithoutMenu_SectionsInput>;
};

export type ServiceCreateNestedOneWithoutTx_AddonsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutTx_AddonsInput>;
  create?: InputMaybe<ServiceCreateWithoutTx_AddonsInput>;
};

export type ServiceCreateNestedOneWithoutTx_ServicesInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutTx_ServicesInput>;
  create?: InputMaybe<ServiceCreateWithoutTx_ServicesInput>;
};

export type ServiceCreateOrConnectWithoutAdd_OnsInput = {
  create: ServiceCreateWithoutAdd_OnsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutAppointment_ServicesInput = {
  create: ServiceCreateWithoutAppointment_ServicesInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutArtist_ExclusionsInput = {
  create: ServiceCreateWithoutArtist_ExclusionsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutCompaniesInput = {
  create: ServiceCreateWithoutCompaniesInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutCore_ServicesInput = {
  create: ServiceCreateWithoutCore_ServicesInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutFacilitiesInput = {
  create: ServiceCreateWithoutFacilitiesInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutMenu_SectionsInput = {
  create: ServiceCreateWithoutMenu_SectionsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutTx_AddonsInput = {
  create: ServiceCreateWithoutTx_AddonsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutTx_ServicesInput = {
  create: ServiceCreateWithoutTx_ServicesInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateWithoutAdd_OnsInput = {
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutServiceInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutServiceInput>;
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  companies?: InputMaybe<CompanyCreateNestedManyWithoutDefault_ServiceInput>;
  company_id: Scalars["String"];
  core_services?: InputMaybe<ServiceAddOnCreateNestedManyWithoutCore_ServiceInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  facilities?: InputMaybe<FacilityServiceCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  menu_sections?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAddonInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceCreateWithoutAppointment_ServicesInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAdd_OnInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutServiceInput>;
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  companies?: InputMaybe<CompanyCreateNestedManyWithoutDefault_ServiceInput>;
  company_id: Scalars["String"];
  core_services?: InputMaybe<ServiceAddOnCreateNestedManyWithoutCore_ServiceInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  facilities?: InputMaybe<FacilityServiceCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  menu_sections?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAddonInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceCreateWithoutArtist_ExclusionsInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAdd_OnInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutServiceInput>;
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  companies?: InputMaybe<CompanyCreateNestedManyWithoutDefault_ServiceInput>;
  company_id: Scalars["String"];
  core_services?: InputMaybe<ServiceAddOnCreateNestedManyWithoutCore_ServiceInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  facilities?: InputMaybe<FacilityServiceCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  menu_sections?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAddonInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceCreateWithoutCompaniesInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAdd_OnInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutServiceInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutServiceInput>;
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  company_id: Scalars["String"];
  core_services?: InputMaybe<ServiceAddOnCreateNestedManyWithoutCore_ServiceInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  facilities?: InputMaybe<FacilityServiceCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  menu_sections?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAddonInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceCreateWithoutCore_ServicesInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAdd_OnInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutServiceInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutServiceInput>;
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  companies?: InputMaybe<CompanyCreateNestedManyWithoutDefault_ServiceInput>;
  company_id: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  facilities?: InputMaybe<FacilityServiceCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  menu_sections?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAddonInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceCreateWithoutFacilitiesInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAdd_OnInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutServiceInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutServiceInput>;
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  companies?: InputMaybe<CompanyCreateNestedManyWithoutDefault_ServiceInput>;
  company_id: Scalars["String"];
  core_services?: InputMaybe<ServiceAddOnCreateNestedManyWithoutCore_ServiceInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  menu_sections?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAddonInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceCreateWithoutMenu_SectionsInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAdd_OnInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutServiceInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutServiceInput>;
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  companies?: InputMaybe<CompanyCreateNestedManyWithoutDefault_ServiceInput>;
  company_id: Scalars["String"];
  core_services?: InputMaybe<ServiceAddOnCreateNestedManyWithoutCore_ServiceInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  facilities?: InputMaybe<FacilityServiceCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAddonInput>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceCreateWithoutTx_AddonsInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAdd_OnInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutServiceInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutServiceInput>;
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  companies?: InputMaybe<CompanyCreateNestedManyWithoutDefault_ServiceInput>;
  company_id: Scalars["String"];
  core_services?: InputMaybe<ServiceAddOnCreateNestedManyWithoutCore_ServiceInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  facilities?: InputMaybe<FacilityServiceCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  menu_sections?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  tx_services?: InputMaybe<TxServiceCreateNestedManyWithoutServiceInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceCreateWithoutTx_ServicesInput = {
  add_ons?: InputMaybe<ServiceAddOnCreateNestedManyWithoutAdd_OnInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutServiceInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionCreateNestedManyWithoutServiceInput>;
  category?: InputMaybe<ServiceCategory>;
  choice_is_required?: InputMaybe<Scalars["Boolean"]>;
  clean_up_time?: InputMaybe<Scalars["Int"]>;
  companies?: InputMaybe<CompanyCreateNestedManyWithoutDefault_ServiceInput>;
  company_id: Scalars["String"];
  core_services?: InputMaybe<ServiceAddOnCreateNestedManyWithoutCore_ServiceInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  facilities?: InputMaybe<FacilityServiceCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars["String"]>;
  is_addon?: InputMaybe<Scalars["Boolean"]>;
  menu_sections?: InputMaybe<MenuSectionServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars["String"];
  photo_1_url?: InputMaybe<Scalars["String"]>;
  photo_2_url?: InputMaybe<Scalars["String"]>;
  photo_3_url?: InputMaybe<Scalars["String"]>;
  photo_4_url?: InputMaybe<Scalars["String"]>;
  photo_5_url?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: InputMaybe<SchedulingPriority>;
  special_conditions?: InputMaybe<Scalars["String"]>;
  tx_addons?: InputMaybe<TxAddonCreateNestedManyWithoutAddonInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type ServiceGroupBy = {
  __typename?: "ServiceGroupBy";
  _avg?: Maybe<ServiceAvgAggregate>;
  _count?: Maybe<ServiceCountAggregate>;
  _max?: Maybe<ServiceMaxAggregate>;
  _min?: Maybe<ServiceMinAggregate>;
  _sum?: Maybe<ServiceSumAggregate>;
  category: ServiceCategory;
  choice_is_required: Scalars["Boolean"];
  clean_up_time?: Maybe<Scalars["Int"]>;
  company_id: Scalars["String"];
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  id: Scalars["String"];
  is_addon: Scalars["Boolean"];
  name: Scalars["String"];
  photo_1_url?: Maybe<Scalars["String"]>;
  photo_2_url?: Maybe<Scalars["String"]>;
  photo_3_url?: Maybe<Scalars["String"]>;
  photo_4_url?: Maybe<Scalars["String"]>;
  photo_5_url?: Maybe<Scalars["String"]>;
  price: Scalars["Float"];
  scheduling_priority?: Maybe<SchedulingPriority>;
  special_conditions?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type ServiceMaxAggregate = {
  __typename?: "ServiceMaxAggregate";
  category?: Maybe<ServiceCategory>;
  choice_is_required?: Maybe<Scalars["Boolean"]>;
  clean_up_time?: Maybe<Scalars["Int"]>;
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  is_addon?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  photo_1_url?: Maybe<Scalars["String"]>;
  photo_2_url?: Maybe<Scalars["String"]>;
  photo_3_url?: Maybe<Scalars["String"]>;
  photo_4_url?: Maybe<Scalars["String"]>;
  photo_5_url?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  scheduling_priority?: Maybe<SchedulingPriority>;
  special_conditions?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ServiceMaxOrderByAggregateInput = {
  category?: InputMaybe<SortOrder>;
  choice_is_required?: InputMaybe<SortOrder>;
  clean_up_time?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_addon?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  photo_1_url?: InputMaybe<SortOrder>;
  photo_2_url?: InputMaybe<SortOrder>;
  photo_3_url?: InputMaybe<SortOrder>;
  photo_4_url?: InputMaybe<SortOrder>;
  photo_5_url?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  scheduling_priority?: InputMaybe<SortOrder>;
  special_conditions?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ServiceMinAggregate = {
  __typename?: "ServiceMinAggregate";
  category?: Maybe<ServiceCategory>;
  choice_is_required?: Maybe<Scalars["Boolean"]>;
  clean_up_time?: Maybe<Scalars["Int"]>;
  company_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  is_addon?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  photo_1_url?: Maybe<Scalars["String"]>;
  photo_2_url?: Maybe<Scalars["String"]>;
  photo_3_url?: Maybe<Scalars["String"]>;
  photo_4_url?: Maybe<Scalars["String"]>;
  photo_5_url?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  scheduling_priority?: Maybe<SchedulingPriority>;
  special_conditions?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ServiceMinOrderByAggregateInput = {
  category?: InputMaybe<SortOrder>;
  choice_is_required?: InputMaybe<SortOrder>;
  clean_up_time?: InputMaybe<SortOrder>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_addon?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  photo_1_url?: InputMaybe<SortOrder>;
  photo_2_url?: InputMaybe<SortOrder>;
  photo_3_url?: InputMaybe<SortOrder>;
  photo_4_url?: InputMaybe<SortOrder>;
  photo_5_url?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  scheduling_priority?: InputMaybe<SortOrder>;
  special_conditions?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ServiceOrderByWithAggregationInput = {
  _avg?: InputMaybe<ServiceAvgOrderByAggregateInput>;
  _count?: InputMaybe<ServiceCountOrderByAggregateInput>;
  _max?: InputMaybe<ServiceMaxOrderByAggregateInput>;
  _min?: InputMaybe<ServiceMinOrderByAggregateInput>;
  _sum?: InputMaybe<ServiceSumOrderByAggregateInput>;
  category?: InputMaybe<SortOrder>;
  choice_is_required?: InputMaybe<SortOrder>;
  clean_up_time?: InputMaybe<SortOrderInput>;
  company_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  duration?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  is_addon?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  photo_1_url?: InputMaybe<SortOrderInput>;
  photo_2_url?: InputMaybe<SortOrderInput>;
  photo_3_url?: InputMaybe<SortOrderInput>;
  photo_4_url?: InputMaybe<SortOrderInput>;
  photo_5_url?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrder>;
  scheduling_priority?: InputMaybe<SortOrderInput>;
  special_conditions?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ServiceOrderByWithRelationInput = {
  add_ons?: InputMaybe<ServiceAddOnOrderByRelationAggregateInput>;
  appointment_services?: InputMaybe<AppointmentServiceOrderByRelationAggregateInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionOrderByRelationAggregateInput>;
  category?: InputMaybe<SortOrder>;
  choice_is_required?: InputMaybe<SortOrder>;
  clean_up_time?: InputMaybe<SortOrderInput>;
  companies?: InputMaybe<CompanyOrderByRelationAggregateInput>;
  company_id?: InputMaybe<SortOrder>;
  core_services?: InputMaybe<ServiceAddOnOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  duration?: InputMaybe<SortOrderInput>;
  facilities?: InputMaybe<FacilityServiceOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  is_addon?: InputMaybe<SortOrder>;
  menu_sections?: InputMaybe<MenuSectionServiceOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  photo_1_url?: InputMaybe<SortOrderInput>;
  photo_2_url?: InputMaybe<SortOrderInput>;
  photo_3_url?: InputMaybe<SortOrderInput>;
  photo_4_url?: InputMaybe<SortOrderInput>;
  photo_5_url?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrder>;
  scheduling_priority?: InputMaybe<SortOrderInput>;
  special_conditions?: InputMaybe<SortOrderInput>;
  tx_addons?: InputMaybe<TxAddonOrderByRelationAggregateInput>;
  tx_services?: InputMaybe<TxServiceOrderByRelationAggregateInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ServiceRelationFilter = {
  is?: InputMaybe<ServiceWhereInput>;
  isNot?: InputMaybe<ServiceWhereInput>;
};

export enum ServiceScalarFieldEnum {
  Category = "category",
  ChoiceIsRequired = "choice_is_required",
  CleanUpTime = "clean_up_time",
  CompanyId = "company_id",
  CreatedAt = "created_at",
  Description = "description",
  Duration = "duration",
  Id = "id",
  IsAddon = "is_addon",
  Name = "name",
  Photo_1Url = "photo_1_url",
  Photo_2Url = "photo_2_url",
  Photo_3Url = "photo_3_url",
  Photo_4Url = "photo_4_url",
  Photo_5Url = "photo_5_url",
  Price = "price",
  SchedulingPriority = "scheduling_priority",
  SpecialConditions = "special_conditions",
  UpdatedAt = "updated_at"
}

export type ServiceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  category?: InputMaybe<EnumServiceCategoryWithAggregatesFilter>;
  choice_is_required?: InputMaybe<BoolWithAggregatesFilter>;
  clean_up_time?: InputMaybe<IntNullableWithAggregatesFilter>;
  company_id?: InputMaybe<UuidWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  duration?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  is_addon?: InputMaybe<BoolWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  photo_1_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  photo_2_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  photo_3_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  photo_4_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  photo_5_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  price?: InputMaybe<FloatWithAggregatesFilter>;
  scheduling_priority?: InputMaybe<EnumSchedulingPriorityNullableWithAggregatesFilter>;
  special_conditions?: InputMaybe<StringNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ServiceSumAggregate = {
  __typename?: "ServiceSumAggregate";
  clean_up_time?: Maybe<Scalars["Int"]>;
  duration?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type ServiceSumOrderByAggregateInput = {
  clean_up_time?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export type ServiceUpdateInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAdd_OnNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutServiceNestedInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutServiceNestedInput>;
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  companies?: InputMaybe<CompanyUpdateManyWithoutDefault_ServiceNestedInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  core_services?: InputMaybe<ServiceAddOnUpdateManyWithoutCore_ServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  facilities?: InputMaybe<FacilityServiceUpdateManyWithoutServiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionServiceUpdateManyWithoutServiceNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAddonNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateManyMutationInput = {
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateOneRequiredWithoutAdd_OnsNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutAdd_OnsInput>;
  create?: InputMaybe<ServiceCreateWithoutAdd_OnsInput>;
  update?: InputMaybe<ServiceUpdateWithoutAdd_OnsInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutAdd_OnsInput>;
};

export type ServiceUpdateOneRequiredWithoutAppointment_ServicesNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutAppointment_ServicesInput>;
  create?: InputMaybe<ServiceCreateWithoutAppointment_ServicesInput>;
  update?: InputMaybe<ServiceUpdateWithoutAppointment_ServicesInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutAppointment_ServicesInput>;
};

export type ServiceUpdateOneRequiredWithoutArtist_ExclusionsNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutArtist_ExclusionsInput>;
  create?: InputMaybe<ServiceCreateWithoutArtist_ExclusionsInput>;
  update?: InputMaybe<ServiceUpdateWithoutArtist_ExclusionsInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutArtist_ExclusionsInput>;
};

export type ServiceUpdateOneRequiredWithoutCore_ServicesNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutCore_ServicesInput>;
  create?: InputMaybe<ServiceCreateWithoutCore_ServicesInput>;
  update?: InputMaybe<ServiceUpdateWithoutCore_ServicesInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutCore_ServicesInput>;
};

export type ServiceUpdateOneWithoutCompaniesNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutCompaniesInput>;
  create?: InputMaybe<ServiceCreateWithoutCompaniesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ServiceUpdateWithoutCompaniesInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutCompaniesInput>;
};

export type ServiceUpdateOneWithoutFacilitiesNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutFacilitiesInput>;
  create?: InputMaybe<ServiceCreateWithoutFacilitiesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ServiceUpdateWithoutFacilitiesInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutFacilitiesInput>;
};

export type ServiceUpdateOneWithoutMenu_SectionsNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutMenu_SectionsInput>;
  create?: InputMaybe<ServiceCreateWithoutMenu_SectionsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ServiceUpdateWithoutMenu_SectionsInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutMenu_SectionsInput>;
};

export type ServiceUpdateOneWithoutTx_AddonsNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutTx_AddonsInput>;
  create?: InputMaybe<ServiceCreateWithoutTx_AddonsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ServiceUpdateWithoutTx_AddonsInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutTx_AddonsInput>;
};

export type ServiceUpdateOneWithoutTx_ServicesNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutTx_ServicesInput>;
  create?: InputMaybe<ServiceCreateWithoutTx_ServicesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ServiceUpdateWithoutTx_ServicesInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutTx_ServicesInput>;
};

export type ServiceUpdateWithoutAdd_OnsInput = {
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutServiceNestedInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutServiceNestedInput>;
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  companies?: InputMaybe<CompanyUpdateManyWithoutDefault_ServiceNestedInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  core_services?: InputMaybe<ServiceAddOnUpdateManyWithoutCore_ServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  facilities?: InputMaybe<FacilityServiceUpdateManyWithoutServiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionServiceUpdateManyWithoutServiceNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAddonNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutAppointment_ServicesInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAdd_OnNestedInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutServiceNestedInput>;
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  companies?: InputMaybe<CompanyUpdateManyWithoutDefault_ServiceNestedInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  core_services?: InputMaybe<ServiceAddOnUpdateManyWithoutCore_ServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  facilities?: InputMaybe<FacilityServiceUpdateManyWithoutServiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionServiceUpdateManyWithoutServiceNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAddonNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutArtist_ExclusionsInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAdd_OnNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutServiceNestedInput>;
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  companies?: InputMaybe<CompanyUpdateManyWithoutDefault_ServiceNestedInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  core_services?: InputMaybe<ServiceAddOnUpdateManyWithoutCore_ServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  facilities?: InputMaybe<FacilityServiceUpdateManyWithoutServiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionServiceUpdateManyWithoutServiceNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAddonNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutCompaniesInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAdd_OnNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutServiceNestedInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutServiceNestedInput>;
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  core_services?: InputMaybe<ServiceAddOnUpdateManyWithoutCore_ServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  facilities?: InputMaybe<FacilityServiceUpdateManyWithoutServiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionServiceUpdateManyWithoutServiceNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAddonNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutCore_ServicesInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAdd_OnNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutServiceNestedInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutServiceNestedInput>;
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  companies?: InputMaybe<CompanyUpdateManyWithoutDefault_ServiceNestedInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  facilities?: InputMaybe<FacilityServiceUpdateManyWithoutServiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionServiceUpdateManyWithoutServiceNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAddonNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutFacilitiesInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAdd_OnNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutServiceNestedInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutServiceNestedInput>;
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  companies?: InputMaybe<CompanyUpdateManyWithoutDefault_ServiceNestedInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  core_services?: InputMaybe<ServiceAddOnUpdateManyWithoutCore_ServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionServiceUpdateManyWithoutServiceNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAddonNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutMenu_SectionsInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAdd_OnNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutServiceNestedInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutServiceNestedInput>;
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  companies?: InputMaybe<CompanyUpdateManyWithoutDefault_ServiceNestedInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  core_services?: InputMaybe<ServiceAddOnUpdateManyWithoutCore_ServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  facilities?: InputMaybe<FacilityServiceUpdateManyWithoutServiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAddonNestedInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutTx_AddonsInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAdd_OnNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutServiceNestedInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutServiceNestedInput>;
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  companies?: InputMaybe<CompanyUpdateManyWithoutDefault_ServiceNestedInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  core_services?: InputMaybe<ServiceAddOnUpdateManyWithoutCore_ServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  facilities?: InputMaybe<FacilityServiceUpdateManyWithoutServiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionServiceUpdateManyWithoutServiceNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tx_services?: InputMaybe<TxServiceUpdateManyWithoutServiceNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutTx_ServicesInput = {
  add_ons?: InputMaybe<ServiceAddOnUpdateManyWithoutAdd_OnNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutServiceNestedInput>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionUpdateManyWithoutServiceNestedInput>;
  category?: InputMaybe<EnumServiceCategoryFieldUpdateOperationsInput>;
  choice_is_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  clean_up_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  companies?: InputMaybe<CompanyUpdateManyWithoutDefault_ServiceNestedInput>;
  company_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  core_services?: InputMaybe<ServiceAddOnUpdateManyWithoutCore_ServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  facilities?: InputMaybe<FacilityServiceUpdateManyWithoutServiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_addon?: InputMaybe<BoolFieldUpdateOperationsInput>;
  menu_sections?: InputMaybe<MenuSectionServiceUpdateManyWithoutServiceNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo_1_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_2_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_3_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_4_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_5_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduling_priority?: InputMaybe<NullableEnumSchedulingPriorityFieldUpdateOperationsInput>;
  special_conditions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tx_addons?: InputMaybe<TxAddonUpdateManyWithoutAddonNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpsertWithoutAdd_OnsInput = {
  create: ServiceCreateWithoutAdd_OnsInput;
  update: ServiceUpdateWithoutAdd_OnsInput;
};

export type ServiceUpsertWithoutAppointment_ServicesInput = {
  create: ServiceCreateWithoutAppointment_ServicesInput;
  update: ServiceUpdateWithoutAppointment_ServicesInput;
};

export type ServiceUpsertWithoutArtist_ExclusionsInput = {
  create: ServiceCreateWithoutArtist_ExclusionsInput;
  update: ServiceUpdateWithoutArtist_ExclusionsInput;
};

export type ServiceUpsertWithoutCompaniesInput = {
  create: ServiceCreateWithoutCompaniesInput;
  update: ServiceUpdateWithoutCompaniesInput;
};

export type ServiceUpsertWithoutCore_ServicesInput = {
  create: ServiceCreateWithoutCore_ServicesInput;
  update: ServiceUpdateWithoutCore_ServicesInput;
};

export type ServiceUpsertWithoutFacilitiesInput = {
  create: ServiceCreateWithoutFacilitiesInput;
  update: ServiceUpdateWithoutFacilitiesInput;
};

export type ServiceUpsertWithoutMenu_SectionsInput = {
  create: ServiceCreateWithoutMenu_SectionsInput;
  update: ServiceUpdateWithoutMenu_SectionsInput;
};

export type ServiceUpsertWithoutTx_AddonsInput = {
  create: ServiceCreateWithoutTx_AddonsInput;
  update: ServiceUpdateWithoutTx_AddonsInput;
};

export type ServiceUpsertWithoutTx_ServicesInput = {
  create: ServiceCreateWithoutTx_ServicesInput;
  update: ServiceUpdateWithoutTx_ServicesInput;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  add_ons?: InputMaybe<ServiceAddOnListRelationFilter>;
  appointment_services?: InputMaybe<AppointmentServiceListRelationFilter>;
  artist_exclusions?: InputMaybe<ArtistServiceExclusionListRelationFilter>;
  category?: InputMaybe<EnumServiceCategoryFilter>;
  choice_is_required?: InputMaybe<BoolFilter>;
  clean_up_time?: InputMaybe<IntNullableFilter>;
  companies?: InputMaybe<CompanyListRelationFilter>;
  company_id?: InputMaybe<UuidFilter>;
  core_services?: InputMaybe<ServiceAddOnListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  facilities?: InputMaybe<FacilityServiceListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  is_addon?: InputMaybe<BoolFilter>;
  menu_sections?: InputMaybe<MenuSectionServiceListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  photo_1_url?: InputMaybe<StringNullableFilter>;
  photo_2_url?: InputMaybe<StringNullableFilter>;
  photo_3_url?: InputMaybe<StringNullableFilter>;
  photo_4_url?: InputMaybe<StringNullableFilter>;
  photo_5_url?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<FloatFilter>;
  scheduling_priority?: InputMaybe<EnumSchedulingPriorityNullableFilter>;
  special_conditions?: InputMaybe<StringNullableFilter>;
  tx_addons?: InputMaybe<TxAddonListRelationFilter>;
  tx_services?: InputMaybe<TxServiceListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export enum SortOrder {
  Asc = "asc",
  Desc = "desc"
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type StripePaymentMethod = {
  __typename?: "StripePaymentMethod";
  _count?: Maybe<StripePaymentMethodCount>;
  brand: Scalars["String"];
  company_payment_methods: Array<CompanyPaymentMethod>;
  created_at: Scalars["DateTime"];
  exp_month: Scalars["Int"];
  exp_year: Scalars["Int"];
  id: Scalars["String"];
  is_default: Scalars["Boolean"];
  last4: Scalars["String"];
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
};

export type StripePaymentMethodCompany_Payment_MethodsArgs = {
  cursor?: InputMaybe<CompanyPaymentMethodWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyPaymentMethodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyPaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type StripePaymentMethodAvgAggregate = {
  __typename?: "StripePaymentMethodAvgAggregate";
  exp_month?: Maybe<Scalars["Float"]>;
  exp_year?: Maybe<Scalars["Float"]>;
};

export type StripePaymentMethodAvgOrderByAggregateInput = {
  exp_month?: InputMaybe<SortOrder>;
  exp_year?: InputMaybe<SortOrder>;
};

export type StripePaymentMethodCount = {
  __typename?: "StripePaymentMethodCount";
  company_payment_methods: Scalars["Int"];
};

export type StripePaymentMethodCountCompany_Payment_MethodsArgs = {
  where?: InputMaybe<CompanyPaymentMethodWhereInput>;
};

export type StripePaymentMethodCountAggregate = {
  __typename?: "StripePaymentMethodCountAggregate";
  _all: Scalars["Int"];
  brand: Scalars["Int"];
  created_at: Scalars["Int"];
  exp_month: Scalars["Int"];
  exp_year: Scalars["Int"];
  id: Scalars["Int"];
  is_default: Scalars["Int"];
  last4: Scalars["Int"];
  object_json: Scalars["Int"];
  stripe_id: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type StripePaymentMethodCountOrderByAggregateInput = {
  brand?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  exp_month?: InputMaybe<SortOrder>;
  exp_year?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_default?: InputMaybe<SortOrder>;
  last4?: InputMaybe<SortOrder>;
  object_json?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type StripePaymentMethodCreateInput = {
  brand: Scalars["String"];
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutStripe_Payment_MethodInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  exp_month: Scalars["Int"];
  exp_year: Scalars["Int"];
  id?: InputMaybe<Scalars["String"]>;
  is_default: Scalars["Boolean"];
  last4: Scalars["String"];
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutStripePaymentMethodsInput;
};

export type StripePaymentMethodCreateManyInput = {
  brand: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  exp_month: Scalars["Int"];
  exp_year: Scalars["Int"];
  id?: InputMaybe<Scalars["String"]>;
  is_default: Scalars["Boolean"];
  last4: Scalars["String"];
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type StripePaymentMethodCreateManyUserInput = {
  brand: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  exp_month: Scalars["Int"];
  exp_year: Scalars["Int"];
  id?: InputMaybe<Scalars["String"]>;
  is_default: Scalars["Boolean"];
  last4: Scalars["String"];
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type StripePaymentMethodCreateManyUserInputEnvelope = {
  data: Array<StripePaymentMethodCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type StripePaymentMethodCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<StripePaymentMethodCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<StripePaymentMethodCreateWithoutUserInput>>;
  createMany?: InputMaybe<StripePaymentMethodCreateManyUserInputEnvelope>;
};

export type StripePaymentMethodCreateNestedOneWithoutCompany_Payment_MethodsInput =
  {
    connect?: InputMaybe<StripePaymentMethodWhereUniqueInput>;
    connectOrCreate?: InputMaybe<StripePaymentMethodCreateOrConnectWithoutCompany_Payment_MethodsInput>;
    create?: InputMaybe<StripePaymentMethodCreateWithoutCompany_Payment_MethodsInput>;
  };

export type StripePaymentMethodCreateOrConnectWithoutCompany_Payment_MethodsInput =
  {
    create: StripePaymentMethodCreateWithoutCompany_Payment_MethodsInput;
    where: StripePaymentMethodWhereUniqueInput;
  };

export type StripePaymentMethodCreateOrConnectWithoutUserInput = {
  create: StripePaymentMethodCreateWithoutUserInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodCreateWithoutCompany_Payment_MethodsInput = {
  brand: Scalars["String"];
  created_at?: InputMaybe<Scalars["DateTime"]>;
  exp_month: Scalars["Int"];
  exp_year: Scalars["Int"];
  id?: InputMaybe<Scalars["String"]>;
  is_default: Scalars["Boolean"];
  last4: Scalars["String"];
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutStripePaymentMethodsInput;
};

export type StripePaymentMethodCreateWithoutUserInput = {
  brand: Scalars["String"];
  company_payment_methods?: InputMaybe<CompanyPaymentMethodCreateNestedManyWithoutStripe_Payment_MethodInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  exp_month: Scalars["Int"];
  exp_year: Scalars["Int"];
  id?: InputMaybe<Scalars["String"]>;
  is_default: Scalars["Boolean"];
  last4: Scalars["String"];
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type StripePaymentMethodGroupBy = {
  __typename?: "StripePaymentMethodGroupBy";
  _avg?: Maybe<StripePaymentMethodAvgAggregate>;
  _count?: Maybe<StripePaymentMethodCountAggregate>;
  _max?: Maybe<StripePaymentMethodMaxAggregate>;
  _min?: Maybe<StripePaymentMethodMinAggregate>;
  _sum?: Maybe<StripePaymentMethodSumAggregate>;
  brand: Scalars["String"];
  created_at: Scalars["DateTime"];
  exp_month: Scalars["Int"];
  exp_year: Scalars["Int"];
  id: Scalars["String"];
  is_default: Scalars["Boolean"];
  last4: Scalars["String"];
  object_json: Scalars["JSON"];
  stripe_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
};

export type StripePaymentMethodListRelationFilter = {
  every?: InputMaybe<StripePaymentMethodWhereInput>;
  none?: InputMaybe<StripePaymentMethodWhereInput>;
  some?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type StripePaymentMethodMaxAggregate = {
  __typename?: "StripePaymentMethodMaxAggregate";
  brand?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  exp_month?: Maybe<Scalars["Int"]>;
  exp_year?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  is_default?: Maybe<Scalars["Boolean"]>;
  last4?: Maybe<Scalars["String"]>;
  stripe_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type StripePaymentMethodMaxOrderByAggregateInput = {
  brand?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  exp_month?: InputMaybe<SortOrder>;
  exp_year?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_default?: InputMaybe<SortOrder>;
  last4?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type StripePaymentMethodMinAggregate = {
  __typename?: "StripePaymentMethodMinAggregate";
  brand?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  exp_month?: Maybe<Scalars["Int"]>;
  exp_year?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  is_default?: Maybe<Scalars["Boolean"]>;
  last4?: Maybe<Scalars["String"]>;
  stripe_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type StripePaymentMethodMinOrderByAggregateInput = {
  brand?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  exp_month?: InputMaybe<SortOrder>;
  exp_year?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_default?: InputMaybe<SortOrder>;
  last4?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type StripePaymentMethodOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StripePaymentMethodOrderByWithAggregationInput = {
  _avg?: InputMaybe<StripePaymentMethodAvgOrderByAggregateInput>;
  _count?: InputMaybe<StripePaymentMethodCountOrderByAggregateInput>;
  _max?: InputMaybe<StripePaymentMethodMaxOrderByAggregateInput>;
  _min?: InputMaybe<StripePaymentMethodMinOrderByAggregateInput>;
  _sum?: InputMaybe<StripePaymentMethodSumOrderByAggregateInput>;
  brand?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  exp_month?: InputMaybe<SortOrder>;
  exp_year?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_default?: InputMaybe<SortOrder>;
  last4?: InputMaybe<SortOrder>;
  object_json?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type StripePaymentMethodOrderByWithRelationInput = {
  brand?: InputMaybe<SortOrder>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  exp_month?: InputMaybe<SortOrder>;
  exp_year?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_default?: InputMaybe<SortOrder>;
  last4?: InputMaybe<SortOrder>;
  object_json?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export type StripePaymentMethodRelationFilter = {
  is?: InputMaybe<StripePaymentMethodWhereInput>;
  isNot?: InputMaybe<StripePaymentMethodWhereInput>;
};

export enum StripePaymentMethodScalarFieldEnum {
  Brand = "brand",
  CreatedAt = "created_at",
  ExpMonth = "exp_month",
  ExpYear = "exp_year",
  Id = "id",
  IsDefault = "is_default",
  Last4 = "last4",
  ObjectJson = "object_json",
  StripeId = "stripe_id",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type StripePaymentMethodScalarWhereInput = {
  AND?: InputMaybe<Array<StripePaymentMethodScalarWhereInput>>;
  NOT?: InputMaybe<Array<StripePaymentMethodScalarWhereInput>>;
  OR?: InputMaybe<Array<StripePaymentMethodScalarWhereInput>>;
  brand?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  exp_month?: InputMaybe<IntFilter>;
  exp_year?: InputMaybe<IntFilter>;
  id?: InputMaybe<UuidFilter>;
  is_default?: InputMaybe<BoolFilter>;
  last4?: InputMaybe<StringFilter>;
  object_json?: InputMaybe<JsonFilter>;
  stripe_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type StripePaymentMethodScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  brand?: InputMaybe<StringWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  exp_month?: InputMaybe<IntWithAggregatesFilter>;
  exp_year?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  is_default?: InputMaybe<BoolWithAggregatesFilter>;
  last4?: InputMaybe<StringWithAggregatesFilter>;
  object_json?: InputMaybe<JsonWithAggregatesFilter>;
  stripe_id?: InputMaybe<StringWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export type StripePaymentMethodSumAggregate = {
  __typename?: "StripePaymentMethodSumAggregate";
  exp_month?: Maybe<Scalars["Int"]>;
  exp_year?: Maybe<Scalars["Int"]>;
};

export type StripePaymentMethodSumOrderByAggregateInput = {
  exp_month?: InputMaybe<SortOrder>;
  exp_year?: InputMaybe<SortOrder>;
};

export type StripePaymentMethodUpdateInput = {
  brand?: InputMaybe<StringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutStripe_Payment_MethodNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exp_month?: InputMaybe<IntFieldUpdateOperationsInput>;
  exp_year?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last4?: InputMaybe<StringFieldUpdateOperationsInput>;
  object_json?: InputMaybe<Scalars["JSON"]>;
  stripe_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutStripePaymentMethodsNestedInput>;
};

export type StripePaymentMethodUpdateManyMutationInput = {
  brand?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exp_month?: InputMaybe<IntFieldUpdateOperationsInput>;
  exp_year?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last4?: InputMaybe<StringFieldUpdateOperationsInput>;
  object_json?: InputMaybe<Scalars["JSON"]>;
  stripe_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StripePaymentMethodUpdateManyWithWhereWithoutUserInput = {
  data: StripePaymentMethodUpdateManyMutationInput;
  where: StripePaymentMethodScalarWhereInput;
};

export type StripePaymentMethodUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<StripePaymentMethodCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<StripePaymentMethodCreateWithoutUserInput>>;
  createMany?: InputMaybe<StripePaymentMethodCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<StripePaymentMethodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StripePaymentMethodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StripePaymentMethodWhereUniqueInput>>;
  set?: InputMaybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: InputMaybe<
    Array<StripePaymentMethodUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<StripePaymentMethodUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<StripePaymentMethodUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type StripePaymentMethodUpdateOneRequiredWithoutCompany_Payment_MethodsNestedInput =
  {
    connect?: InputMaybe<StripePaymentMethodWhereUniqueInput>;
    connectOrCreate?: InputMaybe<StripePaymentMethodCreateOrConnectWithoutCompany_Payment_MethodsInput>;
    create?: InputMaybe<StripePaymentMethodCreateWithoutCompany_Payment_MethodsInput>;
    update?: InputMaybe<StripePaymentMethodUpdateWithoutCompany_Payment_MethodsInput>;
    upsert?: InputMaybe<StripePaymentMethodUpsertWithoutCompany_Payment_MethodsInput>;
  };

export type StripePaymentMethodUpdateWithWhereUniqueWithoutUserInput = {
  data: StripePaymentMethodUpdateWithoutUserInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodUpdateWithoutCompany_Payment_MethodsInput = {
  brand?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exp_month?: InputMaybe<IntFieldUpdateOperationsInput>;
  exp_year?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last4?: InputMaybe<StringFieldUpdateOperationsInput>;
  object_json?: InputMaybe<Scalars["JSON"]>;
  stripe_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutStripePaymentMethodsNestedInput>;
};

export type StripePaymentMethodUpdateWithoutUserInput = {
  brand?: InputMaybe<StringFieldUpdateOperationsInput>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodUpdateManyWithoutStripe_Payment_MethodNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exp_month?: InputMaybe<IntFieldUpdateOperationsInput>;
  exp_year?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last4?: InputMaybe<StringFieldUpdateOperationsInput>;
  object_json?: InputMaybe<Scalars["JSON"]>;
  stripe_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StripePaymentMethodUpsertWithWhereUniqueWithoutUserInput = {
  create: StripePaymentMethodCreateWithoutUserInput;
  update: StripePaymentMethodUpdateWithoutUserInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodUpsertWithoutCompany_Payment_MethodsInput = {
  create: StripePaymentMethodCreateWithoutCompany_Payment_MethodsInput;
  update: StripePaymentMethodUpdateWithoutCompany_Payment_MethodsInput;
};

export type StripePaymentMethodWhereInput = {
  AND?: InputMaybe<Array<StripePaymentMethodWhereInput>>;
  NOT?: InputMaybe<Array<StripePaymentMethodWhereInput>>;
  OR?: InputMaybe<Array<StripePaymentMethodWhereInput>>;
  brand?: InputMaybe<StringFilter>;
  company_payment_methods?: InputMaybe<CompanyPaymentMethodListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  exp_month?: InputMaybe<IntFilter>;
  exp_year?: InputMaybe<IntFilter>;
  id?: InputMaybe<UuidFilter>;
  is_default?: InputMaybe<BoolFilter>;
  last4?: InputMaybe<StringFilter>;
  object_json?: InputMaybe<JsonFilter>;
  stripe_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type StripePaymentMethodWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  stripe_id?: InputMaybe<Scalars["String"]>;
};

export enum StripeTxStatus {
  Failure = "FAILURE",
  Incomplete = "INCOMPLETE",
  Pending = "PENDING",
  Success = "SUCCESS"
}

export enum StripeTxType {
  Capture = "CAPTURE",
  Refund = "REFUND"
}

export type TerminalLocation = {
  __typename?: "TerminalLocation";
  address: Address;
  display_name: Scalars["String"];
  id: Scalars["String"];
  livemode: Scalars["Boolean"];
  object: Scalars["String"];
};

export enum TippingType {
  FixedAmount = "FIXED_AMOUNT",
  FixedAmountPerArtist = "FIXED_AMOUNT_PER_ARTIST",
  NoTip = "NO_TIP",
  PercentAmount = "PERCENT_AMOUNT"
}

export type Transaction = {
  __typename?: "Transaction";
  amount: Scalars["Float"];
  application_fee_amount_captured?: Maybe<Scalars["Decimal"]>;
  code?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["String"];
  order: Order;
  order_id: Scalars["String"];
  payment_fees: Scalars["Float"];
  payment_fees_percent: Scalars["Float"];
  payment_intent_id?: Maybe<Scalars["String"]>;
  payment_method: PaymentMethod;
  platform_take_percent: Scalars["Float"];
  refund?: Maybe<Refund>;
  refund_id?: Maybe<Scalars["String"]>;
  status?: Maybe<StripeTxStatus>;
  stripe_id?: Maybe<Scalars["String"]>;
  tipping_type?: Maybe<TippingType>;
  total_charged_amount: Scalars["Float"];
  total_tip_amount?: Maybe<Scalars["Float"]>;
  type?: Maybe<StripeTxType>;
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
  user_pays_fees: Scalars["Boolean"];
};

export type TransactionAvgAggregate = {
  __typename?: "TransactionAvgAggregate";
  amount?: Maybe<Scalars["Float"]>;
  application_fee_amount_captured?: Maybe<Scalars["Decimal"]>;
  payment_fees?: Maybe<Scalars["Float"]>;
  payment_fees_percent?: Maybe<Scalars["Float"]>;
  platform_take_percent?: Maybe<Scalars["Float"]>;
  total_charged_amount?: Maybe<Scalars["Float"]>;
  total_tip_amount?: Maybe<Scalars["Float"]>;
};

export type TransactionAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  application_fee_amount_captured?: InputMaybe<SortOrder>;
  payment_fees?: InputMaybe<SortOrder>;
  payment_fees_percent?: InputMaybe<SortOrder>;
  platform_take_percent?: InputMaybe<SortOrder>;
  total_charged_amount?: InputMaybe<SortOrder>;
  total_tip_amount?: InputMaybe<SortOrder>;
};

export type TransactionCountAggregate = {
  __typename?: "TransactionCountAggregate";
  _all: Scalars["Int"];
  amount: Scalars["Int"];
  application_fee_amount_captured: Scalars["Int"];
  code: Scalars["Int"];
  created_at: Scalars["Int"];
  description: Scalars["Int"];
  id: Scalars["Int"];
  order_id: Scalars["Int"];
  payment_fees: Scalars["Int"];
  payment_fees_percent: Scalars["Int"];
  payment_intent_id: Scalars["Int"];
  payment_method: Scalars["Int"];
  platform_take_percent: Scalars["Int"];
  refund_id: Scalars["Int"];
  status: Scalars["Int"];
  stripe_id: Scalars["Int"];
  tipping_type: Scalars["Int"];
  total_charged_amount: Scalars["Int"];
  total_tip_amount: Scalars["Int"];
  type: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
  user_pays_fees: Scalars["Int"];
};

export type TransactionCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  application_fee_amount_captured?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  payment_fees?: InputMaybe<SortOrder>;
  payment_fees_percent?: InputMaybe<SortOrder>;
  payment_intent_id?: InputMaybe<SortOrder>;
  payment_method?: InputMaybe<SortOrder>;
  platform_take_percent?: InputMaybe<SortOrder>;
  refund_id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  tipping_type?: InputMaybe<SortOrder>;
  total_charged_amount?: InputMaybe<SortOrder>;
  total_tip_amount?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  user_pays_fees?: InputMaybe<SortOrder>;
};

export type TransactionCreateInput = {
  amount: Scalars["Float"];
  application_fee_amount_captured?: InputMaybe<Scalars["Decimal"]>;
  code?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  order: OrderCreateNestedOneWithoutTransactionsInput;
  payment_fees?: InputMaybe<Scalars["Float"]>;
  payment_fees_percent?: InputMaybe<Scalars["Float"]>;
  payment_intent_id?: InputMaybe<Scalars["String"]>;
  payment_method: PaymentMethod;
  platform_take_percent?: InputMaybe<Scalars["Float"]>;
  refund?: InputMaybe<RefundCreateNestedOneWithoutTransactionInput>;
  status?: InputMaybe<StripeTxStatus>;
  stripe_id?: InputMaybe<Scalars["String"]>;
  tipping_type?: InputMaybe<TippingType>;
  total_charged_amount?: InputMaybe<Scalars["Float"]>;
  total_tip_amount?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<StripeTxType>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutTransactionInput;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
};

export type TransactionCreateManyInput = {
  amount: Scalars["Float"];
  application_fee_amount_captured?: InputMaybe<Scalars["Decimal"]>;
  code?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  order_id: Scalars["String"];
  payment_fees?: InputMaybe<Scalars["Float"]>;
  payment_fees_percent?: InputMaybe<Scalars["Float"]>;
  payment_intent_id?: InputMaybe<Scalars["String"]>;
  payment_method: PaymentMethod;
  platform_take_percent?: InputMaybe<Scalars["Float"]>;
  refund_id?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<StripeTxStatus>;
  stripe_id?: InputMaybe<Scalars["String"]>;
  tipping_type?: InputMaybe<TippingType>;
  total_charged_amount?: InputMaybe<Scalars["Float"]>;
  total_tip_amount?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<StripeTxType>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
};

export type TransactionCreateManyOrderInput = {
  amount: Scalars["Float"];
  application_fee_amount_captured?: InputMaybe<Scalars["Decimal"]>;
  code?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  payment_fees?: InputMaybe<Scalars["Float"]>;
  payment_fees_percent?: InputMaybe<Scalars["Float"]>;
  payment_intent_id?: InputMaybe<Scalars["String"]>;
  payment_method: PaymentMethod;
  platform_take_percent?: InputMaybe<Scalars["Float"]>;
  refund_id?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<StripeTxStatus>;
  stripe_id?: InputMaybe<Scalars["String"]>;
  tipping_type?: InputMaybe<TippingType>;
  total_charged_amount?: InputMaybe<Scalars["Float"]>;
  total_tip_amount?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<StripeTxType>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
};

export type TransactionCreateManyOrderInputEnvelope = {
  data: Array<TransactionCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TransactionCreateManyUserInput = {
  amount: Scalars["Float"];
  application_fee_amount_captured?: InputMaybe<Scalars["Decimal"]>;
  code?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  order_id: Scalars["String"];
  payment_fees?: InputMaybe<Scalars["Float"]>;
  payment_fees_percent?: InputMaybe<Scalars["Float"]>;
  payment_intent_id?: InputMaybe<Scalars["String"]>;
  payment_method: PaymentMethod;
  platform_take_percent?: InputMaybe<Scalars["Float"]>;
  refund_id?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<StripeTxStatus>;
  stripe_id?: InputMaybe<Scalars["String"]>;
  tipping_type?: InputMaybe<TippingType>;
  total_charged_amount?: InputMaybe<Scalars["Float"]>;
  total_tip_amount?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<StripeTxType>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
};

export type TransactionCreateManyUserInputEnvelope = {
  data: Array<TransactionCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TransactionCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TransactionCreateOrConnectWithoutOrderInput>
  >;
  create?: InputMaybe<Array<TransactionCreateWithoutOrderInput>>;
  createMany?: InputMaybe<TransactionCreateManyOrderInputEnvelope>;
};

export type TransactionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TransactionCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<TransactionCreateWithoutUserInput>>;
  createMany?: InputMaybe<TransactionCreateManyUserInputEnvelope>;
};

export type TransactionCreateNestedOneWithoutRefundInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutRefundInput>;
  create?: InputMaybe<TransactionCreateWithoutRefundInput>;
};

export type TransactionCreateOrConnectWithoutOrderInput = {
  create: TransactionCreateWithoutOrderInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutRefundInput = {
  create: TransactionCreateWithoutRefundInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutUserInput = {
  create: TransactionCreateWithoutUserInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateWithoutOrderInput = {
  amount: Scalars["Float"];
  application_fee_amount_captured?: InputMaybe<Scalars["Decimal"]>;
  code?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  payment_fees?: InputMaybe<Scalars["Float"]>;
  payment_fees_percent?: InputMaybe<Scalars["Float"]>;
  payment_intent_id?: InputMaybe<Scalars["String"]>;
  payment_method: PaymentMethod;
  platform_take_percent?: InputMaybe<Scalars["Float"]>;
  refund?: InputMaybe<RefundCreateNestedOneWithoutTransactionInput>;
  status?: InputMaybe<StripeTxStatus>;
  stripe_id?: InputMaybe<Scalars["String"]>;
  tipping_type?: InputMaybe<TippingType>;
  total_charged_amount?: InputMaybe<Scalars["Float"]>;
  total_tip_amount?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<StripeTxType>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutTransactionInput;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
};

export type TransactionCreateWithoutRefundInput = {
  amount: Scalars["Float"];
  application_fee_amount_captured?: InputMaybe<Scalars["Decimal"]>;
  code?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  order: OrderCreateNestedOneWithoutTransactionsInput;
  payment_fees?: InputMaybe<Scalars["Float"]>;
  payment_fees_percent?: InputMaybe<Scalars["Float"]>;
  payment_intent_id?: InputMaybe<Scalars["String"]>;
  payment_method: PaymentMethod;
  platform_take_percent?: InputMaybe<Scalars["Float"]>;
  status?: InputMaybe<StripeTxStatus>;
  stripe_id?: InputMaybe<Scalars["String"]>;
  tipping_type?: InputMaybe<TippingType>;
  total_charged_amount?: InputMaybe<Scalars["Float"]>;
  total_tip_amount?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<StripeTxType>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutTransactionInput;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
};

export type TransactionCreateWithoutUserInput = {
  amount: Scalars["Float"];
  application_fee_amount_captured?: InputMaybe<Scalars["Decimal"]>;
  code?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  order: OrderCreateNestedOneWithoutTransactionsInput;
  payment_fees?: InputMaybe<Scalars["Float"]>;
  payment_fees_percent?: InputMaybe<Scalars["Float"]>;
  payment_intent_id?: InputMaybe<Scalars["String"]>;
  payment_method: PaymentMethod;
  platform_take_percent?: InputMaybe<Scalars["Float"]>;
  refund?: InputMaybe<RefundCreateNestedOneWithoutTransactionInput>;
  status?: InputMaybe<StripeTxStatus>;
  stripe_id?: InputMaybe<Scalars["String"]>;
  tipping_type?: InputMaybe<TippingType>;
  total_charged_amount?: InputMaybe<Scalars["Float"]>;
  total_tip_amount?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<StripeTxType>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_pays_fees?: InputMaybe<Scalars["Boolean"]>;
};

export type TransactionGroupBy = {
  __typename?: "TransactionGroupBy";
  _avg?: Maybe<TransactionAvgAggregate>;
  _count?: Maybe<TransactionCountAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
  amount: Scalars["Float"];
  application_fee_amount_captured?: Maybe<Scalars["Decimal"]>;
  code?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["String"];
  order_id: Scalars["String"];
  payment_fees: Scalars["Float"];
  payment_fees_percent: Scalars["Float"];
  payment_intent_id?: Maybe<Scalars["String"]>;
  payment_method: PaymentMethod;
  platform_take_percent: Scalars["Float"];
  refund_id?: Maybe<Scalars["String"]>;
  status?: Maybe<StripeTxStatus>;
  stripe_id?: Maybe<Scalars["String"]>;
  tipping_type?: Maybe<TippingType>;
  total_charged_amount: Scalars["Float"];
  total_tip_amount?: Maybe<Scalars["Float"]>;
  type?: Maybe<StripeTxType>;
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
  user_pays_fees: Scalars["Boolean"];
};

export type TransactionListRelationFilter = {
  every?: InputMaybe<TransactionWhereInput>;
  none?: InputMaybe<TransactionWhereInput>;
  some?: InputMaybe<TransactionWhereInput>;
};

export type TransactionMaxAggregate = {
  __typename?: "TransactionMaxAggregate";
  amount?: Maybe<Scalars["Float"]>;
  application_fee_amount_captured?: Maybe<Scalars["Decimal"]>;
  code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  payment_fees?: Maybe<Scalars["Float"]>;
  payment_fees_percent?: Maybe<Scalars["Float"]>;
  payment_intent_id?: Maybe<Scalars["String"]>;
  payment_method?: Maybe<PaymentMethod>;
  platform_take_percent?: Maybe<Scalars["Float"]>;
  refund_id?: Maybe<Scalars["String"]>;
  status?: Maybe<StripeTxStatus>;
  stripe_id?: Maybe<Scalars["String"]>;
  tipping_type?: Maybe<TippingType>;
  total_charged_amount?: Maybe<Scalars["Float"]>;
  total_tip_amount?: Maybe<Scalars["Float"]>;
  type?: Maybe<StripeTxType>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
  user_pays_fees?: Maybe<Scalars["Boolean"]>;
};

export type TransactionMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  application_fee_amount_captured?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  payment_fees?: InputMaybe<SortOrder>;
  payment_fees_percent?: InputMaybe<SortOrder>;
  payment_intent_id?: InputMaybe<SortOrder>;
  payment_method?: InputMaybe<SortOrder>;
  platform_take_percent?: InputMaybe<SortOrder>;
  refund_id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  tipping_type?: InputMaybe<SortOrder>;
  total_charged_amount?: InputMaybe<SortOrder>;
  total_tip_amount?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  user_pays_fees?: InputMaybe<SortOrder>;
};

export type TransactionMinAggregate = {
  __typename?: "TransactionMinAggregate";
  amount?: Maybe<Scalars["Float"]>;
  application_fee_amount_captured?: Maybe<Scalars["Decimal"]>;
  code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  payment_fees?: Maybe<Scalars["Float"]>;
  payment_fees_percent?: Maybe<Scalars["Float"]>;
  payment_intent_id?: Maybe<Scalars["String"]>;
  payment_method?: Maybe<PaymentMethod>;
  platform_take_percent?: Maybe<Scalars["Float"]>;
  refund_id?: Maybe<Scalars["String"]>;
  status?: Maybe<StripeTxStatus>;
  stripe_id?: Maybe<Scalars["String"]>;
  tipping_type?: Maybe<TippingType>;
  total_charged_amount?: Maybe<Scalars["Float"]>;
  total_tip_amount?: Maybe<Scalars["Float"]>;
  type?: Maybe<StripeTxType>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
  user_pays_fees?: Maybe<Scalars["Boolean"]>;
};

export type TransactionMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  application_fee_amount_captured?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  payment_fees?: InputMaybe<SortOrder>;
  payment_fees_percent?: InputMaybe<SortOrder>;
  payment_intent_id?: InputMaybe<SortOrder>;
  payment_method?: InputMaybe<SortOrder>;
  platform_take_percent?: InputMaybe<SortOrder>;
  refund_id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_id?: InputMaybe<SortOrder>;
  tipping_type?: InputMaybe<SortOrder>;
  total_charged_amount?: InputMaybe<SortOrder>;
  total_tip_amount?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  user_pays_fees?: InputMaybe<SortOrder>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithAggregationInput = {
  _avg?: InputMaybe<TransactionAvgOrderByAggregateInput>;
  _count?: InputMaybe<TransactionCountOrderByAggregateInput>;
  _max?: InputMaybe<TransactionMaxOrderByAggregateInput>;
  _min?: InputMaybe<TransactionMinOrderByAggregateInput>;
  _sum?: InputMaybe<TransactionSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  application_fee_amount_captured?: InputMaybe<SortOrderInput>;
  code?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  payment_fees?: InputMaybe<SortOrder>;
  payment_fees_percent?: InputMaybe<SortOrder>;
  payment_intent_id?: InputMaybe<SortOrderInput>;
  payment_method?: InputMaybe<SortOrder>;
  platform_take_percent?: InputMaybe<SortOrder>;
  refund_id?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrderInput>;
  stripe_id?: InputMaybe<SortOrderInput>;
  tipping_type?: InputMaybe<SortOrderInput>;
  total_charged_amount?: InputMaybe<SortOrder>;
  total_tip_amount?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  user_pays_fees?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  application_fee_amount_captured?: InputMaybe<SortOrderInput>;
  code?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  order_id?: InputMaybe<SortOrder>;
  payment_fees?: InputMaybe<SortOrder>;
  payment_fees_percent?: InputMaybe<SortOrder>;
  payment_intent_id?: InputMaybe<SortOrderInput>;
  payment_method?: InputMaybe<SortOrder>;
  platform_take_percent?: InputMaybe<SortOrder>;
  refund?: InputMaybe<RefundOrderByWithRelationInput>;
  refund_id?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrderInput>;
  stripe_id?: InputMaybe<SortOrderInput>;
  tipping_type?: InputMaybe<SortOrderInput>;
  total_charged_amount?: InputMaybe<SortOrder>;
  total_tip_amount?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
  user_pays_fees?: InputMaybe<SortOrder>;
};

export type TransactionRelationFilter = {
  is?: InputMaybe<TransactionWhereInput>;
  isNot?: InputMaybe<TransactionWhereInput>;
};

export enum TransactionScalarFieldEnum {
  Amount = "amount",
  ApplicationFeeAmountCaptured = "application_fee_amount_captured",
  Code = "code",
  CreatedAt = "created_at",
  Description = "description",
  Id = "id",
  OrderId = "order_id",
  PaymentFees = "payment_fees",
  PaymentFeesPercent = "payment_fees_percent",
  PaymentIntentId = "payment_intent_id",
  PaymentMethod = "payment_method",
  PlatformTakePercent = "platform_take_percent",
  RefundId = "refund_id",
  Status = "status",
  StripeId = "stripe_id",
  TippingType = "tipping_type",
  TotalChargedAmount = "total_charged_amount",
  TotalTipAmount = "total_tip_amount",
  Type = "type",
  UpdatedAt = "updated_at",
  UserId = "user_id",
  UserPaysFees = "user_pays_fees"
}

export type TransactionScalarWhereInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  application_fee_amount_captured?: InputMaybe<DecimalNullableFilter>;
  code?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  order_id?: InputMaybe<UuidFilter>;
  payment_fees?: InputMaybe<FloatFilter>;
  payment_fees_percent?: InputMaybe<FloatFilter>;
  payment_intent_id?: InputMaybe<StringNullableFilter>;
  payment_method?: InputMaybe<EnumPaymentMethodFilter>;
  platform_take_percent?: InputMaybe<FloatFilter>;
  refund_id?: InputMaybe<UuidNullableFilter>;
  status?: InputMaybe<EnumStripeTxStatusNullableFilter>;
  stripe_id?: InputMaybe<StringNullableFilter>;
  tipping_type?: InputMaybe<EnumTippingTypeNullableFilter>;
  total_charged_amount?: InputMaybe<FloatFilter>;
  total_tip_amount?: InputMaybe<FloatNullableFilter>;
  type?: InputMaybe<EnumStripeTxTypeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
  user_pays_fees?: InputMaybe<BoolFilter>;
};

export type TransactionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<FloatWithAggregatesFilter>;
  application_fee_amount_captured?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  code?: InputMaybe<StringNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  order_id?: InputMaybe<UuidWithAggregatesFilter>;
  payment_fees?: InputMaybe<FloatWithAggregatesFilter>;
  payment_fees_percent?: InputMaybe<FloatWithAggregatesFilter>;
  payment_intent_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  payment_method?: InputMaybe<EnumPaymentMethodWithAggregatesFilter>;
  platform_take_percent?: InputMaybe<FloatWithAggregatesFilter>;
  refund_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumStripeTxStatusNullableWithAggregatesFilter>;
  stripe_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  tipping_type?: InputMaybe<EnumTippingTypeNullableWithAggregatesFilter>;
  total_charged_amount?: InputMaybe<FloatWithAggregatesFilter>;
  total_tip_amount?: InputMaybe<FloatNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumStripeTxTypeNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
  user_pays_fees?: InputMaybe<BoolWithAggregatesFilter>;
};

export type TransactionSumAggregate = {
  __typename?: "TransactionSumAggregate";
  amount?: Maybe<Scalars["Float"]>;
  application_fee_amount_captured?: Maybe<Scalars["Decimal"]>;
  payment_fees?: Maybe<Scalars["Float"]>;
  payment_fees_percent?: Maybe<Scalars["Float"]>;
  platform_take_percent?: Maybe<Scalars["Float"]>;
  total_charged_amount?: Maybe<Scalars["Float"]>;
  total_tip_amount?: Maybe<Scalars["Float"]>;
};

export type TransactionSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  application_fee_amount_captured?: InputMaybe<SortOrder>;
  payment_fees?: InputMaybe<SortOrder>;
  payment_fees_percent?: InputMaybe<SortOrder>;
  platform_take_percent?: InputMaybe<SortOrder>;
  total_charged_amount?: InputMaybe<SortOrder>;
  total_tip_amount?: InputMaybe<SortOrder>;
};

export type TransactionUpdateInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  application_fee_amount_captured?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutTransactionsNestedInput>;
  payment_fees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_fees_percent?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_intent_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_method?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  platform_take_percent?: InputMaybe<FloatFieldUpdateOperationsInput>;
  refund?: InputMaybe<RefundUpdateOneWithoutTransactionNestedInput>;
  status?: InputMaybe<NullableEnumStripeTxStatusFieldUpdateOperationsInput>;
  stripe_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tipping_type?: InputMaybe<NullableEnumTippingTypeFieldUpdateOperationsInput>;
  total_charged_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  total_tip_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumStripeTxTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTransactionNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyMutationInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  application_fee_amount_captured?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  payment_fees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_fees_percent?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_intent_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_method?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  platform_take_percent?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumStripeTxStatusFieldUpdateOperationsInput>;
  stripe_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tipping_type?: InputMaybe<NullableEnumTippingTypeFieldUpdateOperationsInput>;
  total_charged_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  total_tip_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumStripeTxTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyWithWhereWithoutOrderInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithWhereWithoutUserInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TransactionCreateOrConnectWithoutOrderInput>
  >;
  create?: InputMaybe<Array<TransactionCreateWithoutOrderInput>>;
  createMany?: InputMaybe<TransactionCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<
    Array<TransactionUpdateManyWithWhereWithoutOrderInput>
  >;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type TransactionUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TransactionCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<TransactionCreateWithoutUserInput>>;
  createMany?: InputMaybe<TransactionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<
    Array<TransactionUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type TransactionUpdateOneWithoutRefundNestedInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutRefundInput>;
  create?: InputMaybe<TransactionCreateWithoutRefundInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<TransactionUpdateWithoutRefundInput>;
  upsert?: InputMaybe<TransactionUpsertWithoutRefundInput>;
};

export type TransactionUpdateWithWhereUniqueWithoutOrderInput = {
  data: TransactionUpdateWithoutOrderInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithWhereUniqueWithoutUserInput = {
  data: TransactionUpdateWithoutUserInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithoutOrderInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  application_fee_amount_captured?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  payment_fees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_fees_percent?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_intent_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_method?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  platform_take_percent?: InputMaybe<FloatFieldUpdateOperationsInput>;
  refund?: InputMaybe<RefundUpdateOneWithoutTransactionNestedInput>;
  status?: InputMaybe<NullableEnumStripeTxStatusFieldUpdateOperationsInput>;
  stripe_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tipping_type?: InputMaybe<NullableEnumTippingTypeFieldUpdateOperationsInput>;
  total_charged_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  total_tip_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumStripeTxTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTransactionNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type TransactionUpdateWithoutRefundInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  application_fee_amount_captured?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutTransactionsNestedInput>;
  payment_fees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_fees_percent?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_intent_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_method?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  platform_take_percent?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumStripeTxStatusFieldUpdateOperationsInput>;
  stripe_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tipping_type?: InputMaybe<NullableEnumTippingTypeFieldUpdateOperationsInput>;
  total_charged_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  total_tip_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumStripeTxTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTransactionNestedInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type TransactionUpdateWithoutUserInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  application_fee_amount_captured?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutTransactionsNestedInput>;
  payment_fees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_fees_percent?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_intent_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_method?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  platform_take_percent?: InputMaybe<FloatFieldUpdateOperationsInput>;
  refund?: InputMaybe<RefundUpdateOneWithoutTransactionNestedInput>;
  status?: InputMaybe<NullableEnumStripeTxStatusFieldUpdateOperationsInput>;
  stripe_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tipping_type?: InputMaybe<NullableEnumTippingTypeFieldUpdateOperationsInput>;
  total_charged_amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  total_tip_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumStripeTxTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_pays_fees?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type TransactionUpsertWithWhereUniqueWithoutOrderInput = {
  create: TransactionCreateWithoutOrderInput;
  update: TransactionUpdateWithoutOrderInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithWhereUniqueWithoutUserInput = {
  create: TransactionCreateWithoutUserInput;
  update: TransactionUpdateWithoutUserInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithoutRefundInput = {
  create: TransactionCreateWithoutRefundInput;
  update: TransactionUpdateWithoutRefundInput;
};

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  application_fee_amount_captured?: InputMaybe<DecimalNullableFilter>;
  code?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  order_id?: InputMaybe<UuidFilter>;
  payment_fees?: InputMaybe<FloatFilter>;
  payment_fees_percent?: InputMaybe<FloatFilter>;
  payment_intent_id?: InputMaybe<StringNullableFilter>;
  payment_method?: InputMaybe<EnumPaymentMethodFilter>;
  platform_take_percent?: InputMaybe<FloatFilter>;
  refund?: InputMaybe<RefundRelationFilter>;
  refund_id?: InputMaybe<UuidNullableFilter>;
  status?: InputMaybe<EnumStripeTxStatusNullableFilter>;
  stripe_id?: InputMaybe<StringNullableFilter>;
  tipping_type?: InputMaybe<EnumTippingTypeNullableFilter>;
  total_charged_amount?: InputMaybe<FloatFilter>;
  total_tip_amount?: InputMaybe<FloatNullableFilter>;
  type?: InputMaybe<EnumStripeTxTypeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
  user_pays_fees?: InputMaybe<BoolFilter>;
};

export type TransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  refund_id?: InputMaybe<Scalars["String"]>;
};

export type TxAddon = {
  __typename?: "TxAddon";
  addon?: Maybe<Service>;
  addon_id: Scalars["String"];
  appointment_service?: Maybe<AppointmentService>;
  appointment_service_id?: Maybe<Scalars["String"]>;
  artist?: Maybe<Artist>;
  artist_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  order?: Maybe<Order>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  updated_at: Scalars["DateTime"];
};

export type TxAddonAvgAggregate = {
  __typename?: "TxAddonAvgAggregate";
  price?: Maybe<Scalars["Float"]>;
};

export type TxAddonAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type TxAddonCountAggregate = {
  __typename?: "TxAddonCountAggregate";
  _all: Scalars["Int"];
  addon_id: Scalars["Int"];
  appointment_service_id: Scalars["Int"];
  artist_id: Scalars["Int"];
  created_at: Scalars["Int"];
  description: Scalars["Int"];
  id: Scalars["Int"];
  order_id: Scalars["Int"];
  price: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type TxAddonCountOrderByAggregateInput = {
  addon_id?: InputMaybe<SortOrder>;
  appointment_service_id?: InputMaybe<SortOrder>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxAddonCreateInput = {
  addon?: InputMaybe<ServiceCreateNestedOneWithoutTx_AddonsInput>;
  appointment_service?: InputMaybe<AppointmentServiceCreateNestedOneWithoutTx_AddonsInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutTxAddonsInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTx_AddonsInput>;
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxAddonCreateManyAddonInput = {
  appointment_service_id?: InputMaybe<Scalars["String"]>;
  artist_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxAddonCreateManyAddonInputEnvelope = {
  data: Array<TxAddonCreateManyAddonInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TxAddonCreateManyAppointment_ServiceInput = {
  addon_id: Scalars["String"];
  artist_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxAddonCreateManyAppointment_ServiceInputEnvelope = {
  data: Array<TxAddonCreateManyAppointment_ServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TxAddonCreateManyArtistInput = {
  addon_id: Scalars["String"];
  appointment_service_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxAddonCreateManyArtistInputEnvelope = {
  data: Array<TxAddonCreateManyArtistInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TxAddonCreateManyInput = {
  addon_id: Scalars["String"];
  appointment_service_id?: InputMaybe<Scalars["String"]>;
  artist_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxAddonCreateManyOrderInput = {
  addon_id: Scalars["String"];
  appointment_service_id?: InputMaybe<Scalars["String"]>;
  artist_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxAddonCreateManyOrderInputEnvelope = {
  data: Array<TxAddonCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TxAddonCreateNestedManyWithoutAddonInput = {
  connect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TxAddonCreateOrConnectWithoutAddonInput>>;
  create?: InputMaybe<Array<TxAddonCreateWithoutAddonInput>>;
  createMany?: InputMaybe<TxAddonCreateManyAddonInputEnvelope>;
};

export type TxAddonCreateNestedManyWithoutAppointment_ServiceInput = {
  connect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TxAddonCreateOrConnectWithoutAppointment_ServiceInput>
  >;
  create?: InputMaybe<Array<TxAddonCreateWithoutAppointment_ServiceInput>>;
  createMany?: InputMaybe<TxAddonCreateManyAppointment_ServiceInputEnvelope>;
};

export type TxAddonCreateNestedManyWithoutArtistInput = {
  connect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TxAddonCreateOrConnectWithoutArtistInput>>;
  create?: InputMaybe<Array<TxAddonCreateWithoutArtistInput>>;
  createMany?: InputMaybe<TxAddonCreateManyArtistInputEnvelope>;
};

export type TxAddonCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TxAddonCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<TxAddonCreateWithoutOrderInput>>;
  createMany?: InputMaybe<TxAddonCreateManyOrderInputEnvelope>;
};

export type TxAddonCreateOrConnectWithoutAddonInput = {
  create: TxAddonCreateWithoutAddonInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonCreateOrConnectWithoutAppointment_ServiceInput = {
  create: TxAddonCreateWithoutAppointment_ServiceInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonCreateOrConnectWithoutArtistInput = {
  create: TxAddonCreateWithoutArtistInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonCreateOrConnectWithoutOrderInput = {
  create: TxAddonCreateWithoutOrderInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonCreateWithoutAddonInput = {
  appointment_service?: InputMaybe<AppointmentServiceCreateNestedOneWithoutTx_AddonsInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutTxAddonsInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTx_AddonsInput>;
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxAddonCreateWithoutAppointment_ServiceInput = {
  addon?: InputMaybe<ServiceCreateNestedOneWithoutTx_AddonsInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutTxAddonsInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTx_AddonsInput>;
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxAddonCreateWithoutArtistInput = {
  addon?: InputMaybe<ServiceCreateNestedOneWithoutTx_AddonsInput>;
  appointment_service?: InputMaybe<AppointmentServiceCreateNestedOneWithoutTx_AddonsInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTx_AddonsInput>;
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxAddonCreateWithoutOrderInput = {
  addon?: InputMaybe<ServiceCreateNestedOneWithoutTx_AddonsInput>;
  appointment_service?: InputMaybe<AppointmentServiceCreateNestedOneWithoutTx_AddonsInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutTxAddonsInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxAddonGroupBy = {
  __typename?: "TxAddonGroupBy";
  _avg?: Maybe<TxAddonAvgAggregate>;
  _count?: Maybe<TxAddonCountAggregate>;
  _max?: Maybe<TxAddonMaxAggregate>;
  _min?: Maybe<TxAddonMinAggregate>;
  _sum?: Maybe<TxAddonSumAggregate>;
  addon_id: Scalars["String"];
  appointment_service_id?: Maybe<Scalars["String"]>;
  artist_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  order_id: Scalars["String"];
  price: Scalars["Float"];
  updated_at: Scalars["DateTime"];
};

export type TxAddonListRelationFilter = {
  every?: InputMaybe<TxAddonWhereInput>;
  none?: InputMaybe<TxAddonWhereInput>;
  some?: InputMaybe<TxAddonWhereInput>;
};

export type TxAddonMaxAggregate = {
  __typename?: "TxAddonMaxAggregate";
  addon_id?: Maybe<Scalars["String"]>;
  appointment_service_id?: Maybe<Scalars["String"]>;
  artist_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type TxAddonMaxOrderByAggregateInput = {
  addon_id?: InputMaybe<SortOrder>;
  appointment_service_id?: InputMaybe<SortOrder>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxAddonMinAggregate = {
  __typename?: "TxAddonMinAggregate";
  addon_id?: Maybe<Scalars["String"]>;
  appointment_service_id?: Maybe<Scalars["String"]>;
  artist_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type TxAddonMinOrderByAggregateInput = {
  addon_id?: InputMaybe<SortOrder>;
  appointment_service_id?: InputMaybe<SortOrder>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxAddonOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TxAddonOrderByWithAggregationInput = {
  _avg?: InputMaybe<TxAddonAvgOrderByAggregateInput>;
  _count?: InputMaybe<TxAddonCountOrderByAggregateInput>;
  _max?: InputMaybe<TxAddonMaxOrderByAggregateInput>;
  _min?: InputMaybe<TxAddonMinOrderByAggregateInput>;
  _sum?: InputMaybe<TxAddonSumOrderByAggregateInput>;
  addon_id?: InputMaybe<SortOrder>;
  appointment_service_id?: InputMaybe<SortOrderInput>;
  artist_id?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxAddonOrderByWithRelationInput = {
  addon?: InputMaybe<ServiceOrderByWithRelationInput>;
  addon_id?: InputMaybe<SortOrder>;
  appointment_service?: InputMaybe<AppointmentServiceOrderByWithRelationInput>;
  appointment_service_id?: InputMaybe<SortOrderInput>;
  artist?: InputMaybe<ArtistOrderByWithRelationInput>;
  artist_id?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum TxAddonScalarFieldEnum {
  AddonId = "addon_id",
  AppointmentServiceId = "appointment_service_id",
  ArtistId = "artist_id",
  CreatedAt = "created_at",
  Description = "description",
  Id = "id",
  OrderId = "order_id",
  Price = "price",
  UpdatedAt = "updated_at"
}

export type TxAddonScalarWhereInput = {
  AND?: InputMaybe<Array<TxAddonScalarWhereInput>>;
  NOT?: InputMaybe<Array<TxAddonScalarWhereInput>>;
  OR?: InputMaybe<Array<TxAddonScalarWhereInput>>;
  addon_id?: InputMaybe<UuidFilter>;
  appointment_service_id?: InputMaybe<UuidNullableFilter>;
  artist_id?: InputMaybe<UuidNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  order_id?: InputMaybe<UuidFilter>;
  price?: InputMaybe<FloatFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TxAddonScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TxAddonScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TxAddonScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TxAddonScalarWhereWithAggregatesInput>>;
  addon_id?: InputMaybe<UuidWithAggregatesFilter>;
  appointment_service_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  artist_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  order_id?: InputMaybe<UuidWithAggregatesFilter>;
  price?: InputMaybe<FloatWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type TxAddonSumAggregate = {
  __typename?: "TxAddonSumAggregate";
  price?: Maybe<Scalars["Float"]>;
};

export type TxAddonSumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type TxAddonUpdateInput = {
  addon?: InputMaybe<ServiceUpdateOneWithoutTx_AddonsNestedInput>;
  appointment_service?: InputMaybe<AppointmentServiceUpdateOneWithoutTx_AddonsNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutTxAddonsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutTx_AddonsNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxAddonUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxAddonUpdateManyWithWhereWithoutAddonInput = {
  data: TxAddonUpdateManyMutationInput;
  where: TxAddonScalarWhereInput;
};

export type TxAddonUpdateManyWithWhereWithoutAppointment_ServiceInput = {
  data: TxAddonUpdateManyMutationInput;
  where: TxAddonScalarWhereInput;
};

export type TxAddonUpdateManyWithWhereWithoutArtistInput = {
  data: TxAddonUpdateManyMutationInput;
  where: TxAddonScalarWhereInput;
};

export type TxAddonUpdateManyWithWhereWithoutOrderInput = {
  data: TxAddonUpdateManyMutationInput;
  where: TxAddonScalarWhereInput;
};

export type TxAddonUpdateManyWithoutAddonNestedInput = {
  connect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TxAddonCreateOrConnectWithoutAddonInput>>;
  create?: InputMaybe<Array<TxAddonCreateWithoutAddonInput>>;
  createMany?: InputMaybe<TxAddonCreateManyAddonInputEnvelope>;
  delete?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TxAddonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  set?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  update?: InputMaybe<Array<TxAddonUpdateWithWhereUniqueWithoutAddonInput>>;
  updateMany?: InputMaybe<Array<TxAddonUpdateManyWithWhereWithoutAddonInput>>;
  upsert?: InputMaybe<Array<TxAddonUpsertWithWhereUniqueWithoutAddonInput>>;
};

export type TxAddonUpdateManyWithoutAppointment_ServiceNestedInput = {
  connect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TxAddonCreateOrConnectWithoutAppointment_ServiceInput>
  >;
  create?: InputMaybe<Array<TxAddonCreateWithoutAppointment_ServiceInput>>;
  createMany?: InputMaybe<TxAddonCreateManyAppointment_ServiceInputEnvelope>;
  delete?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TxAddonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  set?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TxAddonUpdateWithWhereUniqueWithoutAppointment_ServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<TxAddonUpdateManyWithWhereWithoutAppointment_ServiceInput>
  >;
  upsert?: InputMaybe<
    Array<TxAddonUpsertWithWhereUniqueWithoutAppointment_ServiceInput>
  >;
};

export type TxAddonUpdateManyWithoutArtistNestedInput = {
  connect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TxAddonCreateOrConnectWithoutArtistInput>>;
  create?: InputMaybe<Array<TxAddonCreateWithoutArtistInput>>;
  createMany?: InputMaybe<TxAddonCreateManyArtistInputEnvelope>;
  delete?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TxAddonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  set?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  update?: InputMaybe<Array<TxAddonUpdateWithWhereUniqueWithoutArtistInput>>;
  updateMany?: InputMaybe<Array<TxAddonUpdateManyWithWhereWithoutArtistInput>>;
  upsert?: InputMaybe<Array<TxAddonUpsertWithWhereUniqueWithoutArtistInput>>;
};

export type TxAddonUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TxAddonCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<TxAddonCreateWithoutOrderInput>>;
  createMany?: InputMaybe<TxAddonCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TxAddonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  set?: InputMaybe<Array<TxAddonWhereUniqueInput>>;
  update?: InputMaybe<Array<TxAddonUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<TxAddonUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<TxAddonUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type TxAddonUpdateWithWhereUniqueWithoutAddonInput = {
  data: TxAddonUpdateWithoutAddonInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonUpdateWithWhereUniqueWithoutAppointment_ServiceInput = {
  data: TxAddonUpdateWithoutAppointment_ServiceInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonUpdateWithWhereUniqueWithoutArtistInput = {
  data: TxAddonUpdateWithoutArtistInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonUpdateWithWhereUniqueWithoutOrderInput = {
  data: TxAddonUpdateWithoutOrderInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonUpdateWithoutAddonInput = {
  appointment_service?: InputMaybe<AppointmentServiceUpdateOneWithoutTx_AddonsNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutTxAddonsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutTx_AddonsNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxAddonUpdateWithoutAppointment_ServiceInput = {
  addon?: InputMaybe<ServiceUpdateOneWithoutTx_AddonsNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutTxAddonsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutTx_AddonsNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxAddonUpdateWithoutArtistInput = {
  addon?: InputMaybe<ServiceUpdateOneWithoutTx_AddonsNestedInput>;
  appointment_service?: InputMaybe<AppointmentServiceUpdateOneWithoutTx_AddonsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutTx_AddonsNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxAddonUpdateWithoutOrderInput = {
  addon?: InputMaybe<ServiceUpdateOneWithoutTx_AddonsNestedInput>;
  appointment_service?: InputMaybe<AppointmentServiceUpdateOneWithoutTx_AddonsNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutTxAddonsNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxAddonUpsertWithWhereUniqueWithoutAddonInput = {
  create: TxAddonCreateWithoutAddonInput;
  update: TxAddonUpdateWithoutAddonInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonUpsertWithWhereUniqueWithoutAppointment_ServiceInput = {
  create: TxAddonCreateWithoutAppointment_ServiceInput;
  update: TxAddonUpdateWithoutAppointment_ServiceInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonUpsertWithWhereUniqueWithoutArtistInput = {
  create: TxAddonCreateWithoutArtistInput;
  update: TxAddonUpdateWithoutArtistInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonUpsertWithWhereUniqueWithoutOrderInput = {
  create: TxAddonCreateWithoutOrderInput;
  update: TxAddonUpdateWithoutOrderInput;
  where: TxAddonWhereUniqueInput;
};

export type TxAddonWhereInput = {
  AND?: InputMaybe<Array<TxAddonWhereInput>>;
  NOT?: InputMaybe<Array<TxAddonWhereInput>>;
  OR?: InputMaybe<Array<TxAddonWhereInput>>;
  addon?: InputMaybe<ServiceRelationFilter>;
  addon_id?: InputMaybe<UuidFilter>;
  appointment_service?: InputMaybe<AppointmentServiceRelationFilter>;
  appointment_service_id?: InputMaybe<UuidNullableFilter>;
  artist?: InputMaybe<ArtistRelationFilter>;
  artist_id?: InputMaybe<UuidNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  order_id?: InputMaybe<UuidFilter>;
  price?: InputMaybe<FloatFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TxAddonWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type TxCancellationPolicy = {
  __typename?: "TxCancellationPolicy";
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  late_cancellation_charge: Scalars["Float"];
  late_cancellation_hours: Scalars["Int"];
  no_show_charge: Scalars["Float"];
  order?: Maybe<Order>;
  order_id: Scalars["String"];
  payment_type: PaymentType;
  updated_at: Scalars["DateTime"];
};

export type TxCancellationPolicyAvgAggregate = {
  __typename?: "TxCancellationPolicyAvgAggregate";
  late_cancellation_charge?: Maybe<Scalars["Float"]>;
  late_cancellation_hours?: Maybe<Scalars["Float"]>;
  no_show_charge?: Maybe<Scalars["Float"]>;
};

export type TxCancellationPolicyAvgOrderByAggregateInput = {
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
};

export type TxCancellationPolicyCountAggregate = {
  __typename?: "TxCancellationPolicyCountAggregate";
  _all: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  late_cancellation_charge: Scalars["Int"];
  late_cancellation_hours: Scalars["Int"];
  no_show_charge: Scalars["Int"];
  order_id: Scalars["Int"];
  payment_type: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type TxCancellationPolicyCountOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  payment_type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxCancellationPolicyCreateInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTx_Cancellation_PolicyInput>;
  payment_type?: InputMaybe<PaymentType>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxCancellationPolicyCreateManyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  order_id: Scalars["String"];
  payment_type?: InputMaybe<PaymentType>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxCancellationPolicyCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<TxCancellationPolicyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TxCancellationPolicyCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<TxCancellationPolicyCreateWithoutOrderInput>;
};

export type TxCancellationPolicyCreateOrConnectWithoutOrderInput = {
  create: TxCancellationPolicyCreateWithoutOrderInput;
  where: TxCancellationPolicyWhereUniqueInput;
};

export type TxCancellationPolicyCreateWithoutOrderInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  late_cancellation_charge?: InputMaybe<Scalars["Float"]>;
  late_cancellation_hours?: InputMaybe<Scalars["Int"]>;
  no_show_charge?: InputMaybe<Scalars["Float"]>;
  payment_type?: InputMaybe<PaymentType>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxCancellationPolicyGroupBy = {
  __typename?: "TxCancellationPolicyGroupBy";
  _avg?: Maybe<TxCancellationPolicyAvgAggregate>;
  _count?: Maybe<TxCancellationPolicyCountAggregate>;
  _max?: Maybe<TxCancellationPolicyMaxAggregate>;
  _min?: Maybe<TxCancellationPolicyMinAggregate>;
  _sum?: Maybe<TxCancellationPolicySumAggregate>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  late_cancellation_charge: Scalars["Float"];
  late_cancellation_hours: Scalars["Int"];
  no_show_charge: Scalars["Float"];
  order_id: Scalars["String"];
  payment_type: PaymentType;
  updated_at: Scalars["DateTime"];
};

export type TxCancellationPolicyMaxAggregate = {
  __typename?: "TxCancellationPolicyMaxAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  late_cancellation_charge?: Maybe<Scalars["Float"]>;
  late_cancellation_hours?: Maybe<Scalars["Int"]>;
  no_show_charge?: Maybe<Scalars["Float"]>;
  order_id?: Maybe<Scalars["String"]>;
  payment_type?: Maybe<PaymentType>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type TxCancellationPolicyMaxOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  payment_type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxCancellationPolicyMinAggregate = {
  __typename?: "TxCancellationPolicyMinAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  late_cancellation_charge?: Maybe<Scalars["Float"]>;
  late_cancellation_hours?: Maybe<Scalars["Int"]>;
  no_show_charge?: Maybe<Scalars["Float"]>;
  order_id?: Maybe<Scalars["String"]>;
  payment_type?: Maybe<PaymentType>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type TxCancellationPolicyMinOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  payment_type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxCancellationPolicyOrderByWithAggregationInput = {
  _avg?: InputMaybe<TxCancellationPolicyAvgOrderByAggregateInput>;
  _count?: InputMaybe<TxCancellationPolicyCountOrderByAggregateInput>;
  _max?: InputMaybe<TxCancellationPolicyMaxOrderByAggregateInput>;
  _min?: InputMaybe<TxCancellationPolicyMinOrderByAggregateInput>;
  _sum?: InputMaybe<TxCancellationPolicySumOrderByAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  payment_type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxCancellationPolicyOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  order_id?: InputMaybe<SortOrder>;
  payment_type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxCancellationPolicyRelationFilter = {
  is?: InputMaybe<TxCancellationPolicyWhereInput>;
  isNot?: InputMaybe<TxCancellationPolicyWhereInput>;
};

export enum TxCancellationPolicyScalarFieldEnum {
  CreatedAt = "created_at",
  Id = "id",
  LateCancellationCharge = "late_cancellation_charge",
  LateCancellationHours = "late_cancellation_hours",
  NoShowCharge = "no_show_charge",
  OrderId = "order_id",
  PaymentType = "payment_type",
  UpdatedAt = "updated_at"
}

export type TxCancellationPolicyScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TxCancellationPolicyScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TxCancellationPolicyScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TxCancellationPolicyScalarWhereWithAggregatesInput>>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  late_cancellation_charge?: InputMaybe<FloatWithAggregatesFilter>;
  late_cancellation_hours?: InputMaybe<IntWithAggregatesFilter>;
  no_show_charge?: InputMaybe<FloatWithAggregatesFilter>;
  order_id?: InputMaybe<UuidWithAggregatesFilter>;
  payment_type?: InputMaybe<EnumPaymentTypeWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type TxCancellationPolicySumAggregate = {
  __typename?: "TxCancellationPolicySumAggregate";
  late_cancellation_charge?: Maybe<Scalars["Float"]>;
  late_cancellation_hours?: Maybe<Scalars["Int"]>;
  no_show_charge?: Maybe<Scalars["Float"]>;
};

export type TxCancellationPolicySumOrderByAggregateInput = {
  late_cancellation_charge?: InputMaybe<SortOrder>;
  late_cancellation_hours?: InputMaybe<SortOrder>;
  no_show_charge?: InputMaybe<SortOrder>;
};

export type TxCancellationPolicyUpdateInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutTx_Cancellation_PolicyNestedInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxCancellationPolicyUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxCancellationPolicyUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<TxCancellationPolicyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TxCancellationPolicyCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<TxCancellationPolicyCreateWithoutOrderInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<TxCancellationPolicyUpdateWithoutOrderInput>;
  upsert?: InputMaybe<TxCancellationPolicyUpsertWithoutOrderInput>;
};

export type TxCancellationPolicyUpdateWithoutOrderInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  late_cancellation_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  late_cancellation_hours?: InputMaybe<IntFieldUpdateOperationsInput>;
  no_show_charge?: InputMaybe<FloatFieldUpdateOperationsInput>;
  payment_type?: InputMaybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxCancellationPolicyUpsertWithoutOrderInput = {
  create: TxCancellationPolicyCreateWithoutOrderInput;
  update: TxCancellationPolicyUpdateWithoutOrderInput;
};

export type TxCancellationPolicyWhereInput = {
  AND?: InputMaybe<Array<TxCancellationPolicyWhereInput>>;
  NOT?: InputMaybe<Array<TxCancellationPolicyWhereInput>>;
  OR?: InputMaybe<Array<TxCancellationPolicyWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  late_cancellation_charge?: InputMaybe<FloatFilter>;
  late_cancellation_hours?: InputMaybe<IntFilter>;
  no_show_charge?: InputMaybe<FloatFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  order_id?: InputMaybe<UuidFilter>;
  payment_type?: InputMaybe<EnumPaymentTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TxCancellationPolicyWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  order_id?: InputMaybe<Scalars["String"]>;
};

export type TxItem = {
  __typename?: "TxItem";
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  order?: Maybe<Order>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  updated_at: Scalars["DateTime"];
};

export type TxItemAvgAggregate = {
  __typename?: "TxItemAvgAggregate";
  price?: Maybe<Scalars["Float"]>;
};

export type TxItemAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type TxItemCountAggregate = {
  __typename?: "TxItemCountAggregate";
  _all: Scalars["Int"];
  created_at: Scalars["Int"];
  description: Scalars["Int"];
  id: Scalars["Int"];
  order_id: Scalars["Int"];
  price: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type TxItemCountOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxItemCreateInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTx_ItemsInput>;
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxItemCreateManyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxItemCreateManyOrderInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxItemCreateManyOrderInputEnvelope = {
  data: Array<TxItemCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TxItemCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<TxItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TxItemCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<TxItemCreateWithoutOrderInput>>;
  createMany?: InputMaybe<TxItemCreateManyOrderInputEnvelope>;
};

export type TxItemCreateOrConnectWithoutOrderInput = {
  create: TxItemCreateWithoutOrderInput;
  where: TxItemWhereUniqueInput;
};

export type TxItemCreateWithoutOrderInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxItemGroupBy = {
  __typename?: "TxItemGroupBy";
  _avg?: Maybe<TxItemAvgAggregate>;
  _count?: Maybe<TxItemCountAggregate>;
  _max?: Maybe<TxItemMaxAggregate>;
  _min?: Maybe<TxItemMinAggregate>;
  _sum?: Maybe<TxItemSumAggregate>;
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  order_id: Scalars["String"];
  price: Scalars["Float"];
  updated_at: Scalars["DateTime"];
};

export type TxItemListRelationFilter = {
  every?: InputMaybe<TxItemWhereInput>;
  none?: InputMaybe<TxItemWhereInput>;
  some?: InputMaybe<TxItemWhereInput>;
};

export type TxItemMaxAggregate = {
  __typename?: "TxItemMaxAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type TxItemMaxOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxItemMinAggregate = {
  __typename?: "TxItemMinAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type TxItemMinOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TxItemOrderByWithAggregationInput = {
  _avg?: InputMaybe<TxItemAvgOrderByAggregateInput>;
  _count?: InputMaybe<TxItemCountOrderByAggregateInput>;
  _max?: InputMaybe<TxItemMaxOrderByAggregateInput>;
  _min?: InputMaybe<TxItemMinOrderByAggregateInput>;
  _sum?: InputMaybe<TxItemSumOrderByAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxItemOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum TxItemScalarFieldEnum {
  CreatedAt = "created_at",
  Description = "description",
  Id = "id",
  OrderId = "order_id",
  Price = "price",
  UpdatedAt = "updated_at"
}

export type TxItemScalarWhereInput = {
  AND?: InputMaybe<Array<TxItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<TxItemScalarWhereInput>>;
  OR?: InputMaybe<Array<TxItemScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  order_id?: InputMaybe<UuidFilter>;
  price?: InputMaybe<FloatFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TxItemScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TxItemScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TxItemScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TxItemScalarWhereWithAggregatesInput>>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  order_id?: InputMaybe<UuidWithAggregatesFilter>;
  price?: InputMaybe<FloatWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type TxItemSumAggregate = {
  __typename?: "TxItemSumAggregate";
  price?: Maybe<Scalars["Float"]>;
};

export type TxItemSumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type TxItemUpdateInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutTx_ItemsNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxItemUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxItemUpdateManyWithWhereWithoutOrderInput = {
  data: TxItemUpdateManyMutationInput;
  where: TxItemScalarWhereInput;
};

export type TxItemUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<TxItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TxItemCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<TxItemCreateWithoutOrderInput>>;
  createMany?: InputMaybe<TxItemCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<TxItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TxItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TxItemWhereUniqueInput>>;
  set?: InputMaybe<Array<TxItemWhereUniqueInput>>;
  update?: InputMaybe<Array<TxItemUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<TxItemUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<TxItemUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type TxItemUpdateWithWhereUniqueWithoutOrderInput = {
  data: TxItemUpdateWithoutOrderInput;
  where: TxItemWhereUniqueInput;
};

export type TxItemUpdateWithoutOrderInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxItemUpsertWithWhereUniqueWithoutOrderInput = {
  create: TxItemCreateWithoutOrderInput;
  update: TxItemUpdateWithoutOrderInput;
  where: TxItemWhereUniqueInput;
};

export type TxItemWhereInput = {
  AND?: InputMaybe<Array<TxItemWhereInput>>;
  NOT?: InputMaybe<Array<TxItemWhereInput>>;
  OR?: InputMaybe<Array<TxItemWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  order_id?: InputMaybe<UuidFilter>;
  price?: InputMaybe<FloatFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TxItemWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type TxService = {
  __typename?: "TxService";
  appointment_service?: Maybe<AppointmentService>;
  appointment_service_id?: Maybe<Scalars["String"]>;
  artist?: Maybe<Artist>;
  artist_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  order?: Maybe<Order>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  service?: Maybe<Service>;
  service_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type TxServiceAvgAggregate = {
  __typename?: "TxServiceAvgAggregate";
  price?: Maybe<Scalars["Float"]>;
};

export type TxServiceAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type TxServiceCountAggregate = {
  __typename?: "TxServiceCountAggregate";
  _all: Scalars["Int"];
  appointment_service_id: Scalars["Int"];
  artist_id: Scalars["Int"];
  created_at: Scalars["Int"];
  description: Scalars["Int"];
  id: Scalars["Int"];
  order_id: Scalars["Int"];
  price: Scalars["Int"];
  service_id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type TxServiceCountOrderByAggregateInput = {
  appointment_service_id?: InputMaybe<SortOrder>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxServiceCreateInput = {
  appointment_service?: InputMaybe<AppointmentServiceCreateNestedOneWithoutTx_ServiceInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutTxServicesInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTx_ServicesInput>;
  price: Scalars["Float"];
  service?: InputMaybe<ServiceCreateNestedOneWithoutTx_ServicesInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxServiceCreateManyArtistInput = {
  appointment_service_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  service_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxServiceCreateManyArtistInputEnvelope = {
  data: Array<TxServiceCreateManyArtistInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TxServiceCreateManyInput = {
  appointment_service_id?: InputMaybe<Scalars["String"]>;
  artist_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  service_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxServiceCreateManyOrderInput = {
  appointment_service_id?: InputMaybe<Scalars["String"]>;
  artist_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  service_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxServiceCreateManyOrderInputEnvelope = {
  data: Array<TxServiceCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TxServiceCreateManyServiceInput = {
  appointment_service_id?: InputMaybe<Scalars["String"]>;
  artist_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order_id: Scalars["String"];
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxServiceCreateManyServiceInputEnvelope = {
  data: Array<TxServiceCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TxServiceCreateNestedManyWithoutArtistInput = {
  connect?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TxServiceCreateOrConnectWithoutArtistInput>
  >;
  create?: InputMaybe<Array<TxServiceCreateWithoutArtistInput>>;
  createMany?: InputMaybe<TxServiceCreateManyArtistInputEnvelope>;
};

export type TxServiceCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TxServiceCreateOrConnectWithoutOrderInput>
  >;
  create?: InputMaybe<Array<TxServiceCreateWithoutOrderInput>>;
  createMany?: InputMaybe<TxServiceCreateManyOrderInputEnvelope>;
};

export type TxServiceCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TxServiceCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<TxServiceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<TxServiceCreateManyServiceInputEnvelope>;
};

export type TxServiceCreateNestedOneWithoutAppointment_ServiceInput = {
  connect?: InputMaybe<TxServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TxServiceCreateOrConnectWithoutAppointment_ServiceInput>;
  create?: InputMaybe<TxServiceCreateWithoutAppointment_ServiceInput>;
};

export type TxServiceCreateOrConnectWithoutAppointment_ServiceInput = {
  create: TxServiceCreateWithoutAppointment_ServiceInput;
  where: TxServiceWhereUniqueInput;
};

export type TxServiceCreateOrConnectWithoutArtistInput = {
  create: TxServiceCreateWithoutArtistInput;
  where: TxServiceWhereUniqueInput;
};

export type TxServiceCreateOrConnectWithoutOrderInput = {
  create: TxServiceCreateWithoutOrderInput;
  where: TxServiceWhereUniqueInput;
};

export type TxServiceCreateOrConnectWithoutServiceInput = {
  create: TxServiceCreateWithoutServiceInput;
  where: TxServiceWhereUniqueInput;
};

export type TxServiceCreateWithoutAppointment_ServiceInput = {
  artist?: InputMaybe<ArtistCreateNestedOneWithoutTxServicesInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTx_ServicesInput>;
  price: Scalars["Float"];
  service?: InputMaybe<ServiceCreateNestedOneWithoutTx_ServicesInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxServiceCreateWithoutArtistInput = {
  appointment_service?: InputMaybe<AppointmentServiceCreateNestedOneWithoutTx_ServiceInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTx_ServicesInput>;
  price: Scalars["Float"];
  service?: InputMaybe<ServiceCreateNestedOneWithoutTx_ServicesInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxServiceCreateWithoutOrderInput = {
  appointment_service?: InputMaybe<AppointmentServiceCreateNestedOneWithoutTx_ServiceInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutTxServicesInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  price: Scalars["Float"];
  service?: InputMaybe<ServiceCreateNestedOneWithoutTx_ServicesInput>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxServiceCreateWithoutServiceInput = {
  appointment_service?: InputMaybe<AppointmentServiceCreateNestedOneWithoutTx_ServiceInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutTxServicesInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTx_ServicesInput>;
  price: Scalars["Float"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type TxServiceGroupBy = {
  __typename?: "TxServiceGroupBy";
  _avg?: Maybe<TxServiceAvgAggregate>;
  _count?: Maybe<TxServiceCountAggregate>;
  _max?: Maybe<TxServiceMaxAggregate>;
  _min?: Maybe<TxServiceMinAggregate>;
  _sum?: Maybe<TxServiceSumAggregate>;
  appointment_service_id?: Maybe<Scalars["String"]>;
  artist_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  order_id: Scalars["String"];
  price: Scalars["Float"];
  service_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type TxServiceListRelationFilter = {
  every?: InputMaybe<TxServiceWhereInput>;
  none?: InputMaybe<TxServiceWhereInput>;
  some?: InputMaybe<TxServiceWhereInput>;
};

export type TxServiceMaxAggregate = {
  __typename?: "TxServiceMaxAggregate";
  appointment_service_id?: Maybe<Scalars["String"]>;
  artist_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type TxServiceMaxOrderByAggregateInput = {
  appointment_service_id?: InputMaybe<SortOrder>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxServiceMinAggregate = {
  __typename?: "TxServiceMinAggregate";
  appointment_service_id?: Maybe<Scalars["String"]>;
  artist_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  service_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type TxServiceMinOrderByAggregateInput = {
  appointment_service_id?: InputMaybe<SortOrder>;
  artist_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxServiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TxServiceOrderByWithAggregationInput = {
  _avg?: InputMaybe<TxServiceAvgOrderByAggregateInput>;
  _count?: InputMaybe<TxServiceCountOrderByAggregateInput>;
  _max?: InputMaybe<TxServiceMaxOrderByAggregateInput>;
  _min?: InputMaybe<TxServiceMinOrderByAggregateInput>;
  _sum?: InputMaybe<TxServiceSumOrderByAggregateInput>;
  appointment_service_id?: InputMaybe<SortOrderInput>;
  artist_id?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxServiceOrderByWithRelationInput = {
  appointment_service?: InputMaybe<AppointmentServiceOrderByWithRelationInput>;
  appointment_service_id?: InputMaybe<SortOrderInput>;
  artist?: InputMaybe<ArtistOrderByWithRelationInput>;
  artist_id?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  order_id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  service_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TxServiceRelationFilter = {
  is?: InputMaybe<TxServiceWhereInput>;
  isNot?: InputMaybe<TxServiceWhereInput>;
};

export enum TxServiceScalarFieldEnum {
  AppointmentServiceId = "appointment_service_id",
  ArtistId = "artist_id",
  CreatedAt = "created_at",
  Description = "description",
  Id = "id",
  OrderId = "order_id",
  Price = "price",
  ServiceId = "service_id",
  UpdatedAt = "updated_at"
}

export type TxServiceScalarWhereInput = {
  AND?: InputMaybe<Array<TxServiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<TxServiceScalarWhereInput>>;
  OR?: InputMaybe<Array<TxServiceScalarWhereInput>>;
  appointment_service_id?: InputMaybe<UuidNullableFilter>;
  artist_id?: InputMaybe<UuidNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  order_id?: InputMaybe<UuidFilter>;
  price?: InputMaybe<FloatFilter>;
  service_id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TxServiceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TxServiceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TxServiceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TxServiceScalarWhereWithAggregatesInput>>;
  appointment_service_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  artist_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  order_id?: InputMaybe<UuidWithAggregatesFilter>;
  price?: InputMaybe<FloatWithAggregatesFilter>;
  service_id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type TxServiceSumAggregate = {
  __typename?: "TxServiceSumAggregate";
  price?: Maybe<Scalars["Float"]>;
};

export type TxServiceSumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type TxServiceUpdateInput = {
  appointment_service?: InputMaybe<AppointmentServiceUpdateOneWithoutTx_ServiceNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutTxServicesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutTx_ServicesNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutTx_ServicesNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxServiceUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxServiceUpdateManyWithWhereWithoutArtistInput = {
  data: TxServiceUpdateManyMutationInput;
  where: TxServiceScalarWhereInput;
};

export type TxServiceUpdateManyWithWhereWithoutOrderInput = {
  data: TxServiceUpdateManyMutationInput;
  where: TxServiceScalarWhereInput;
};

export type TxServiceUpdateManyWithWhereWithoutServiceInput = {
  data: TxServiceUpdateManyMutationInput;
  where: TxServiceScalarWhereInput;
};

export type TxServiceUpdateManyWithoutArtistNestedInput = {
  connect?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TxServiceCreateOrConnectWithoutArtistInput>
  >;
  create?: InputMaybe<Array<TxServiceCreateWithoutArtistInput>>;
  createMany?: InputMaybe<TxServiceCreateManyArtistInputEnvelope>;
  delete?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TxServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  update?: InputMaybe<Array<TxServiceUpdateWithWhereUniqueWithoutArtistInput>>;
  updateMany?: InputMaybe<
    Array<TxServiceUpdateManyWithWhereWithoutArtistInput>
  >;
  upsert?: InputMaybe<Array<TxServiceUpsertWithWhereUniqueWithoutArtistInput>>;
};

export type TxServiceUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TxServiceCreateOrConnectWithoutOrderInput>
  >;
  create?: InputMaybe<Array<TxServiceCreateWithoutOrderInput>>;
  createMany?: InputMaybe<TxServiceCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TxServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  update?: InputMaybe<Array<TxServiceUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<TxServiceUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<TxServiceUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type TxServiceUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TxServiceCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<TxServiceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<TxServiceCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TxServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<TxServiceWhereUniqueInput>>;
  update?: InputMaybe<Array<TxServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: InputMaybe<
    Array<TxServiceUpdateManyWithWhereWithoutServiceInput>
  >;
  upsert?: InputMaybe<Array<TxServiceUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type TxServiceUpdateOneWithoutAppointment_ServiceNestedInput = {
  connect?: InputMaybe<TxServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TxServiceCreateOrConnectWithoutAppointment_ServiceInput>;
  create?: InputMaybe<TxServiceCreateWithoutAppointment_ServiceInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<TxServiceUpdateWithoutAppointment_ServiceInput>;
  upsert?: InputMaybe<TxServiceUpsertWithoutAppointment_ServiceInput>;
};

export type TxServiceUpdateWithWhereUniqueWithoutArtistInput = {
  data: TxServiceUpdateWithoutArtistInput;
  where: TxServiceWhereUniqueInput;
};

export type TxServiceUpdateWithWhereUniqueWithoutOrderInput = {
  data: TxServiceUpdateWithoutOrderInput;
  where: TxServiceWhereUniqueInput;
};

export type TxServiceUpdateWithWhereUniqueWithoutServiceInput = {
  data: TxServiceUpdateWithoutServiceInput;
  where: TxServiceWhereUniqueInput;
};

export type TxServiceUpdateWithoutAppointment_ServiceInput = {
  artist?: InputMaybe<ArtistUpdateOneWithoutTxServicesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutTx_ServicesNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutTx_ServicesNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxServiceUpdateWithoutArtistInput = {
  appointment_service?: InputMaybe<AppointmentServiceUpdateOneWithoutTx_ServiceNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutTx_ServicesNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutTx_ServicesNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxServiceUpdateWithoutOrderInput = {
  appointment_service?: InputMaybe<AppointmentServiceUpdateOneWithoutTx_ServiceNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutTxServicesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutTx_ServicesNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxServiceUpdateWithoutServiceInput = {
  appointment_service?: InputMaybe<AppointmentServiceUpdateOneWithoutTx_ServiceNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutTxServicesNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutTx_ServicesNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TxServiceUpsertWithWhereUniqueWithoutArtistInput = {
  create: TxServiceCreateWithoutArtistInput;
  update: TxServiceUpdateWithoutArtistInput;
  where: TxServiceWhereUniqueInput;
};

export type TxServiceUpsertWithWhereUniqueWithoutOrderInput = {
  create: TxServiceCreateWithoutOrderInput;
  update: TxServiceUpdateWithoutOrderInput;
  where: TxServiceWhereUniqueInput;
};

export type TxServiceUpsertWithWhereUniqueWithoutServiceInput = {
  create: TxServiceCreateWithoutServiceInput;
  update: TxServiceUpdateWithoutServiceInput;
  where: TxServiceWhereUniqueInput;
};

export type TxServiceUpsertWithoutAppointment_ServiceInput = {
  create: TxServiceCreateWithoutAppointment_ServiceInput;
  update: TxServiceUpdateWithoutAppointment_ServiceInput;
};

export type TxServiceWhereInput = {
  AND?: InputMaybe<Array<TxServiceWhereInput>>;
  NOT?: InputMaybe<Array<TxServiceWhereInput>>;
  OR?: InputMaybe<Array<TxServiceWhereInput>>;
  appointment_service?: InputMaybe<AppointmentServiceRelationFilter>;
  appointment_service_id?: InputMaybe<UuidNullableFilter>;
  artist?: InputMaybe<ArtistRelationFilter>;
  artist_id?: InputMaybe<UuidNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  order_id?: InputMaybe<UuidFilter>;
  price?: InputMaybe<FloatFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  service_id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TxServiceWhereUniqueInput = {
  appointment_service_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  Order: Array<Order>;
  Refund: Array<Refund>;
  StripePaymentMethods: Array<StripePaymentMethod>;
  Transaction: Array<Transaction>;
  _count?: Maybe<UserCount>;
  appointment_services: Array<AppointmentService>;
  appointments: Array<Appointment>;
  artist?: Maybe<Artist>;
  availability: Array<Availability>;
  birthday?: Maybe<Scalars["DateTime"]>;
  booked_appointments: Array<Appointment>;
  cognito_id?: Maybe<Scalars["String"]>;
  company?: Maybe<Company>;
  company_descriptions: Array<CompanyUserDescription>;
  created_at: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  favorite_artists: Array<FavoriteArtist>;
  favorite_locations: Array<UserFavoriteLocation>;
  first_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  impersonate_company_id?: Maybe<Scalars["String"]>;
  impersonate_company_name?: Maybe<Scalars["String"]>;
  impersonate_id?: Maybe<Scalars["String"]>;
  impersonate_name?: Maybe<Scalars["String"]>;
  joined_company?: Maybe<Company>;
  joined_company_id?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  last_viewed_notifications?: Maybe<Scalars["DateTime"]>;
  libphonenumber?: Maybe<Scalars["String"]>;
  newsletter: Scalars["Boolean"];
  notifications: Array<Notification>;
  phone_number?: Maybe<Scalars["String"]>;
  photo_url?: Maybe<Scalars["String"]>;
  pronoun?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  provider_id?: Maybe<Scalars["String"]>;
  reminder_settings: Array<ReminderSetting>;
  role?: Maybe<Role>;
  status: Scalars["String"];
  stripe_customer_id?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type UserOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<OrderWhereInput>;
};

export type UserRefundArgs = {
  cursor?: InputMaybe<RefundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RefundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RefundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RefundWhereInput>;
};

export type UserStripePaymentMethodsArgs = {
  cursor?: InputMaybe<StripePaymentMethodWhereUniqueInput>;
  distinct?: InputMaybe<Array<StripePaymentMethodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StripePaymentMethodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type UserTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type UserAppointment_ServicesArgs = {
  cursor?: InputMaybe<AppointmentServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type UserAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type UserAvailabilityArgs = {
  cursor?: InputMaybe<AvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type UserBooked_AppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type UserCompany_DescriptionsArgs = {
  cursor?: InputMaybe<CompanyUserDescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyUserDescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyUserDescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type UserFavorite_ArtistsArgs = {
  cursor?: InputMaybe<FavoriteArtistWhereUniqueInput>;
  distinct?: InputMaybe<Array<FavoriteArtistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FavoriteArtistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type UserFavorite_LocationsArgs = {
  cursor?: InputMaybe<UserFavoriteLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserFavoriteLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserFavoriteLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type UserNotificationsArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<NotificationWhereInput>;
};

export type UserReminder_SettingsArgs = {
  cursor?: InputMaybe<ReminderSettingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReminderSettingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReminderSettingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ReminderSettingWhereInput>;
};

export type UserCount = {
  __typename?: "UserCount";
  Order: Scalars["Int"];
  Refund: Scalars["Int"];
  StripePaymentMethods: Scalars["Int"];
  Transaction: Scalars["Int"];
  appointment_services: Scalars["Int"];
  appointments: Scalars["Int"];
  availability: Scalars["Int"];
  booked_appointments: Scalars["Int"];
  company_descriptions: Scalars["Int"];
  favorite_artists: Scalars["Int"];
  favorite_locations: Scalars["Int"];
  notifications: Scalars["Int"];
  reminder_settings: Scalars["Int"];
};

export type UserCountOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};

export type UserCountRefundArgs = {
  where?: InputMaybe<RefundWhereInput>;
};

export type UserCountStripePaymentMethodsArgs = {
  where?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type UserCountTransactionArgs = {
  where?: InputMaybe<TransactionWhereInput>;
};

export type UserCountAppointment_ServicesArgs = {
  where?: InputMaybe<AppointmentServiceWhereInput>;
};

export type UserCountAppointmentsArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};

export type UserCountAvailabilityArgs = {
  where?: InputMaybe<AvailabilityWhereInput>;
};

export type UserCountBooked_AppointmentsArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};

export type UserCountCompany_DescriptionsArgs = {
  where?: InputMaybe<CompanyUserDescriptionWhereInput>;
};

export type UserCountFavorite_ArtistsArgs = {
  where?: InputMaybe<FavoriteArtistWhereInput>;
};

export type UserCountFavorite_LocationsArgs = {
  where?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type UserCountNotificationsArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};

export type UserCountReminder_SettingsArgs = {
  where?: InputMaybe<ReminderSettingWhereInput>;
};

export type UserCountAggregate = {
  __typename?: "UserCountAggregate";
  _all: Scalars["Int"];
  birthday: Scalars["Int"];
  cognito_id: Scalars["Int"];
  created_at: Scalars["Int"];
  email: Scalars["Int"];
  first_name: Scalars["Int"];
  full_name: Scalars["Int"];
  id: Scalars["Int"];
  impersonate_company_id: Scalars["Int"];
  impersonate_company_name: Scalars["Int"];
  impersonate_id: Scalars["Int"];
  impersonate_name: Scalars["Int"];
  joined_company_id: Scalars["Int"];
  last_name: Scalars["Int"];
  last_viewed_notifications: Scalars["Int"];
  libphonenumber: Scalars["Int"];
  newsletter: Scalars["Int"];
  phone_number: Scalars["Int"];
  photo_url: Scalars["Int"];
  pronoun: Scalars["Int"];
  provider: Scalars["Int"];
  provider_id: Scalars["Int"];
  status: Scalars["Int"];
  stripe_customer_id: Scalars["Int"];
  updated_at: Scalars["Int"];
};

export type UserCountOrderByAggregateInput = {
  birthday?: InputMaybe<SortOrder>;
  cognito_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  first_name?: InputMaybe<SortOrder>;
  full_name?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  impersonate_company_id?: InputMaybe<SortOrder>;
  impersonate_company_name?: InputMaybe<SortOrder>;
  impersonate_id?: InputMaybe<SortOrder>;
  impersonate_name?: InputMaybe<SortOrder>;
  joined_company_id?: InputMaybe<SortOrder>;
  last_name?: InputMaybe<SortOrder>;
  last_viewed_notifications?: InputMaybe<SortOrder>;
  libphonenumber?: InputMaybe<SortOrder>;
  newsletter?: InputMaybe<SortOrder>;
  phone_number?: InputMaybe<SortOrder>;
  photo_url?: InputMaybe<SortOrder>;
  pronoun?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  provider_id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_customer_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateManyInput = {
  birthday?: InputMaybe<Scalars["DateTime"]>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company_id?: InputMaybe<Scalars["String"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateManyJoined_CompanyInput = {
  birthday?: InputMaybe<Scalars["DateTime"]>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateManyJoined_CompanyInputEnvelope = {
  data: Array<UserCreateManyJoined_CompanyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type UserCreateNestedManyWithoutJoined_CompanyInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserCreateOrConnectWithoutJoined_CompanyInput>
  >;
  create?: InputMaybe<Array<UserCreateWithoutJoined_CompanyInput>>;
  createMany?: InputMaybe<UserCreateManyJoined_CompanyInputEnvelope>;
};

export type UserCreateNestedOneWithoutAppointment_ServicesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAppointment_ServicesInput>;
  create?: InputMaybe<UserCreateWithoutAppointment_ServicesInput>;
};

export type UserCreateNestedOneWithoutAppointmentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<UserCreateWithoutAppointmentsInput>;
};

export type UserCreateNestedOneWithoutArtistInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutArtistInput>;
  create?: InputMaybe<UserCreateWithoutArtistInput>;
};

export type UserCreateNestedOneWithoutAvailabilityInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAvailabilityInput>;
  create?: InputMaybe<UserCreateWithoutAvailabilityInput>;
};

export type UserCreateNestedOneWithoutBooked_AppointmentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBooked_AppointmentsInput>;
  create?: InputMaybe<UserCreateWithoutBooked_AppointmentsInput>;
};

export type UserCreateNestedOneWithoutCompanyInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCompanyInput>;
  create?: InputMaybe<UserCreateWithoutCompanyInput>;
};

export type UserCreateNestedOneWithoutCompany_DescriptionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCompany_DescriptionsInput>;
  create?: InputMaybe<UserCreateWithoutCompany_DescriptionsInput>;
};

export type UserCreateNestedOneWithoutFavorite_ArtistsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFavorite_ArtistsInput>;
  create?: InputMaybe<UserCreateWithoutFavorite_ArtistsInput>;
};

export type UserCreateNestedOneWithoutFavorite_LocationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFavorite_LocationsInput>;
  create?: InputMaybe<UserCreateWithoutFavorite_LocationsInput>;
};

export type UserCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutNotificationsInput>;
};

export type UserCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<UserCreateWithoutOrderInput>;
};

export type UserCreateNestedOneWithoutRefundInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRefundInput>;
  create?: InputMaybe<UserCreateWithoutRefundInput>;
};

export type UserCreateNestedOneWithoutReminder_SettingsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReminder_SettingsInput>;
  create?: InputMaybe<UserCreateWithoutReminder_SettingsInput>;
};

export type UserCreateNestedOneWithoutRoleInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRoleInput>;
  create?: InputMaybe<UserCreateWithoutRoleInput>;
};

export type UserCreateNestedOneWithoutStripePaymentMethodsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutStripePaymentMethodsInput>;
  create?: InputMaybe<UserCreateWithoutStripePaymentMethodsInput>;
};

export type UserCreateNestedOneWithoutTransactionInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<UserCreateWithoutTransactionInput>;
};

export type UserCreateOrConnectWithoutAppointment_ServicesInput = {
  create: UserCreateWithoutAppointment_ServicesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAppointmentsInput = {
  create: UserCreateWithoutAppointmentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutArtistInput = {
  create: UserCreateWithoutArtistInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAvailabilityInput = {
  create: UserCreateWithoutAvailabilityInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBooked_AppointmentsInput = {
  create: UserCreateWithoutBooked_AppointmentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCompanyInput = {
  create: UserCreateWithoutCompanyInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCompany_DescriptionsInput = {
  create: UserCreateWithoutCompany_DescriptionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFavorite_ArtistsInput = {
  create: UserCreateWithoutFavorite_ArtistsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFavorite_LocationsInput = {
  create: UserCreateWithoutFavorite_LocationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutJoined_CompanyInput = {
  create: UserCreateWithoutJoined_CompanyInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNotificationsInput = {
  create: UserCreateWithoutNotificationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOrderInput = {
  create: UserCreateWithoutOrderInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRefundInput = {
  create: UserCreateWithoutRefundInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReminder_SettingsInput = {
  create: UserCreateWithoutReminder_SettingsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRoleInput = {
  create: UserCreateWithoutRoleInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutStripePaymentMethodsInput = {
  create: UserCreateWithoutStripePaymentMethodsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTransactionInput = {
  create: UserCreateWithoutTransactionInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAppointment_ServicesInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutAppointmentsInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutArtistInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutAvailabilityInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutBooked_AppointmentsInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutCompanyInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutCompany_DescriptionsInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutFavorite_ArtistsInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutFavorite_LocationsInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutJoined_CompanyInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutNotificationsInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutOrderInput = {
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutRefundInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutReminder_SettingsInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutRoleInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutStripePaymentMethodsInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  Transaction?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCreateWithoutTransactionInput = {
  Order?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  Refund?: InputMaybe<RefundCreateNestedManyWithoutUserInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutUserInput>;
  appointment_services?: InputMaybe<AppointmentServiceCreateNestedManyWithoutUserInput>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutUserInput>;
  artist?: InputMaybe<ArtistCreateNestedOneWithoutUserInput>;
  availability?: InputMaybe<AvailabilityCreateNestedManyWithoutInserted_ByInput>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  booked_appointments?: InputMaybe<AppointmentCreateNestedManyWithoutInserted_ByInput>;
  cognito_id?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyCreateNestedOneWithoutOwnerInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite_artists?: InputMaybe<FavoriteArtistCreateNestedManyWithoutUserInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  impersonate_company_id?: InputMaybe<Scalars["String"]>;
  impersonate_company_name?: InputMaybe<Scalars["String"]>;
  impersonate_id?: InputMaybe<Scalars["String"]>;
  impersonate_name?: InputMaybe<Scalars["String"]>;
  joined_company?: InputMaybe<CompanyCreateNestedOneWithoutUsersInput>;
  last_name?: InputMaybe<Scalars["String"]>;
  last_viewed_notifications?: InputMaybe<Scalars["DateTime"]>;
  libphonenumber?: InputMaybe<Scalars["String"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  phone_number?: InputMaybe<Scalars["String"]>;
  photo_url?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_id?: InputMaybe<Scalars["String"]>;
  reminder_settings?: InputMaybe<ReminderSettingCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<RoleCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<Scalars["String"]>;
  stripe_customer_id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserEmailProviderProvider_IdCompoundUniqueInput = {
  email: Scalars["String"];
  provider: Scalars["String"];
  provider_id: Scalars["String"];
};

export type UserFavoriteLocation = {
  __typename?: "UserFavoriteLocation";
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  location: Location;
  location_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user: User;
  user_id: Scalars["String"];
};

export type UserFavoriteLocationCountAggregate = {
  __typename?: "UserFavoriteLocationCountAggregate";
  _all: Scalars["Int"];
  created_at: Scalars["Int"];
  id: Scalars["Int"];
  location_id: Scalars["Int"];
  updated_at: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type UserFavoriteLocationCountOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type UserFavoriteLocationCreateInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location: LocationCreateNestedOneWithoutFavorited_By_UsersInput;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutFavorite_LocationsInput;
};

export type UserFavoriteLocationCreateManyInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type UserFavoriteLocationCreateManyLocationInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user_id: Scalars["String"];
};

export type UserFavoriteLocationCreateManyLocationInputEnvelope = {
  data: Array<UserFavoriteLocationCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type UserFavoriteLocationCreateManyUserInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location_id: Scalars["String"];
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserFavoriteLocationCreateManyUserInputEnvelope = {
  data: Array<UserFavoriteLocationCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type UserFavoriteLocationCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<UserFavoriteLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserFavoriteLocationCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<UserFavoriteLocationCreateWithoutLocationInput>>;
  createMany?: InputMaybe<UserFavoriteLocationCreateManyLocationInputEnvelope>;
};

export type UserFavoriteLocationCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserFavoriteLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserFavoriteLocationCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserFavoriteLocationCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserFavoriteLocationCreateManyUserInputEnvelope>;
};

export type UserFavoriteLocationCreateOrConnectWithoutLocationInput = {
  create: UserFavoriteLocationCreateWithoutLocationInput;
  where: UserFavoriteLocationWhereUniqueInput;
};

export type UserFavoriteLocationCreateOrConnectWithoutUserInput = {
  create: UserFavoriteLocationCreateWithoutUserInput;
  where: UserFavoriteLocationWhereUniqueInput;
};

export type UserFavoriteLocationCreateWithoutLocationInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
  user: UserCreateNestedOneWithoutFavorite_LocationsInput;
};

export type UserFavoriteLocationCreateWithoutUserInput = {
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location: LocationCreateNestedOneWithoutFavorited_By_UsersInput;
  updated_at?: InputMaybe<Scalars["DateTime"]>;
};

export type UserFavoriteLocationGroupBy = {
  __typename?: "UserFavoriteLocationGroupBy";
  _count?: Maybe<UserFavoriteLocationCountAggregate>;
  _max?: Maybe<UserFavoriteLocationMaxAggregate>;
  _min?: Maybe<UserFavoriteLocationMinAggregate>;
  created_at: Scalars["DateTime"];
  id: Scalars["String"];
  location_id: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user_id: Scalars["String"];
};

export type UserFavoriteLocationListRelationFilter = {
  every?: InputMaybe<UserFavoriteLocationWhereInput>;
  none?: InputMaybe<UserFavoriteLocationWhereInput>;
  some?: InputMaybe<UserFavoriteLocationWhereInput>;
};

export type UserFavoriteLocationMaxAggregate = {
  __typename?: "UserFavoriteLocationMaxAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type UserFavoriteLocationMaxOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type UserFavoriteLocationMinAggregate = {
  __typename?: "UserFavoriteLocationMinAggregate";
  created_at?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type UserFavoriteLocationMinOrderByAggregateInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type UserFavoriteLocationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserFavoriteLocationOrderByWithAggregationInput = {
  _count?: InputMaybe<UserFavoriteLocationCountOrderByAggregateInput>;
  _max?: InputMaybe<UserFavoriteLocationMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserFavoriteLocationMinOrderByAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type UserFavoriteLocationOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByWithRelationInput>;
  location_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export enum UserFavoriteLocationScalarFieldEnum {
  CreatedAt = "created_at",
  Id = "id",
  LocationId = "location_id",
  UpdatedAt = "updated_at",
  UserId = "user_id"
}

export type UserFavoriteLocationScalarWhereInput = {
  AND?: InputMaybe<Array<UserFavoriteLocationScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserFavoriteLocationScalarWhereInput>>;
  OR?: InputMaybe<Array<UserFavoriteLocationScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  location_id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type UserFavoriteLocationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserFavoriteLocationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserFavoriteLocationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserFavoriteLocationScalarWhereWithAggregatesInput>>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  location_id?: InputMaybe<UuidWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  user_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export type UserFavoriteLocationUpdateInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutFavorited_By_UsersNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutFavorite_LocationsNestedInput>;
};

export type UserFavoriteLocationUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserFavoriteLocationUpdateManyWithWhereWithoutLocationInput = {
  data: UserFavoriteLocationUpdateManyMutationInput;
  where: UserFavoriteLocationScalarWhereInput;
};

export type UserFavoriteLocationUpdateManyWithWhereWithoutUserInput = {
  data: UserFavoriteLocationUpdateManyMutationInput;
  where: UserFavoriteLocationScalarWhereInput;
};

export type UserFavoriteLocationUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<UserFavoriteLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserFavoriteLocationCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<UserFavoriteLocationCreateWithoutLocationInput>>;
  createMany?: InputMaybe<UserFavoriteLocationCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<UserFavoriteLocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserFavoriteLocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserFavoriteLocationWhereUniqueInput>>;
  set?: InputMaybe<Array<UserFavoriteLocationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserFavoriteLocationUpdateWithWhereUniqueWithoutLocationInput>
  >;
  updateMany?: InputMaybe<
    Array<UserFavoriteLocationUpdateManyWithWhereWithoutLocationInput>
  >;
  upsert?: InputMaybe<
    Array<UserFavoriteLocationUpsertWithWhereUniqueWithoutLocationInput>
  >;
};

export type UserFavoriteLocationUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserFavoriteLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserFavoriteLocationCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserFavoriteLocationCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserFavoriteLocationCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserFavoriteLocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserFavoriteLocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserFavoriteLocationWhereUniqueInput>>;
  set?: InputMaybe<Array<UserFavoriteLocationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserFavoriteLocationUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<UserFavoriteLocationUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<UserFavoriteLocationUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type UserFavoriteLocationUpdateWithWhereUniqueWithoutLocationInput = {
  data: UserFavoriteLocationUpdateWithoutLocationInput;
  where: UserFavoriteLocationWhereUniqueInput;
};

export type UserFavoriteLocationUpdateWithWhereUniqueWithoutUserInput = {
  data: UserFavoriteLocationUpdateWithoutUserInput;
  where: UserFavoriteLocationWhereUniqueInput;
};

export type UserFavoriteLocationUpdateWithoutLocationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutFavorite_LocationsNestedInput>;
};

export type UserFavoriteLocationUpdateWithoutUserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutFavorited_By_UsersNestedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserFavoriteLocationUpsertWithWhereUniqueWithoutLocationInput = {
  create: UserFavoriteLocationCreateWithoutLocationInput;
  update: UserFavoriteLocationUpdateWithoutLocationInput;
  where: UserFavoriteLocationWhereUniqueInput;
};

export type UserFavoriteLocationUpsertWithWhereUniqueWithoutUserInput = {
  create: UserFavoriteLocationCreateWithoutUserInput;
  update: UserFavoriteLocationUpdateWithoutUserInput;
  where: UserFavoriteLocationWhereUniqueInput;
};

export type UserFavoriteLocationUser_IdLocation_IdCompoundUniqueInput = {
  location_id: Scalars["String"];
  user_id: Scalars["String"];
};

export type UserFavoriteLocationWhereInput = {
  AND?: InputMaybe<Array<UserFavoriteLocationWhereInput>>;
  NOT?: InputMaybe<Array<UserFavoriteLocationWhereInput>>;
  OR?: InputMaybe<Array<UserFavoriteLocationWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  location_id?: InputMaybe<UuidFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  user_id?: InputMaybe<UuidFilter>;
};

export type UserFavoriteLocationWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  user_id_location_id?: InputMaybe<UserFavoriteLocationUser_IdLocation_IdCompoundUniqueInput>;
};

export type UserGroupBy = {
  __typename?: "UserGroupBy";
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  birthday?: Maybe<Scalars["DateTime"]>;
  cognito_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  impersonate_company_id?: Maybe<Scalars["String"]>;
  impersonate_company_name?: Maybe<Scalars["String"]>;
  impersonate_id?: Maybe<Scalars["String"]>;
  impersonate_name?: Maybe<Scalars["String"]>;
  joined_company_id?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  last_viewed_notifications?: Maybe<Scalars["DateTime"]>;
  libphonenumber?: Maybe<Scalars["String"]>;
  newsletter: Scalars["Boolean"];
  phone_number?: Maybe<Scalars["String"]>;
  photo_url?: Maybe<Scalars["String"]>;
  pronoun?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  provider_id?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  stripe_customer_id?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserMaxAggregate = {
  __typename?: "UserMaxAggregate";
  birthday?: Maybe<Scalars["DateTime"]>;
  cognito_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  impersonate_company_id?: Maybe<Scalars["String"]>;
  impersonate_company_name?: Maybe<Scalars["String"]>;
  impersonate_id?: Maybe<Scalars["String"]>;
  impersonate_name?: Maybe<Scalars["String"]>;
  joined_company_id?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  last_viewed_notifications?: Maybe<Scalars["DateTime"]>;
  libphonenumber?: Maybe<Scalars["String"]>;
  newsletter?: Maybe<Scalars["Boolean"]>;
  phone_number?: Maybe<Scalars["String"]>;
  photo_url?: Maybe<Scalars["String"]>;
  pronoun?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  provider_id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  stripe_customer_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type UserMaxOrderByAggregateInput = {
  birthday?: InputMaybe<SortOrder>;
  cognito_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  first_name?: InputMaybe<SortOrder>;
  full_name?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  impersonate_company_id?: InputMaybe<SortOrder>;
  impersonate_company_name?: InputMaybe<SortOrder>;
  impersonate_id?: InputMaybe<SortOrder>;
  impersonate_name?: InputMaybe<SortOrder>;
  joined_company_id?: InputMaybe<SortOrder>;
  last_name?: InputMaybe<SortOrder>;
  last_viewed_notifications?: InputMaybe<SortOrder>;
  libphonenumber?: InputMaybe<SortOrder>;
  newsletter?: InputMaybe<SortOrder>;
  phone_number?: InputMaybe<SortOrder>;
  photo_url?: InputMaybe<SortOrder>;
  pronoun?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  provider_id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_customer_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: "UserMinAggregate";
  birthday?: Maybe<Scalars["DateTime"]>;
  cognito_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  impersonate_company_id?: Maybe<Scalars["String"]>;
  impersonate_company_name?: Maybe<Scalars["String"]>;
  impersonate_id?: Maybe<Scalars["String"]>;
  impersonate_name?: Maybe<Scalars["String"]>;
  joined_company_id?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  last_viewed_notifications?: Maybe<Scalars["DateTime"]>;
  libphonenumber?: Maybe<Scalars["String"]>;
  newsletter?: Maybe<Scalars["Boolean"]>;
  phone_number?: Maybe<Scalars["String"]>;
  photo_url?: Maybe<Scalars["String"]>;
  pronoun?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  provider_id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  stripe_customer_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type UserMinOrderByAggregateInput = {
  birthday?: InputMaybe<SortOrder>;
  cognito_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  first_name?: InputMaybe<SortOrder>;
  full_name?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  impersonate_company_id?: InputMaybe<SortOrder>;
  impersonate_company_name?: InputMaybe<SortOrder>;
  impersonate_id?: InputMaybe<SortOrder>;
  impersonate_name?: InputMaybe<SortOrder>;
  joined_company_id?: InputMaybe<SortOrder>;
  last_name?: InputMaybe<SortOrder>;
  last_viewed_notifications?: InputMaybe<SortOrder>;
  libphonenumber?: InputMaybe<SortOrder>;
  newsletter?: InputMaybe<SortOrder>;
  phone_number?: InputMaybe<SortOrder>;
  photo_url?: InputMaybe<SortOrder>;
  pronoun?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  provider_id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripe_customer_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  birthday?: InputMaybe<SortOrderInput>;
  cognito_id?: InputMaybe<SortOrderInput>;
  created_at?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrderInput>;
  first_name?: InputMaybe<SortOrderInput>;
  full_name?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  impersonate_company_id?: InputMaybe<SortOrderInput>;
  impersonate_company_name?: InputMaybe<SortOrderInput>;
  impersonate_id?: InputMaybe<SortOrderInput>;
  impersonate_name?: InputMaybe<SortOrderInput>;
  joined_company_id?: InputMaybe<SortOrderInput>;
  last_name?: InputMaybe<SortOrderInput>;
  last_viewed_notifications?: InputMaybe<SortOrderInput>;
  libphonenumber?: InputMaybe<SortOrderInput>;
  newsletter?: InputMaybe<SortOrder>;
  phone_number?: InputMaybe<SortOrderInput>;
  photo_url?: InputMaybe<SortOrderInput>;
  pronoun?: InputMaybe<SortOrderInput>;
  provider?: InputMaybe<SortOrderInput>;
  provider_id?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  stripe_customer_id?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  Order?: InputMaybe<OrderOrderByRelationAggregateInput>;
  Refund?: InputMaybe<RefundOrderByRelationAggregateInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodOrderByRelationAggregateInput>;
  Transaction?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  appointment_services?: InputMaybe<AppointmentServiceOrderByRelationAggregateInput>;
  appointments?: InputMaybe<AppointmentOrderByRelationAggregateInput>;
  artist?: InputMaybe<ArtistOrderByWithRelationInput>;
  availability?: InputMaybe<AvailabilityOrderByRelationAggregateInput>;
  birthday?: InputMaybe<SortOrderInput>;
  booked_appointments?: InputMaybe<AppointmentOrderByRelationAggregateInput>;
  cognito_id?: InputMaybe<SortOrderInput>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrderInput>;
  favorite_artists?: InputMaybe<FavoriteArtistOrderByRelationAggregateInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationOrderByRelationAggregateInput>;
  first_name?: InputMaybe<SortOrderInput>;
  full_name?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  impersonate_company_id?: InputMaybe<SortOrderInput>;
  impersonate_company_name?: InputMaybe<SortOrderInput>;
  impersonate_id?: InputMaybe<SortOrderInput>;
  impersonate_name?: InputMaybe<SortOrderInput>;
  joined_company?: InputMaybe<CompanyOrderByWithRelationInput>;
  joined_company_id?: InputMaybe<SortOrderInput>;
  last_name?: InputMaybe<SortOrderInput>;
  last_viewed_notifications?: InputMaybe<SortOrderInput>;
  libphonenumber?: InputMaybe<SortOrderInput>;
  newsletter?: InputMaybe<SortOrder>;
  notifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  phone_number?: InputMaybe<SortOrderInput>;
  photo_url?: InputMaybe<SortOrderInput>;
  pronoun?: InputMaybe<SortOrderInput>;
  provider?: InputMaybe<SortOrderInput>;
  provider_id?: InputMaybe<SortOrderInput>;
  reminder_settings?: InputMaybe<ReminderSettingOrderByRelationAggregateInput>;
  role?: InputMaybe<RoleOrderByWithRelationInput>;
  status?: InputMaybe<SortOrder>;
  stripe_customer_id?: InputMaybe<SortOrderInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  Birthday = "birthday",
  CognitoId = "cognito_id",
  CreatedAt = "created_at",
  Email = "email",
  FirstName = "first_name",
  FullName = "full_name",
  Id = "id",
  ImpersonateCompanyId = "impersonate_company_id",
  ImpersonateCompanyName = "impersonate_company_name",
  ImpersonateId = "impersonate_id",
  ImpersonateName = "impersonate_name",
  JoinedCompanyId = "joined_company_id",
  LastName = "last_name",
  LastViewedNotifications = "last_viewed_notifications",
  Libphonenumber = "libphonenumber",
  Newsletter = "newsletter",
  PhoneNumber = "phone_number",
  PhotoUrl = "photo_url",
  Pronoun = "pronoun",
  Provider = "provider",
  ProviderId = "provider_id",
  Status = "status",
  StripeCustomerId = "stripe_customer_id",
  UpdatedAt = "updated_at"
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  birthday?: InputMaybe<DateTimeNullableFilter>;
  cognito_id?: InputMaybe<UuidNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  first_name?: InputMaybe<StringNullableFilter>;
  full_name?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  impersonate_company_id?: InputMaybe<UuidNullableFilter>;
  impersonate_company_name?: InputMaybe<StringNullableFilter>;
  impersonate_id?: InputMaybe<UuidNullableFilter>;
  impersonate_name?: InputMaybe<StringNullableFilter>;
  joined_company_id?: InputMaybe<UuidNullableFilter>;
  last_name?: InputMaybe<StringNullableFilter>;
  last_viewed_notifications?: InputMaybe<DateTimeNullableFilter>;
  libphonenumber?: InputMaybe<StringNullableFilter>;
  newsletter?: InputMaybe<BoolFilter>;
  phone_number?: InputMaybe<StringNullableFilter>;
  photo_url?: InputMaybe<StringNullableFilter>;
  pronoun?: InputMaybe<StringNullableFilter>;
  provider?: InputMaybe<StringNullableFilter>;
  provider_id?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<StringFilter>;
  stripe_customer_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  birthday?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  cognito_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  created_at?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringNullableWithAggregatesFilter>;
  first_name?: InputMaybe<StringNullableWithAggregatesFilter>;
  full_name?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  impersonate_company_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  impersonate_company_name?: InputMaybe<StringNullableWithAggregatesFilter>;
  impersonate_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  impersonate_name?: InputMaybe<StringNullableWithAggregatesFilter>;
  joined_company_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  last_name?: InputMaybe<StringNullableWithAggregatesFilter>;
  last_viewed_notifications?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  libphonenumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  newsletter?: InputMaybe<BoolWithAggregatesFilter>;
  phone_number?: InputMaybe<StringNullableWithAggregatesFilter>;
  photo_url?: InputMaybe<StringNullableWithAggregatesFilter>;
  pronoun?: InputMaybe<StringNullableWithAggregatesFilter>;
  provider?: InputMaybe<StringNullableWithAggregatesFilter>;
  provider_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<StringWithAggregatesFilter>;
  stripe_customer_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  updated_at?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type UserUpdateInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutJoined_CompanyInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutJoined_CompanyNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserCreateOrConnectWithoutJoined_CompanyInput>
  >;
  create?: InputMaybe<Array<UserCreateWithoutJoined_CompanyInput>>;
  createMany?: InputMaybe<UserCreateManyJoined_CompanyInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserUpdateWithWhereUniqueWithoutJoined_CompanyInput>
  >;
  updateMany?: InputMaybe<
    Array<UserUpdateManyWithWhereWithoutJoined_CompanyInput>
  >;
  upsert?: InputMaybe<
    Array<UserUpsertWithWhereUniqueWithoutJoined_CompanyInput>
  >;
};

export type UserUpdateOneRequiredWithoutAppointmentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<UserCreateWithoutAppointmentsInput>;
  update?: InputMaybe<UserUpdateWithoutAppointmentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutAppointmentsInput>;
};

export type UserUpdateOneRequiredWithoutAvailabilityNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAvailabilityInput>;
  create?: InputMaybe<UserCreateWithoutAvailabilityInput>;
  update?: InputMaybe<UserUpdateWithoutAvailabilityInput>;
  upsert?: InputMaybe<UserUpsertWithoutAvailabilityInput>;
};

export type UserUpdateOneRequiredWithoutBooked_AppointmentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBooked_AppointmentsInput>;
  create?: InputMaybe<UserCreateWithoutBooked_AppointmentsInput>;
  update?: InputMaybe<UserUpdateWithoutBooked_AppointmentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutBooked_AppointmentsInput>;
};

export type UserUpdateOneRequiredWithoutCompanyNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCompanyInput>;
  create?: InputMaybe<UserCreateWithoutCompanyInput>;
  update?: InputMaybe<UserUpdateWithoutCompanyInput>;
  upsert?: InputMaybe<UserUpsertWithoutCompanyInput>;
};

export type UserUpdateOneRequiredWithoutCompany_DescriptionsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCompany_DescriptionsInput>;
  create?: InputMaybe<UserCreateWithoutCompany_DescriptionsInput>;
  update?: InputMaybe<UserUpdateWithoutCompany_DescriptionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCompany_DescriptionsInput>;
};

export type UserUpdateOneRequiredWithoutFavorite_ArtistsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFavorite_ArtistsInput>;
  create?: InputMaybe<UserCreateWithoutFavorite_ArtistsInput>;
  update?: InputMaybe<UserUpdateWithoutFavorite_ArtistsInput>;
  upsert?: InputMaybe<UserUpsertWithoutFavorite_ArtistsInput>;
};

export type UserUpdateOneRequiredWithoutFavorite_LocationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFavorite_LocationsInput>;
  create?: InputMaybe<UserCreateWithoutFavorite_LocationsInput>;
  update?: InputMaybe<UserUpdateWithoutFavorite_LocationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutFavorite_LocationsInput>;
};

export type UserUpdateOneRequiredWithoutNotificationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutNotificationsInput>;
  update?: InputMaybe<UserUpdateWithoutNotificationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutNotificationsInput>;
};

export type UserUpdateOneRequiredWithoutOrderNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<UserCreateWithoutOrderInput>;
  update?: InputMaybe<UserUpdateWithoutOrderInput>;
  upsert?: InputMaybe<UserUpsertWithoutOrderInput>;
};

export type UserUpdateOneRequiredWithoutRefundNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRefundInput>;
  create?: InputMaybe<UserCreateWithoutRefundInput>;
  update?: InputMaybe<UserUpdateWithoutRefundInput>;
  upsert?: InputMaybe<UserUpsertWithoutRefundInput>;
};

export type UserUpdateOneRequiredWithoutReminder_SettingsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReminder_SettingsInput>;
  create?: InputMaybe<UserCreateWithoutReminder_SettingsInput>;
  update?: InputMaybe<UserUpdateWithoutReminder_SettingsInput>;
  upsert?: InputMaybe<UserUpsertWithoutReminder_SettingsInput>;
};

export type UserUpdateOneRequiredWithoutRoleNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRoleInput>;
  create?: InputMaybe<UserCreateWithoutRoleInput>;
  update?: InputMaybe<UserUpdateWithoutRoleInput>;
  upsert?: InputMaybe<UserUpsertWithoutRoleInput>;
};

export type UserUpdateOneRequiredWithoutStripePaymentMethodsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutStripePaymentMethodsInput>;
  create?: InputMaybe<UserCreateWithoutStripePaymentMethodsInput>;
  update?: InputMaybe<UserUpdateWithoutStripePaymentMethodsInput>;
  upsert?: InputMaybe<UserUpsertWithoutStripePaymentMethodsInput>;
};

export type UserUpdateOneRequiredWithoutTransactionNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<UserCreateWithoutTransactionInput>;
  update?: InputMaybe<UserUpdateWithoutTransactionInput>;
  upsert?: InputMaybe<UserUpsertWithoutTransactionInput>;
};

export type UserUpdateOneWithoutAppointment_ServicesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAppointment_ServicesInput>;
  create?: InputMaybe<UserCreateWithoutAppointment_ServicesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<UserUpdateWithoutAppointment_ServicesInput>;
  upsert?: InputMaybe<UserUpsertWithoutAppointment_ServicesInput>;
};

export type UserUpdateOneWithoutArtistNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutArtistInput>;
  create?: InputMaybe<UserCreateWithoutArtistInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<UserUpdateWithoutArtistInput>;
  upsert?: InputMaybe<UserUpsertWithoutArtistInput>;
};

export type UserUpdateWithWhereUniqueWithoutJoined_CompanyInput = {
  data: UserUpdateWithoutJoined_CompanyInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutAppointment_ServicesInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutAppointmentsInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutArtistInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutAvailabilityInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutBooked_AppointmentsInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCompanyInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCompany_DescriptionsInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFavorite_ArtistsInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFavorite_LocationsInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutJoined_CompanyInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutNotificationsInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutOrderInput = {
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutRefundInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutReminder_SettingsInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutRoleInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutStripePaymentMethodsInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  Transaction?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTransactionInput = {
  Order?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  Refund?: InputMaybe<RefundUpdateManyWithoutUserNestedInput>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutUserNestedInput>;
  appointment_services?: InputMaybe<AppointmentServiceUpdateManyWithoutUserNestedInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutUserNestedInput>;
  artist?: InputMaybe<ArtistUpdateOneWithoutUserNestedInput>;
  availability?: InputMaybe<AvailabilityUpdateManyWithoutInserted_ByNestedInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  booked_appointments?: InputMaybe<AppointmentUpdateManyWithoutInserted_ByNestedInput>;
  cognito_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<CompanyUpdateOneWithoutOwnerNestedInput>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionUpdateManyWithoutUserNestedInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favorite_artists?: InputMaybe<FavoriteArtistUpdateManyWithoutUserNestedInput>;
  favorite_locations?: InputMaybe<UserFavoriteLocationUpdateManyWithoutUserNestedInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  impersonate_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  impersonate_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joined_company?: InputMaybe<CompanyUpdateOneWithoutUsersNestedInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_viewed_notifications?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  libphonenumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  newsletter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  photo_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pronoun?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reminder_settings?: InputMaybe<ReminderSettingUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<RoleUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripe_customer_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpsertWithWhereUniqueWithoutJoined_CompanyInput = {
  create: UserCreateWithoutJoined_CompanyInput;
  update: UserUpdateWithoutJoined_CompanyInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutAppointment_ServicesInput = {
  create: UserCreateWithoutAppointment_ServicesInput;
  update: UserUpdateWithoutAppointment_ServicesInput;
};

export type UserUpsertWithoutAppointmentsInput = {
  create: UserCreateWithoutAppointmentsInput;
  update: UserUpdateWithoutAppointmentsInput;
};

export type UserUpsertWithoutArtistInput = {
  create: UserCreateWithoutArtistInput;
  update: UserUpdateWithoutArtistInput;
};

export type UserUpsertWithoutAvailabilityInput = {
  create: UserCreateWithoutAvailabilityInput;
  update: UserUpdateWithoutAvailabilityInput;
};

export type UserUpsertWithoutBooked_AppointmentsInput = {
  create: UserCreateWithoutBooked_AppointmentsInput;
  update: UserUpdateWithoutBooked_AppointmentsInput;
};

export type UserUpsertWithoutCompanyInput = {
  create: UserCreateWithoutCompanyInput;
  update: UserUpdateWithoutCompanyInput;
};

export type UserUpsertWithoutCompany_DescriptionsInput = {
  create: UserCreateWithoutCompany_DescriptionsInput;
  update: UserUpdateWithoutCompany_DescriptionsInput;
};

export type UserUpsertWithoutFavorite_ArtistsInput = {
  create: UserCreateWithoutFavorite_ArtistsInput;
  update: UserUpdateWithoutFavorite_ArtistsInput;
};

export type UserUpsertWithoutFavorite_LocationsInput = {
  create: UserCreateWithoutFavorite_LocationsInput;
  update: UserUpdateWithoutFavorite_LocationsInput;
};

export type UserUpsertWithoutNotificationsInput = {
  create: UserCreateWithoutNotificationsInput;
  update: UserUpdateWithoutNotificationsInput;
};

export type UserUpsertWithoutOrderInput = {
  create: UserCreateWithoutOrderInput;
  update: UserUpdateWithoutOrderInput;
};

export type UserUpsertWithoutRefundInput = {
  create: UserCreateWithoutRefundInput;
  update: UserUpdateWithoutRefundInput;
};

export type UserUpsertWithoutReminder_SettingsInput = {
  create: UserCreateWithoutReminder_SettingsInput;
  update: UserUpdateWithoutReminder_SettingsInput;
};

export type UserUpsertWithoutRoleInput = {
  create: UserCreateWithoutRoleInput;
  update: UserUpdateWithoutRoleInput;
};

export type UserUpsertWithoutStripePaymentMethodsInput = {
  create: UserCreateWithoutStripePaymentMethodsInput;
  update: UserUpdateWithoutStripePaymentMethodsInput;
};

export type UserUpsertWithoutTransactionInput = {
  create: UserCreateWithoutTransactionInput;
  update: UserUpdateWithoutTransactionInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  Order?: InputMaybe<OrderListRelationFilter>;
  Refund?: InputMaybe<RefundListRelationFilter>;
  StripePaymentMethods?: InputMaybe<StripePaymentMethodListRelationFilter>;
  Transaction?: InputMaybe<TransactionListRelationFilter>;
  appointment_services?: InputMaybe<AppointmentServiceListRelationFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  artist?: InputMaybe<ArtistRelationFilter>;
  availability?: InputMaybe<AvailabilityListRelationFilter>;
  birthday?: InputMaybe<DateTimeNullableFilter>;
  booked_appointments?: InputMaybe<AppointmentListRelationFilter>;
  cognito_id?: InputMaybe<UuidNullableFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  company_descriptions?: InputMaybe<CompanyUserDescriptionListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  favorite_artists?: InputMaybe<FavoriteArtistListRelationFilter>;
  favorite_locations?: InputMaybe<UserFavoriteLocationListRelationFilter>;
  first_name?: InputMaybe<StringNullableFilter>;
  full_name?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  impersonate_company_id?: InputMaybe<UuidNullableFilter>;
  impersonate_company_name?: InputMaybe<StringNullableFilter>;
  impersonate_id?: InputMaybe<UuidNullableFilter>;
  impersonate_name?: InputMaybe<StringNullableFilter>;
  joined_company?: InputMaybe<CompanyRelationFilter>;
  joined_company_id?: InputMaybe<UuidNullableFilter>;
  last_name?: InputMaybe<StringNullableFilter>;
  last_viewed_notifications?: InputMaybe<DateTimeNullableFilter>;
  libphonenumber?: InputMaybe<StringNullableFilter>;
  newsletter?: InputMaybe<BoolFilter>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  phone_number?: InputMaybe<StringNullableFilter>;
  photo_url?: InputMaybe<StringNullableFilter>;
  pronoun?: InputMaybe<StringNullableFilter>;
  provider?: InputMaybe<StringNullableFilter>;
  provider_id?: InputMaybe<StringNullableFilter>;
  reminder_settings?: InputMaybe<ReminderSettingListRelationFilter>;
  role?: InputMaybe<RoleRelationFilter>;
  status?: InputMaybe<StringFilter>;
  stripe_customer_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  cognito_id?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  email_provider_provider_id?: InputMaybe<UserEmailProviderProvider_IdCompoundUniqueInput>;
  id?: InputMaybe<Scalars["String"]>;
};

export type UuidFilter = {
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type UuidNullableFilter = {
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type UuidNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type UuidWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};
