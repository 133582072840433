// DraggableRadiusMap.tsx
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  GoogleMap,
  Circle,
  Marker,
  useLoadScript,
  InfoWindow
} from "@react-google-maps/api";
import useResponsive from "hooks/useResponsive";
import pinIcon from "assets/pin.png";
import { Link } from "react-router-dom";
import { ImageElement } from "elements/image/Image";

interface DraggableRadiusMapProps {
  onCircleChange?: (info: { lat: number; lng: number; radius: number }) => void;
  initialCenter?: { lat: number; lng: number };
  initialRadius?: number;
  style?: React.CSSProperties;
  allLocationsData: any;
}

const defaultCenter = { lat: 37.7749, lng: -122.4194 };
const defaultRadius = 3000;

const DraggableRadiusMap: React.FC<DraggableRadiusMapProps> = ({
  onCircleChange,
  initialCenter = defaultCenter,
  initialRadius = defaultRadius,
  style,
  allLocationsData
}) => {
  const [libraries] = useState(["geometry"]);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCjpqehe9pqE8Hk6BnqlI4UNy1WcVpFEO8",
    libraries: libraries as any
  });
  const { isMobile } = useResponsive();

  const [center, setCenter] = useState(initialCenter);
  const [radius, setRadius] = useState(initialRadius);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);

  const circleRef = useRef<google.maps.Circle | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const circleOptions = useMemo<google.maps.CircleOptions>(
    () => ({
      strokeColor: "#B8722A",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#B8722A",
      fillOpacity: 0.2,
      editable: true,
      draggable: true
    }),
    []
  );

  const onCircleLoad = useCallback((circle: google.maps.Circle) => {
    circleRef.current = circle;
  }, []);

  const handleRecenterAndZoom = useCallback(() => {
    if (mapRef.current && circleRef.current) {
      const bounds = new google.maps.LatLngBounds();

      bounds.extend(center);

      const earthRadius = 6378137; // Earth’s radius in meters
      const latOffset = (radius / earthRadius) * (180 / Math.PI);
      const lngOffset =
        (radius / (earthRadius * Math.cos((Math.PI * center.lat) / 180))) *
        (180 / Math.PI);

      const southwest = new google.maps.LatLng(
        center.lat - latOffset,
        center.lng - lngOffset
      );
      const northeast = new google.maps.LatLng(
        center.lat + latOffset,
        center.lng + lngOffset
      );

      bounds.extend(southwest);
      bounds.extend(northeast);

      mapRef.current.fitBounds(bounds);
    }
  }, [center, radius]);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    setTimeout(() => {
      handleRecenterAndZoom();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCenterChanged = useCallback(
    (dragEnd?: string) => {
      if (isDragging && !dragEnd) return;
      if (!circleRef.current) return;
      const newCenter = circleRef.current.getCenter();
      if (!newCenter) return;

      const newLat = newCenter.lat();
      const newLng = newCenter.lng();

      if (newLat !== center.lat || newLng !== center.lng) {
        setCenter({ lat: newLat, lng: newLng });
      }
    },
    [center, isDragging]
  );

  const onRadiusChanged = useCallback(
    (dragEnd?: string) => {
      if (isDragging && !dragEnd) return;
      if (!circleRef.current) return;
      const newRadius = circleRef.current.getRadius();
      if (newRadius && newRadius !== radius) {
        setRadius(newRadius);
      }
    },
    [radius, isDragging]
  );

  useEffect(() => {
    onCircleChange?.({ lat: center.lat, lng: center.lng, radius });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, radius]);

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <div className="w-full h-full">
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: isMobile ? "400px" : "calc(100dvh - 196px)",
          margin: "8px 0px 0px 0px",
          ...style
        }}
        center={center}
        zoom={12}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: true,
          streetViewControl: false,
          gestureHandling: "greedy"
          // styles: [
          //   {
          //     featureType: "poi",
          //     elementType: "labels",
          //     stylers: [
          //       { visibility: "off" }
          //     ]
          //   },
          //   {
          //     featureType: "transit",
          //     elementType: "labels",
          //     stylers: [
          //       { visibility: "off" }
          //     ]
          //   },
          //   {
          //     featureType: "road",
          //     elementType: "labels",
          //     stylers: [
          //       { visibility: "on" }
          //     ]
          //   }
          // ]
        }}
        onLoad={onMapLoad}
      >
        <Circle
          center={center}
          radius={radius}
          onLoad={onCircleLoad}
          onCenterChanged={onCenterChanged}
          onRadiusChanged={onRadiusChanged}
          options={circleOptions}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => {
            setIsDragging(false);
            onCenterChanged("dragEnd");
            onRadiusChanged("dragEnd");
          }}
        />
        {allLocationsData?.locations?.map((location: any) => (
          <Marker
            key={location.id}
            position={{
              lat: location.address_latitude,
              lng: location.address_longitude
            }}
            title={location.name}
            clickable
            onClick={() => setSelectedLocation(location)}
            icon={{
              url: pinIcon,
              scaledSize: new google.maps.Size(25, 30)
            }}
          />
        ))}
        {selectedLocation && (
          <InfoWindow
            position={{
              lat: selectedLocation?.address_latitude,
              lng: selectedLocation?.address_longitude
            }}
            onCloseClick={() => setSelectedLocation(null)} // Close InfoWindow on close
          >
            <div className="flex gap-4 max-w-[375px]">
              <div className="min-w-[100px] max-w-[100px] box-border">
                <ImageElement
                  className="h-full w-full object-cover"
                  src={selectedLocation.cover_photo_url}
                  alt={selectedLocation.name}
                  aspectRatio="100:140"
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-[20px] font-[500]">
                  {selectedLocation?.name}
                </div>
                <div className="text-[14px]">
                  {selectedLocation?.address_display}
                </div>
                <Link
                  to={`/company/${selectedLocation?.company?.slug}/location/${selectedLocation?.slug}`}
                >
                  <div className="text-tertiary text-[16px]">View Salon</div>
                </Link>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      <div
        aria-hidden
        onClick={handleRecenterAndZoom}
        className="absolute left-3 w-10 h-10 flex justify-center items-center
         bg-white p-1 cursor-pointer"
        style={{
          top: isMobile ? "154px" : "82px",
          boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px"
        }}
      >
        <div className="border-2 border-[#B8722A] bg-[rgba(184,114,42,0.2)] w-[30px] h-[30px] rounded-[50%]" />
      </div>
    </div>
  );
};

export default DraggableRadiusMap;
