import "./Navigation.scss";
import { IconButton, TIconButtonSize } from "elements/icon-button/IconButton";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { joinClassNames } from "utils/join-class-names";

interface INavigationButtonConfig {
  color?: COLORS;
  className?: string;
  disabled?: boolean;
  size?: TIconButtonSize;
  variant?: VARIANTS;
  onClick?: () => void;
}

export interface INavigation {
  text?: string;
  leftButtonConfig?: INavigationButtonConfig;
  rightButtonConfig?: INavigationButtonConfig;
  className?: string;
  enableRadiusFilter?: boolean;
}

const defaultButtonConfig = {
  color: COLORS.PRIMARY
};

const Navigation = ({
  text,
  leftButtonConfig,
  rightButtonConfig,
  className = "",
  enableRadiusFilter
}: INavigation): JSX.Element => {
  return (
    <div className={joinClassNames("navigation", className)}>
      <div
        className={`flex justify-center items-center gap-1 px-1 rounded-md ${
          enableRadiusFilter ? "bg-white" : ""
        }`}
        style={{
          boxShadow: enableRadiusFilter
            ? "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px"
            : ""
        }}
      >
        <IconButton
          iconName="arrow_left"
          color={
            leftButtonConfig?.color
              ? leftButtonConfig?.color
              : defaultButtonConfig.color
          }
          variant={leftButtonConfig?.variant}
          size={leftButtonConfig?.size}
          disabled={leftButtonConfig?.disabled}
          className={leftButtonConfig?.className}
          onClick={leftButtonConfig?.onClick}
        />
        {text}
        <IconButton
          iconName="arrow_right"
          color={
            rightButtonConfig?.color
              ? rightButtonConfig?.color
              : defaultButtonConfig.color
          }
          variant={rightButtonConfig?.variant}
          size={rightButtonConfig?.size}
          disabled={rightButtonConfig?.disabled}
          className={rightButtonConfig?.className}
          onClick={rightButtonConfig?.onClick}
        />
      </div>
    </div>
  );
};

export default Navigation;
