import "./SelectWithFilter.scss";
import React, { useEffect, useRef, useState } from "react";
import { SvgIcon } from "../svg-icon/svg-icon";
import { bemElement, bemModifier } from "../../utils/bem-class-names";
import { joinClassNames } from "../../utils/join-class-names";

export interface IOptionData {
  label: string;
  value: string | number;
}

export interface ISelectWithFilterData {
  value?: string | number;
  label?: string;
  options?: IOptionData[];
  onChange?: (value: string | number | undefined) => void;
  className?: string;
  cyId?: string;
  errorMsg?: string;
  withoutErrorAndHint?: boolean;
}

const baseClassName = "select";
const bem = bemElement(baseClassName);

const SelectWithFilter = ({
  value = "",
  label = "",
  options = [],
  onChange,
  className = "",
  cyId = "",
  errorMsg,
  withoutErrorAndHint = false
}: ISelectWithFilterData) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterTerm, setFilterTerm] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLInputElement>(null);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().startsWith(filterTerm.toLowerCase())
  );

  useEffect(() => {
    if (isOpen) {
      filterRef.current?.focus();
    }
  }, [isOpen]);

  return (
    <div
      className={joinClassNames(
        bemModifier(baseClassName, {
          open: isOpen,
          error: errorMsg
        }),
        className
      )}
    >
      <div
        className={bem("wrapper")}
        onClick={() => setIsOpen((prevState) => !prevState)}
        data-cy={`${cyId}-wrapper`}
        aria-hidden
        ref={ref}
      >
        <div className={bem("left-side")}>
          <div className={bem("label")}>{value === "" ? "" : label}</div>
          <div className={bem("selected")}>
            <div data-cy={`${cyId}-selected`}>
              {value === "" ? label : value}
            </div>
            {isOpen && (
              <div className={`${bem("options")}`}>
                <div className="w-full">
                  <input
                    type="text"
                    ref={filterRef}
                    className="w-full focus:ring-primary focus:border-primary !border-0 !ring-0 !border-b"
                    placeholder="Type to filter..."
                    value={filterTerm}
                    onChange={(e) => setFilterTerm(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <div className="max-h-[250px] overflow-auto">
                  {filteredOptions.map((option: IOptionData) => (
                    <div
                      className={bem("option")}
                      key={option.value}
                      data-cy={cyId}
                      onClick={() => onChange && onChange(option.value)}
                      aria-hidden
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={bem("right-side")}>
          {isOpen ? (
            <SvgIcon name="arrow_top" />
          ) : (
            <SvgIcon name="arrow_bottom" />
          )}
        </div>
      </div>
      {!withoutErrorAndHint && (
        <div className={bem("error-msg-container")}>
          {errorMsg && <span className={bem("error-msg")}>{errorMsg}</span>}
        </div>
      )}
    </div>
  );
};

export default SelectWithFilter;
